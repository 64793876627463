<div class="row" *ngIf="spotMediaDetail" id="media-details">

  <img src="{{ spotMediaDetail.thumbnailUrl }}"
       alt="Thumbnail"
       (load)="getThumbnailDimensions($event)"
       style="display: none;"
       #hiddenImage />

  <ng-container>
    <div>
      <!-- <div class="col-8"> -->
      <div class="tylie-panel tylie-panel--primary">
        <div class="tylie-panel__header">
          <h4 class="tylie-panel__title">
            {{spotMediaDetail.title}}
          </h4>
        </div>

        <div class="tylie-panel__body">
          <div class="row">
            <div class="col-8">
              <div class="tylie-v-player video-overlay" *ngIf="proxyUrl">
                <div class="tylie-v-player__body" style="z-index: 100">
                  <div class="safe-overlay-bg-4-3" [style.height]="overyLayHeight + 'px'" *ngIf="isOver43Overlay == true"></div>
                  <div class="safe-overlay-bg-16-9" [style.height]="overyLayHeight + 'px'" *ngIf="isOver169Overlay == true"></div>
                  <video #videoPlayerP class="video-js vjs-default-skin vjs-big-play-centered" controls preload="auto">
                    Your browser does not support the video tag.
                  </video>
                  <div class="smpte-timecode">{{ smpteTimecode }}</div>
                </div>
                <div class="tylie-v-player__footer">
                  <div *ngIf="is430verlayRequired || is169OverlayRequired">
                    <dx-select-box [(value)]="selectedOverlay"
                                   [dataSource]="overlayOptions"
                                   displayExpr="name"
                                   valueExpr="value"
                                   placeholder="OverLays"
                                   showClearButton="true"
                                   (onValueChanged)="toggleOverlay($event.value)">
                    </dx-select-box>
                  </div>
                </div>
              </div>


              <div class="tylie-v-player video-overlay" *ngIf="!proxyUrl">
                <img class="tylie-v-player__fallback-img" width="100%" [src]="spotMediaDetail.thumbnailUrl" />
              </div>
            </div>

            <div [ngClass]="{'col-md-12': isPopup == true, 'col-md-4 justify-content-end':isPopup == false }">
              <!-- <div class="col-4"> -->
              <form [formGroup]="spotEditRequest" #f="ngForm" (ngSubmit)="onSaveEdit(spotEditRequest)">
                <div class="tylie-panel tylie-panel--secondary" *ngIf="spotMediaDetail.rightsManagementDetail != null && spotMediaDetail.isOperationsUser == false">
                  <div class="tylie-panel__header">
                    <h4 class="tylie-panel__title">
                      Talent Rights
                    </h4>
                  </div>
                  <div class="tylie-panel__body">
                    <p class="p-small" *ngIf="spotMediaDetail.rightsManagementDetail.rmDetails.length == 0">
                      <label class="tylie-data__lbl">Project Name:</label><span class="tylie-data__txt">{{spotMediaDetail.rightsManagementDetail.projectName}}</span>
                      <span *ngIf="spotMediaDetail.rightsManagementDetail.rmDetails.length == 0">
                        No Terms of Use Available.
                      </span>
                    </p>

                    <ng-container *ngFor="let rm of spotMediaDetail.rightsManagementDetail.rmDetails; index as i">
                      <div>
                        <div class="tylie-data tylie-data--c-block">
                          {{rm.elementName}}
                        </div>

                        <div style="text-align:center;" *ngIf="rm.isCurrent" class="tyl-talentrights-green-status"><Span class="tyl-vertical-align tyl-center">CURRENT</Span></div>
                        <div style="text-align:center;" *ngIf="rm.isExpired" class="tyl-talentrights-red-status">EXPIRED</div>
                        <div style="text-align:center;" *ngIf="rm.isExpiringSoon" class="tyl-talentrights-orange-status">EXPIRING SOON</div>
                        <br />
                        <div class="tylie-data tylie-data--c-block">
                          <label class="tylie-data__lbl">Type: </label> <span class="tylie-data__txt">{{rm.elementTypeDesc}}</span>
                        </div>
                        <div class="tylie-data tylie-data--c-block">
                          <label class="tylie-data__lbl">Territory: </label> <span class="tylie-data__txt">{{rm.territory}}</span>
                        </div>
                        <div class="tylie-data tylie-data--c-block">
                          <span [ngClass]="{'error': rm.isAlertRequired == true, 'black': rm.isAlertRequired == false }">Status: </span> <span [ngClass]="{'error tylie-data__txt': rm.isAlertRequired == true, 'tylie-data__txt': rm.isAlertRequired == false }">{{rm.displayStatus}}</span>
                        </div>
                      </div>
                      <div class="tyl-padding-top-1em" *ngIf="i < spotMediaDetail.rightsManagementDetail.rmDetails.length - 1">
                        <hr>
                      </div>
                    </ng-container>
                  </div>
                </div>


                <div class="tylie-panel tylie-panel--secondary">
                  <div class="tylie-panel__header">
                    <h4 class="tylie-panel__title">
                      Asset Specs
                      <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon"
                              [ngClass]="{'collapsed': spotMediaDetail.isOperationsUser == false}" data-bs-toggle="collapse" data-bs-target="#asset-specs" aria-expanded="true" aria-controls="collapseOne">
                        SHOW
                        <span class="tylie-button__icon e">
                          <icon-arrow-simple-down height="12px" width="12px" color="#1e90ff"></icon-arrow-simple-down>
                        </span>
                      </button>
                    </h4>
                  </div>

                  <div class="tylie-panel__body" [ngClass]="spotMediaDetail.isOperationsUser?'collapse':'collapse show'" id="asset-specs">
                    <div class="tylie-data tylie-data--c-block">
                      <label class="tylie-data__lbl">Video:</label><span class="tylie-data__txt">{{spotMediaDetail.mediaSpecs.video}}</span>
                    </div>
                    <div class="tylie-data tylie-data--c-block">
                      <label class="tylie-data__lbl">Audio:</label><span class="tylie-data__txt">{{spotMediaDetail.mediaSpecs.audio}}</span>
                    </div>
                    <div class="tylie-data tylie-data--c-block">
                      <label class="tylie-data__lbl">Scan Type:</label><span class="tylie-data__txt">{{spotMediaDetail.mediaSpecs.scanType}}</span>
                    </div>
                    <div class="tylie-data tylie-data--c-block">
                      <label class="tylie-data__lbl">Asset Size:</label><span class="tylie-data__txt">{{spotMediaDetail.mediaSpecs.mediaSize}}</span>
                    </div>
                    <div class="tylie-data tylie-data--c-block">
                      <label class="tylie-data__lbl">Asset Type:</label><span class="tylie-data__txt">{{spotMediaDetail.mediaSpecs.mediaType}}</span>
                    </div>
                  </div>
                </div>

                <div class="tylie-panel tylie-panel--secondary">
                  <div class="tylie-panel__header">
                    <h4 class="tylie-panel__title">
                      Technical Specs
                      <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon"
                              [ngClass]="{'collapsed': spotMediaDetail.isOperationsUser == false}" data-bs-toggle="collapse" data-bs-target="#tech-specs" aria-expanded="true" aria-controls="collapseOne">
                        SHOW
                        <span class="tylie-button__icon e">
                          <icon-arrow-simple-down height="12px" width="12px" color="#1e90ff"></icon-arrow-simple-down>
                        </span>
                      </button>
                    </h4>
                  </div>
                  <div class="tylie-panel__body" [ngClass]="spotMediaDetail.isOperationsUser?'collapse':'collapse show'" id="tech-specs">
                    <div class="d-flex justify-content-end mb-2">
                      <button type="button" secured [accessControlListComponent]="[this.userRoles.getMediaEditorRoleName()]" *ngIf="editMode == false && spotMediaDetail.isTechSpecEditAllowed == true && isPopup == false" (click)="onEdit()" class="tylie-button tylie-button--xs tylie-button--link">EDIT</button>
                      <button type="button" *ngIf="editMode == true && spotMediaDetail.isTechSpecEditAllowed == true && isPopup == false" (click)="f.ngSubmit.emit();" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon me-3"><span class="tylie-button__icon"><icon-save color="#1e90ff"></icon-save></span>Save</button>
                      <button type="button" *ngIf="editMode == true && spotMediaDetail.isTechSpecEditAllowed == true && isPopup == false" (click)="onCancelEdit()" class="tylie-button tylie-button--xs tylie-button--link">Cancel</button>
                    </div>
                    <div [ngClass]="{'d-flex justify-content-between align-items-center': editMode == true, 'tylie-data tylie-data--c-block': editMode == false}">
                      <label class="tylie-data__lbl">Ad-ID:</label> <span class="tylie-data__txt" *ngIf="editMode == false">{{spotMediaDetail.adId}}</span>
                      <input class="tylie-text tylie-text--sm form-control mb-2" [ngStyle]="{ 'width': '60%'}" *ngIf="editMode == true" type="text" [ngClass]="{'error': (editMode == true && spotEditRequest.controls['adId'].errors)}" name="title" formControlName="adId">
                    </div>
                    <div [ngClass]="{'d-flex justify-content-between align-items-center': editMode == true, 'tylie-data tylie-data--c-block': editMode == false}">
                      <label class="tylie-data__lbl">Title:</label> <span class="tylie-data__txt" *ngIf="editMode == false">{{spotMediaDetail.title}}</span>
                      <input class="tylie-text tylie-text--sm form-control mb-2" [ngStyle]="{ 'width': '60%'}" *ngIf="editMode == true" type="text" [ngClass]="{'error': (editMode == true && spotEditRequest.controls['title'].errors)}" name="title" formControlName="title">
                    </div>
                    <div [ngClass]="{'d-flex justify-content-between align-items-center': editMode == true, 'tylie-data tylie-data--c-block': editMode == false}">
                      <label class="tylie-data__lbl">Client:</label>
                      <span *ngIf="editMode == false" class="tylie-data__txt">{{spotMediaDetail.client}}</span>
                      <select class="tylie-select tylie-select--sm form-control mb-2" [ngStyle]="{ 'width': '60%'}" *ngIf="editMode == true" formControlName="client" name="client">
                        <option *ngFor="let c of clientDataSource" [value]="c.id">{{c.name}}</option>
                      </select>
                    </div>
                    <div [ngClass]="{'d-flex justify-content-between align-items-center': editMode == true, 'tylie-data tylie-data--c-block': editMode == false}">
                      <label class="tylie-data__lbl">Brand:</label>
                      <span *ngIf="editMode == false || isClientChanged == true" class="tylie-data__txt">{{spotMediaDetail.brand}}</span>
                      <select class="tylie-select tylie-select--sm form-control mb-2" [ngStyle]="{ 'width': '60%'}" *ngIf="editMode == true && isClientChanged == false" formControlName="brand" name="brand" (ngModelChange)="onBrandValueChanged($event)">
                        <option *ngFor="let c of brandDataSource" [value]="c.id">{{c.name}}</option>
                      </select>
                    </div>

                    <div [ngClass]="{'d-flex justify-content-between align-items-center': editMode == true, 'tylie-data tylie-data--c-block': editMode == false}">
                      <label class="tylie-data__lbl">Product:</label>
                      <span *ngIf="editMode == false || isClientChanged == true" class="tylie-data__txt">{{spotMediaDetail.product}}</span>
                      <select class="tylie-select tylie-select--sm form-control mb-2" [ngStyle]="{ 'width': '60%'}" *ngIf="editMode == true && isClientChanged == false" formControlName="product" name="product" [ngClass]="{error: editMode && spotMediaDetail.isProductNotSelected && spotEditRequest.controls['product'].value == 0}">
                        <option value="0">--select--</option>
                        <option *ngFor="let e of productDataSource" [value]="e.id">{{e.name}}</option>
                      </select>
                    </div>
                    <div [ngClass]="{'d-flex justify-content-between align-items-center': editMode == true, 'tylie-data tylie-data--c-block': editMode == false}">
                      <label class="tylie-data__lbl">Editorial House:</label>
                      <span *ngIf="editMode == false" class="tylie-data__txt">{{spotMediaDetail.editorialHouse}}</span>
                      <select class="tylie-select tylie-select--sm form-control mb-2" [ngStyle]="{ 'width': '60%'}" *ngIf="editMode == true" formControlName="editorialHouse" name="editorialHouse" (ngModelChange)="onEditorialHouseValueChanged($event)">
                        <option value="0">--select--</option>
                        <option *ngFor="let e of editorialHouseDataSource" [value]="e.id">{{e.name}}</option>
                      </select>
                    </div>
                    <div [ngClass]="{'d-flex justify-content-between align-items-center': editMode == true, 'tylie-data tylie-data--c-block': editMode == false}">
                      <label class="tylie-data__lbl">Agency:</label>
                      <span *ngIf="editMode == false" class="tylie-data__txt">{{spotMediaDetail.agency}}</span>
                      <select class="tylie-select tylie-select--sm form-control mb-2" [ngStyle]="{ 'width': '60%'}" *ngIf="editMode == true" formControlName="agency" name="agency">
                        <option value="0">--select--</option>
                        <option *ngFor="let e of agenciesDataSource" [value]="e.id">{{e.name}}</option>
                      </select>
                    </div>
                    <div [ngClass]="{'d-flex justify-content-between align-items-center': editMode == true, 'tylie-data tylie-data--c-block': editMode == false}">
                      <label class="tylie-data__lbl">User Groups:</label>
                      <span *ngIf="editMode == false" class="tylie-data__txt">{{spotMediaDetail.spotUserGroupNames.join(', ')}}</span>
                      <dx-drop-down-box [(value)]="userGrpsValue"
                                        formControlName="userGroups"
                                        id="userGroups"
                                        class="tylie-select form-control"
                                        valueExpr="clientUserGroupId"
                                        displayExpr="displayName"
                                        (onValueChanged)="syncTreeViewSelection()"
                                        [dataSource]="clientUserGroupDataSource"
                                        [ngStyle]="{ 'width': '60%'}"
                                        *ngIf="editMode == true">
                        <div *dxTemplate="let data of 'content'">
                          <dx-tree-view [dataSource]="clientUserGroupDataSource"
                                        dataStructure="plain"
                                        keyExpr="clientUserGroupId"
                                        #treeUserGrps
                                        parentIdExpr="parentId"
                                        selectionMode="multiple"
                                        showCheckBoxesMode="normal"
                                        displayExpr="displayName"
                                        [selectByClick]="true"
                                        (onContentReady)="syncTreeViewSelection($event)"
                                        (onItemSelectionChanged)="treeView_itemSelectionChanged($event)">
                          </dx-tree-view>
                        </div>
                      </dx-drop-down-box>

                      <!--<select class="tylie-select tylie-select--sm form-control mb-2" [ngStyle]="{ 'width': '60%'}" *ngIf="editMode == true" formControlName="agency" name="agency">
              <option value="0">--select--</option>
              <option *ngFor="let c of clientUserGroupDataSource" [value]="c.clientUserGroupId">{{c.displayName}}</option>
            </select>-->
                    </div>
                    <div [ngClass]="{'d-flex justify-content-between align-items-center': editMode == true, 'tylie-data tylie-data--c-block': editMode == false}">
                      <label class="tylie-data__lbl">Format:</label>
                      <span *ngIf="editMode == false && spotMediaDetail.formatSource != ''" class="tylie-data__txt">
                        <img class="formatIcon" [src]="spotMediaDetail.formatSource" />
                      </span>
                      <select class="tylie-select tylie-select--sm form-control mb-2" [ngStyle]="{ 'width': '60%'}" *ngIf="editMode == true" formControlName="format" name="format">
                        <option *ngFor="let f of formatDataSource" [value]="f.id">{{f.name}}</option>
                      </select>
                    </div>
                    <div [ngClass]="{'d-flex justify-content-between align-items-center': editMode == true, 'tylie-data tylie-data--c-block': editMode == false}">
                      <label class="tylie-data__lbl">Aspect Ratio:</label>
                      <span *ngIf="editMode == false" class="tylie-data__txt"> {{spotMediaDetail.aspectRatio}}</span>
                      <select class="tylie-select tylie-select--sm form-control mb-2" [ngStyle]="{ 'width': '60%'}" *ngIf="editMode == true" formControlName="aspectRatio" name="aspectRatio">
                        <option *ngFor="let f of aspectRatioDataSource" [value]="f">{{f}}</option>
                      </select>
                    </div>
                    <div [ngClass]="{'d-flex justify-content-between align-items-center': editMode == true, 'tylie-data tylie-data--c-block': editMode == false}">
                      <label class="tylie-data__lbl">Encoded:</label>
                      <span class="tylie-data__txt" *ngIf="editMode == false">{{spotMediaDetail.encoded}}</span>
                      <input class="tylie-text tylie-text--sm form-control mb-2" [ngStyle]="{ 'width': '60%'}" *ngIf="editMode == true" type="text" name="encoded" formControlName="encoded">
                    </div>
                    <div [ngClass]="{'d-flex justify-content-between align-items-center': editMode == true, 'tylie-data tylie-data--c-block': editMode == false}">
                      <label class="tylie-data__lbl">LKFS:</label> <span class="tylie-data__txt" *ngIf="editMode == false">{{spotMediaDetail.lkfs}}</span>
                      <input class="tylie-text tylie-text--sm form-control mb-2" [ngStyle]="{ 'width': '60%'}" *ngIf="editMode == true" type="text" [ngClass]="{'error': (editMode == true && spotEditRequest.controls['lkfs'].errors)}"
                             name="lkfs" formControlName="lkfs">
                    </div>
                    <div [ngClass]="{'d-flex justify-content-between align-items-center': editMode == true, 'tylie-data tylie-data--c-block': editMode == false}">
                      <label class="tylie-data__lbl">Markers:</label>
                      <span class="tylie-data__txt" *ngIf="editMode == false">
                        <img *ngFor="let iconSrc of spotMediaDetail.markerSources" class="formatIcon" [src]="iconSrc" />
                      </span>
                      <dx-drop-down-box *ngIf="editMode == true"
                                        id="markersDetail"
                                        name="markersDetail"
                                        [(value)]="markerValue"
                                        class="tylie-select tylie-select--sm form-control mb-2"
                                        [ngStyle]="{ 'width': '60%'}"
                                        valueExpr="id"
                                        displayExpr="name"
                                        formControlName="markers"
                                        (onValueChanged)="onMarkerChanged()"
                                        placeholder="Select a value..."
                                        [dataSource]="markerTypesDataSource">
                        <div *dxTemplate="let data of 'content'">
                          <dx-tree-view [dataSource]="markerTypesDataSource"
                                        dataStructure="plain"
                                        keyExpr="id"
                                        displayExpr="name"
                                        #treeMarker
                                        selectionMode="multiple"
                                        showCheckBoxesMode="normal"
                                        (onContentReady)="onMarkerChanged($event)"
                                        (onItemSelectionChanged)="onMarkerTreeChanged($event)"
                                        [selectByClick]="true">
                          </dx-tree-view>
                        </div>
                      </dx-drop-down-box>
                    </div>


                    <!--// validate on save submit  api-->
                    <div [ngClass]="{'d-flex justify-content-between align-items-center': editMode == true, 'tylie-data tylie-data--c-block': editMode == false}">
                      <label class="tylie-data__lbl">Start of Message:</label> <span class="tylie-data__txt" *ngIf="editMode == false">{{spotMediaDetail.startOfMessage}}</span>
                      <input class="tylie-text tylie-text--sm form-control mb-2" [ngStyle]="{ 'width': '60%'}" *ngIf="editMode == true" type="text"
                             [ngClass]="{'error': (editMode == true && spotEditRequest.controls['startOfMessage'].errors)}" name="startOfMessage"
                             formControlName="startOfMessage" [textMask]="{mask: timeCodeMask, guide: false, modelClean: true}">
                    </div>

                    <div [ngClass]="{'d-flex justify-content-between align-items-center': editMode == true, 'tylie-data tylie-data--c-block': editMode == false}">
                      <label class="tylie-data__lbl">End of Message:</label> <span class="tylie-data__txt" *ngIf="editMode == false">{{spotMediaDetail.endOfMessage}}</span>
                      <input class="tylie-text tylie-text--sm form-control mb-2" [ngStyle]="{ 'width': '60%'}" *ngIf="editMode == true" type="text"
                             [ngClass]="{'error': (editMode == true && spotEditRequest.controls['endOfMessage'].errors)}"
                             name="endOfMessage" formControlName="endOfMessage" [textMask]="{mask: timeCodeMask, guide: false, modelClean: true}">
                    </div>
                    <div [ngClass]="{'d-flex justify-content-between align-items-center': editMode == true, 'tylie-data tylie-data--c-block': editMode == false}">
                      <label class="tylie-data__lbl">Expiry Date:</label>
                      <span *ngIf="editMode == false" class="tylie-data__txt">{{spotMediaDetail.expiryDate | date: 'MM/dd/yyyy'}}</span>
                      <input class="tylie-text tylie-text--sm form-control mb-2" [ngStyle]="{ 'width': '60%'}" type="date" formControlName="expiryDate" [value]="spotMediaDetail.expiryDate | date:'Y-m-d'" name="expiryDate" *ngIf="editMode == true">
                    </div>
                    <div [ngClass]="{'d-flex justify-content-between align-items-center': editMode == true, 'tylie-data tylie-data--c-block': editMode == false}">
                      <label class="tylie-data__lbl">Duration:</label> <span *ngIf="editMode == false" class="tylie-data__txt">{{spotMediaDetail.duration}}</span>
                      <input class="tylie-text tylie-text--sm form-control mb-2" [ngStyle]="{ 'width': '60%'}" *ngIf="editMode == true" type="number" name="duration" formControlName="duration">
                    </div>
                    <div class="tylie-data tylie-data--c-block">
                      <label class="tylie-data__lbl">Received Duration:</label> <span class="tylie-data__txt">{{spotMediaDetail.receivedDuration}}</span>
                    </div>
                    <div class="tylie-data tylie-data--c-block">
                      <label class="tylie-data__lbl">File Name:</label> <span class="tylie-data__txt">{{spotMediaDetail.fileName}}</span>
                    </div>
                    <div class="tylie-data tylie-data--c-block">
                      <label class="tylie-data__lbl">Created Date:</label> <span class="tylie-data__txt">{{spotMediaDetail.created | usertimezone: 'MM/DD/YYYY HH:mm:ss A z'}}</span>
                    </div>
                    <div *ngIf="spotMediaDetail.isArchived != null">
                      <div class="tylie-data tylie-data--c-block">
                        <label class="tylie-data__lbl">Archived:</label> <span class="tylie-data__txt">{{spotMediaDetail.isArchived.toString()}}</span>
                      </div>
                      <div class="tylie-data tylie-data--c-block">
                        <label class="tylie-data__lbl">Storage Class:</label> <span class="tylie-data__txt">{{spotMediaDetail.storageClass}}</span>
                      </div>
                    </div>
                    <div *ngIf="spotMediaDetail.isMasterRequiresQC == true">
                      <div class="tylie-data tylie-data--c-block">
                        <label class="tylie-data__lbl">QC Date:</label> <span class="tylie-data__txt">{{spotMediaDetail.qcCompletionDate | usertimezone: 'MM/DD/YYYY HH:mm:ss A z'}}</span>
                      </div>
                      <div class="tylie-data tylie-data--c-block">
                        <label class="tylie-data__lbl">QC By:</label> <span class="tylie-data__txt">{{spotMediaDetail.qcUser}}</span>
                      </div>
                      <div [ngClass]="{'d-flex justify-content-between align-items-center': editMode == true, 'tylie-data tylie-data--c-block': editMode == false}">
                        <label class="tylie-data__lbl">QC Status:</label>
                        <span *ngIf="editMode == false" class="tylie-data__txt">{{spotMediaDetail.qcStatus}}</span>
                        <select class="tylie-select tylie-select--sm form-control mb-2" [ngStyle]="{ 'width': '60%'}" *ngIf="editMode == true" formControlName="qcStatus" #t name="qcStatus" (change)="qcStatusChange(t.value)">
                          <option *ngFor="let q of qcStatusDataSource" [value]="q.id">{{q.name}}</option>
                        </select>
                      </div>
                    </div>
                    <div *ngIf="spotMediaDetail.isPostProductionRequiresApproval == true">
                      <div class="tylie-data tylie-data--c-block">
                        <label class="tylie-data__lbl">Approved Date:</label> <span class="tylie-data__txt">{{spotMediaDetail.approvalCompletedDate | usertimezone: 'MM/DD/YYYY HH:mm:ss A z'}}</span>
                      </div>
                      <div [ngClass]="{'d-flex justify-content-between align-items-center': editMode == true, 'tylie-data tylie-data--c-block': editMode == false}">
                        <label class="tylie-data__lbl">Approval Status:</label>
                        <span class="tylie-data__txt" *ngIf="editMode == false || (editMode == true && spotMediaDetail.enableApprovalRejection == false)">{{spotMediaDetail.approvalStatus}}</span>
                        <select class="tylie-select tylie-select--sm form-control mb-2" [ngStyle]="{ 'width': '60%'}" *ngIf="editMode == true && spotMediaDetail.enableApprovalRejection == true" formControlName="approvalStatus" #t name="approvalStatus" (change)="approvalStatusChange(t.value)">
                          <option *ngFor="let q of approvalStatusDataSource" [value]="q.id">{{q.name}}</option>
                        </select>
                      </div>
                      <div [ngClass]="{'d-flex justify-content-between align-items-center': editMode == true, 'tylie-data tylie-data--c-block': editMode == false}" *ngIf="spotMediaDetail.totalLevels > 0 && !spotMediaDetail.approvalCompletedDate">
                        <label class="tylie-data__lbl">Pending Approval Level:</label>
                        <span class="tylie-data__txt">
                          <a *ngIf="spotMediaDetail.totalLevels > 0" triggers="mouseenter:mouseleave" #approverspop="bs-popover" [popoverContext]="spotMediaDetail" [popover]="approversList">
                            {{spotMediaDetail.currentApproverLevel.toString()}}
                          </a>
                          <a *ngIf="spotMediaDetail.totalLevels == 0">
                            N/A
                          </a>
                        </span>
                      </div>
                      <div class="tylie-data tylie-data--c-block" *ngIf="spotMediaDetail.totalLevels > 0 && !spotMediaDetail.approvalCompletedDate">
                        <label class="tylie-data__lbl">Total Approval Levels:</label> <span class="tylie-data__txt">{{spotMediaDetail.totalLevels == 0 ? "N/A" : spotMediaDetail.totalLevels.toString()}}</span>
                      </div>
                    </div>
                    <div [ngClass]="{'d-flex justify-content-between align-items-center': editMode == true, 'tylie-data tylie-data--c-block': editMode == false}" *ngIf="editMode == true && spotMediaDetail.isMasterRequiresQC && isPopup == false && this.isEditNotesVisible == true">
                      <label class="tylie-data__lbl">Notes:</label>
                      ​<textarea class="tylie-textarea tylie-textarea--sm form-control mb-2" [ngStyle]="{ 'width': '60%'}" name="notes" formControlName="notes" [ngClass]="{error: editMode && isQCNotesRequired && spotEditRequest.controls['notes'].value == ''}"></textarea>
                    </div>
                    <div [ngClass]="{'d-flex justify-content-between align-items-center': editMode == true, 'tylie-data tylie-data--c-block': editMode == false}" *ngIf="editMode == true && spotMediaDetail.enableApprovalRejection == true && isPopup == false">
                      <label class="tylie-data__lbl">Notes:</label>
                      ​<textarea class="tylie-textarea tylie-textarea--sm form-control mb-2" [ngStyle]="{ 'width': '60%'}" name="approvalNotes" formControlName="approvalNotes" [ngClass]="{error: editMode && isApprovalNotesRequired && spotEditRequest.controls['approvalNotes'].value == ''}"></textarea>
                    </div>
                  </div>
                </div>

                <div *ngIf="spotMediaDetail.isPostProductionRequiresApproval == true && isPopup == false">
                  <h6 class="media-approvers-hedding"> Approver(s)</h6>
                  <div class="media-approvers-details">
                    <div class="tylie-data" *ngFor="let a of spotMediaDetail.approvals">
                      <label class="tylie-data__lbl">{{a.user}} :</label> <span class="tylie-data__txt">On {{a.completionDate| usertimezone: 'MM/DD/YYYY HH:mm:ss A z'}} ({{a.status}})</span>
                    </div>
                  </div>
                </div>
              </form>

              <div class="tylie-panel tylie-panel--secondary">
                <div class="tylie-panel__header">
                  <h4 class="tylie-panel__title">
                    Custom Tags
                    <button type="button" secured [accessControlListComponent]="[this.userRoles.getMediaEditorRoleName()]" *ngIf="editMode == false && isPopup == false" for="f02" class="tylie-button tylie-button--xs tylie-button--link" (click)="onAddEditMediaCustomTags()">
                      ADD / EDIT
                    </button>
                  </h4>
                </div>
                <div class="tylie-panel__body">
                  <ng-container *ngIf="spotMediaDetail.customTags && spotMediaDetail.customTags.length > 0; else noActiveMessageBlock">
                    <div *ngFor="let customTag of spotMediaDetail.customTags" class="p-small">
                      <label class="tylie-data__lbl">{{customTag.tag}}:</label><span class="tylie-data__txt">{{customTag.tagValue}}</span>
                    </div>
                  </ng-container>
                  <ng-template #noActiveMessageBlock>
                    <div>No active custom tags</div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12" *ngIf="isPopup == false">
              <div class="d-flex flex-column align-items-start gap-2">
                <!-- First Row: Approve & Reject (with extra bottom margin for spacing) -->
                <div class="d-flex flex-column gap-2 mb-4">
                  <button type="button" *ngIf="spotMediaDetail.isPostProductionRequiresApproval && spotMediaDetail.isCurrentApprover"
                          class="tylie-button tylie-button--xs tylie-button--icon tylie-button--success"
                          (click)="showStatusConfirm(statusConfirm, true)">
                    <span class="tylie-button__icon"><icon-checkmark></icon-checkmark></span>
                    <span class="tylie-button__text">Approve</span>
                  </button>

                  <button type="button" *ngIf="spotMediaDetail.isPostProductionRequiresApproval && spotMediaDetail.isCurrentApprover"
                          class="tylie-button tylie-button--xs tylie-button--icon tylie-button--error"
                          (click)="showStatusConfirm(statusConfirm, false)">
                    <span class="tylie-button__icon"><icon-delete></icon-delete></span>
                    <span class="tylie-button__text">Reject</span>
                  </button>
                </div>

                <!-- Second Section: Each Button in a Separate Row -->
                <div class="d-flex flex-column gap-2">
                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon"
                          (click)="downloadSpotProxy()"
                          *ngIf="proxyUrl && !spotMediaDetail.showOnlyBasicActions">
                    <span class="tylie-button__icon"><icon-download color='#1e90ff'></icon-download></span>
                    Download Proxy
                  </button>

                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon"
                          (click)="downloadSpot()"
                          *ngIf="isSpotDownloadable && !spotMediaDetail.showOnlyBasicActions">
                    <span class="tylie-button__icon"><icon-download color='#1e90ff'></icon-download></span>
                    <span>Download Master</span>
                  </button>

                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon"
                          (click)="shareMasterMedia()"
                          *ngIf="spotMediaDetail.isMasterDownloadable && spotMediaDetail.isMasterShareVisible">
                    <span class="tylie-button__icon"><icon-envelope-share color='#1e90ff'></icon-envelope-share></span>
                    {{spotMediaDetail.showOnlyBasicActions ? 'Order High Res Master' : 'Share Master'}}
                  </button>

                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon"
                          (click)="shareSpot()"
                          *ngIf="spotMediaDetail.isPlayable">
                    <span class="tylie-button__icon"><icon-envelope-share color='#1e90ff'></icon-envelope-share></span>
                    {{spotMediaDetail.isMasterShareVisible && spotMediaDetail.showOnlyBasicActions ? 'Share Low Res File' : 'Share'}}
                  </button>

                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon"
                          (click)="showSpotNotifications()"
                          *ngIf="!spotMediaDetail.showOnlyBasicActions">
                    <span class="tylie-button__icon"><icon-notification-bell color='#1e90ff'></icon-notification-bell></span>
                    Notifications
                  </button>

                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon"
                          (click)="showEditHistory()">
                    <span class="tylie-button__icon"><icon-edit-history color='#1e90ff'></icon-edit-history></span>
                    Edit History
                  </button>

                  <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon"
                          (click)="showOrderHistory()">
                    <span class="tylie-button__icon"><icon-edit-history color='#1e90ff'></icon-edit-history></span>
                    Order History
                  </button>

                  <div secured [accessControlListComponent]="[this.userRoles.getMediaEditorRoleName()]">
                    <button type="button" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon"
                            *ngIf="spotMediaDetail.isActive && spotMediaDetail.isMasterRequiresQC && !spotMediaDetail.isPlaceholder"
                            (click)="purgeMedia()">
                      <i>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.29 78.29">
                          <title>PURGE</title>
                          <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1">
                              <path fill="#000000" d="M39.15,0A39.15,39.15,0,1,0,78.29,39.15,39.15,39.15,0,0,0,39.15,0Zm19.1,44.66h-37V36.84H58.25Z" />
                            </g>
                          </g>
                        </svg>
                      </i>
                      <span>Purge</span>
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="tylie-panel tylie-panel--secondary" *ngIf="isPopup == false">
        <div class="tylie-panel__header">
          <h4 class="tylie-panel__title">
            Timecode Comments
          </h4>
        </div>
        <div class="tylie-panel__body">
          <form [formGroup]="addTimecodeCommentRequest" #ft="ngForm" (ngSubmit)="onSaveTimecodeComment(addTimecodeCommentRequest)">
            <div class="row" *ngIf="(spotMediaDetail.enableTimecodeComments == true)">
              <div class="col-md-5">
                <div class="tylie-form-group form-group" [ngClass]="{error: addTimecodeCommentRequest.controls['timeCodeIn'].errors && ft.submitted}">
                  <label class="tylie-form-group__lbl" for="timein">Time In<span class="required">*</span></label>
                  <div class="row">
                    <div class="col">
                      <input type="text" name="timein" id="timein" formControlName="timeIn" class="tylie-text form-control" readonly>
                    </div>
                    <div class="col">
                      <button class="tylie-button tylie-button--sm tylie-button--block" (click)="timeIn()" type="button">Time In</button>
                    </div>
                  </div>
                  <p class="p-extra-small">Invalid Time In</p>
                </div>
                <div class="tylie-form-group form-group" [ngClass]="{error: (addTimecodeCommentRequest.controls['timeCodeOut'].errors || addTimecodeCommentRequest.controls['timeCodeOut'].value < addTimecodeCommentRequest.controls['timeCodeIn'].value) && ft.submitted}">
                  <label class="tylie-form-group__lbl" for="timeout">Time Out<span class="required">*</span></label>
                  <div class="row">
                    <div class="col">
                      <input type="text" name="timeout" id="timeout" formControlName="timeOut" class="tylie-text form-control" readonly>
                    </div>
                    <div class="col">
                      <button class="tylie-button tylie-button--sm tylie-button--block" (click)="timeOut()" type="button">Time Out</button>
                    </div>
                  </div>
                  <p class="p-extra-small">Invalid Time Out</p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="tylie-form-group form-group" [ngClass]="{error: addTimecodeCommentRequest.controls['comments'].errors && ft.submitted}">
                  <label class="tylie-form-group__lbl" for="note">Note<span class="required">*</span></label>
                  <textarea id="note" class="tylie-textarea tylie-textarea--lg form-control" formControlName="comments"></textarea>
                  <p class="p-extra-small">Please Enter Note</p>
                </div>
              </div>
              <div class="col-md-3">
                <button class="tylie-button tylie-button--xs tylie-button--icon mt-5" type="submit">
                  <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
                  <span class="tylie-button__text">Save Timecode</span>
                </button>
                <button type="button" class="tylie-button tylie-button--xs tylie-button--tr" (click)="clearComment(true)">
                  Cancel Timecode
                </button>
              </div>
            </div>
            <div class="row time-comment-view" *ngIf="spotMediaDetail.timeCodeComments.length > 0">
              <div class="col-md-12">
                <table class="table table-bordered" style="table-layout: fixed;">
                  <thead>
                    <tr>
                      <th>Time In</th>
                      <th>Time Out</th>
                      <th>Notes</th>
                      <th>Added By</th>
                      <th>Added On</th>
                      <!--<th>Action</th>-->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let tc of spotMediaDetail.timeCodeComments; let i = index" (click)="seekPlayer(tc.timeCodeIn, i)" [class.active]="i == selectedTimeCodeRow">
                      <td>{{tc.timeCodeIn | timecodeformat : spotMediaDetail.mediaSpecs.frameRate}}</td>
                      <td>{{tc.timeCodeOut | timecodeformat: spotMediaDetail.mediaSpecs.frameRate}}</td>
                      <td style="word-wrap: break-word;">{{tc.comments}}</td>
                      <td>{{tc.user}}</td>
                      <td>{{tc.created | usertimezone: 'MM/DD/YYYY HH:mm:ss A z' }}</td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--<div class="row time-comment-view" *ngIf="((spotMediaDetail.qcCompletionDate != null) || (spotMediaDetail.approvalCompletedDate != null)) && spotMediaDetail.timeCodeComments.length === 0">-->
            <div class="row time-comment-view" *ngIf="(spotMediaDetail.timeCodeComments.length === 0 && spotMediaDetail.enableTimecodeComments == false)">
              <div class="col-md-12">
                No comments
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="tylie-panel tylie-panel--secondary" *ngIf="isPopup == false">
        <div class="tylie-panel__header">
          <h4 class="tylie-panel__title">
            General Comments
          </h4>
        </div>
        <div class="tylie-panel__body">
          <form [formGroup]="addGeneralCommentRequest" #fg="ngForm" (ngSubmit)="onSaveGeneralComment(addGeneralCommentRequest)">
            <div class="row">
              <div class="col-md-9">
                <div class="tylie-form-group form-group" [ngClass]="{error: addGeneralCommentRequest.controls['comments'].errors && fg.submitted}">
                  <label class="tylie-form-group__lbl" for="generalnote">Note<span class="required">*</span></label>
                  <textarea id="generalnote" class="tylie-textarea tylie-textarea--lg form-control" formControlName="comments" name="comments"></textarea>
                  <p class="p-extra-small">Please Enter Note</p>
                </div>
              </div>
              <div class="col-md-3">
                <button class="tylie-button tylie-button--xs tylie-button--icon mt-5" type="submit">
                  <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
                  <span class="tylie-button__text">Save comment</span>
                </button>

                <button type="button" class="tylie-button tylie-button--xs tylie-button--tr" (click)="clearComment(false)">
                  Cancel Comment
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>



      <div class="row" *ngIf="isPopup == false">
        <div class="col-md-12" *ngFor="let tc of spotMediaDetail.generalComments">
          <p class="commented-by black p-extra-small">
            {{tc.created | usertimezone: 'MM/DD/YYYY HH:mm A z' }} by {{tc.user}}
          </p>
          <p class="commented-content p-extra-small" style="word-wrap: break-word">
            {{tc.comments}}
          </p>
        </div>
      </div>

      <div class="tylie-panel tylie-panel--secondary" *ngIf="isPopup == false">
        <div class="tylie-panel__header">
          <h4 class="tylie-panel__title">
            QC Comments
            <button *ngIf="!isPopup" (click)="onAddQCComment()" type="button" class="tylie-button tylie-button--xs tylie-button--icon">
                <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
                <span class="tylie-button__text">ADD New QC Comment</span>
            </button>
          </h4>
        </div>
      </div>

      <div class="row" *ngIf="isPopup == false">
        <div class="col-md-12" *ngFor="let tc of spotMediaDetail.qcComments">
          <p class="commented-by black p-extra-small">
            {{ tc.created | usertimezone: 'MM/DD/YYYY HH:mm A z' }} by {{ tc.user }}
            <span style="color:gray" *ngIf="tc.modifiedBy != null">Edited</span>
            <button *ngIf="!isPopup" (click)="onEditQCComment(tc)" type="button" class="tylie-button tylie-button--xs tylie-button--link" style="margin-left: 10px;">
              EDIT
            </button>
          </p>
          <p class="commented-content p-extra-small" style="word-wrap: break-word">
            {{tc.comments}}
          </p>
        </div>
      </div>
    </div>
</ng-container>


</div>

<ng-template #statusConfirm>
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="modalClose()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">Are you sure ?</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="approvalRequest" #fs="ngForm" (ngSubmit)="updateStatus(approvalRequest)">
      <div class="form-group">
        <label class="label label-success" [ngClass]="{'label-success': userApprovalStatus=='Approved', 'label-danger': userApprovalStatus=='Rejected'}">{{userApprovalStatus.toUpperCase()}}</label>
      </div>
      <div id="commentDiv" class="tylie-form-group form-group qc-notes" [ngClass]="{error: approvalRequest.controls['comments'].errors && fs.submitted}">
        <label class="tylie-form-group__lbl" for="notes">Notes<span class="required">*</span></label>
        <textarea formControlName="comments" name="notes" class="tylie-textarea tylie-textarea--md form-control"></textarea>
        <p class="p-extra-small">Please enter notes</p>
      </div>
      <div class="d-flex justify-content-end">
        <button class="tylie-button tylie-button--xs me-3" type="submit">Yes</button>
        <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="modalClose()">No</button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #approversList let-approvers="approvers">
  <p class='tylie-type-body mb-2' *ngFor="let usr of spotMediaDetail.approvers">{{usr}}</p>
</ng-template>
