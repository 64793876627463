import { error } from '@angular/compiler/src/util';
import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { forEach } from 'core-js/fn/array';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Constants } from '../../../configurations/constants';
import { customTimeCodeValidator, customTimeValidator } from '../../../configurations/custom-validations';
import { VaultAssetStages } from '../../../configurations/enums/enums';
import { CreateVaultRequest, UpdateVaultAssetServicesResponse, VaultAssetDetail, VaultAssetEditConfig, VaultAssetEditRequest, Vaultassetlineitem, VaultAssetNote, Vaultassetnotes, VaultAssetPopupGridModel, VaultFile, Vaultservice, VaultStatusChangeRequest } from '../../../models/vault/vault-create.model';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { ConfigService } from '../../../services/core/config.service';
import { GlobalService } from '../../../services/core/global.service';
import { Utilities } from '../../../services/core/utilities';
import { DownloadMediaService } from '../../../services/media/media-download.service';
import { VaultService } from '../../../services/vault/vault.service';
import { AddVaultAssetAdditionServicePopupComponent } from '../../popups/add-vault-asset-addition-service-popup/add-vault-asset-addition-service-popup.component';
import { AddVaultAssetAttachmentPopupComponent } from '../../popups/add-vault-asset-attachment-popup/add-vault-asset-attachment-popup.component';
import { AddVaultAssetNoteComponent } from '../../popups/add-vault-asset-note/add-vault-asset-note.component';
import { SelectattachmentvaultpopupComponent } from '../../popups/selectattachmentvaultpopup/selectattachmentvaultpopup.component';
import { VaultAssetPopupComponent } from '../../popups/vault-asset-popup/vault-asset-popup.component';
import { VaultassetsremovedestroypopupComponent } from '../../popups/vaultassetsremovedestroypopup/vaultassetsremovedestroypopup.component';

@Component({
  selector: 'app-vault-asset-detail',
  templateUrl: './vault-asset-detail.component.html',
  styleUrls: ['./vault-asset-detail.component.css']
})
export class VaultAssetDetailComponent implements OnInit {

  @Input('vaultAssetGuid') vaultAssetGuid: string;

  @ViewChildren('pop') pop: QueryList<PopoverDirective>;
  @ViewChild('clearVaultpop', { static: false }) clearVaultpop: PopoverDirective;

  public attachments: Array<VaultFile>;
  public assetlineitems: Array<Vaultassetlineitem>;
  public additionServices: Array<Vaultservice>;
  public notes: Array<Vaultassetnotes>;
  public vaultAssetDetail: VaultAssetDetail;
  public bsModalRef: BsModalRef;
  public vaultAssetRequest: FormGroup;
  public IsEditable: boolean = false;
  public vaultAssetEditConfig: VaultAssetEditConfig;
  public assetLineItem: Vaultassetlineitem;
  public deletedLineItemIds: any = [];
  public assets: Array<any>;

  public warehouseDataSource: any = [];
  public formatDataSource: any = [];
  public clientDataSource: any = [];
  public brandDataSource: any = [];
  public productDataSource: any = [];


  constructor(
    private vaultService: VaultService,
    public util: Utilities,
    public modalService: BsModalService,
    private alertService: AlertService,
    private fb: FormBuilder,
    private configService: ConfigService,
    private ds: DownloadMediaService,
    private router: Router,
    private gs: GlobalService) { }

  ngOnInit() {
    this.getVaultAssetDetail();
  }

  private getVaultAssetDetail() {

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.vaultService.getVaultAssetDetails(this.vaultAssetGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.vaultAssetDetail = res.result as VaultAssetDetail;
        console.log(this.vaultAssetDetail);

        if (this.vaultAssetDetail.location != null && this.vaultAssetDetail.location != "" ) {
          this.vaultAssetDetail.location = JSON.parse(this.vaultAssetDetail.location);
        }
        this.assetlineitems = this.vaultAssetDetail.vaultAssetLineItems;
        this.attachments = this.vaultAssetDetail.vaultFiles;
        this.additionServices = this.vaultAssetDetail.vaultServices;
        this.notes = this.vaultAssetDetail.vaultAssetNotes;

        this.createDatasources();

        this.vaultAssetRequest = this.vaultAssetForm;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public get vaultAssetForm() {
   return this.fb.group({
      cartonColumn: new FormControl(this.vaultAssetDetail.cartonColumn, Validators.required),
      cartonPosition: new FormControl(this.vaultAssetDetail.cartonPosition, Validators.required),
      cartonbarcode: new FormControl(this.vaultAssetDetail.cartonBarcode, Validators.required),
      assetbarcode: new FormControl(this.vaultAssetDetail.assetbarcode, Validators.required),
      assetdescription: new FormControl(this.vaultAssetDetail.description, Validators.required),
      createdby: new FormControl(this.vaultAssetDetail.createdby),
      assetingesteddate: new FormControl(this.vaultAssetDetail.assetingesteddate, Validators.required),
      assetIngestedby: new FormControl(this.vaultAssetDetail.assetingestedbyuserprofilename),
      requestedby: new FormControl(this.vaultAssetDetail.vaultRequestRequestedBy),
      clientjobnumber: new FormControl(this.vaultAssetDetail.vaultRequestClientJobNumber),
      reference: new FormControl(this.vaultAssetDetail.vaultRequestReference),
      receivedfrom: new FormControl(this.vaultAssetDetail.vaultRequestReceivedFrom),
      cartonWarehouseId: new FormControl(this.vaultAssetDetail.cartonWarehouseId, Validators.required),
      formatId: new FormControl(this.vaultAssetDetail.formatid, Validators.required),
      clientId: new FormControl(this.vaultAssetDetail.vaultRequestClientId),
      brandId: new FormControl(this.vaultAssetDetail.vaultRequestBrandId),
      productId: new FormControl(this.vaultAssetDetail.vaultRequestProductId),
      assetlastloggedoutdate: new FormControl(this.vaultAssetDetail.assetlastloggedoutdate),
      expectedreturndate: new FormControl(this.vaultAssetDetail.expectedreturndate),
      location: new FormControl(this.vaultAssetDetail.location),
      assetLineItemData: this.addAssetLineItemArrayForm
    });
  }

  public createGridModel() {
    this.assets = new Array<any>();
    var vaultAsset = new VaultAssetPopupGridModel();
    vaultAsset.vaultAssetId = this.vaultAssetDetail.vaultassetid;
    vaultAsset.assetBarcode = this.vaultAssetDetail.assetbarcode;
    vaultAsset.format = this.vaultAssetDetail.format;
    vaultAsset.client = this.vaultAssetDetail.vaultRequestClient;
    vaultAsset.brand = this.vaultAssetDetail.vaultRequestBrand;
    vaultAsset.product = this.vaultAssetDetail.vaultRequestProduct;
    this.assets.push(vaultAsset);
  }

  public loginClicked() {
    this.createGridModel();

    var initialState = {
      selectedAssets: this.assets,
      source: VaultAssetStages.login
    };
      console.log(initialState);
    this.bsModalRef = this.modalService.show(VaultAssetPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));

    this.bsModalRef.content.onVaultAssetStatusUpdated.subscribe(result => {
      console.log(result);
      if (result == true) {
        this.getVaultAssetDetail();
      }
    });
  }

  public stagingClicked() {
    this.createGridModel();

    var initialState = {
      selectedAssets: this.assets,
      source: VaultAssetStages.staging
    };
    console.log(initialState);
    this.bsModalRef = this.modalService.show(VaultAssetPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));

    this.bsModalRef.content.onVaultAssetStatusUpdated.subscribe(result => {
      console.log(result);
      if (result == true) {
        this.getVaultAssetDetail();
      }
    });
  }

  public backtoClicked() {
    console.log(this.vaultAssetDetail.vaultassetid);
    var request = new VaultStatusChangeRequest();
    request.IsVaultDigitalAsset = false;
    request.VaultAssetId = this.vaultAssetDetail.vaultassetid;

    this.alertService.ShowLoader(true);
    this.vaultService.updateVaultAssetStatusVault(request).subscribe((res: any) => {
      this.alertService.ShowLoader(false);
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESSS", Constants.assetStatusStagingUpdated, MessageSeverity.success, true);
        this.getVaultAssetDetail();
      } else {
        this.alertService.showMessage("ERROR", Constants.assetStatusUpdatedFail, MessageSeverity.error, true);
      }
    });
  }

  public logoutClicked() {
    this.createGridModel();
    var initialState = {
      selectedAssets: this.assets,
      source: VaultAssetStages.logout,
      isVaultDigitalAsset: false
    };
    console.log(initialState);
    this.bsModalRef = this.modalService.show(VaultassetsremovedestroypopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));

    this.bsModalRef.content.onVaultAssetStatusUpdated.subscribe(result => {
      console.log(result);
      if (result == true) {
        this.getVaultAssetDetail();
      }
    });
  }

  public removeClicked() {
    this.createGridModel();
    var initialState = {
      selectedAssets: this.assets,
      source: VaultAssetStages.remove,
      isVaultDigitalAsset: false
    };
    console.log(initialState);
    this.bsModalRef = this.modalService.show(VaultassetsremovedestroypopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));

    this.bsModalRef.content.onVaultAssetStatusUpdated.subscribe(result => {
      console.log(result);
      if (result == true) {
        this.getVaultAssetDetail();
      }
    });
  }

  public destroyClicked() {
    this.createGridModel();

    var initialState = {
      selectedAssets: this.assets,
      source: VaultAssetStages.destroy
    };
    console.log(initialState);
    this.bsModalRef = this.modalService.show(VaultAssetPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));

    this.bsModalRef.content.onVaultAssetStatusUpdated.subscribe(result => {
      console.log(result);
      if (result == true) {
        this.getVaultAssetDetail();
      }
    });
  }

  public missingClicked() {
    this.alertService.ShowLoader(true);
    this.vaultService.updateVaultAssetMissing(this.vaultAssetDetail.vaultassetid).subscribe((res: any) => {
      this.alertService.ShowLoader(false);
      this.vaultAssetDetail.isassetmissinginvault = true;
    });
  }

  public markAsAvailable() {
    this.alertService.ShowLoader(true);
    console.log(this.vaultAssetDetail.vaultassetid);
    this.vaultService.updateVaultAssetAvailable(this.vaultAssetDetail.vaultassetid+"").subscribe((res: any) => {
      this.alertService.ShowLoader(false);
      this.vaultAssetDetail.isassetmissinginvault = false;
    });
  }

  public editClicked() {
    this.getVaultAssetEditConfig();
    this.vaultAssetRequest = this.vaultAssetForm;
    this.alertService.ShowLoader(true);
  }

  public onSubmitVaultAsset({ value, valid }: { value: VaultAssetEditRequest, valid: boolean }) {
    value.vaultAssetId = this.vaultAssetDetail.vaultassetid;
    value.vaultAssetGuid = this.vaultAssetDetail.vaultassetguid;
    value.deletedLineItemIds = this.deletedLineItemIds;
    console.log(value);
    console.log(valid);
    if (!valid) {
      return;
    }

    this.alertService.ShowLoader(true);

    this.vaultService.updateVaultAsset(value).subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {
        this.alertService.ShowLoader(false);
        this.alertService.showMessage("Success", Constants.success_upadate_asset, MessageSeverity.success, true);
        this.vaultAssetDetail = res.result as VaultAssetDetail;

        this.assetlineitems = this.vaultAssetDetail.vaultAssetLineItems;
        this.attachments = this.vaultAssetDetail.vaultFiles;
        this.additionServices = this.vaultAssetDetail.vaultServices;
        this.notes = this.vaultAssetDetail.vaultAssetNotes;

        this.IsEditable = false;

        this.gs.vaultAssetEdited();
      } else {
        this.alertService.ShowLoader(false);
        this.alertService.showMessage("Failed", Constants.failed_upadate_asset, MessageSeverity.error, true);
      }
    }, error => {
      this.util.handleError(error);
      this.alertService.ShowLoader(false);
    });
  }


  public onDownloadFile(vaultFileGuid, fileName) {
    if (vaultFileGuid == null || vaultFileGuid == '00000000-0000-0000-0000-000000000000' || vaultFileGuid == '')
      return;

    this.vaultService.downloadVaultFile(vaultFileGuid)
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          var presignedUrl = res.result;
          this.util.downloadFile(this.util.getFileUrl(presignedUrl)).subscribe(
            fileData => {
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.href = window.URL.createObjectURL(fileData);
              a.download = fileName;
              a.click();
            });
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }

  public onHideClearVaultPop() {
    this.clearVaultpop.hide();
  }

  public clearSavedData(): void {
    this.IsEditable = false;
    this.onHideClearVaultPop();
  }

  public getVaultAssetEditConfig() {
    this.vaultService.getVaultAssetEditConfig().subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.ShowLoader(false);
        this.vaultAssetEditConfig = res.result as VaultAssetEditConfig;
        console.log(this.vaultAssetEditConfig);
        this.warehouseDataSource = this.vaultAssetEditConfig.warehouses;
        this.formatDataSource = this.vaultAssetEditConfig.formats;
        this.IsEditable = true;
      } else {
        this.alertService.ShowLoader(false);
        this.alertService.showMessage("Failed", Constants.faildtoLoadEditConfig, MessageSeverity.error, true);
      }
    });
  }

  public createDatasources() {
    this.clientDataSource = [{ id: this.vaultAssetDetail.vaultRequestClientId, name: this.vaultAssetDetail.vaultRequestClient}];
    this.productDataSource = [{ id: this.vaultAssetDetail.vaultRequestProductId, name: this.vaultAssetDetail.vaultRequestProduct}];
    this.brandDataSource = [{ id: this.vaultAssetDetail.vaultRequestBrandId, name: this.vaultAssetDetail.vaultRequestBrand}];
  }

  private get addAssetLineItemArrayForm() {
    var assetsLineAssets: FormArray = this.fb.array([]);

    console.log(this.vaultAssetDetail.vaultAssetLineItems)

    for (let i = 0; i < this.vaultAssetDetail.vaultAssetLineItems.length; i++) {
      var assetLineFb = this.fb.group({
        vaultAssetLineitemId: [null],
        assetLineSequenceId: [null],
        adId: [null, Validators.required],
        title: [null, Validators.required],
        length: [null, Validators.compose([customTimeValidator])],
      });
      console.log(this.vaultAssetDetail.vaultAssetLineItems[i]);

      this.assetLineItem = this.vaultAssetDetail.vaultAssetLineItems[i];
      assetLineFb.controls['vaultAssetLineitemId'].setValue(this.assetLineItem.vaultAssetLineitemId);
      assetLineFb.controls['assetLineSequenceId'].setValue(i+1);
      assetLineFb.controls['adId'].setValue(this.assetLineItem.adid);
      assetLineFb.controls['title'].setValue(this.assetLineItem.title);
      assetLineFb.controls['length'].setValue(this.assetLineItem.length);
      assetsLineAssets.push(assetLineFb);
    }

    return assetsLineAssets as FormArray;
  }


  public onAddNewAssetLine() {
    var assetLineItems = this.vaultAssetRequest.controls['assetLineItemData'] as FormArray;
    var seq = assetLineItems.length + 1;

    var assetLineFb = this.fb.group({
      vaultAssetLineitemId: [null],
      assetLineSequenceId: [null],
      adId: [null, Validators.required],
      title: [null, Validators.required],
      length: [null, Validators.compose([customTimeValidator])],
    });

    assetLineFb.controls['assetLineSequenceId'].setValue(seq);

    assetLineItems.push(assetLineFb);
  }


  public onDeleteAssetLineItem(assetLineIndex) {
    var assetLineItems = this.vaultAssetRequest.controls['assetLineItemData'] as FormArray;
    var id = assetLineItems.at(assetLineIndex).value.vaultAssetLineitemId;
    console.log(id);
    if (id != null && id != 0) {
      this.deletedLineItemIds.push(assetLineItems.at(assetLineIndex).value.vaultAssetLineitemId);
    }
    assetLineItems.removeAt(assetLineIndex);
  }


  public onAddNewNote() {
    const initialState = {
      vaultAssetId: this.vaultAssetDetail.vaultassetid
    };
    this.bsModalRef = this.modalService.show(AddVaultAssetNoteComponent, this.util.getModalComponentOptions(initialState, false, false, true));
    this.bsModalRef.content.onSaveVaultAssetNote.subscribe((result: Vaultassetnotes) => {
      console.log(result);
      if (result != null && result != undefined) {
        this.vaultAssetDetail.vaultAssetNotes.push(result);
        this.notes = this.vaultAssetDetail.vaultAssetNotes
      }
    });
  }

  public onAddNewService() {
    const initialState = {
      vaultAssetId: this.vaultAssetDetail.vaultassetid
    };
    console.log(this.vaultAssetDetail.vaultassetid);
    console.log(initialState);
    this.bsModalRef = this.modalService.show(AddVaultAssetAdditionServicePopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
    this.bsModalRef.content.onSelectAdditionalServiceVault.subscribe((result: UpdateVaultAssetServicesResponse) => {
      console.log(result);
      if (result != null && result != undefined) {
        this.vaultAssetDetail.vaultServices = result.vaultServices;
        this.additionServices = this.vaultAssetDetail.vaultServices;
      }
    });

  }

  public onAddNewAttachment() {
    var initialState = {
      vaultAssetId: this.vaultAssetDetail.vaultassetid,
      vaultAssetGuid: this.vaultAssetDetail.vaultassetguid,
      fileType: "Attachment"
    };

    this.bsModalRef = this.modalService.show(AddVaultAssetAttachmentPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
    this.bsModalRef.content.onSelectAttachmentVault.subscribe((result: VaultFile) => {
      console.log(result);
      if (result != null && result != undefined) {
        this.vaultAssetDetail.vaultFiles.push(result);
        this.attachments = this.vaultAssetDetail.vaultFiles
      }
    });
  }

  public onAttDelete(fileName: string, vaultFileGuid: string) {

    this.vaultService.deleteVaultFile(vaultFileGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var attToDeleteIndex = this.attachments.findIndex(t => t.vaultFileGuid == vaultFileGuid);

        if (attToDeleteIndex >= 0)
          this.attachments.splice(attToDeleteIndex, 1);

        this.alertService.showMessage("SUCCESSS", fileName + ' deleted successfully', MessageSeverity.success, true);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });

    this.onHidePop();
  }

  public onHidePop() {
    this.pop.forEach(p => {
      p.hide();
    });
  }


  //create dummy object for development
  //public createDummyVaultAssetDetail()
  //{
  //  this.vaultAssetDetail = new VaultAssetDetail();

  //  this.vaultAssetDetail.cartonColumn = "1";
  //  this.vaultAssetDetail.cartonPosition = "2";
  //  this.vaultAssetDetail.cartonBarcode = "B3";
  //  this.vaultAssetDetail.cartonWarehouseId = 1;
  //  this.vaultAssetDetail.formatid = 1;
  //  this.vaultAssetDetail.vaultRequestClientId = 1;
  //  this.vaultAssetDetail.vaultRequestProductId = 1;
  //  this.vaultAssetDetail.vaultRequestBrandId = 1;

  //  this.vaultAssetDetail.isLoggedOut = true;
  //  this.vaultAssetDetail.isStagging=false;
  //  this.vaultAssetDetail.isRemoved = false;
  //  this.vaultAssetDetail.isInVault = false;
  //  this.vaultAssetDetail.isDestroyed = false;
  //  this.vaultAssetDetail.vaultAssetLineItems = new Array<Vaultassetlineitem>();
  //  this.vaultAssetDetail.vaultFiles = new Array<VaultFile>();
  //  this.vaultAssetDetail.vaultServices = new Array<Vaultservice>();
  //  this.vaultAssetDetail.vaultAssetNotes = new Array<Vaultassetnotes>();
  //  this.vaultAssetDetail.vaultassetid = 1;

  //  let x = <Vaultassetlineitem>{
  //    vaultassetlineitemid: 1,
  //    vaultassetid: 1,
  //    sequence: 1,
  //    adid: "First Adid",
  //    title: "First Title",
  //    length: 50
  //  };
  //  this.vaultAssetDetail.vaultAssetLineItems.push(x);

  //  let y = <Vaultassetlineitem>{
  //    vaultassetlineitemid: 1,
  //    vaultassetid: 1,
  //    sequence: 1,
  //    adid: "2 Adid",
  //    title: "2 Title",
  //    length: 100
  //  };
  //  this.vaultAssetDetail.vaultAssetLineItems.push(y);

  //  this.assetlineitems = this.vaultAssetDetail.vaultAssetLineItems;
  //  this.attachments = this.vaultAssetDetail.vaultFiles;
  //  this.additionServices = this.vaultAssetDetail.vaultServices;
  //  this.notes = this.vaultAssetDetail.vaultAssetNotes;

  //  this.createDatasources();
  //  console.log(this.clientDataSource);

  //  this.vaultAssetRequest = this.fb.group({
  //    cartonColumn: new FormControl(this.vaultAssetDetail.cartonColumn, Validators.required),
  //    cartonPosition: new FormControl(this.vaultAssetDetail.cartonPosition, Validators.required),
  //    cartonbarcode: new FormControl(this.vaultAssetDetail.cartonBarcode, Validators.required),
  //    assetbarcode: new FormControl(this.vaultAssetDetail.assetbarcode, Validators.required),
  //    assetdescription: new FormControl(this.vaultAssetDetail.description, Validators.required),
  //    createdby: new FormControl(this.vaultAssetDetail.createdby),
  //    assetingesteddate: new FormControl(this.vaultAssetDetail.assetingesteddate, Validators.required),
  //    assetIngestedby: new FormControl(this.vaultAssetDetail.assetingestedbyuserprofilename, Validators.required),
  //    requestedby: new FormControl(this.vaultAssetDetail.vaultRequestRequestedBy),
  //    clientjobnumber: new FormControl(this.vaultAssetDetail.vaultRequestClientJobNumber),
  //    reference: new FormControl(this.vaultAssetDetail.vaultRequestReference),
  //    receivedfrom: new FormControl(this.vaultAssetDetail.vaultRequestReceivedFrom),
  //    cartonWarehouseId: new FormControl(this.vaultAssetDetail.cartonWarehouseId, Validators.required),
  //    formatId: new FormControl(this.vaultAssetDetail.formatid, Validators.required),
  //    clientId: new FormControl(this.vaultAssetDetail.vaultRequestClientId),
  //    brandId: new FormControl(this.vaultAssetDetail.vaultRequestBrandId),
  //    productId: new FormControl(this.vaultAssetDetail.vaultRequestProductId),
  //    assetLineItemData: this.addAssetLineItemArrayForm,
  //  });

  //  this.alertService.ShowLoader(false);
  //}
}
