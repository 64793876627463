import { Component, OnInit, Input, NgZone, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { TruncateByLetters } from "../../../pipes/truncateByLetters.pipe";
import { Utilities } from '../../../services/core/utilities';
import { SpotService } from '../../../services/media/spot.service';
import { BasePopupComponent } from '../../popups/basepopup/basepopup.component';
import videojs from 'video.js';

@Component({
  selector: 'quickplayer',
  templateUrl: './quickplayer.component.html',
  styleUrls: ['./quickplayer.component.css']
})

export class QuickplayerComponent extends BasePopupComponent {

  @Input('videoSource') videoSource: string;
  @Input('thumbnail') thumbnail: string;
  @Input('title') title: string;
  @Input('format') format: string;

  public proxyUrl: string = null;
  public overyLayHeightQ: number = 0;
  public is169OverlayRequired: boolean = false;
  public is430verlayRequired: boolean = false;
  public isOver169Overlay: boolean = false;
  public isOver43Overlay: boolean = false;
  public playListHeight: number = (window.innerHeight - 60);
  private player: any;
  public videoPlayerInitialized = false;
  public smpteTimecode: string = '00:00:00:00';
  selectedOverlay: any = null;
  public isAudioFile: boolean = false;

  originalWidth: number = 640;
  originalHeight: number = 360;
  scaledWidth: number;
  scaledHeight: number;

  @ViewChild('videoPlayerQ', { static: false }) videoPlayer: ElementRef;
  playerWidthClass: string = '';
  videoPlayerEle: HTMLVideoElement;
  playerWidth: 0;

  public overlayOptions = [
    { name: '4:3 Overlay', value: '43' },
    { name: '16:9 Overlay', value: '169' }
  ];
  constructor(ngZone: NgZone, public bsModalRef: BsModalRef, public util: Utilities, public spotService: SpotService) {

    super();

    window.onresize = () => {
      ngZone.run(() => {
        this.playListHeight = (window.innerHeight - 60);
        this.isOver169Overlay = false;
        this.isOver43Overlay = false;
      });
    };
  }

  ngOnInit() {
    
    super.ngOnInit();
    if(this.format?.toLowerCase() === 'audio') {
        this.is169OverlayRequired = false;
        this.is430verlayRequired = false;
    }
    else {
      this.is169OverlayRequired = this.util.is169OverlayRequired(this.format);
      this.is430verlayRequired = this.util.is430verlayRequired(this.format);
    }
    this.getProxyUrl();
  }

  ngAfterViewInit() {
    if (this.videoPlayer?.nativeElement) {
      this.initializeVideoJS();
    }
  }

  ngAfterViewChecked() {
    if (this.videoPlayer?.nativeElement && !this.videoPlayerInitialized) {
      this.initializeVideoJS();
      this.videoPlayerInitialized = true;
    }
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }

  private initializeVideoJS() {
    if (!this.videoPlayer?.nativeElement) {
      console.error('Video player element is not available.');
    }

   this.player = videojs(this.videoPlayer.nativeElement, {
      controls: true,
      autoplay: false,
      preload: true,
      poster:this.thumbnail,
      sources: [{ src: this.proxyUrl, type: 'video/mp4' }]
    });

    this.player.on('error', (error: any) => {
      console.error('Video.js Error:', error);
    });

    this.player.on('play', () => {
      if (this.format?.toLowerCase() === 'audio') {
        const videoContainer = this.player.el();
        videoContainer.style.backgroundImage = `url(${this.thumbnail})`;
        videoContainer.style.backgroundSize = 'cover !important';
        videoContainer.style.backgroundRepeat = 'no-repeat';
        videoContainer.style.backgroundPosition = 'center';
      }
    });

    this.player.on('mousemove', () => {
      this.player.getChild('controlBar').el().style.opacity = '1';
    });

    this.player.on('mouseleave', () => {
      this.player.getChild('controlBar').el().style.opacity = '0';
    });

    this.player.on('loadeddata', () => {
      this.smpteTimecode = this.util.formatSMPTETime(this.player.currentTime(), 0);
      this.loadVideoMetadata();
    });

    this.player.on('timeupdate', () => {
      this.smpteTimecode = this.util.formatSMPTETime(this.player.currentTime(), 0);
    });

    this.player.on('seeked', () => {
      this.smpteTimecode = this.util.formatSMPTETime(this.player.currentTime(), 0);
    });

    this.player.on('ended', () => {
      this.isOver169Overlay = false;
      this.isOver43Overlay = false;
      this.selectedOverlay = null;
    });
  }

  private loadVideoMetadata() {
    if (!this.player) {
      console.error("Player is not initialized.");
      return;
    }

    this.originalWidth = this.player.videoWidth();
    this.originalHeight = this.player.videoHeight();

    this.calculateVideoSize();
  }

  private calculateVideoSize() {
    let width = this.originalWidth || 640;
    let height = this.originalHeight || 360;

    if (width > 675 || height > 675) {
      width = width / 2;
      height = height / 2;
    }

    this.scaledWidth = width;
    this.scaledHeight = height;

    const videoContainer = this.player.el();
    videoContainer.style.width = `${this.scaledWidth}px`;
    videoContainer.style.height = `${this.scaledHeight}px`;
  }

  private getProxyUrl() {
    this.spotService.getProxyUrl({ objectKey: this.videoSource }).subscribe(
      (res: any) => {
        if (res.isSuccess) {
          this.proxyUrl = res.result;
        } else {
          this.util.handleIsNotSuccess(res.errors);
          this.closePopup();
        }
      },
      (error) => {
        this.util.handleError(error);
        this.closePopup();
      });
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public toggleOverlayQ(overlayType: string) {
    if (this.isAudioFile) return;
    
    if (overlayType === '169') {
      this.isOver43Overlay = false;
      this.isOver169Overlay = !this.isOver169Overlay;
    } else if (overlayType === '43') {
      this.isOver169Overlay = false;
      this.isOver43Overlay = !this.isOver43Overlay;
    } else {
      this.isOver43Overlay = false;
      this.isOver169Overlay = false;
    }

    this.overyLayHeightQ = this.videoPlayer.nativeElement.offsetHeight;
  }
}
