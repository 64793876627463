import { ClientBrandAttachment } from "../../order/order-uploadfile.model";
import { ProductModel } from "../brand/createproduct.model";
import { AssetShareBillingSettings, ManagementFeeSettings, StorageManagementFeeSettings, TranscodeBillingSettings } from "./billingsettings.model";
import { PostHouseModel } from "./client";
import { CustomTagConfig } from "./customtagconfig.model";
import { ClientFileDeliveryConfig } from "./deliveryconfig.model";

export class CreateClientModel {
  public clientName: string;
  public accountExecutive: number;
  public clientType: number;
  public name: string;
  public aliasId: string;
  public address1: string;
  public address2: string;
  public city: string;
  public state: number;
  public zipCode: string;
  public phone: string;
  public mobile: string;
  public email: string;
  public teamEmail: string;
  public poRequired: boolean;
  public isViewRestricted: boolean;
  public orderReviewRequired: boolean;
  public bundleServicesOffered: boolean;
  public isVaultBilling: boolean;
  public isOnTrail: boolean;
  public orderFinalReviewRequired: boolean;
  public status: number;
  public autoBillManagementFee: boolean;
  public billingFrequency: number;
  public costCenters: string[];
  public billTos: string[];
  public opsNotes: string;
  public podDistributionEmails: string;
  public logo: string;
  public brandSelectionList: ClientBrandSelectionModel[];
  public bundleServices: BundleService[];
  public orderedBy: string;
  public archiveDurationInDays: string;
  public infrqStorageDays: string;
  public stdStorageDays: string;
  public fileDeliveryConfigurations: ClientFileDeliveryConfig[]
  public transcodeBillingSettings: TranscodeBillingSettings;
  public assetShareBillingSettings: AssetShareBillingSettings;
  public managementFeeSettings: ManagementFeeSettings;
  public storageManagementFeeSettings: StorageManagementFeeSettings;
  public partnerClients: number[];
  public cxComments: string;
  public clientGuid: string;
  public attachments: Array<ClientBrandAttachment>;
}

export class ClientBrandSelectionModel {
  public id: number;
  public shortName: string;
  public brandName: string;
  public approvalLevel: number;
  public products: ProductModel[];
  public deletedProducts: ProductModel[];
  public preferredNetwork: number;//PreferredNetwork
  public preferredNetworkName: string; //PreferredNetworkName
  public excludedNetworkList: number[]; //ExcludedNetworkList
  public excludedNetwork: string; //ExcludedNetwork
  public brandId: number;
  public isDestinationHubbed: boolean;
  public costCenter: string;
  public billTo: string;
  public costCenters: string[];
  public billTos: string[];
  public hasBrandProducts: boolean;
  public cutDownServicesOfferedIds: number[];
  public cutDownServicesOffered: string;
  public reslateBundleServicesOfferedIds: number[];
  public reslateBundleServicesOffered: string;
  public customTagConfigurations: CustomTagConfig[];
  public displayCustomConfigurations: string;
  public specialServicesOffered: string;
  public bundleServices: BundleService[];
  public podDistributionEmails: string;
  public isTrafficEmailInPODDisplayed: boolean;
  public archiveDurationInDays: string;
  public infrqStorageDays: string;
  public stdStorageDays: string;
  public opsNotes: string;
  public cxComments: string;
  public transcodeBillingSettings: TranscodeBillingSettings;
  public assetShareBillingSettings: AssetShareBillingSettings;
  public managementFeeSettings: ManagementFeeSettings;
  public storageManagementFeeSettings: StorageManagementFeeSettings;
  public attachments: Array<ClientBrandAttachment>;
}

export class BrandSelectionResponse {
  public selectedClientBrands: ClientBrandSelectionModel[];
  public availablePostHouses: PostHouseModel[];
}

export class CostCenterAndBillToValues {
  public costCenters: string[];
  public billTos: string[];
}

export class CostCenterAndBillToObjects {
  public costCenters: CostCenter[];
  public billTos: BillTo[];
  public cutDownServices: number[];
  public reslatebundleServices: number[];
  public podEmails: string;
  public stdStorageDays: string;
  public infrqStorageDays: string;
  public archiveDurationInDays: string;
  public activeTandemStorageInDays: string;
  public isWCPFileDeliveryBilled: boolean;
  public opsNotes: string;
  public cxComments: string;
  public attachments: Array<ClientBrandAttachment>;
}

export class ClientBrandVendorInfoObjects {
  public preferredNetworkVendor: number[];
  public excludeNetworkVendor: number[];
}

export class BillTo {
  public billTo: string;
}

export class CostCenter {
  public costCenter: string;
}

export class ClientBrandProductSelectedEvent {
  public productSelected: number;
  public groupName: number;
}

export class CreateClientBrandProduct {
  public clientBrandProductGroups: ProductCostCenterAndInvoiceObject[];
}

export class ProductCostCenterAndInvoiceObject {
  public id: number;
  public clientBrandProductId: number;
  public name: string;
  public shortName: string;
  public inVoiceTo: BillTo[];
  public costCenter: CostCenter[];
  public podEmails: string;
  public isTrafficEmailInPODDisplayed: boolean;
  public archiveDurationInDays: string;
  public activeTandemStorageInDays: string;
  public infrqStorageDays: string;
  public stdStorageDays: string;
}

export class AddPosthouseToClientRequest {
  public postHouseId: number;
  public postHouseName: number;
  public agencyId: number;
  public postHouseBrandSelectionList: ClientBrandSelectionModel[];
}

export class AddPosthouseToClientResponse {
  public postHouses: PostHouseModel[];
  public errors: string;
}

export class BundleService {
  public bundleServiceName: string;
  public bundleServiceCode: string;
  public serviceCodes: string[];
  public serviceNames: string;
}

export class SelectedBundleServices {
  public bundleServices: BundleService[];
  public bundleServicesList: any[];
  public servicesList: any[];
}

