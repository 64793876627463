import { RightsManagement } from "./rightsmanagement.model";

export class SpotMediaDetail {
  public spotFileGuid: string;
  public spotGuid: string;
  public adId: string;
  public title: string;
  public client: string;
  public brand: string;
  public product: string;
  public productId: number;
  public editorialHouse: string;
  public agency: string;
  public subClient: string;
  public duration: string;
  public receivedDuration: string;
  public proxyUrl: string;
  public thumbnailUrl: string;
  public isCurrentApprover: boolean;
  public currentApproverLevel: number;
  public totalLevels: number;
  public enableTimecodeComments: boolean;
  public enableApprovalRejection: boolean;
  public isMasterRequiresQC: boolean;
  public isPostProductionMaster: boolean;
  public isPostProductionRequiresApproval: boolean;
  public isProductNotSelected: boolean;
  public isPlayable: boolean;
  public qcStatus: string;
  public qcUser: string;
  public qcStatusId: number;
  public qcCompletionDate: Date;
  public mediaType: string;
  public fileName: string;
  public proxyFileName: string;
  public masterFileName: string;
  public format: string;
  public expiryDate: Date;
  public approvalStatus: string;
  public approvalStatusId: number;
  public approvalCompletedDate: Date;
  public createdBy: string;
  public created: Date;
  public clipId: string;
  public formatId: number;
  public brandId: number;
  public clientId: number;
  public editorialHouseId: number;
  public subClientId: number;
  public agencyId: number;
  public isOperationsUser: boolean;

  public aspectRatio: string;
  public encoded: string;
  public audioFormat: string;
  public channels: number;
  public spotTrac: boolean;
  public veilEncode: boolean;
  public descriptiveVideo: boolean;
  public centerCutProtected: boolean;
  public subtitles: boolean;
  public cc: boolean;


  public broadcast: boolean;
  public web: boolean;
  public taggable: boolean;
  public generic: boolean;
  public runningFootage: boolean;

  public sap: boolean;
  public vchip: boolean;
  public surround: boolean;
  public mono: boolean;
  public letterBox: boolean;
  public lkfs: string;

  public markers: Array<number>;

  public isShareForApprovalAllowed: boolean;

  public mediaSpecs: MediaSpecs;
  public approvals: Array<SpotApproval>;
  public timeCodeComments: Array<SpotComment>;
  public generalComments: Array<SpotComment>;
  public qcComments: Array<SpotComment>;
  public approvers: Array<string>;
  public clients: Array<any>;
  public agencies: Array<any>;
  public clientBrands: Array<any>;
  public editorialHouses: Array<any>;
  public formats: Array<any>;
  public aspectRatios: Array<any>;
  public qcStatuses: Array<any>;
  public approvalStatuses: Array<any>;
  public markerTypes: Array<any>;
  public brandProducts: Array<any>;

  public spotUserGroupNames: Array<string>;
  public spotUserGroups: Array<number>;
  public clientUserGroups: Array<any>;

  public errors: Array<string>;

  public formatSource: string;
  public markerSources: Array<string>;

  public isMasterDownloadable: boolean;

  public storageKey: string;
  public storageclass: string;
  public isArchived: boolean;

  public startOfMessage: string;
  public endOfMessage: string;
  public rightsManagementDetail: RightsManagement;
}

export class MediaSpecs {
  public video: string;
  public audio: string;
  public mediaSize: string;
  public mediaType: string;
  public clipId: string;
  public frameRate: string;
  public scanType: string;
}

export class SpotApproval {
  public user: string;
  public approvalLevel: number;
  public status: string;
  public completionDate: Date;
}

export class SpotComment {
  public spotCommentGuid: string;
  public user: string;
  public comments: string;
  public timeCodeIn: string;
  public timeCodeOut: string;
  public isOwner: boolean;
  public commentType: string;
  public createdBy: string;
  public created: Date;
  public modifiedBy: Date;
}
