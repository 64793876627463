export class PresignedUrlS3Upload {
  uploadUrl: string;
  downloadUrl: string;
  fileGuid: string;
}

export interface UploadOrderFileRequest {
  fileName: string;
  fileType: string;
  fileCategory: string;
  description: string;
  orderGuid: string;
  orderDestinationId: number;
}

export interface UploadClientBrandAttachmentRequest {
  fileName: string;
  fileType: string;
  fileCategory: string;
  description: string;
  clientGuid: string;
  orderDestinationId: number;
  brandId: number;
}

export interface ClientBrandAttachment {
  clientBrandAttachmentGuid: string;
  clientBrandAttachmentId: number;
  fileName: string;
  filePath: string;
  fileType: string;
  fileCategory: string;
  description: string;
  clientGuid: string;
  orderDestinationId: number;
  brandId: number;
}

export interface UploadTaggerFileRequest {
  fileName: string;
  fileType: string;
  //fileCategory: string;
  description: string;
  taggerGuid: string;
  taggerDestinationId: number;
}
