import { Observable, Subject, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { UploadOrderFileRequest, PresignedUrlS3Upload, UploadClientBrandAttachmentRequest } from '../../models/order/order-uploadfile.model';
import { Constants } from '../../configurations/constants';
import { OrderFile } from '../../models/order/order-create.model';
import { Utilities } from '../core/utilities';
import { AlertService, MessageSeverity } from '../core/alert.service';
import { LogSeverity } from '../../models/core/logmessage.model';
import { OrderDetailFTPDestinationModel } from '../../models/order/order-userdestination';

@Injectable()
export class UploadOrderFileService {

  public onOrderClaimedforProcessing = new Subject<any>();

  constructor(
    private http: HttpClient,
    private apiendPoint: ApiEndPoint,
    private alertService: AlertService,
    public util: Utilities) { }


  public uploadOrderFile(request: UploadOrderFileRequest, uploadedFile: any[]) {
    var fileGuid = "00000000-0000-0000-0000-000000000000";
    return this.initiateUploadOrderFile(request).pipe(
      mergeMap((res: any) => {
        if (res != null && res != undefined) {
          if (this.util.notEmpty(res.url)) {
            fileGuid = res.fileGuid;
            return this.uploadToS3(res.url, uploadedFile);
          }
          else {
            return of(false);
          }
        }
      }),
      mergeMap(res3 => {
        if (res3 == true) {
          return this.completeUploadOrderFile(fileGuid);
        }
        else {
          return of(null);
        }
      }),
      map(newFile => newFile));
  }

  private initiateUploadOrderFile(request: UploadOrderFileRequest) {
    return this.initiateOrderFileUpload(request).pipe(
      map((res: any) => {
        if (res.isSuccess == true) {
          var presignedS3Result = res.result as PresignedUrlS3Upload;
          return presignedS3Result;
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
          return new PresignedUrlS3Upload();
        }
      }),
      catchError(error => {
        this.util.handleError(error);
        return of(new PresignedUrlS3Upload());
      }))
  }

  private uploadToS3(presignedUrl: string, uploadedFile: any[]) {
    return this.uploadfileAWSS3(presignedUrl, "application/octet-stream", uploadedFile).pipe(
      map((res: any) => {
        if (res.type === HttpEventType.Response) {
          if (res.status == 200) {
            return true;
          }
          else {
            this.util.handleIsNotSuccess([Constants.uploadOrderFileFailed]);
            return false;
          }
        }
      }),
      catchError((error: any) => {
        this.alertService.logDB(error.toString(), LogSeverity.error);
        this.alertService.showMessage("ERROR", Constants.uploadOrderFileFailed, MessageSeverity.error, true);
        return of(false);
      }))
  }

  private completeUploadOrderFile(fileGuid: string) {
    return this.completeOrderUpload(fileGuid).pipe(
      map((res: any) => {
        if (res.isSuccess == true) {
          var newFile = res.result as OrderFile;
          return newFile;
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      }),
      catchError(error => {
        this.util.handleError(error);
        return of(null);
      }))
  }

  private completeOrderUpload(orderFileGuid: string) {
    return this.http.put(this.apiendPoint.completeOrderFileUploadEndpoint() + "/" + orderFileGuid, "");
  }

  private initiateOrderFileUpload(request: any) {
    return this.http.post(this.apiendPoint.initiateOrderFileUploadEndpoint(), request);
  }

  private uploadfileAWSS3(fileuploadurl, contenttype, file) {
    var header = new HttpHeaders();
    header = header.append("Content-Type", contenttype);
    header = header.append("Skip-Token", "skip");

    var res = this.http.put(fileuploadurl, file, {
      headers: header, reportProgress: true,
      observe: 'events'
    });

    return res;
  }

  //  for FTP POD Upload
  public uploadOrderFTPPOd(request: UploadOrderFileRequest, uploadedFile: any[]) {
    var fileGuid = "00000000-0000-0000-0000-000000000000";
    return this.initiateUploadFTPPod(request).pipe(
      mergeMap((res: any) => {
        if (res != null && res != undefined) {
          if (this.util.notEmpty(res.url)) {
            fileGuid = res.fileGuid;
            return this.uploadToS3(res.url, uploadedFile);
          }
          else {
            return of(false);
          }
        }
      }),
      mergeMap(res3 => {
        if (res3 == true) {
          return this.completeUploadFTPPod(fileGuid, request.orderGuid, request.orderDestinationId);
        }
        else {
          return of(null);
        }
      }),
      map(newFile => newFile));
  }

  private initiateUploadFTPPod(request: UploadOrderFileRequest) {
    return this.initiateUploadOrderFTPPodRequest(request).pipe(
      map((res: any) => {
        if (res.isSuccess == true) {
          var presignedS3Result = res.result as PresignedUrlS3Upload;
          return presignedS3Result;
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
          return new PresignedUrlS3Upload();
        }
      }),
      catchError(error => {
        this.util.handleError(error);
        return of(new PresignedUrlS3Upload());
      }))
  }

  private completeUploadFTPPod(fileGuid: string, orderGuid: string, orderDestinationId: number) {
    return this.completeUploadOrderFTPPodRequest(fileGuid, { orderGuid: orderGuid, orderDestinationId: orderDestinationId }).pipe(
      map((res: any) => {
        if (res.isSuccess == true) {
          var ftpOrderDetail = res.result as OrderDetailFTPDestinationModel;
          this.alertService.showMessage("SUCCESS", Constants.orderFtpPodUpload, MessageSeverity.success);
          return ftpOrderDetail;
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      }),
      catchError(error => {
        this.util.handleError(error);
        return of(null);
      }))
  }

  private initiateUploadOrderFTPPodRequest(request: any) {
    return this.http.post(this.apiendPoint.initiateUploadOrderFTPPodEndpoint(), request);
  }

  private completeUploadOrderFTPPodRequest(orderFileGuid: string, request: any) {
    return this.http.put(this.apiendPoint.completeUploadOrderFTPPodEndpoint() + "/" + orderFileGuid, request);
  }

  //upload OPs Notes attachment
  public uploadOPsAttachment(request: UploadClientBrandAttachmentRequest, uploadedFile: any[]) {
    var fileGuid = "00000000-0000-0000-0000-000000000000";
    return this.initiateUploadOpsAttachment(request).pipe(
      mergeMap((res: any) => {
        //console.log("San1");
        if (res != null && res != undefined) {
          if (this.util.notEmpty(res.url)) {
            fileGuid = res.fileGuid;
            return this.uploadToS3(res.url, uploadedFile);
          }
          else {
            return of(false);
          }
        }
      }),
      mergeMap(res3 => {
        //console.log("San2");
        if (res3 == true) {
          return this.completeUploadOpsAttachment(fileGuid, request.clientGuid, request.orderDestinationId);
        }
        else {
          return of(null);
        }
      }),
      map(newFile => newFile));
  }

  private initiateUploadOpsAttachment(request: UploadClientBrandAttachmentRequest) {
    return this.initiateUploadOpsAttachmentRequest(request).pipe(
      map((res: any) => {
        //console.log("San3");
        if (res.isSuccess == true) {
          var presignedS3Result = res.result as PresignedUrlS3Upload;
          return presignedS3Result;
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
          return new PresignedUrlS3Upload();
        }
      }),
      catchError(error => {
        //console.log("San4");
        this.util.handleError(error);
        return of(new PresignedUrlS3Upload());
      }))
  }

  private completeUploadOpsAttachment(fileGuid: string, clientGuid: string, orderDestinationId: number) {
    return this.completeUploadOpsAttachmentRequest(fileGuid, { clientGuid: clientGuid, orderDestinationId: orderDestinationId }).pipe(
      map((res: any) => {
        //console.log("San5");
        if (res.isSuccess == true) {
          var ftpOrderDetail = res.result as OrderDetailFTPDestinationModel;
          this.alertService.showMessage("SUCCESS", Constants.orderFtpPodUpload, MessageSeverity.success);
          return ftpOrderDetail;
        }
        else {
          //console.log("San6");
          this.util.handleIsNotSuccess(res.errors);
        }
      }),
      catchError(error => {
        //console.log("San7");
        this.util.handleError(error);
        return of(null);
      }))
  }

  private initiateUploadOpsAttachmentRequest(request: any) {
    return this.http.post(this.apiendPoint.initiateUploadOpsAttachmentEndpoint(), request);
  }

  private completeUploadOpsAttachmentRequest(opsFileGuid: string, request: any) {
    return this.http.put(this.apiendPoint.completeUploadOpsAttachmentEndpoint() + "/" + opsFileGuid, request);
  }


}

