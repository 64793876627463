<div class="tylie-panel">
  <div class="tylie-panel__header">
    <h4 class="tylie-panel__title">
        Brands
    </h4>
  </div>

  <div class="tylie-panel__body" *ngIf="(this.allowEdits == true || (isClientEdit == true && editConfig.canAddBrand == true))">
      <button type="button" class="tylie-button tylie-button--xs tylie-button--icon" (click)="onAddBrands()">
        <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
        <span class="tylie-button__text">Add Brands</span>
      </button>
  </div>

  <div class="tylie-panel__body" *ngIf="brandStore">
    <dx-data-grid id="brandStoreGrid" class="tylie-grid"
                  #brandStoreGrid
                  [dataSource]="brandStore"
                  [allowColumnReordering]="true"
                  [allowColumnResizing]="true"
                  [columnAutoWidth]="true"
                  [showColumnLines]="true"
                  [showBorders]="true"
                  [showRowLines]="true"
                  [hoverStateEnabled]="true"
                  keyExpr="brandId"
                  [rowAlternationEnabled]="true"
                  [wordWrapEnabled]="true">
      <dxo-export [enabled]="false" fileName="Orders" [allowExportSelectedData]="true"></dxo-export>
      <dxi-column dataField="brandName" caption="Brand Name" width="30%" cellTemplate='brandsTemplate' cssClass="gridStyle tyl-vertical-align-intial" sortOrder="asc"></dxi-column>
      <dxi-column dataField="products" caption="Product" width="25%" cellTemplate="productTemplate" cssClass="gridStyle tyl-vertical-align-intial" [allowSorting]="false"></dxi-column>
      <dxi-column caption="Appr.Lvl" width="8%" cellTemplate="approvalLvlTemplate" cssClass="gridStyle tyl-vertical-align-intial" [allowSorting]="false"></dxi-column>
      <dxi-column caption="Network" width="15%" cellTemplate="vendorTemplate" cssClass="gridStyle tyl-vertical-align-intial" [allowSorting]="false"></dxi-column>-->
      <dxi-column dataField="isDestinationHubbed" width="10%" cellTemplate="destHubTemplate" caption="Dest.Hub" cssClass="gridStyle tyl-text-align-left tyl-vertical-align-intial" [allowSorting]="false"></dxi-column>
      <dxi-column dataField="isTrafficEmailInPODDisplayed" width="15%" cellTemplate="TrafficEmailInPODDisplayed" caption="Traff.Email In POD" cssClass="gridStyle tyl-text-align-left tyl-vertical-align-intial" [allowSorting]="false"></dxi-column>
      <dxi-column dataField="brandId" caption="Delete" width="7%" cssClass="gridStyle tyl-text-align-left tyl-vertical-align-intial" cellTemplate="actionsTemplate" [visible]="(this.allowEdits == true || (isClientEdit == true && editConfig.canDeleteBrand == true))" [allowSorting]="false"></dxi-column>

      <div *dxTemplate="let t of 'brandsTemplate'">
        <div style="vertical-align:middle;">
          <div class="p-normal-bold">
            {{t.data.brandName }}
          </div>
          <!--<div *ngIf="t.data.costCenters?.length == 0
          && t.data.billTos?.length == 0 && t.data.cutDownServicesOfferedIds?.length == 0
          && (t.data.podDistributionEmails == null || t.data.podDistributionEmails == undefined || t.data.podDistributionEmails?.length == 0)
          && t.data.bundleServices?.length == 0 && (this.allowEdits == true || (isClientEdit == true && editConfig.canEditBrandDetail == true))"
         class="tyl-margin-top-large">
      <a for="f02" class="tylie-lnk" (click)="addBillToAndCostCenter(t.data)">
        edit detail
      </a>
      <a *ngIf="(this.allowEdits == true || (isClientEdit == true && editConfig.canAddEditCustomTags == true)) && !t.data.displayCustomConfigurations" for="f02"
         class="tylie-lnk ms-3" (click)="onAddEditCustomTags(t.data)">
        add custom tags
      </a>
    </div>-->
        </div>

        <div class="my-4">
          <div *ngIf="t.data.costCenters?.length > 0" class="tyl-wordwrap">
            <span class="tyl-bold p-small">Cost Center: </span>
            <span>{{t.data.costCenter}}</span>
          </div>
          <div *ngIf="t.data.billTos?.length > 0" class="tyl-wordwrap">
            <span class="tyl-bold p-small">Bill To: </span>
            <span>{{t.data.billTo}}</span>
          </div>
          <div *ngIf="t.data.cutDownServicesOfferedIds?.length > 0" class="tyl-wordwrap">
            <span class="tyl-bold p-small">Cutdown Services: </span>
            <span>{{t.data.cutDownServicesOffered}}</span>
          </div>
          <div *ngIf="t.data.bundleServices && t.data.bundleServices.length > 0">
            <span class="tyl-bold p-small">Bundle Services: </span>
            <ng-container *ngFor="let bundleSerStr of t.data.bundleServices; let i = index">
              <span class="tyl-wordwrap">
                {{bundleSerStr.bundleServiceName}} ({{bundleSerStr.serviceNames}})<span *ngIf="i != (t.data.bundleServices.length-1)">, </span>
              </span>
            </ng-container>
          </div>
          <div *ngIf="t.data.podDistributionEmails" class="tyl-wordwrap">
            <span class="tyl-bold p-small">POD Recipients: </span>
            <span>{{t.data.podDistributionEmails}}</span>
          </div>
          <div *ngIf="t.data.opsNotes" class="tyl-wordwrap">
            <span class="tyl-bold p-small">Ops Notes: </span>
            <span>{{t.data.opsNotes}}</span>
          </div>
          <div *ngIf="t.data.cxComments" class="tyl-wordwrap">
            <span class="tyl-bold p-small">CX Comments: </span>
            <span>{{t.data.cxComments}}</span>
          </div>
          <div *ngIf="t.data.stdStorageDays" class="tyl-wordwrap">
            <span class="tyl-bold p-small">Standard (Minimum # of days): </span>
            <span>{{t.data.stdStorageDays}}</span>
          </div>
          <div *ngIf="t.data.infrqStorageDays" class="tyl-wordwrap">
            <span class="tyl-bold p-small">Infrequent Access (Minimum # of days): </span>
            <span>{{t.data.infrqStorageDays}}</span>
          </div>
          <div *ngIf="t.data.archiveDurationInDays" class="tyl-wordwrap">
            <span class="tyl-bold p-small">Archive (Minimum # of days): </span>
            <span>{{t.data.archiveDurationInDays}}</span>
          </div>
          <div *ngIf="t.data.activeTandemStorageInDays" class="tyl-wordwrap">
            <span class="tyl-bold p-small">Active Tandem Storage (Minimum # of days): </span>
            <span>{{t.data.activeTandemStorageInDays}}</span>
          </div>
          <div *ngIf="t.data.isWCPFileDeliveryBilled == true" class="tyl-wordwrap">
            <span for="isWCPFileDeliveryBilled" class="p-tiny-bold">WCP File Delivery Billed</span>
          </div>
          <div *ngIf="t.data.attachments?.length > 0" class="tyl-wordwrap">
            <span class="tyl-bold p-small">Attachments: </span>
            <span>{{t.data.attachments?.length}}</span>
          </div>
        </div>

        <ng-container *ngIf="(this.allowEdits == true || (isClientEdit == true && editConfig.canEditBrandDetail == true))">
          <a for="f02" class="tylie-lnk" (click)="addBillToAndCostCenter(t.data)">
            edit detail
          </a>
          <a *ngIf="(this.allowEdits == true || (isClientEdit == true && editConfig.canAddEditCustomTags == true)) && !t.data.displayCustomConfigurations" for="f02" class="tylie-lnk" (click)="onAddEditCustomTags(t.data)">ADD CUSTOM TAGS</a>
        </ng-container>

        <!--<ng-container *ngIf="(this.allowEdits == true || (isClientEdit == true && editConfig.canEditBrandDetail == true)) && ((t.data.standardStorageDurationInDays != null && t.data.standardStorageDurationInDays?.length > 0) || (t.data.infrequentStorageDurationInDays != null && t.data.infrequentStorageDurationInDays?.length > 0) || (t.data.archiveDurationInDays != null && t.data.archiveDurationInDays?.length > 0) || (t.data.podDistributionEmails != null && t.data.podDistributionEmails?.length > 0) || t.data.costCenters?.length > 0 || t.data.billTos?.length > 0 || t.data.cutDownServicesOfferedIds?.length > 0 || t.data.bundleServices?.length > 0 )">
    <a for="f02" class="tylie-lnk" (click)="addBillToAndCostCenter(t.data)">
      edit detail
    </a>
    <a *ngIf="(this.allowEdits == true || (isClientEdit == true && editConfig.canAddEditCustomTags == true)) && !t.data.displayCustomConfigurations" for="f02" class="tylie-lnk" (click)="onAddEditCustomTags(t.data)">ADD CUSTOM TAGS</a>
  </ng-container>-->

        <ng-container *ngIf="t.data.displayCustomConfigurations">
          <!--change to  if and else blocks-->
          <div class="my-4">
            <div class="tyl-bold p-small">Custom Tags:</div>
            <span>{{t.data.displayCustomConfigurations}}</span>
          </div>
          <a for="f02" *ngIf="(this.allowEdits == true || (isClientEdit == true && editConfig.canAddEditCustomTags == true))" class="tylie-lnk ms-3" (click)="onAddEditCustomTags(t.data)">
            edit
          </a>
        </ng-container>

        <ng-container *ngIf="t.data.transcodeBillingSettings">
          <!--change to  if and else blocks-->
          <div class="my-4">
            <div class="tyl-bold p-small">Transcode Billing Settings:</div>
            <span>{{t.data.transcodeBillingSettings.billingType}} ({{t.data.transcodeBillingSettings.billingCycle}})</span>
          </div>
          <a for="f02" *ngIf="(this.allowEdits == true || (isClientEdit == true && editConfig.canAddEditBillingSettings == true))" class="tylie-lnk me-3" (click)="onAddEditTranscodeBillingSettings(t.data)">
            edit
          </a>
          <a for="f02" *ngIf="(this.allowEdits == true || (isClientEdit == true && editConfig.canAddEditBillingSettings == true))" class="tylie-lnk" (click)="onRemoveTranscodeBillingSettings(t.data)">
            remove
          </a>
        </ng-container>

        <ng-container *ngIf="t.data.assetShareBillingSettings">
          <!--change to  if and else blocks-->
          <div class="my-4">
            <div class="tyl-bold p-small">Asset Share Billing Settings:</div>
            <span>{{t.data.assetShareBillingSettings.billingType}} ({{t.data.assetShareBillingSettings.billingCycle}})</span>
          </div>
          <a for="f02" *ngIf="(this.allowEdits == true || (isClientEdit == true && editConfig.canAddEditBillingSettings == true))" class="tylie-lnk me-3" (click)="onAddEditAssetShareBillingSettings(t.data)">
            edit
          </a>
          <a for="f02" *ngIf="(this.allowEdits == true || (isClientEdit == true && editConfig.canAddEditBillingSettings == true))" class="tylie-lnk" (click)="onRemoveAssetShareBillingSettings(t.data)">
            remove
          </a>
        </ng-container>

        <ng-container *ngIf="t.data.managementFeeSettings">
          <!--change to  if and else blocks-->
          <div class="my-4">
            <div class="tyl-bold p-small">Management Fee Settings:</div>
            <span>{{t.data.managementFeeSettings.billingCycle}}</span>
          </div>
          <a for="f02" *ngIf="(this.allowEdits == true || (isClientEdit == true && editConfig.canAddEditBillingSettings == true))" class="tylie-lnk me-3" (click)="onAddEditManagementFeeSettings(t.data)">
            edit
          </a>
          <a for="f02" *ngIf="(this.allowEdits == true || (isClientEdit == true && editConfig.canAddEditBillingSettings == true))" class="tylie-lnk" (click)="onRemoveManagementSettings(t.data)">
            remove
          </a>
        </ng-container>

        <ng-container *ngIf="t.data.storageManagementFeeSettings">
          <!--change to  if and else blocks-->
          <div class="my-4">
            <div class="tyl-bold p-small">Cloud Storage Billing Settings:</div>
            <span>{{t.data.storageManagementFeeSettings.billingCycle}}</span>
          </div>
          <a for="f02" *ngIf="(this.allowEdits == true || (isClientEdit == true && editConfig.canAddEditBillingSettings == true))" class="tylie-lnk me-3" (click)="onAddEditStorageManagementFeeSettings(t.data)">
            edit
          </a>
          <a for="f02" *ngIf="(this.allowEdits == true || (isClientEdit == true && editConfig.canAddEditBillingSettings == true))" class="tylie-lnk" (click)="onRemoveStorageManagementSettings(t.data)">
            remove
          </a>
        </ng-container>
      </div>

      <div *dxTemplate="let t of 'actionsTemplate'">
        <div style="text-align:center;" class="tyl-vertical-align">
          <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onBrandStoreDelete(t.data)">
            <icon-trash-can color="#1e90ff"></icon-trash-can>
          </a>
        </div>
      </div>

      <div *dxTemplate="let t of 'productTemplate'">
        <ng-container *ngFor="let product of t.data.products; let i = index">
          <div [ngClass]="{'tyl-12-margin-bottom': i != t.data.products.length-1 && ((product.costCenter != null && product.costCenter?.length > 0) || (product.inVoiceTo != null && product.inVoiceTo?.length > 0) || (product.isTrafficEmailInPODDisplayed) || (product.podDistributionEmails != null && product.podDistributionEmails?.length > 0))}">
            <span class="p-normal-bold ">
              {{product.name}}
            </span>
            <div [ngClass]="{'tyl-margin-top-large': (product.costCenter != null && product.costCenter?.length > 0) || (product.inVoiceTo != null && product.inVoiceTo?.length > 0) || (product.podDistributionEmails != null && product.podDistributionEmails?.length > 0) || (product.isTrafficEmailInPODDisplayed)}">
              <div class="tyl-wordwrap" *ngIf="product.costCenter != null && product.costCenter?.length > 0">
                <span class="tyl-bold p-small">Cost Center: &nbsp; </span>
                <span>{{product.costCenterDisplay}}</span>
              </div>
              <div class="tyl-wordwrap" *ngIf="product.inVoiceTo != null && product.inVoiceTo?.length > 0">
                <span class="tyl-bold p-small">Bill To: &nbsp; </span>
                <span>{{product.billToDisplay}}</span>
              </div>
              <div *ngIf="product.podDistributionEmails" class="tyl-wordwrap">
                <span class="tyl-bold p-small">POD Recipients: </span>
                <span>{{product.podDistributionEmails}}</span>
              </div>
              <div *ngIf="product.isTrafficEmailInPODDisplayed" class="tyl-wordwrap">
                <span class="tyl-bold p-small">Traff. Email In POD: </span>
                <span>{{product.isTrafficEmailInPODDisplayed}}</span>
              </div>
              <div *ngIf="product.stdStorageDays" class="tyl-wordwrap">
                <span class="tyl-bold p-small">Standard (Minimum # of days): </span>
                <span>{{product.stdStorageDays}}</span>
              </div>
              <div *ngIf="product.infrqStorageDays" class="tyl-wordwrap">
                <span class="tyl-bold p-small">Infrequent Access (Minimum # of days): </span>
                <span>{{product.infrqStorageDays}}</span>
              </div>
              <div *ngIf="product.archiveDurationInDays" class="tyl-wordwrap">
                <span class="tyl-bold p-small">Archive (Minimum # of days): </span>
                <span>{{product.archiveDurationInDays}}</span>
              </div>
              <div *ngIf="product.activeTandemStorageInDays" class="tyl-wordwrap">
                <span class="tyl-bold p-small">Active Tandem Storage (Minimum # of days): </span>
                <span>{{product.activeTandemStorageInDays}}</span>
              </div>
            </div>
          </div>
        </ng-container>
        <div *ngIf="t.data.products.length > 0 && (this.allowEdits == true || (isClientEdit == true && editConfig.canEditBrandDetail == true))" class="tyl-margin-top-large">
          <a for="f02" class="tylie-lnk" (click)="addClientBrandProduct(t.data)">
            edit
          </a>
        </div>
        <a style="vertical-align:middle;" *ngIf="t.data.hasBrandProducts == true && t.data.products.length  == 0 && (this.allowEdits == true || (isClientEdit == true && editConfig.canEditBrandDetail == true))" for="f02" class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" (click)="addClientBrandProduct(t.data)">
          <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>ADD PRODUCTS
        </a>
      </div>

      <div *dxTemplate="let t of 'vendorTemplate'">
        <div *ngIf="(this.allowEdits == true || (isClientEdit == true && editConfig.canEditBrand == true))">
          <div class="my-4" *ngIf="(t.data.preferredNetworkName != null && t.data.preferredNetworkName?.length > 0)">
            <div class="tyl-bold p-small">Preferred Network:</div>
            <span>{{t.data.preferredNetworkName}}</span>
          </div>
          <div class="my-4" *ngIf="(t.data.excludedNetwork != null && t.data.excludedNetwork?.length > 0)">
            <div class="tyl-bold p-small">Excluded Networks:</div>
            <span>{{t.data.excludedNetwork}}</span>
          </div>
          <a for="f02" class="tylie-lnk" (click)="editVendorPreference(t.data)">
            edit vendor info
          </a>
          <!--<dx-select-box [dataSource]="preferredVendorsDataSource"
              #preferredVendors
              name="preferredVendors" id="preferredVendors" class="form-control"
              valueExpr="id"
              [showClearButton]="true"
              displayExpr="name"
              placeholder="Select a Network"
              [value]="t.data.preferredNetwork"
              (onSelectionChanged)="onSelectionChangedPreferredVendor($event, t.data.brandId)">
</dx-select-box>-->
        </div>
        <div *ngIf="(isClientView == true || (isClientEdit == true && editConfig.canEditBrand == false))">
          <div class="my-4" *ngIf="(t.data.preferredNetworkName != null && t.data.preferredNetworkName?.length > 0)">
            <div class="tyl-bold p-small">Preferred Network:</div>
            <span>{{t.data.preferredNetworkName}}</span>
          </div>
          <div class="my-4"  *ngIf="(t.data.excludedNetwork != null && t.data.excludedNetwork?.length > 0)">
            <div class="tyl-bold p-small">Excluded Networks:</div>
            <span>{{t.data.excludedNetwork}}</span>
          </div>
        </div>
      </div>

      <div *dxTemplate="let t of 'approvalLvlTemplate'">
        <div *ngIf="(this.allowEdits == true || (isClientEdit == true && editConfig.canEditBrand == true))">
          <dx-select-box [dataSource]="approvalLevelsDataSource"
                          #approvalLevel
                          name="approvalLevel" id="approvalLevel" class="form-control"
                          valueExpr="id"
                          displayExpr="name"
                          [value]="t.data.approvalLevel"
                          (onSelectionChanged)="onSelectionChangedApprovalLvl($event, t.data.brandId)">
          </dx-select-box>
        </div>
        <div *ngIf="(isClientView == true || (isClientEdit == true && editConfig.canEditBrand == false))">
          {{t.data.approvalLevel}}
        </div>
      </div>

      <div *dxTemplate="let t of 'destHubTemplate'">
        <div style="text-align:center;" *ngIf="(this.allowEdits == true || (isClientEdit == true && editConfig.canEditBrand == true))">
          <dx-check-box [value]="t.data.isDestinationHubbed"
                        (onValueChanged)="onValueChanged_destHub($event, t.data.brandId)"></dx-check-box>
        </div>
        <div *ngIf="(isClientView == true || (isClientEdit == true && editConfig.canEditBrand == false))">
          {{t.data.isDestinationHubbed == true? 'Yes': 'No'}}
        </div>
      </div>

      <div *dxTemplate="let t of 'TrafficEmailInPODDisplayed'">
        <div style="text-align:center;" *ngIf="(this.allowEdits == true || (isClientEdit == true && editConfig.canEditBrand == true))">
          <dx-check-box [value]="t.data.isTrafficEmailInPODDisplayed"
                        (onValueChanged)="onValueChanged_TrafficEmailInPODDisplayed($event, t.data.brandId)"></dx-check-box>
        </div>
        <div *ngIf="(isClientView == true || (isClientEdit == true && editConfig.canEditBrand == false))">
          {{t.data.isTrafficEmailInPODDisplayed == true? 'Yes': 'No'}}
        </div>
      </div>

    </dx-data-grid>
  </div>
  <div class="tylie-panel__body" *ngIf="isClientView == true && (clientBrands == null || clientBrands?.length == 0)">
    No brands are associated with this client.
  </div>
</div>
