import { finalize } from 'rxjs/operators';
import { Component, OnInit, ViewChild, PACKAGE_ROOT_URL, ChangeDetectorRef, NgZone, Inject, ViewChildren, QueryList, ElementRef, Input } from '@angular/core';
import { Utilities } from "../../../../services/core/utilities";
import { AlertService, MessageSeverity } from '../../../../services/core/alert.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Constants } from "../../../../configurations/constants";
import { FormGroup, FormControl, FormBuilder, Validators, NgForm, FormArray, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { CreateOrderRequest, OrderHeader, OrderGroup, OrderFile, SpotServiceData, ServiceData, ServiceMetadata, OrderInvalidDestination, UpdatedResults, OrderInvalidAdId, SelectedOrderDestinations, CopyOrderFileRequestViewModel, CopyOrderFileRequest } from '../../../../models/order/order-create.model';
import { OrderService } from '../../../../services/order/order.service';
import { CreateOrderConfig } from '../../../../models/order/createorder-config.model';
import { ConfigService } from '../../../../services/core/config.service';
import { OrderDestination } from '../../../../models/order/order-destinations.model';
import { ClientBrandAttachment, PresignedUrlS3Upload, UploadOrderFileRequest } from '../../../../models/order/order-uploadfile.model';
import * as moment from 'moment-timezone';
import { DxTreeViewComponent, DxDateBoxComponent } from 'devextreme-angular';
import { UserTimeZoneToUtc } from '../../../../pipes/usertimezoneToUtc.pipe';
import { UserTimeZone } from '../../../../pipes/usertimezone.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { InfoPopupComponent } from '../../../popups/infopopup/infopopup.component';
import { confirmEventType, deliveryMethodEnum, metadataCategoryEnum } from '../../../../configurations/enums/enums';
import { ConfirmPopupComponent } from '../../../popups/confirmationpopups/confirmpopup.component';
import { ServiceType } from '../../../../models/order/servicetype.model';
import { Subscription, Observable } from 'rxjs';
import { OrderConfirmationPopupComponent } from '../../../popups/orderconfirmationpoup/orderconfirmationpopup.component';
import { OrderFinalizePopupComponent } from '../../../popups/orderfinalizepopup/orderfinalizepopup.component';
import { UUID } from 'angular2-uuid';
import { SelectAttachmentCategoryPopupComponent } from '../../../popups/selectattachmentcategorypopup/selectattachmentcategorypopup.component';
import { OrderAdditionalMetadata } from '../../../../models/order/order-additionalmetadata';
import { OrderErrors } from '../../../../models/order/ordererror.model';
import { UserProfileService } from '../../../../services/user/user-profile.service';
import { RouteConstants } from "../../../../configurations/route-constants";
import { SpotResendConfirm } from '../../../../models/order/spot-onhand.model';
import { SpotResendPopupComponent } from '../../../popups/spotresendpopup/spotresendpopup.component';
import { OrderAddServicePopupComponent } from '../../../popups/orderaddservicepopup/orderaddservicepopup.component';
import { SpotSelectedServicesRequest, SpotServicesOptionsRequest } from '../../../../models/order/order-spotselectedservice-request.model';
import { OrderInvalidDataPopupComponent } from '../../../popups/orderinvaliddatapopup/orderinvaliddatapopup.component';
import { totalmem, constants } from 'os';
import { CurrencyPipe, DOCUMENT } from '@angular/common';
import { Array } from 'core-js';

import { UploadOrderFileService } from '../../../../services/order/uploadorderfile.service';
import { SpotDestinationComb } from '../../../../models/order/order-spotdestcomb.model';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { AuthService } from '../../../../services/auth/auth.service';
import { CreateOrderGroupComponent } from './../create-order-group/createordergroup.component';
import { GlobalService } from '../../../../services/core/global.service';
import { EmailListValidator, EmailListValidatorIfValueExists } from '../../../../configurations/custom-validations';
import { OrderAddShareLinksPopupComponent } from '../../../popups/orderaddsharelinkspopup/orderaddsharelinkspopup.component';
import { SpotFile } from '../../../../models/spot/spot-file.model';
import { RouteToTranscodeEmitData } from '../../../../models/order/order-detail.model';
import { copy } from 'angular';

var stringify = require('json-stringify-safe');

@Component({
  selector: 'createorder-content',
  templateUrl: './createordercontent.component.html',
  styleUrls: ['./createordercontent.component.css'],
  providers: [UserTimeZone, UserTimeZoneToUtc, CurrencyPipe]
})
export class CreateOrderContentComponent implements OnInit {

  public createOrderRequest: FormGroup;
  public createOrderConfig: CreateOrderConfig;
  public createOrderObject: CreateOrderRequest;

  public modalRef: BsModalRef;

  public clientDataSource: any = [];
  public brandDataSource: any = [];
  public productDataSource: any = [];
  public specialVisibilityClientDataSource: any = [];

  private previousClientId: number = null;
  private previousBrandId: number = null;
  private previousAgencyId: number = null;

  @Input() isPopOver: boolean;

  @Input() orderDraftGuid: string;
  @Input() unSavedOrderGuid: string;

  @Input() uploadedOrderGuid: string;

  @Input() copyOrderGuid: string;
  @Input() copyOptions: any;

  private loadingDraft: boolean = false;
  public isDraftOrder: boolean = false;
  private draftOrderNumber: string = "";

  public additionalMetadata: OrderAdditionalMetadata = null;

  private loadingConfig: boolean = false;

  public triedToSaveDraft: boolean = false;
  public triedToSubmitOrder: boolean = false;
  public triedToAddMedia: boolean = false;

  public headerErrors: Array<string> = [];

  public isAttaching: boolean = false;
  private createOrderModuleType: string = "draft";
  private userProfile: any;

  public inVoiceToDataSource: any = [];

  public costCenterDataSource: any = [];
  public orderedByDataSource: any = [];

  public ordersRoute = RouteConstants.ordersRoute;
  public invoiceAndBillToNoDataText = Constants.invoiceAndBillToInitialNoDataText;
  public orderedByNoDataText = Constants.orderedByNoDataText;

  public containsServiceErrors: boolean = false;
  public UserCorrectedDestinations: Array<OrderInvalidDestination> = null;

  public isOrderSubmitted: boolean = false;

  public static readonly DIGITALDELIVERY: string = "digital delivery";
  public static readonly POSTPRODUCTION: string = "post production";
  public static readonly ADDITIONAL: string = "additional";
  public isBackFromTrancodeRequest: boolean = false;
  public isRoutingToTranscodeRequest: boolean = false;

  @ViewChild('clearOrderpop', { static: false }) clearOrderpop: PopoverDirective;

  @ViewChildren(CreateOrderGroupComponent) chs: QueryList<CreateOrderGroupComponent>;

  @ViewChild(DxDateBoxComponent, { static: true }) airDtStart: DxDateBoxComponent;
  @ViewChild(DxDateBoxComponent, { static: true }) airDtEnd: DxDateBoxComponent;
  @ViewChild('selectedFile', { static: true }) selectedFile: ElementRef;

  constructor(public util: Utilities,
    private alertService: AlertService,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    private _fb: FormBuilder,
    private userProfileService: UserProfileService,
    private utz: UserTimeZone,
    private route: ActivatedRoute,
    private utzToUtc: UserTimeZoneToUtc,
    private modalService: BsModalService,
    private orderService: OrderService,
    private uploadOrderFileService: UploadOrderFileService,
    private cp: CurrencyPipe,
    private cdr: ChangeDetectorRef,
    private gs: GlobalService,
    ngZone: NgZone,
    @Inject(DOCUMENT) private document: Document) {
    window.onscroll = (e) => {
      ngZone.run(() => {
        if (this.document.documentElement.scrollTop > 50) {
          $('.scrolltop:hidden').stop(true, true).fadeIn();
        } else {
          $('.scrolltop').stop(true, true).fadeOut();
        }
      });
    };
  }

  ngOnInit() {
    this.isRoutingToTranscodeRequest = false;

    this.util.userCreatOrderForm = null;

    this.userProfile = this.userProfileService.getUserProfile();

    this.additionalMetadata = { orderGuid: '', spotTracCode: '', allowDestinationHub: false, allowSpecialOptions: false, ppFileSpecs: [], transcodePredefinedSpecs: [], customFileNameOptions: [] };

    this.createEmptyForm();

    if (this.isBackFromTrancodeRequest) {
      this.createOrderRequest.controls['orderGuid'].setValue(this.unSavedOrderGuid);
      this.createOrderRequest.controls['orderDraftGuid'].setValue(this.unSavedOrderGuid);
    }
    else {
       this.createOrderRequest.controls['orderGuid'].setValue(UUID.UUID()); 
    }

    if (this.unSavedOrderGuid != null && this.unSavedOrderGuid != '') {
      this.getUnSavedOrder();
      //orderGuid will be set to unSavedOrderGuid when getUnSavedOrder is successful.
    }
    else if (this.orderDraftGuid != null && this.orderDraftGuid != '') {
      this.isDraftOrder = true;
      this.getDraft();
      //orderGuid will be set to orderDraftGuid when getDraft is successful.
    }
    else if (this.copyOrderGuid != null && this.copyOrderGuid != '') {
      this.getCopyOrderDetail();
      //set to new one above. don't use the copied one.
    }
    else if (this.uploadedOrderGuid != null && this.uploadedOrderGuid != '') {
      this.createOrderModuleType = 'upload';
      this.getUploadedOrderDraft();
      //orderGuid will be set to uploadedOrderGuid when getUploadedOrder is succesful.
    }
    else {
      this.groups.push(this.newGroupForm);

      this.getConfig();
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();

  }

  //#region public functions

  public onHideClearOrderPop() {
    this.clearOrderpop.hide();
  }

  public clearSavedData(): void {

    this.createEmptyForm();

    this.groups.push(this.newGroupForm);

    if (this.orderDraftGuid != null && this.orderDraftGuid != '')
      this.createOrderRequest.controls['orderGuid'].setValue(this.orderDraftGuid);
    else if (this.copyOrderGuid != null && this.copyOrderGuid != '')
      this.createOrderRequest.controls['orderGuid'].setValue(UUID.UUID());
    else if (this.uploadedOrderGuid != null && this.uploadedOrderGuid != '')
      this.createOrderRequest.controls['orderGuid'].setValue(this.uploadedOrderGuid);
    else
      this.createOrderRequest.controls['orderGuid'].setValue(UUID.UUID());

    this.util.userCreatOrderForm = null;

    this.triedToSubmitOrder = false;
    this.triedToSaveDraft = false;
    this.triedToAddMedia = false;

    this.onHideClearOrderPop();
  }

  public hasUnsavedData(): boolean {
    return (this.util.userCreatOrderForm != null && this.isOrderSubmitted == false && this.authService.isLoggedIn() && !this.isRoutingToTranscodeRequest);
  }

  public onScrollToTop() {
    this.util.scrollTo('scrollContainer');
  }

  public onAddNewGroup() {

    this.groups.push(this.newGroupForm);
  }

  public onTryingToAddMediaInGroup() {
    this.triedToSubmitOrder = false;
    this.triedToSaveDraft = false;
    this.triedToAddMedia = true;
  }

  public onSubmitOrder({ value, valid }: { value: CreateOrderRequest, valid: boolean }) {
    //this.createOrderObject = value; // we don't need this - testing
    //this.uploadClientLevelFiles();  // we don't need this - testing
    //return;                         // we don't need this - testing

    if (!this.isUploadedDataValid())
      return;

    this.triedToSubmitOrder = true;
    this.triedToSaveDraft = false;
    this.triedToAddMedia = false;

    var isOrderValid: boolean = valid;

    var warningExists: boolean = false;
    if (this.airDtEnd != null && !this.airDtEnd.isValid)
      isOrderValid = false;

    if (this.airDtStart != null && !this.airDtStart.isValid)
      isOrderValid = false;

    if (this.header.controls['podEmails'].hasError('inValidEmailList'))
      isOrderValid = false;

    if (this.containsServiceErrors == true)
      isOrderValid = false;

    if (this.groups.controls.length == 0 && this.additionalMetadata.allowSpecialOptions == false)
      isOrderValid = false;

    if (this.groups.controls.length == 0 && this.additionalMetadata.allowSpecialOptions == true) {
      var existingServices = this.header.controls['serviceData'].value as Array<ServiceData>;

      if (existingServices == null || existingServices.length == 0) {
        isOrderValid = false;

        this.util.addError(this.headerErrors, 'No Groups or Order services added.');
      }
      else
        this.headerErrors = this.util.removeError(this.headerErrors, 'No Groups or Order services added.');
    }

    this.chs.forEach(p => {
      p.setGroupError();
    });

    if (isOrderValid == true) {
      for (var i = 0; i <= this.groups.controls.length - 1; i++) {
        var gp = <FormGroup>this.groups.controls[i];

        if (gp.controls['containsGroupErrors'].value == true)
          isOrderValid = false;
        if (gp.controls['containsAdIDWarnings'].value == true)
          warningExists = true;
      }
    }

    let isGroupsValid: boolean = this.isGroupValidationPassed();

    if (isOrderValid === false || isGroupsValid === false) {
      this.alertService.showMessage("ERROR", Constants.submitMandatoryFields, MessageSeverity.error);
      return;
    }

    this.createOrderObject = value;

    this.showOrderFinalize(warningExists);
  }

  public onSaveChanges({ value, valid }: { value: CreateOrderRequest, valid: boolean }) {
    if (this.createOrderModuleType == 'upload')
      this.onSaveUploadedOrderChanges(value);
    else
      this.onSaveDraft(value);

    this.raiseOrderEditEvents();
  }

  public onSaveUploadedOrderChanges(value: CreateOrderRequest) {

    this.triedToSubmitOrder = false;
    this.triedToSaveDraft = true;
    this.triedToAddMedia = false;

    if (this.header.controls["clientId"].errors || this.header.controls["brandId"].errors || this.header.controls["productId"].errors) {
      this.alertService.showMessage("ERROR", Constants.draftMandatoryFields, MessageSeverity.error);
      return;
    }

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.createOrderObject.orderDraftGuid = this.uploadedOrderGuid;


    this.orderService.saveUploadedOrderChanges(value).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESSS", Constants.draftCreated, MessageSeverity.success, true);
        //this.gotoOrderHome();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.util.userCreatOrderForm = null;
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
        this.util.userCreatOrderForm = null;
      });
  }

  public onSaveDraft(value: CreateOrderRequest) {

    this.triedToSubmitOrder = false;
    this.triedToSaveDraft = true;
    this.triedToAddMedia = false;

    if (this.header.controls["clientId"].errors || this.header.controls["brandId"].errors || this.header.controls["productId"].errors) {
      this.alertService.showMessage("ERROR", Constants.draftMandatoryFields, MessageSeverity.error);
      return;
    }

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.orderService.saveDraft(value).subscribe((res: any) => {

      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESSS", Constants.draftCreated, MessageSeverity.success, true);
        //this.gotoOrderHome();
        this.createOrderRequest.controls['orderGuid'].setValue(res.result.draftOrderGuid);
        this.createOrderRequest.controls['orderDraftGuid'].setValue(res.result.draftOrderGuid);
        this.orderDraftGuid = res.result.draftOrderGuid;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.util.userCreatOrderForm = null;

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
        this.util.userCreatOrderForm = null;
      });
  }

  public saveUnSavedOrder(value: CreateOrderRequest, routeToTranscode: boolean, e: RouteToTranscodeEmitData, groupSequenceId: number) {
    this.triedToSubmitOrder = false;
    this.triedToSaveDraft = true;
    this.triedToAddMedia = false;

    if (this.header.controls["clientId"].errors || this.header.controls["brandId"].errors || this.header.controls["productId"].errors) {
      this.alertService.showMessage("ERROR", Constants.draftMandatoryFields, MessageSeverity.error);
      return;
    }

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.orderService.saveUnSavedOrder(value).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.createOrderRequest.controls['orderGuid'].setValue(res.result.unSavedOrderGuid);
        this.router.navigate([RouteConstants.orderTrancodesRoute], {
          state: {
            transcodeOrigination: 'Order', groupSequenceId: groupSequenceId, orderGuid: this.unSavedOrderGuid, orderGrpTranscodeEditSpotFileGuid: e.spotFileGuid
          }
        });
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.util.userCreatOrderForm = null;

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
        this.util.userCreatOrderForm = null;
      });
  }

  public onClientValueChanged(e) {

    if (e.value === this.previousClientId || e.value == null)
      return;

    if (this.anyMediaInGroups() && this.loadingDraft == false && this.loadingConfig == false) {

      var initialState = {
        eventType: confirmEventType[confirmEventType.ChangeOrderClient]
      };

      this.modalRef = this.modalService.show(ConfirmPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

      this.modalRef.content.onClose.subscribe(result => {
        //Added this to resolve copy order onClientChange set to null on result no
        this.previousClientId = e.previousValue;
        this.onClientChangeSubscriptionRaised(result);
      });
    }
    else {
      this.onClientChangeConfirmed();
    }

    this.setInVoiceToAndCostCenterData();
    this.getOrderedByData(e.value);
  }

  public onAgencyValueChanged(e) {

    if (e.value === this.previousAgencyId || e.value == null)
      return;

    if (this.anyMediaInGroups() && this.loadingDraft == false && this.loadingConfig == false) {

      var initialState = {
        eventType: confirmEventType[confirmEventType.ChangeOrderAgency]
      };

      this.modalRef = this.modalService.show(ConfirmPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

      this.modalRef.content.onClose.subscribe(result => {
        //Added this to resolve copy order onClientChange set to null on result no
        this.previousAgencyId = e.previousValue;
        this.onAgencyChangeSubscriptionRaised(result);
      });
    }
    else {
      this.onAgencyChangeConfirmed();
    }
  }

  public onBrandValueChanged(e) {

    if (e.value === this.previousBrandId || e.value == null)
      return;

    if (this.anyMediaInGroups() && this.loadingDraft == false && this.loadingConfig == false) {

      var initialState = {
        eventType: confirmEventType[confirmEventType.ChangeOrderBrand]
      };

      this.modalRef = this.modalService.show(ConfirmPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

      this.modalRef.content.onClose.subscribe(result => {
        //Added this to resolve copy order onBrandChange set to null on result no
        this.previousBrandId = e.previousValue;
        this.onBrandChangeSubscriptionRaised(result);
        //this.setInVoiceToAndCostCenterData();
      });
    }
    else {
      //this.setInVoiceToNames();
      this.onBrandChangeConfirmed();
    }

    this.setInVoiceToAndCostCenterData();
  }

  public onProductValueChanged(e) {

    if (e.value == null)
      return;

    this.setInVoiceToAndCostCenterData();
  }

  public onAddMediaToAllGroups(selectedSpotFiles: Array<SpotFile>) {
    this.chs.forEach(p => {
      p.onSelectSpotsSubscriptionRaised(selectedSpotFiles);
    });
  }

  public onAddDestinationsToAllGroups(allDestinationsSelected: SelectedOrderDestinations) {
    this.chs.forEach(p => {
      p.onSelectDestinationsSubscriptionRaised(allDestinationsSelected);
    });
  }

  public onDeleteGroup(seq: number) {
    var index = -1;
    for (var i = 0; i <= this.groups.controls.length - 1; i++) {
      var gp = <FormGroup>this.groups.controls[i];

      if (gp.controls['sequenceId'].value == seq.toString())
        index = i;
    }
    if (index > -1) {
      this.groups.removeAt(index);

      if (this.createOrderObject != null) {
        this.onDeleteRemoveGroupFromInvalidAdids(seq.toString());
        this.onDeleteRemoveGroupFromInvalidDestinations(seq.toString());
      }
    }

    for (var i = 0; i <= this.groups.controls.length - 1; i++) {
      var gp = <FormGroup>this.groups.controls[i];

      gp.controls['sequenceId'].setValue(i + 1);
    }

  }

  public onDeleteRemoveGroupFromInvalidAdids(seq: string) {
    for (var i = 0; i < this.createOrderObject.invalidAdIds.length; i++) {
      var index = this.createOrderObject.invalidAdIds[i].groups.findIndex(i => i.sequenceId.toLowerCase() == seq.toLowerCase());
      if (index > -1) {
        this.createOrderObject.invalidAdIds[i].groups = this.createOrderObject.invalidAdIds[i].groups.filter(j => j.sequenceId != seq);
        if (this.createOrderObject.invalidAdIds[i].groups == null || this.createOrderObject.invalidAdIds[i].groups.length == 0) {
          this.createOrderObject.invalidAdIds[i].isDeletedByUser = true;
          this.createOrderObject.invalidAdIds[i].status = "modified";
        }
      }
      for (var d = 0; d < this.createOrderObject.invalidAdIds[i].groups.length; d++) {
        var invalidAdid = this.createOrderObject.invalidAdIds[i].groups[d];
        if (invalidAdid != null) {
          if (invalidAdid.sequenceId >= seq) {
            invalidAdid.sequenceId = (+invalidAdid.sequenceId - 1).toString();
          }
        }
      }
    }
  }

  public onDeleteRemoveGroupFromInvalidDestinations(seq: string) {

    for (var i = 0; i < this.createOrderObject.invalidDestinations.length; i++) {
      var index = this.createOrderObject.invalidDestinations[i].groups.findIndex(i => i.sequenceId.toLowerCase() == seq.toLowerCase());
      if (index > -1) {
        this.createOrderObject.invalidDestinations[i].groups = this.createOrderObject.invalidDestinations[i].groups.filter(j => j.sequenceId != seq);
        if (this.createOrderObject.invalidDestinations[i].groups == null || this.createOrderObject.invalidDestinations[i].groups.length == 0) {
          this.createOrderObject.invalidDestinations[i].isDeletedByUser = true;
          this.createOrderObject.invalidDestinations[i].status = "modified";
        }
      }
      for (var d = 0; d < this.createOrderObject.invalidDestinations[i].groups.length; d++) {
        var invaliddestination = this.createOrderObject.invalidDestinations[i].groups[d];
        if (invaliddestination != null) {

          if (invaliddestination.sequenceId >= seq) {
            invaliddestination.sequenceId = (+invaliddestination.sequenceId - 1).toString();
          }
        }
      }
    }
  }

  public onAddAttachments() {
    var initialState = {
      attachmentCategories: this.createOrderConfig.attachmentCategories,
      orderGuid: this.createOrderRequest.controls['orderGuid'].value
    };

    this.modalRef = this.modalService.show(SelectAttachmentCategoryPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

    this.modalRef.content.onSelectAttachmentCategory.subscribe(result => {
      this.onSelectAttachmentCategorySubscriptionRaised(result);
    });
  }

  public onAddPO(event: any) {
    if (event.target.files && event.target.files.length > 0) {

      for (const uploadFile of event.target.files) {
        if (uploadFile.type !== 'application/pdf') {
          this.isAttaching = false;
          this.selectedFile.nativeElement.value = "";
          return;
        }
      }

      for (const uploadFile of event.target.files) {
        this.isAttaching = true;

        var request: UploadOrderFileRequest = {
          fileType: uploadFile.type,
          fileName: uploadFile.name,
          description: "",
          fileCategory: "Purchase Order",
          orderGuid: this.createOrderRequest.controls['orderGuid'].value,
          orderDestinationId: 0
        };

        this.uploadOrderFileService.uploadOrderFile(request, uploadFile).pipe(
          finalize(() => {
            this.isAttaching = false;
          }))
          .subscribe((newFile: OrderFile) => {
            if (newFile != null && newFile != undefined) {
              this.addFileToHeader(newFile)
            }
          });
      }
    }
    else
      this.isAttaching = false;
  }

  public onAttachmentDelete(orderFileGuid: string) {

    var isClientLevelFile = false;
    var existingAttachments = this.header.controls['attachments'].value as Array<OrderFile>;
    if (existingAttachments && existingAttachments != null && existingAttachments.length > 0) {
      var file = existingAttachments.find(t => t.orderFileGuid == orderFileGuid);
      isClientLevelFile = file.isClientLevelFile;
    }
    if (isClientLevelFile) {

      if (existingAttachments && existingAttachments != null && existingAttachments.length > 0) {
        var newAttachmentsAfterDelete = existingAttachments.filter(t => t.orderFileGuid.toLowerCase() != orderFileGuid.toLowerCase());
        this.header.controls['attachments'].setValue(newAttachmentsAfterDelete);
      }

    } else {
      this.orderService.deleteOrderFile(orderFileGuid).subscribe((res: any) => {
        if (res.isSuccess == true) {
          var existingAttachments = this.header.controls['attachments'].value as Array<OrderFile>;

          if (existingAttachments && existingAttachments != null && existingAttachments.length > 0) {
            var newAttachmentsAfterDelete = existingAttachments.filter(t => t.orderFileGuid.toLowerCase() != orderFileGuid.toLowerCase());

            this.header.controls['attachments'].setValue(newAttachmentsAfterDelete);
          }
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }

      },
        error => {
          this.util.handleError(error);
        });
    }
  }

  public get groups() {
    return <FormArray>this.createOrderRequest.get('groups');
  }

  public get header() {
    return (<FormGroup>this.createOrderRequest.controls['header']);
  }

  //public gotoOrderHome() {
  //  this.router.navigate([RouteConstants.ordersRoute]);
  //}

  public onDownloadFile(orderFileGuid: string, fileName: string) {

    if (orderFileGuid == null || orderFileGuid == '00000000-0000-0000-0000-000000000000' || orderFileGuid == '')
      return;

    var isClientLevelFile = false;
    var existingAttachments = this.header.controls['attachments'].value as Array<OrderFile>;
    if (existingAttachments && existingAttachments != null && existingAttachments.length > 0) {
      var file = existingAttachments.find(t => t.orderFileGuid == orderFileGuid);
      isClientLevelFile = file.isClientLevelFile;
    }
    if (isClientLevelFile) {
      this.orderService.downloadOpsAttachment(orderFileGuid)
        .subscribe((res: any) => {
          if (res.isSuccess == true) {
            var presignedUrl = res.result;
            this.util.downloadFile(this.util.getFileUrl(presignedUrl)).subscribe(
              fileData => {
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.href = window.URL.createObjectURL(fileData);
                a.download = fileName;
                a.click();
              });
          }
          else {
            this.util.handleIsNotSuccess(res.errors);
          }
        },
          error => {
            this.util.handleError(error);
          });
    } else {
      this.orderService.downloadOrderFile(orderFileGuid)
        .subscribe((res: any) => {
          if (res.isSuccess == true) {
            var presignedUrl = res.result;
            this.util.downloadFile(this.util.getFileUrl(presignedUrl)).subscribe(
              fileData => {
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.href = window.URL.createObjectURL(fileData);
                a.download = fileName;
                a.click();
              });
          }
          else {
            this.util.handleIsNotSuccess(res.errors);
          }
        },
          error => {
            this.util.handleError(error);
          });
    }
  }

  public onAddEditAddlServices(editMode: boolean) {

    var existingServices = this.header.controls['serviceData'].value as Array<ServiceData>;

    var initialState = {
      spotFileGuid: '',
      applyAllMode: false,
      editMode: editMode,
      orderGuid: this.createOrderRequest.controls['orderGuid'].value,
      serviceData: existingServices,
      showInEditMode: true,
      specialServiceMode: false,
      additionalMetadata: this.additionalMetadata,
      orderEditMode: false,
      orderLevel: true,
      groupLevel: false,
      spotLevel: false,
    };

    this.modalRef = this.modalService.show(OrderAddServicePopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));

    this.modalRef.content.onSelectServicesComplete.subscribe(result => {
      this.onApplyOrderServicesSubscriptionRaised(result);
    });

    this.modalRef.content.onServiceSubmitOptionsComplete.subscribe(result => {
      this.onSubmitOrderServiceOptionsRaised(result);
    });

  }

  public onServiceDelete(id: number) {

    var existingServices = this.header.controls['serviceData'].value as Array<ServiceData>;

    if (existingServices && existingServices != null && existingServices.length > 0) {
      var newServicesAfterDelete = existingServices.filter(t => t.serviceId != id);

      this.header.controls['serviceData'].setValue(newServicesAfterDelete);
    }

    this.setError();
  }

  public onServiceMetadataDelete(serviceId: number, metadataKey: string) {
    var existingServices = this.header.controls['serviceData'].value as Array<ServiceData>;

    if (existingServices != null) {
      var currentService = existingServices.find(t => t.serviceId == serviceId) as ServiceData;

      if (currentService != null) {
        currentService.serviceMetadata = currentService.serviceMetadata.filter(t => t.metadataKey.toLowerCase() != metadataKey.toLowerCase());

        this.header.controls['serviceData'].setValue(existingServices);
      }
    }

    this.setError();
  }

  public onCollapse() {
    (<any>$(".build-order .collapse")).collapse('hide');
  }

  public onCosolidatedFlagChanged() {
    var clientId = this.header.controls['clientId'].value;
    this.updateClientPOValidation(clientId);
  }

  public onAddMorePODEmails() {
    var initialState = {
      podMode: true
    };

    this.modalRef = this.modalService.show(OrderAddShareLinksPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, "modal-dialog--w60"));

    this.modalRef.content.onClose.subscribe(result => {
      this.onAddPODEmailsSubscriptionRaised(result);
    });
  }

  //#endregion

  //#region private functions

  private onAddPODEmailsSubscriptionRaised(allDestinationsSelected: SelectedOrderDestinations) {

    if (allDestinationsSelected === null)
      return;

    var selectedUserDistributionLists = allDestinationsSelected.shareLinks;

    if (selectedUserDistributionLists && selectedUserDistributionLists != null && selectedUserDistributionLists.length > 0) {
      var existingPODEmails: string = this.header.controls['podEmails'].value;
      var newEmails: Array<string> = [];

      if (existingPODEmails && existingPODEmails != null && existingPODEmails.trim() != '') {

        existingPODEmails = existingPODEmails.trim();

        if (existingPODEmails.endsWith(';') || existingPODEmails.endsWith(','))
          existingPODEmails = existingPODEmails.slice(0, -1);

        newEmails.push(existingPODEmails);
      }

      selectedUserDistributionLists.forEach(item => {
        item.groupEmails.forEach(em => {
          newEmails.push(em);
        });
      });

      this.header.controls['podEmails'].setValue(newEmails.join(';'));

      //if (!existingPODEmails || existingPODEmails == null || existingPODEmails == '')
      //  this.header.controls['podEmails'].setValue(newEmails.join(';'));
      //else if (existingPODEmails.endsWith(';') || existingPODEmails.endsWith(','))
      //  this.header.controls['podEmails'].setValue(existingPODEmails + newEmails.join(';'));
      //else
      //  this.header.controls['podEmails'].setValue(existingPODEmails + ';' + newEmails.join(';'));
    }
  }

  private raiseOrderEditEvents() {
    if (this.orderDraftGuid != null && this.orderDraftGuid != '')
      this.gs.draftOrderEdited();

    if (this.uploadedOrderGuid != null && this.uploadedOrderGuid != '')
      this.gs.uploadedOrderEdited();

    if (this.copyOrderGuid != null && this.copyOrderGuid != '')
      this.gs.copyOrderEdited();
  }

  private isUploadedDataValid(): boolean {

    var invalidDestinations = this.createOrderRequest.controls['invalidDestinations'].value;
    var invalidAdIds = this.createOrderRequest.controls['invalidAdIds'].value;

    if ((invalidAdIds != null && invalidAdIds.length > 0) ||
      (invalidDestinations != null && invalidDestinations.length > 0)) {
      var invalAdid = invalidAdIds.find(i => i.status == null);
      var invalDest = invalidDestinations.find(i => i.status == null)

      if (invalAdid != null || invalDest != null) {
        this.showInvalidDataCorrectionPopup();
        return false;
        //return;
      }
    }

    return true;
  }

  private isGroupValidationPassed(): boolean {

    let spotDest: Array<SpotDestinationComb> = [];
    let isValid: boolean = true;

    for (var i = 0; i <= this.groups.controls.length - 1; i++) {
      var gp = <FormGroup>this.groups.controls[i];
      var spots = gp.controls['spotData'].value as Array<SpotServiceData>;
      var destinations = gp.controls['destinations'].value as Array<OrderDestination>;
      var groupId = gp.controls['sequenceId'].value;

      spots.forEach(sd => {

        let adid: string = sd.ppSpotFile == null ? sd.spotFile.adId.toLowerCase() : sd.ppSpotFile.adId.toLowerCase();

        sd.serviceData.forEach(ser => {
          if (ser.service.category.toLowerCase() == CreateOrderContentComponent.DIGITALDELIVERY && ser.service.isDigital == true) {
            destinations.forEach(des => {
              /**tylie File Distribution changes**/
              //if (des.fileDeliveryType != null && des.fileDeliveryType.toUpperCase() != 'CLEARANCE') {
              if (des.destinationType != null && des.destinationType.toUpperCase() != 'CLEARANCE') {
                let dcode = des.code.toLowerCase();
                let existingEntry = spotDest.find(t => t.adid == adid && t.code == dcode);

                if (existingEntry != null) {
                  des.errorMsg = des.errorMsg + `Already exists for ${adid.toUpperCase()} in Group ${existingEntry.seq}. `;
                  des.containsDestinationError = true;
                  gp.controls['containsGroupErrors'].setValue(true);
                  isValid = false;
                }
                else {
                  spotDest.push({ adid: adid, code: dcode, seq: groupId });
                }
              }
            });
          }
        });

        sd.serviceData.forEach(ser => {
          if (ser.service.isClearance == true) {
            destinations.forEach(des => {
              /**tylie File Distribution changes**/
              //if (des.fileDeliveryType != null && des.fileDeliveryType.toUpperCase() == 'CLEARANCE') {
              if (des.destinationType != null && des.destinationType.toUpperCase() == 'CLEARANCE') {
                let dcode = des.code.toLowerCase();
                let existingEntry = spotDest.find(t => t.adid == adid && t.code == dcode);

                if (existingEntry != null) {
                  des.errorMsg = des.errorMsg + `Already exists for ${adid.toUpperCase()} in Group ${existingEntry.seq}. `;
                  des.containsDestinationError = true;
                  gp.controls['containsGroupErrors'].setValue(true);
                  isValid = false;
                }
                else {
                  spotDest.push({ adid: adid, code: dcode, seq: groupId });
                }
              }
            });
          }
        });
      });
    }

    return isValid;
  }

  private setError() {

    this.containsServiceErrors = false;
    var existingServices = this.header.controls['serviceData'].value as Array<ServiceData>;

    existingServices.forEach(s => {

      s.errorMsg = '';

      if (s.service.isAdditionalDataNeeded == true && this.util.isServiceMetadataValid(s.service.code, s.serviceMetadata, true, false, false, false, this.additionalMetadata.allowSpecialOptions) == false) {
        s.containsServiceMetadataDataErrors = true;
        s.errorMsg = Constants.orderError_OptionsMissing;
      }
      else
        s.containsServiceMetadataDataErrors = false;
    });

    if (existingServices.some(t => t.containsServiceMetadataDataErrors == true)) {
      this.containsServiceErrors = true;
    }
    else {
      this.containsServiceErrors = false;
    }


    this.header.controls['serviceData'].setValue(existingServices);
  }

  private onApplyOrderServicesSubscriptionRaised(selectedServicesRequest: SpotSelectedServicesRequest) {
    var existingServices = this.header.controls['serviceData'].value as Array<ServiceData>;

    selectedServicesRequest.serviceData.forEach(sdata => {
      if (existingServices.find(t => t.service.id === sdata.service.id) == null) {
        existingServices.push(this.copyServiceData(sdata));
      }
    });

    //existingServices = selectedServicesRequest.serviceData.filter(t => selectedServicesRequest.serviceData.some(f => f.service.id === t.service.id));
    existingServices = existingServices.filter(t => selectedServicesRequest.serviceData.some(f => f.service.id === t.service.id));

    this.header.controls['serviceData'].setValue(existingServices);

    this.setError();
  }

  private onSubmitOrderServiceOptionsRaised(spotServicesOptionsRequest: SpotServicesOptionsRequest) {
    var existingServices = this.header.controls['serviceData'].value as Array<ServiceData>;

    var newServiceDataAfterDelete = existingServices.filter(t => spotServicesOptionsRequest.deletedServices.find(s => s == t.service.id) == null);

    spotServicesOptionsRequest.addedServices.forEach(aSer => {
      if (newServiceDataAfterDelete.find(t => t.serviceId == aSer.serviceId) == null) {
        newServiceDataAfterDelete.push(this.copyServiceData(aSer));
      }
    });

    spotServicesOptionsRequest.serviceData.forEach(servItem => {
      var serviceData = newServiceDataAfterDelete.find(t => t.service.id === servItem.service.id);

      if (serviceData != null) {
        serviceData.serviceMetadata = [];

        servItem.serviceMetadata.forEach(sm => {
          serviceData.serviceMetadata.push({ category: sm.category, fileValue: sm.fileValue, objectValue: sm.objectValue, arrayValue: sm.arrayValue, metadataKey: sm.metadataKey, textValue: sm.textValue, display: sm.display });
        });

        serviceData.optionsSubmitted = true;
      }
      else {

      }
    });

    this.header.controls['serviceData'].setValue(newServiceDataAfterDelete);

    this.setError();
  }

  public isNonTextItemsExists(services: Array<ServiceData>): boolean {
    var exists = false;

    if (services == null)
      return exists;

    services.forEach(sdata => {
      sdata.serviceMetadata.forEach(mt => {
        if (mt.category === metadataCategoryEnum[metadataCategoryEnum.File]
          || mt.category === metadataCategoryEnum[metadataCategoryEnum.BigText]
          || mt.category === metadataCategoryEnum[metadataCategoryEnum.BigMultiple]
          || mt.category === metadataCategoryEnum[metadataCategoryEnum.CopyText])
          exists = true;
      });
    });

    return exists;
  }

  public getTextData(mdata: Array<ServiceMetadata>): string {
    var textData: Array<string> = [];

    mdata.forEach(mt => {
      if (this.isNonTextItem(mt) === false && this.isHiddenItem(mt) === false) {
        if (mt.textValue !== null && mt.textValue != '') {
          if (mt.category === metadataCategoryEnum[metadataCategoryEnum.HoursMins]) {
            if (mt.textValue != '0')
              textData.push(this.util.convertStringToHoursMins(mt.textValue));
          }
          else if (mt.category === metadataCategoryEnum[metadataCategoryEnum.Dollar]) {
            textData.push(this.cp.transform(mt.textValue, 'USD', true));
          }
          else
            textData.push(mt.textValue);
        }
        else if (mt.arrayValue !== null && mt.arrayValue != '')
          textData.push(mt.arrayValue);
        else if (mt.objectValue !== null && mt.objectValue != '') {
          if (mt.category === metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleMinsTransfers]) {

            var arr = this.util.convertStringToObjectMultiple(mt.objectValue);

            textData.push(arr.map(item => this.util.convertStringToHoursMins(item.val1) + ' - ' + item.val2).join(' , '));
          }
          else if (mt.category === metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired]) {
            var res = this.util.convertStringToThreeRequiredObject(mt.objectValue);
            textData.push(res.map(item => item.val1 + ' - ' + item.val2 + ' - ' + item.val3).join(' , '));
          }
          else if (mt.category === metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleFourRequired]) {
            var resu = this.util.convertStringToFourRequiredObject(mt.objectValue);
            textData.push(resu.map(item => item.val1 + ' - ' + item.val2 + ' - ' + item.val3 + ' - ' + item.val4).join(' , '));
          }
          else {

            var arr = this.util.convertStringToObjectMultiple(mt.objectValue);

            textData.push(arr.map(item => item.val1 + ' - ' + item.val2).join(' , '));
          }
        }
      }
    });

    if (textData.length > 0)
      return "(Options : " + textData.join(", ") + ")";
  }

  public isNonTextItem(mt: ServiceMetadata): boolean {
    return (mt.category === metadataCategoryEnum[metadataCategoryEnum.File]
      || mt.category === metadataCategoryEnum[metadataCategoryEnum.BigText]
      || mt.category === metadataCategoryEnum[metadataCategoryEnum.BigMultiple]
      || mt.category === metadataCategoryEnum[metadataCategoryEnum.CopyText]);
  }

  public onRouteToTranscodeRequests(e: RouteToTranscodeEmitData, groupSeqId: number) {
    this.unSavedOrderGuid = this.createOrderRequest.controls['orderGuid'].value;
    this.createOrderRequest.controls['orderDraftGuid'].setValue(this.unSavedOrderGuid);
    this.saveUnSavedOrder(this.createOrderRequest.value, true, e, groupSeqId);
  }

  private isHiddenItem(mt: ServiceMetadata) {
    return mt.category === metadataCategoryEnum[metadataCategoryEnum.NA];
  }

  private copyServiceData(sdata: ServiceData): ServiceData {
    let newSdata: ServiceData = {
      service: sdata.service, serviceMetadata: [], recentlyAdded: false, serviceId: sdata.service.id,
      containsServiceMetadataDataErrors: sdata.containsServiceMetadataDataErrors, errorMsg: sdata.errorMsg,
      optionsSubmitted: sdata.optionsSubmitted,
      parentServices: sdata.parentServices,
      displaySequence: sdata.displaySequence
    };

    sdata.serviceMetadata.forEach(sm => {
      newSdata.serviceMetadata.push({ category: sm.category, fileValue: sm.fileValue, objectValue: sm.objectValue, arrayValue: sm.arrayValue, metadataKey: sm.metadataKey, textValue: sm.textValue, display: sm.display });
    });

    return newSdata;
  }

  public getOrderedByData(id: number) {

    if (id === null || id <= 0) {
      return;
    }

    this.configService.getOrderedByData(id).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.orderedByDataSource = res.result;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  private setInVoiceToAndCostCenterData() {
    var productId = this.header.controls['productId'].value;
    var brandId = this.header.controls['brandId'].value;
    var clientId = this.header.controls['clientId'].value;

    this.header.controls['inVoiceTo'].setValue('');
    this.header.controls['costCenter'].setValue('');

    this.inVoiceToDataSource = [];
    this.costCenterDataSource = [];
    this.invoiceAndBillToNoDataText = Constants.invoiceAndBillToInitialNoDataText;

    if (clientId != null && clientId != undefined && clientId > 0 && brandId != null && brandId != undefined && brandId > 0 && productId != null && productId >= 0) {
      this.configService.getCostCenterAndInvoice({ clientId: clientId, brandId: brandId, productId: productId }).subscribe((res: any) => {
        if (res.isSuccess == true) {
          var costcenterAndInvoiceTo = res.result;
          this.costCenterDataSource = costcenterAndInvoiceTo.item2;
          this.inVoiceToDataSource = costcenterAndInvoiceTo.item1;

          if (this.costCenterDataSource.length == 0) {
            this.invoiceAndBillToNoDataText = Constants.invoiceAndBillToNoDataText;
          }

          //this.header.controls['costCenter'].updateValueAndValidity();

          if (this.inVoiceToDataSource.length == 0) {
            this.header.controls['inVoiceTo'].clearValidators();
            this.invoiceAndBillToNoDataText = Constants.invoiceAndBillToNoDataText;
          }
          else {

            if (this.inVoiceToDataSource.length == 1) {
              this.header.controls['inVoiceTo'].setValue(this.inVoiceToDataSource[0]);
            }
            this.header.controls['inVoiceTo'].setValidators([Validators.required]);
          }

          this.header.controls['inVoiceTo'].updateValueAndValidity();
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
    }
  }

  private onSelectAttachmentCategorySubscriptionRaised(orderFile: OrderFile) {
    this.addFileToHeader(orderFile);
  }

  private anyMediaInGroups(): boolean {

    for (var i = 0; i <= this.groups.controls.length - 1; i++) {
      var gp = <FormGroup>this.groups.controls[i];

      if (gp.controls["spotData"].value != null && gp.controls["spotData"].value.length > 0)
        return true;
    }

    return false;
  }

  private clearMediaInAllGroups() {
    for (var i = 0; i <= this.groups.controls.length - 1; i++) {
      var gp = <FormGroup>this.groups.controls[i];

      gp.controls['spotData'].setValue([]);
    }
  }

  private getBrandProducts(id: number) {

    if (id === null || id <= 0) {
      //this.productDataSource = [];
      return;
    }

    this.configService.getCurrentBrandProducts(id, this.header.value.clientId).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var products = res.result;
        //this.productDataSource = products;
        this.productDataSource = (products != null || products != undefined) ? (products.length > 1 ? products.filter(pds => pds.id != 0) : products) : products;

        if (this.productDataSource.length == 1)
          this.header.controls['productId'].setValue(this.productDataSource[0].id);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        //this.productDataSource = [];
      });
  }

  private getClientSpecialVisibilityClients(id: number) {
    if (id === null || id <= 0) {
      return;
    }

    this.configService.getClientSpecialVisibilityClients(id).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.specialVisibilityClientDataSource = res.result;

        //if (this.specialVisibilityClientDataSource.length == 1)
        //  this.header.controls['specialVisibilityClientId'].setValue(this.specialVisibilityClientDataSource[0].id);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  private getClientBrands(id: number) {

    if (id === null || id <= 0) {
      //this.brandDataSource = [];
      //this.productDataSource = [];
      return;
    }

    this.configService.getCurrentClientBrands(id).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var brands = res.result;
        this.brandDataSource = brands;
        if (this.brandDataSource.length == 1)
          this.header.controls['brandId'].setValue(this.brandDataSource[0].id);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        //this.brandDataSource = [];
        //this.productDataSource = [];
      });
  }

  private getConfig(selectedClientId: number = 0, selectedBrandId: number = 0, selectedProductId: number = 0) {

    var clientId = selectedClientId == 0 ? this.header.controls['clientId'].value : selectedClientId;
    var brandId = selectedBrandId == 0 ? this.header.controls['brandId'].value : selectedBrandId;
    var productId = selectedProductId == 0 ? this.header.controls['productId'].value : selectedProductId;

    var config = { clientId: clientId, brandId: brandId, productId: productId };

    this.loadingConfig = true;

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.configService.getCreateOrderConfig(config).subscribe((res: any) => {
      if (res.isSuccess == true) {

        this.createOrderConfig = res.result as CreateOrderConfig;
        this.additionalMetadata.allowSpecialOptions = this.createOrderConfig.allowSpecialOptions;

        if (this.createOrderConfig.allowSpecialOptions == true)
          this.additionalMetadata.allowDestinationHub = true;

        this.setConfig();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.loadingConfig = false;
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.loadingConfig = false;
        this.alertService.ShowLoader(false);
      });
  }

  private setConfig(isdraft: boolean = false) {
    //Also bring copy order from util as this is saved here
    //if (!isdraft) {
    this.createOrderRequest.valueChanges.subscribe(val => {
      this.util.userCreatOrderForm = this.createOrderRequest;
    });
    //}

    this.clientDataSource = this.createOrderConfig.clients;
    this.brandDataSource = this.createOrderConfig.brands;
    this.productDataSource = (this.createOrderConfig.products != null || this.createOrderConfig.products != undefined) ? (this.createOrderConfig.products.length > 1 ? this.createOrderConfig.products.filter(pds => pds.id != 0) : this.createOrderConfig.products) : this.createOrderConfig.products;

      if (this.clientDataSource.length == 1)
      this.header.controls['clientId'].setValue(this.clientDataSource[0].id);


    if (this.createOrderConfig.allowSpecialOptions == false) {
      this.header.controls['orderedBy'].setValue(this.userProfile.firstName + ' ' + this.userProfile.lastName);
    }

    this.additionalMetadata.customFileNameOptions = this.createOrderConfig.customFileNameOptions;
    this.additionalMetadata.transcodePredefinedSpecs = this.createOrderConfig.transcodePredefinedSpecs;
  }

  private addClientBrandAttachment(opsAttachment: any) {
    var existingAttachments = this.header.controls['attachments'].value as Array<OrderFile>;
    if (existingAttachments && existingAttachments != null && existingAttachments.length > 0) {
      var newAttachmentsAfterDelete = existingAttachments.filter(t => t.fileCategory.toLowerCase() != "ops notes");
      this.header.controls['attachments'].setValue(newAttachmentsAfterDelete);
    }

    if (opsAttachment != null) {
      var orderFile = new OrderFile();
      orderFile.orderFileGuid = opsAttachment.clientBrandAttachmentGuid;
      orderFile.fileName = opsAttachment.fileName;
      orderFile.fileType = opsAttachment.fileType;
      orderFile.fileCategory = opsAttachment.fileCategory;
      orderFile.description = opsAttachment.description;
      orderFile.filePath = opsAttachment.filePath;
      orderFile.orderFileId = opsAttachment.clientBrandAttachmentId;  // doubt
      orderFile.uploadedOn = opsAttachment.uploadedOn;
      orderFile.isClientLevelFile = true;

      this.addFileToHeader(orderFile);
    }
  }

  private addFileToHeader(newFile: OrderFile) {
    var exisitingFiles = this.header.controls['attachments'].value as Array<OrderFile>;

    exisitingFiles.push(newFile);

    this.header.controls['attachments'].setValue(exisitingFiles);
  }

  private onAgencyChangeConfirmed() {

    if (this.loadingDraft) return;

    this.previousClientId = this.header.controls['clientId'].value;

    this.checkSpecialOptionsForClient(this.previousClientId);

    this.header.controls['brandId'].setValue(null);
    this.header.controls['productId'].setValue(null);

    this.brandDataSource = [];
    this.productDataSource = [];
    this.specialVisibilityClientDataSource = [];

    this.clearMediaInAllGroups();

    this.getClientBrands(this.header.controls['clientId'].value);
    this.getClientSpecialVisibilityClients(this.header.controls['clientId'].value);
  }

  private onAgencyChangeSubscriptionRaised(decision: boolean) { 
    if (decision == true) {
      this.onAgencyChangeConfirmed();
    }
    else {
      this.header.controls['specialVisibilityClientId'].setValue(this.previousAgencyId);
    }
  }

  private onClientChangeConfirmed() {

    if (this.loadingDraft) return;
    //var previousClient = this.clientDataSource.find(t => t.id == this.previousClientId);
    //console.log(previousClient);

    this.previousClientId = this.header.controls['clientId'].value;

    this.checkSpecialOptionsForClient(this.previousClientId);

    this.header.controls['brandId'].setValue(null);
    this.header.controls['productId'].setValue(null);

    var client = this.clientDataSource.find(t => t.id == this.header.controls['clientId'].value);
    if (client != null) {
      this.header.controls['notes'].setValue(client.opsNotes);
      this.header.controls['comments'].setValue(client.cxComments);
    }

    this.brandDataSource = [];
    this.productDataSource = [];
    this.specialVisibilityClientDataSource = [];

    this.clearMediaInAllGroups();

    this.getClientBrands(this.header.controls['clientId'].value);
    this.getClientSpecialVisibilityClients(this.header.controls['clientId'].value);

    var clientOpsAttachment = client.attachments.find(x => x.fileCategory.toLowerCase() == "ops notes");
    this.addClientBrandAttachment(clientOpsAttachment);
    //if (clientOpsAttachment != null) {
    //  var orderFile = new OrderFile();
    //  orderFile.orderFileGuid = client.clientBrandAttachmentGuid;
    //  orderFile.fileName = clientOpsAttachment.fileName;
    //  orderFile.fileType = clientOpsAttachment.fileType;
    //  orderFile.fileCategory = clientOpsAttachment.fileCategory;
    //  orderFile.description = clientOpsAttachment.description;
    //  orderFile.filePath = clientOpsAttachment.filePath;
    //  orderFile.orderFileId = clientOpsAttachment.clientBrandAttachmentId;  // doubt
    //  orderFile.uploadedOn = clientOpsAttachment.uploadedOn;
      
    //  this.addFileToHeader(orderFile);
    //}
  }

  private onClientChangeSubscriptionRaised(decision: boolean) {
    if (decision == true) {
      this.onClientChangeConfirmed();
    }
    else {
      this.header.controls['clientId'].setValue(this.previousClientId);
    }
  }

  private onBrandChangeConfirmed() {

    if (this.loadingDraft) return;
    //var previousClientBrand = this.brandDataSource.find(t => t.id == this.previousBrandId);
    //console.log(previousClientBrand);

    this.previousBrandId = this.header.controls['brandId'].value;

    this.header.controls['productId'].setValue(null);

    var clientBrand = this.brandDataSource.find(t => t.id == this.header.controls['brandId'].value);
    if (clientBrand != null) {
      if (clientBrand.cxComments != null && clientBrand.cxComments != '') {
        this.header.controls['comments'].setValue(clientBrand.cxComments);
      } else {
        var client = this.clientDataSource.find(t => t.id == this.header.controls['clientId'].value);
        if (client != null) {
          this.header.controls['comments'].setValue(client.cxComments);
        }
      }

      if (clientBrand.opsNotes != null && clientBrand.opsNotes != '') {
        this.header.controls['notes'].setValue(clientBrand.opsNotes);
      } else {
        var client = this.clientDataSource.find(t => t.id == this.header.controls['clientId'].value);
        if (client != null) {
          this.header.controls['notes'].setValue(client.opsNotes);
        }
      }
    }

    this.checkSpecialOptionsForBrand(this.previousBrandId);

    this.productDataSource = [];

    this.clearMediaInAllGroups();

    this.getBrandProducts(this.header.controls['brandId'].value);

    var clientBrandOpsAttachment = clientBrand.attachments.find(x => x.fileCategory.toLowerCase() == "ops notes");
    if (clientBrandOpsAttachment != null) {
      this.addClientBrandAttachment(clientBrandOpsAttachment);
    } else {
      var client = this.clientDataSource.find(t => t.id == this.header.controls['clientId'].value);
      if (client != null) {
        var clientOpsAttachment = client.attachments.find(x => x.fileCategory.toLowerCase() == "ops notes");
        this.addClientBrandAttachment(clientOpsAttachment);
      }
    }
  }

  private checkSpecialOptionsForBrand(brandId: number) {
    var brand = this.brandDataSource.find(t => t.id == brandId);

    if (brand != null) {
      this.additionalMetadata.allowDestinationHub = this.additionalMetadata.allowSpecialOptions == true ? true : brand.allowDestinationHub;
      this.additionalMetadata.spotTracCode = brand.spotTracCode;
    }
    else {
      this.additionalMetadata.allowDestinationHub = this.additionalMetadata.allowSpecialOptions == true ? true : false;
      this.additionalMetadata.spotTracCode = '';
    }

    this.header.controls['isDestinationsHubbed'].setValue(brand.allowDestinationHub == true);
  }

  private checkSpecialOptionsForClient(clientId: number) {
    var isOrderFinalReviewRequiredForClient = false;

    if (clientId != null && clientId != 0) {
      var client = this.clientDataSource.find(t => t.id == clientId);
      if (client != null) {
        isOrderFinalReviewRequiredForClient = (client.isOrderFinalReviewRequired == true);
      }
    }

    this.header.controls['isFinalReviewRequired'].setValue(isOrderFinalReviewRequiredForClient);

    this.updateClientPOValidation(clientId);
  }

  private updateClientPOValidation(clientId: number) {
    var isPORequiredForClient = false;

    var isConsolidated = (this.header.controls['isConsolidatedOrder'].value == true);

    if (clientId != null && clientId != 0) {
      var client = this.clientDataSource.find(t => t.id == clientId);

      if (client != null)
        isPORequiredForClient = (client.isPoRequired == true);
    }

    if (isConsolidated == true || isPORequiredForClient == true)
      this.header.controls['clientPurchaseOrder'].setValidators([Validators.required, Validators.maxLength(15)]);
    else {
      this.header.controls['clientPurchaseOrder'].clearValidators();
      this.header.controls['clientPurchaseOrder'].setValidators([Validators.maxLength(15)]);
    }

    this.header.controls['clientPurchaseOrder'].updateValueAndValidity();
  }

  private onBrandChangeSubscriptionRaised(decision: boolean) {
    if (decision == true) {
      this.onBrandChangeConfirmed();
    }
    else {
      this.header.controls['brandId'].setValue(this.previousBrandId);
    }
  }

  private showOrderFinalize(showWarningMessage: boolean) {
    var initialState = {
      showWarningMessage: showWarningMessage
    };

    this.modalRef = this.modalService.show(OrderFinalizePopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

    this.modalRef.content.onFinalizeOrderComplete.subscribe(result => {
      this.onFinalizeOrderCompleteSubscriptionRaised(result);
    });
  }

  private showOrderConfirmation(workOrder: string, orderLink: string, orderGuid: string) {
    var initialState = {
      workOrder: workOrder,
      orderLink: orderLink,
      orderGuid: orderGuid
    };

    this.modalRef = this.modalService.show(OrderConfirmationPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

    this.modalRef.content.onClose.subscribe(result => {

      if (this.isPopOver == false) {
        this.router.navigate([RouteConstants.ordersRoute]);
      }
      else {
        this.gs.closePopover();

        if (this.orderDraftGuid != null && this.orderDraftGuid != '') {
          this.gs.draftOrderEdited();
        }
        else if (this.uploadedOrderGuid != null && this.uploadedOrderGuid != '') {
          this.gs.uploadedOrderEdited();
        }
        else if (this.copyOrderGuid != null && this.copyOrderGuid != '') {
          this.gs.copyOrderEdited();
        }
        //else
        //this.router.navigate([RouteConstants.ordersRoute]);
      }
    });
  }

  private onFinalizeOrderCompleteSubscriptionRaised(isFinalized: boolean) {

    if (!isFinalized)
      return;

    this.createOrderObject.resendData = [];

    //this.submitOrder();
    this.uploadClientLevelFiles();
  }

  private uploadClientLevelFiles() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    if (this.createOrderObject.header.attachments != null && this.createOrderObject.header.attachments.length > 0 && this.createOrderObject.header.attachments.some(a => a.fileCategory === 'OPS Notes')) {
      var copyFiles: CopyOrderFileRequest[] = [];
      for (var i = 0; i < this.createOrderObject.header.attachments.length; i++) {
        var copyFile = new CopyOrderFileRequest();
        copyFile.orderGuid = this.createOrderObject.orderGuid;
        copyFile.clientLevelFilePath = this.createOrderObject.header.attachments[i].filePath;
        copyFile.fileGuid = this.createOrderObject.header.attachments[i].orderFileGuid;
        copyFile.fileName = this.createOrderObject.header.attachments[i].fileName;
        copyFile.description = this.createOrderObject.header.attachments[i].description;
        copyFile.fileCategory = this.createOrderObject.header.attachments[i].fileCategory;
        copyFile.orderDestinationId = 0;
        copyFile.fileType = this.createOrderObject.header.attachments[i].fileType;
        copyFiles.push(copyFile);
      }
      var request = new CopyOrderFileRequestViewModel()
      request.orderGuid = this.createOrderObject.orderGuid;
      request.copyFiles = copyFiles;
      
      this.copyClientLavelFile(request);
      
    } else {
      this.submitOrder();
    }
  }

  private copyClientLavelFile(copyFileRequest: CopyOrderFileRequestViewModel) {
    this.orderService.copyClientLevelOrderFile(copyFileRequest).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var newFiles = res.result as Array<OrderFile>;
        if (newFiles != null && newFiles != undefined) {
          if (this.createOrderObject.header.attachments != null) {
            for (var i = 0; i < copyFileRequest.copyFiles.length; i++) {
              this.createOrderObject.header.attachments = this.createOrderObject.header.attachments.filter(x => x.orderFileGuid != copyFileRequest.copyFiles[i].fileGuid);
            }
            this.createOrderObject.header.attachments.push(...newFiles);

            //for (var i = 0; i < newFiles.length; i++) {
            //  this.addFileToHeader(newFiles[i]);
            //}
            
            this.submitOrder();
          } else {
            this.util.handleIsNotSuccess([Constants.uploadOrderFileFailed]);
            this.alertService.ShowLoader(false);
          }
        } else {
          this.util.handleIsNotSuccess([Constants.uploadOrderFileFailed]);
          this.alertService.ShowLoader(false);
        }
      } else {
        this.util.handleIsNotSuccess([Constants.uploadOrderFileFailed]);
        this.alertService.ShowLoader(false);
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }


  private submitOrder() {

    this.orderService.createOrder(this.createOrderObject).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.isOrderSubmitted = true;
        this.raiseOrderEditEvents();
        this.showOrderConfirmation(res.result.workOrder, res.result.orderLink, res.result.orderGuid);
      }
      else {
        var spotResendData = res.result.spotResendData as Array<SpotResendConfirm>;

        if (spotResendData != null && spotResendData.length > 0) {
          var initialState = {
            spotResendData: spotResendData,
            spotDestinationOnHandDurationInDays: this.createOrderConfig.spotDestinationOnHandDurationInDays
          };

          this.modalRef = this.modalService.show(SpotResendPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, 'modal-dialog--w75'));

          this.modalRef.content.onResendConfirmComplete.subscribe(result => {
            this.onResendConfirmCompleteSubscriptionRaised(result);
          });
        }
        else {
          this.alertService.showMessage("ERROR", Constants.orderSubmitErrors, MessageSeverity.error);

          var orderErrors = res.result.orderErrors as OrderErrors;

          if (orderErrors != null && orderErrors.headerErrors != null && orderErrors.headerErrors.length > 0)
            this.headerErrors = orderErrors.headerErrors;
          else
            this.headerErrors = [];

          if (orderErrors.inValidServices != null && orderErrors.inValidServices.length > 0) {
            orderErrors.inValidServices.forEach(sp => {
              this.headerErrors.push(`Invalid Spot added ${sp}`);
            });
          }

          if (orderErrors != null && orderErrors.groupErrors != null) {
            for (var i = 0; i <= this.groups.controls.length - 1; i++) {
              var gp = <FormGroup>this.groups.controls[i];
              var gpIdentifier = gp.controls['sequenceId'].value as number;
              var gpError = orderErrors.groupErrors.find(t => t.groupIdentifer == gpIdentifier);

              if (gpError != null)
                gp.controls['groupError'].setValue(gpError);
            }
          }
        }
      }

      this.util.userCreatOrderForm = null;

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
        this.util.userCreatOrderForm = null;
      });
  }

  private onResendConfirmCompleteSubscriptionRaised(resendData: Array<SpotResendConfirm>) {


    this.createOrderObject.resendData = resendData;

    this.submitOrder();
  }

  private get headerForm() {
    var head = this._fb.group({
      clientId: new FormControl(null, Validators.required),
      brandId: new FormControl(null, Validators.required),
      productId: new FormControl(null, Validators.required),
      clientPurchaseOrder: new FormControl(null, Validators.maxLength(15)),
      jobNumber: new FormControl(null, Validators.maxLength(15)),
      airDateStart: new FormControl(null),
      airDateEnd: new FormControl(null),
      estimate: new FormControl(null),
      refKeywords: new FormControl(null),
      notes: new FormControl(null),
      attachments: new FormControl(null),
      serviceData: new FormControl(null),
      isConsolidatedOrder: new FormControl(null),
      isProBono: new FormControl(null),
      isDestinationsHubbed: new FormControl(null),
      isFinalReviewRequired: new FormControl(null),
      isOrderFlagged: new FormControl(null),
      orderedBy: new FormControl(null, [Validators.required]),
      inVoiceTo: new FormControl(null),
      costCenter: new FormControl(null, Validators.maxLength(35)),
      comments: new FormControl(null),
      originalWorkOrder: new FormControl(null),
      podEmails: new FormControl('', [EmailListValidatorIfValueExists]),
      specialVisibilityClientId: new FormControl(null),
      isPostDatedOrder: new FormControl(null)
    });

    head.controls['attachments'].setValue([]);
    head.controls['serviceData'].setValue([]);

    head.setValidators([
      this.airdateValidator(head.get('airDateStart'), head.get('airDateEnd'))
    ]);

    head.updateValueAndValidity();

    return head;
  }

  private get newGroupForm() {
    var seq = this.groups.length + 1;
    var grplFb = this._fb.group({
      destinations: [null],
      ftpDestinations: [null],
      shareLinks: [null],
      isRush: ['false', Validators.required],
      name: [null],
      spotData: [null],
      serviceData: new FormControl(null),
      sequenceId: [null],
      containsGroupErrors: [null],
      containsGroupServiceErrors: [null],
      containsAdIDWarnings: [null],
      containsGroupServiceLevelErrors: [null],
      containsGroupAdIdErrors: [null],
      containsGroupTitleErrors: [null],
      containsGroupDestinationErrors: [null],
      groupError: [null],
      transcodeRequest: [null]
    });

    grplFb.controls['serviceData'].setValue([]);

    grplFb.controls['containsGroupErrors'].setValue(true);
    grplFb.controls['sequenceId'].setValue(seq);
    grplFb.controls['isRush'].setValue('false');
    grplFb.controls['destinations'].setValue([]);
    grplFb.controls['ftpDestinations'].setValue([]);
    grplFb.controls['shareLinks'].setValue([]);
    grplFb.controls['spotData'].setValue([]);

    return grplFb;
  }

  private createEmptyForm() {
    this.createOrderRequest = this._fb.group({
      groups: this._fb.array([]),
      header: this.headerForm,
      orderDraftGuid: [null],
      orderGuid: [null],
      createOrderModule: [null],
      invalidAdIds: [null],
      invalidDestinations: [null],
      invalidServices: [null]
    });
  }

  private getCopyOrderDetail() {

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.loadingDraft = true;

    let request = { orderGuid: this.copyOrderGuid, media: this.copyOptions.media, destinations: this.copyOptions.destinations, services: this.copyOptions.services };

    this.orderService.getCopyOrderDetail(request).subscribe((res: any) => {
      if (res.isSuccess == true) {

        this.createOrderConfig = res.result.config as CreateOrderConfig;
        this.additionalMetadata.allowSpecialOptions = this.createOrderConfig.allowSpecialOptions;

        if (this.createOrderConfig.allowSpecialOptions == true)
          this.additionalMetadata.allowDestinationHub = true;


        this.setConfig(true);

        this.createOrderObject = res.result.draft as CreateOrderRequest;
        this.setOpsNotesforCopyOrder();
        this.createOrderObject.invalidAdIds = [];
        this.createOrderObject.invalidDestinations = [];
        this.updateDraftForm(true);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.loadingDraft = false;
      this.util.userCreatOrderForm = null;
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.loadingDraft = false;
        this.util.userCreatOrderForm = null;
        this.alertService.ShowLoader(false);
      });
  }

  public setOpsNotesforCopyOrder() {
    if (this.createOrderObject != null && this.createOrderConfig != null) {
      var clientBrand = this.createOrderConfig.brands.find(b => b.id == this.createOrderObject.header.brandId);
      if (clientBrand != null && clientBrand.opsNotes != null && clientBrand.opsNotes != undefined && clientBrand.opsNotes != '') {
        this.createOrderObject.header.notes = clientBrand.opsNotes;
      } else {
        var client = this.createOrderConfig.clients.find(c => c.id == this.createOrderObject.header.clientId);
        if (client != null && client.opsNotes != null && client.opsNotes != undefined && client.opsNotes != '') {
          this.createOrderObject.header.notes = client.opsNotes;
        }
      }
      if (clientBrand != null && clientBrand.cxComments != null && clientBrand.cxComments != undefined && clientBrand.cxComments != '') {
        this.createOrderObject.header.comments = clientBrand.cxComments;
      } else {
        var client = this.createOrderConfig.clients.find(c => c.id == this.createOrderObject.header.clientId);
        if (client != null && client.cxComments != null && client.cxComments != undefined && client.cxComments != '') {
          this.createOrderObject.header.comments = client.cxComments;
        }
      }
    }
  }

  private getDraft() {

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.loadingDraft = true;
    //this.createOrderModule = "DraftOrder";
    this.createOrderRequest.controls['orderDraftGuid'].setValue(this.orderDraftGuid);

    this.orderService.getDraft(this.orderDraftGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {

        this.createOrderConfig = res.result.config as CreateOrderConfig;
        this.additionalMetadata.allowSpecialOptions = this.createOrderConfig.allowSpecialOptions;

        if (this.createOrderConfig.allowSpecialOptions == true)
          this.additionalMetadata.allowDestinationHub = true;

        this.setConfig();

        this.createOrderObject = res.result.draft as CreateOrderRequest;
        this.createOrderObject.invalidAdIds = [];
        this.createOrderObject.invalidDestinations = [];

        this.createOrderRequest.controls['orderGuid'].setValue(this.orderDraftGuid);
        this.draftOrderNumber = this.createOrderObject.draftOrderNumber;

        this.updateDraftForm();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        this.gs.draftOrderEdited();
        this.gs.closePopover();
      }

      this.loadingDraft = false;
      this.util.userCreatOrderForm = null;
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.loadingDraft = false;
        this.util.userCreatOrderForm = null;
        this.alertService.ShowLoader(false);
        this.gs.draftOrderEdited();
        this.gs.closePopover();
      });
  }

  private getUnSavedOrder() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.loadingDraft = true;

    this.orderService.getUnSavedOrder(this.unSavedOrderGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {

        this.createOrderConfig = res.result.config as CreateOrderConfig;
        this.additionalMetadata.allowSpecialOptions = this.createOrderConfig.allowSpecialOptions;

        if (this.createOrderConfig.allowSpecialOptions == true)
          this.additionalMetadata.allowDestinationHub = true;

        this.setConfig();

        this.createOrderObject = res.result.unSavedOrder as CreateOrderRequest;
        this.createOrderObject.invalidAdIds = [];
        this.createOrderObject.invalidDestinations = [];

        this.createOrderRequest.controls['orderGuid'].setValue(this.unSavedOrderGuid);

        this.updateDraftForm();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.loadingDraft = false;
      this.util.userCreatOrderForm = null;
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.loadingDraft = false;
        this.util.userCreatOrderForm = null;
        this.alertService.ShowLoader(false);
      });
  }

  private getUploadedOrderDraft() {
    //aravind -upload order -loading data and

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.loadingDraft = true;

    this.createOrderRequest.controls['orderDraftGuid'].setValue(this.uploadedOrderGuid);

    this.orderService.getUploadedOrder(this.uploadedOrderGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.createOrderConfig = res.result.config as CreateOrderConfig;

        this.additionalMetadata.allowSpecialOptions = this.createOrderConfig.allowSpecialOptions;

        if (this.createOrderConfig.allowSpecialOptions == true)
          this.additionalMetadata.allowDestinationHub = true;

        this.setConfig();

        //console.log("Successfully loaded uploaded order for guid :" + this.uploadedOrderGuid);

        this.createOrderObject = res.result.draft as CreateOrderRequest;

        //console.log(this.createOrderObject);

        if (this.createOrderObject.createOrderModule != null && this.createOrderObject.createOrderModule.toLowerCase().trim() == "uploadedorder") {
          //this.createOrderModule = "UploadedOrder";
          this.createOrderObject.orderDraftGuid = this.uploadedOrderGuid;
        }

        if (this.createOrderObject.invalidAdIds == null) {
          this.createOrderObject.invalidAdIds = [];
        }

        if (this.createOrderObject.invalidDestinations == null) {
          this.createOrderObject.invalidDestinations = [];
        }

        this.createOrderRequest.controls['createOrderModule'].setValue(this.createOrderObject.createOrderModule);
        this.createOrderRequest.controls['orderGuid'].setValue(this.uploadedOrderGuid);

        this.updateDraftForm();

        var invalidDestinations = this.createOrderRequest.controls['invalidDestinations'].value;
        var invalidAdIds = this.createOrderRequest.controls['invalidAdIds'].value;

        if ((invalidDestinations != null && invalidDestinations.length > 0) || invalidAdIds != null && invalidAdIds.length > 0) {
          var invalAdid = invalidAdIds.find(i => i.status == null);
          var invalDest = invalidDestinations.find(i => i.status == null);

          if (invalAdid != null || invalDest != null) {
            this.showInvalidDataCorrectionPopup();
          }
        }
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        this.gs.uploadedOrderEdited();
        this.gs.closePopover();
      }

      this.loadingDraft = false;
      this.util.userCreatOrderForm = null;
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.loadingDraft = false;
        this.util.userCreatOrderForm = null;
        this.alertService.ShowLoader(false);
        this.gs.uploadedOrderEdited();
        this.gs.closePopover();
      });
  }

  private draftGroupForm(gp: OrderGroup) {

    var grplFb = this._fb.group({
      destinations: [null],
      ftpDestinations: [null],
      shareLinks: [null],
      isRush: [null, Validators.required],
      name: [null],
      spotData: [null],
      serviceData: new FormControl(null),
      sequenceId: [null],
      containsGroupErrors: [null],
      containsGroupServiceErrors: [null],
      containsAdIDWarnings: [null],
      containsGroupAdIdErrors: [null],
      containsGroupTitleErrors: [null],
      containsGroupDestinationErrors: [null],
      containsGroupServiceLevelErrors: [null],
      groupError: [null],
      invalidAdids: [null],
      invalidDestinations: [null],
      invalidServices: [null],
      transcodeRequest: [null]
    });

    grplFb.controls['containsGroupErrors'].setValue(gp.containsGroupErrors);
    grplFb.controls['containsGroupServiceErrors'].setValue(gp.containsGroupServiceErrors);
    grplFb.controls['containsAdIDWarnings'].setValue(gp.containsAdIDWarnings);
    grplFb.controls['containsGroupAdIdErrors'].setValue(gp.containsGroupAdIdErrors);
    grplFb.controls['containsGroupDestinationErrors'].setValue(gp.containsGroupDestinationErrors);
    grplFb.controls['containsGroupServiceLevelErrors'].setValue(gp.containsGroupServiceLevelErrors);
    grplFb.controls['containsGroupTitleErrors'].setValue(gp.containsGroupTitleErrors);
    grplFb.controls['isRush'].setValue(gp.isRush != null ? gp.isRush.toString() : 'false');
    grplFb.controls['name'].setValue(gp.name);
    grplFb.controls['sequenceId'].setValue(gp.sequenceId);
    grplFb.controls['ftpDestinations'].setValue(gp.ftpDestinations);
    grplFb.controls['shareLinks'].setValue(gp.shareLinks);
    grplFb.controls['destinations'].setValue(gp.destinations);
    grplFb.controls['serviceData'].setValue(gp.serviceData == null ? [] : gp.serviceData);

    grplFb.controls['invalidAdids'].setValue(gp.invalidAdids);
    grplFb.controls['invalidDestinations'].setValue(gp.invalidDestinations);
    grplFb.controls['invalidServices'].setValue(gp.invalidServices);
    grplFb.controls['transcodeRequest'].setValue(gp.serviceData == null ? null : gp.transcodeRequest);

    for (let s of gp.spotData) {

      s.groupSpotId = UUID.UUID();

      s.spotFile.thumbnailUrl = this.util.getThumbnailUrl(s.spotFile.thumbnailUrl, (s.spotFile.format.toLowerCase() == 'audio'), (s.spotFile.status.toLowerCase() == 'awaiting media'), (s.spotFile.status.toLowerCase() == 'creating media'));
      s.spotFile.proxyUrl = this.util.getProxyUrl(s.spotFile.proxyUrl);

      s.spotFile.formatSources = this.util.getAllIconSources(s.spotFile.format, s.spotFile.audioFormat, s.spotFile.spotTrac, s.spotFile.veilEncode,
        s.spotFile.subtitles, s.spotFile.centerCutProtected, s.spotFile.descriptiveVideo, s.spotFile.broadcast, s.spotFile.web, s.spotFile.taggable, s.spotFile.generic,
        s.spotFile.runningFootage, s.spotFile.cc, s.spotFile.sap, s.spotFile.vchip, s.spotFile.letterBox, s.spotFile.surround, s.spotFile.mono);

      s.spotFile.formatClasses = this.util.getAllIconClasses(s.spotFile.format, s.spotFile.audioFormat, s.spotFile.spotTrac, s.spotFile.veilEncode,
        s.spotFile.subtitles, s.spotFile.centerCutProtected, s.spotFile.descriptiveVideo,
        s.spotFile.broadcast, s.spotFile.web, s.spotFile.taggable, s.spotFile.generic, s.spotFile.runningFootage, s.spotFile.cc,
        s.spotFile.sap, s.spotFile.vchip, s.spotFile.letterBox, s.spotFile.surround, s.spotFile.mono);

      for (let spotfile of s.spotFile.postProdFiles) {
        spotfile.thumbnailUrl = this.util.getThumbnailUrl(spotfile.thumbnailUrl, (s.spotFile.format.toLowerCase() == 'audio'), (s.spotFile.status.toLowerCase() == 'awaiting media'), (s.spotFile.status.toLowerCase() == 'creating media'));
        spotfile.proxyUrl = this.util.getProxyUrl(spotfile.proxyUrl);
        spotfile.formatSources = this.util.getAllIconSources(s.spotFile.format, spotfile.audioFormat, spotfile.spotTrac,
          spotfile.veilEncode, spotfile.subtitles, spotfile.centerCutProtected, spotfile.descriptiveVideo,
          spotfile.broadcast, spotfile.web, spotfile.taggable, spotfile.generic, spotfile.runningFootage, spotfile.cc,
          s.spotFile.sap, s.spotFile.vchip, s.spotFile.letterBox, s.spotFile.surround, s.spotFile.mono);

        spotfile.formatClasses = this.util.getAllIconClasses(s.spotFile.format, spotfile.audioFormat, spotfile.spotTrac,
          spotfile.veilEncode, spotfile.subtitles, spotfile.centerCutProtected, spotfile.descriptiveVideo,
          spotfile.broadcast, spotfile.web, spotfile.taggable, spotfile.generic, spotfile.runningFootage, spotfile.cc,
          s.spotFile.sap, s.spotFile.vchip, s.spotFile.letterBox, s.spotFile.surround, s.spotFile.mono);
      }

      s.isPpExists = (s.serviceData.some(t => t.service.category.toLowerCase() === 'post production'));
      s.isAddlExists = (s.serviceData.some(t => t.service.category.toLowerCase() === 'additional'));
      s.isDeliveryExists = (s.serviceData.some(t => t.service.category.toLowerCase() === 'digital delivery'));
    }

    grplFb.controls['spotData'].setValue(gp.spotData);

    return grplFb;
  }

  private updateDraftForm(isCopiedOrder: boolean = false) {
    if (this.groups != null && this.groups.length > 0) {
      for (var i = 0; i <= this.createOrderObject.groups.length - 1; i++) {
        this.groups.removeAt(0);
      }
    }

    this.header.controls['clientId'].setValue(this.createOrderObject.header.clientId);
    this.header.controls['brandId'].setValue(this.createOrderObject.header.brandId);
    this.header.controls['productId'].setValue(this.createOrderObject.header.productId);
    this.header.controls['clientPurchaseOrder'].setValue(this.createOrderObject.header.clientPurchaseOrder);
    this.header.controls['airDateStart'].setValue(this.createOrderObject.header.airDateStart);
    this.header.controls['airDateEnd'].setValue(this.createOrderObject.header.airDateEnd);
    this.header.controls['notes'].setValue(this.createOrderObject.header.notes);
    this.header.controls['estimate'].setValue(this.createOrderObject.header.estimate);
    this.header.controls['refKeywords'].setValue(this.createOrderObject.header.refKeywords);
    this.header.controls['jobNumber'].setValue(this.createOrderObject.header.jobNumber);
    this.header.controls['originalWorkOrder'].setValue(this.createOrderObject.header.originalWorkOrder);
    this.header.controls['orderedBy'].setValue(this.createOrderConfig.allowSpecialOptions == false ? this.userProfile.firstName + ' ' + this.userProfile.lastName : isCopiedOrder ? null : this.createOrderObject.header.orderedBy);
    this.header.controls['inVoiceTo'].setValue(this.createOrderObject.header.inVoiceTo);
    this.header.controls['costCenter'].setValue(this.createOrderObject.header.costCenter);
    this.header.controls['comments'].setValue(this.createOrderObject.header.comments);
    this.header.controls['isConsolidatedOrder'].setValue(this.createOrderObject.header.isConsolidatedOrder);
    this.header.controls['isProBono'].setValue(this.createOrderObject.header.isProBono);
    this.header.controls['isDestinationsHubbed'].setValue(this.createOrderObject.header.isDestinationsHubbed);
    this.header.controls['isFinalReviewRequired'].setValue(this.createOrderObject.header.isFinalReviewRequired);
    this.header.controls['isOrderFlagged'].setValue(this.createOrderObject.header.isOrderFlagged);
    this.header.controls['specialVisibilityClientId'].setValue(this.createOrderObject.header.specialVisibilityClientId);
    this.header.controls['isPostDatedOrder'].setValue(this.createOrderObject.header.isPostDatedOrder);

    this.header.controls['podEmails'].setValue(this.createOrderObject.header.podEmails);

    this.header.controls['serviceData'].setValue(this.createOrderObject.header.serviceData == null ? [] : this.createOrderObject.header.serviceData);
    this.header.controls['attachments'].setValue(this.createOrderObject.header.attachments == null ? [] : this.createOrderObject.header.attachments);

    this.createOrderRequest.controls['invalidDestinations'].setValue(this.createOrderObject.invalidDestinations);
    this.createOrderRequest.controls['invalidAdIds'].setValue(this.createOrderObject.invalidAdIds);
    this.createOrderRequest.controls['invalidServices'].setValue(this.createOrderObject.invalidServices);

    for (var i = 0; i <= this.createOrderObject.groups.length - 1; i++) {
      var gp = this.createOrderObject.groups[i];
      var draftGp = this.draftGroupForm(gp);
      this.groups.push(draftGp);
    }
  }

  // remove below
  private updateOrderFormBackFromTrancode(selectedClientId: number = 0, selectedBrandId: number = 0, selectedProductId: number = 0) {
    var clientId = selectedClientId == 0 ? this.header.controls['clientId'].value : selectedClientId;
    var brandId = selectedBrandId == 0 ? this.header.controls['brandId'].value : selectedBrandId;
    var productId = selectedProductId == 0 ? this.header.controls['productId'].value : selectedProductId;

    var config = { clientId: clientId, brandId: brandId, productId: productId };

    this.loadingConfig = true;

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.configService.getCreateOrderConfig(config).subscribe((res: any) => {
      if (res.isSuccess == true) {

        this.createOrderConfig = res.result as CreateOrderConfig;

        this.additionalMetadata.allowSpecialOptions = this.createOrderConfig.allowSpecialOptions;

        if (this.createOrderConfig.allowSpecialOptions == true)
          this.additionalMetadata.allowDestinationHub = true;

        this.setConfig();
        this.updateDraftForm();

        this.loadingDraft = false;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        this.loadingDraft = false;
      }

      this.loadingConfig = false;
      this.alertService.ShowLoader(false);
      this.loadingDraft = false;
    },
      error => {
        this.util.handleError(error);
        this.loadingConfig = false;
        this.loadingDraft = false;
        this.alertService.ShowLoader(false);
      });

    if (this.groups != null && this.groups.length > 0) {
      for (var i = 0; i <= this.createOrderObject.groups.length - 1; i++) {
        this.groups.removeAt(0);
      }
    }
  }



  private airdateValidator(...controls: AbstractControl[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      if (controls[0].value != null
        && controls[1].value != null
        && Date.parse(controls[0].value) > Date.parse(controls[1].value))
        return { airdatesInvalid: true };

      return null;
    };
  }

  public showInvalidDataCorrectionPopup() {

    //if ((this.createOrderObject.invalidDestinations == null || this.createOrderObject.invalidDestinations.length == 0) &&
    //  (this.createOrderObject.invalidAdIds == null || this.createOrderObject.invalidAdIds.length == 0)) {
    //  return;
    //}

    var initialState = {
      "invalidDestinations": this.createOrderRequest.controls['invalidDestinations'].value,
      "invalidAdids": this.createOrderRequest.controls['invalidAdIds'].value
    };

    this.modalRef = this.modalService.show(OrderInvalidDataPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, 'modal-dialog--w75'));

    this.modalRef.content.onApplyChanges.subscribe(result => {
      this.onSelectDestinationsSubscriptionRaised(result);
    });
  }

  private onSelectDestinationsSubscriptionRaised(updatedState: UpdatedResults) {
    //console.log("this is inside onSelectDestinationsSubscriptionRaised");
    //console.log(updatedState);

    if (updatedState.invalidAdids != null && updatedState.invalidAdids.length > 0) {
      this.onSelectedAdidsChanged(updatedState.invalidAdids);
    }

    if (updatedState.invalidDestinations != null && updatedState.invalidDestinations.length > 0) {
      this.onSelectDestinationsChanged(updatedState.invalidDestinations);
    }

    //console.log("this is inside onSelectDestinationsSubscriptionRaised");
  }

  private onSelectedAdidsChanged(allAdidsSelected: Array<OrderInvalidAdId>) {
    if (allAdidsSelected == null || allAdidsSelected.length == 0) {
      return;
    }
    var deletedItems = allAdidsSelected.filter(i => i.isDeletedByUser == false);
    if (deletedItems != null && deletedItems.length > 0) {
      //no corrective action is taken, then do no proceed.
      return;
    }

    //if (this.createOrderObject == null) {
    //  return;
    //}

    var modifiedRecords = allAdidsSelected.filter(i => i.status != null && i.status.trim().toLowerCase() == "modified");

    if (modifiedRecords != null) {
      var invalidAdIdsOrder = this.createOrderRequest.controls['invalidAdIds'].value;
      for (let rec of modifiedRecords) {
        if (rec.isDeletedByUser == true) {
          //for (let mydraft of this.createOrderObject.groups)
          for (var i = 0; i <= this.groups.controls.length - 1; i++) {
            var gp = <FormGroup>this.groups.controls[i];
            var invalidAdids = gp.controls['invalidAdids'].value;
            var spotData = gp.controls['spotData'].value;

            if (invalidAdids != null) {
              var adidsObjectInError = invalidAdids.filter(d => d.adid == rec.adId);

              if (adidsObjectInError != null && adidsObjectInError.length > 0) {
                //destinationObject[0].code == fixedDestinations.correctedDestination;
                if (spotData != null && spotData.length > 0) {
                  spotData = spotData.filter(o => o.spotFile.adId != rec.adId);
                }
                if (invalidAdids != null && invalidAdids.length > 0) {
                  invalidAdids = invalidAdids.filter(o => o.adid != rec.adId);
                }


              }
            }
            gp.controls['invalidAdids'].setValue(invalidAdids);
            gp.controls['spotData'].setValue(spotData);
          }
        }

        if (invalidAdIdsOrder != null && invalidAdIdsOrder.length > 0)
          invalidAdIdsOrder = invalidAdIdsOrder.filter(i => i.adId != rec.adId);
      }
      this.createOrderRequest.controls['invalidAdIds'].setValue(invalidAdIdsOrder);
    }
  }

  private onSelectDestinationsChanged(allDestinationsSelected: Array<OrderInvalidDestination>) {

    if (allDestinationsSelected == null || allDestinationsSelected.length == 0) {
      return;
    }

    var deletedItems = allDestinationsSelected.filter(i => i.isDeletedByUser == false);
    var updatedItems = allDestinationsSelected.filter(i => i.isModifiedByUser == false);

    if (deletedItems != null && deletedItems.length == 0 && updatedItems != null && updatedItems.length == 0) {
      //no correction action is taken, then do no proceed.
      return;
    }

    //if (this.createOrderObject == null) {
    //  return;
    //}

    for (let userfixedDestination of allDestinationsSelected) {
      if (userfixedDestination.isModifiedByUser == true) {
        //for (let mydraft of this.createOrderObject.groups)
        for (var i = 0; i <= this.groups.controls.length - 1; i++) {
          var gp = <FormGroup>this.groups.controls[i];
          var invalidDestinations = gp.controls['invalidDestinations'].value;
          var destinations = gp.controls['destinations'].value;

          if (invalidDestinations != null) {
            //that is there are invalid detinations present only then proceed
            var destinationInErrorInSelectedGroup = invalidDestinations.filter(d => d.destination == userfixedDestination.destination);

            //check if the list conains the record in the selected group.
            if (destinationInErrorInSelectedGroup != null && destinationInErrorInSelectedGroup.length > 0) {   //only if there are invalid destinatons found in the group then proceed
              // get the actual destination in the group

              var destinationObject = destinations.filter(d => d.code == userfixedDestination.destination);

              if (destinationObject == null || destinationObject.length == 0) {
                if (userfixedDestination.userSelectedDestination != null) {
                  destinations.push(userfixedDestination.userSelectedDestination);
                }
                //if (userfixedDestination.userSelectedDestination != null) {
                //  mydraft.destinations.push(userfixedDestination.userSelectedDestination);
                //}
              }
              else {
                if (userfixedDestination.userSelectedDestination != null) {
                  destinationObject[0] = userfixedDestination.userSelectedDestination;
                }
              }
            }
            invalidDestinations = invalidDestinations.filter(d => d.destination != userfixedDestination.destination);   //since this is fixed remove it from invalid group
          }

          gp.controls['invalidDestinations'].setValue(invalidDestinations);
          gp.controls['destinations'].setValue(destinations);
        }
      }

      if (userfixedDestination.isDeletedByUser == true) {
        //for (let mydraft of this.createOrderObject.groups)
        for (var i = 0; i <= this.groups.controls.length - 1; i++) {
          var gp = <FormGroup>this.groups.controls[i];
          var invalidDestinations = gp.controls['invalidDestinations'].value;
          var destinations = gp.controls['destinations'].value;

          if (invalidDestinations != null) {
            var destinationObjectInError = invalidDestinations.filter(d => d.destination == userfixedDestination.destination);

            if (destinationObjectInError != null && destinationObjectInError.length > 0) {
              //  destinationObject[0].code == fixedDestinations.correctedDestination;

              if (destinations != null) {
                destinations = destinations.filter(o => o.code != userfixedDestination.destination);
                invalidDestinations = invalidDestinations.filter(o => o.destination != userfixedDestination.destination);
              }
            }
          }

          gp.controls['invalidDestinations'].setValue(invalidDestinations);
          gp.controls['destinations'].setValue(destinations);
        }
      }
    }

    var modifiedRecords = allDestinationsSelected.filter(i => i.status.trim().toLowerCase() == "modified");

    if (modifiedRecords != null) {
      var invalidDestinations = this.createOrderRequest.controls['invalidDestinations'].value;
      for (let rec of modifiedRecords) {
        //this.createOrderObject.invalidDestinations = this.createOrderObject.invalidDestinations.filter(i => i.destination != rec.destination);
        invalidDestinations = invalidDestinations.filter(i => i.destination != rec.destination);
      }
    }
    this.createOrderRequest.controls['invalidDestinations'].setValue(invalidDestinations);
  }

  //#endregion
}
