import { Injectable, Inject } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable()
export class ApiEndPoint {
  // Auth
  //private _tokenEndpoint: string = "connect/token";

  // Log
  private _getLogEndpoint: string = "Log/LogMessage";

  // Media
  private _updateSpotQC: string = "Spot/UpdateSpotQC";
  private _getMediaEndpointQC: string = "Spot/GetMedia";
  private _searchSpotsEndpoint: string = "Spot/SearchSpots";
  private _getSpotFilesEndpoint: string = "Spot/GetSpotFiles";
  private _searchQCReadySpots: string = "Spot/SearchQCReadySpots";
  private _createPlaceHolder: string = "Spot/CreatePlaceHolder";
  private _uploadMetadata: string = "Spot/SpotUploadMetadata";
  private _createSpotFileEndpoint: string = "Spot/CreateSpotFiles";
  private _searchApprovalReadySpots = "Spot/SearchApprovalReadySpots";
  private _getApprovalReadySpots = "Spot/GetApprovalReadySpots";
  private _getRecentlyViewedSpots = "Spot/GetRecentlyViewedSpots";
  private _getSpotDetailEndpoint = "Spot/GetSpotDetail";
  private _addSpotComment = "Spot/AddSpotComment";
  private _expireSpot = "Spot/ExpireSpot";
  private _restoreSpots = "Spot/RestoreSpots";
  private _editSpot = "Spot/EditSpot";
  private _purgeMediaEndPoint = "Spot/PurgeMedia";
  private _spotApprovalSubmit = "Spot/SubmitSpotApproval";
  private _getAuditLogs = "Spot/GetAuditLogs";
  private _deletePlaceholder = "Spot/DeletePlaceholder";
  private _searchSpotsForOrderEndpoint: string = "Spot/SearchSpotsForOrder";
  private _createQuickLinkRequestEndPoint: string =
    "Spot/CreateQuickLinkRequest";
  private _getQuickLinks: string = "Spot/GetQuickLinks";
  private _getQuickLinkDetail: string = "Spot/GetQuickLinkDetail";
  private _getMediaCustomTags: string = "Spot/GetMediaCustomTags";
  private _addEditMediaCustomTags: string = "Spot/AddEditMediaCustomTags";
  private _downloadMetadaFileEndpoint: string = "Config/DownloadTemplate";

  // upload asset to s3
  private _initiateAssetMultipartUpload: string =
    "Spot/InitiateAssetMultipartUpload";
  private _getAssetPartUploadPresignedURL: string =
    "Spot/GetAssetPartUploadPresignedURL";
  private _generateAssetSinglePartSignedURL: string =
    "Spot/GenerateAssetSinglePartSignedURL";
  private _completeAssetMultipartUpload: string =
    "Spot/completeAssetMultipartUpload";
  private _abortAssetMultipartUpload: string = "Spot/abortAssetMultipartUpload";

  //Download Media
  private _registerDownloadMediaEndPoint: string = "Spot/RegisterDownloadMedia";
  private _completeDownloadMediaFileEndPoint: string =
    "Spot/CompleteDownloadMediaFile";
  private _downloadMediaUrlEndPoint: string = "Spot/GetMediaDownloadUrl";

  // PlayList
  private _getPlayLists: string = "PlayList/GetPlayLists";
  private _deletePlayList: string = "PlayList/DeletePlayList";
  private _createPlayList: string = "PlayList/CreatePlayList";
  private _getPlayListFiles: string = "PlayList/GetPlayListFiles";
  private _addToPlayList: string = "PlayList/AddToPlayList";
  private _removeFromPlayList: string = "PlayList/RemoveFromPlayList";
  private _shufflePlayList: string = "PlayList/ShufflePlayList";
  private _isPasswordRequired: string = "PlayList/IsPasswordRequired";
  private _isSharedMediaValid: string = "PlayList/IsSharedMediaValid";
  private _showSharedMediaFiles: string = "PlayList/ShowSharedMediaFiles";
  private _showSharedMediaFilesWithAuth: string =
    "PlayList/ShowSharedMediaFilesWithAuth";
  private _shareMedia: string = "PlayList/ShareMedia";
  private _generateToken: string = "PlayList/GenerateShareableLink";

  // Config
  private _getUploadConfigEndPoint: string = "Config/GetUploadConfig";
  private _getUploadOrderConfigEndpoint: string = "Config/GetUploadOrderConfig";
  private _getclientBrandsEndpoint: string = "Config/GetClientBrands";
  private _getBrandProductsEndpoint: string = "Config/GetBrandProducts";
  private _getCurrentBrandProductsEndpoint: string =
    "Config/GetCurrentBrandProducts";
  private _getCurrentProductAgenciesEndpoint: string =
    "Config/GetAgenciesByClientBrandProduct";
  private _getAdminCurrentBrandProductsEndpoint: string =
    "Config/GetAdminCurrentBrandProducts";
  private _getBrandProductsForListEndpoint: String =
    "Config/GetBrandProductsForList";
  private _getTimeZones: string = "Config/GetTimeZones";
  private _autoGeneratePassword: string = "Config/AutoGeneratePassword";
  private _getShareMediaConfig: string = "Config/GetShareMediaConfig";
  private _getCreateOrderConfigEndpoint: string = "Config/GetCreateOrderConfig";
  private _getCreateVaultConfigEndpoint: string = "Vault/GetCreateVaultConfig";
  private _searchDestinationsEndpoint: string = "Config/SearchDestinations";
  private _validateDestinationsTextEndpoint: string =
    "Config/ValidateDestinationsFromText";
  private _validateDestinationsFromFileEndpoint: string =
    "Config/ValidateDestinationsFromFile";
  private _getClientFtpDestinationsEndpoint: string =
    "Config/GetClientFtpDestinations";
  private _createClientFtpDestinationEndpoint: string =
    "Config/CreateClientFtpDestination";
  private _getServiceCategoriesEndPoint: string = "Config/GetServiceCategories";
  private _getClientBrandProductsEndPoint: string =
    "Config/GetClientBrandProducts";
  private _getCurrentClientBrandProductsEndPoint: string =
    "Config/GetCurrentClientBrandProducts";
  private _getClientProductsEndpoint: string = "Config/GetClientProducts";
  private _getFormat: string = "Config/GetCreatePlaceHolderConfig";
  private _searchUserDestinationEndpoint: string =
    "Config/SearchUserDestinations";
  private _searchFTPDestinationEndpoint: string =
    "Config/SearchFTPDestinations";
  private _searchShareLinkDestinations: string =
    "Config/SearchShareLinkDestinations";
  private _getUserSavedDestinationListsEndpoint: string =
    "Config/GetUserSavedDestinationLists";
  private _addToUserSavedDestinationListEndpoint: string =
    "Config/AddToUserSavedDestinationList";
  private _updateUserSavedDestinationListEndpoint: string =
    "Config/UpdateUserSavedDestinationList";
  private _createUserSavedDestinationListEndpoint: string =
    "Config/CreateUserSavedDestinationList";
  private _deleteUserSavedDestinationListEndpoint: string =
    "Config/DeleteUserSavedDestinationList";
  private _deleteUserFtpDestinationEndpoint: string =
    "Config/DeleteUserFtpDestination";
  private _createUserFtpDestinationEndpoint: string =
    "Config/CreateUserFtpDestination";
  private _updateUserFtpDestinationEndpoint: string =
    "Config/UpdateUserFtpDestination";
  private _getBrandSelectionConfigEndpoint: string =
    "Config/GetBrandSelectionConfig";
  private _getCreateClientConfigEndpoint: string =
    "Config/GetCreateClientConfig";
  private _getBrandsEndpoint: string = "Config/GetBrands";
  private _getCurrentBrandsEndpoint: string = "Config/GetCurrentBrands";
  private _getVendorsForSendOrerEmailEndpoint: string = "Config/GetDigitalVendorsForSendOrderEmail";
  private _getProductsEndpoint: string = "Config/GetProducts";
  private _getCurrentProducts: string = "Config/GetCurrentProducts";
  private _getClientSearchConfig: string = "Config/GetClientSearchConfig";
  private _getBrandSearchConfig: string = "Config/GetBrandSearchConfig";
  private _getCreateBrandConfig: string = "Config/GetCreateBrandConfig";
  private _getCostCenterAndInvoice: string = "Config/GetCostCenterAndInVoiceTo";
  private _getAssignVendorConfig = "Config/GetAssignVendorConfig";
  private _getAddPostHouseConfig = "Config/GetAddPostHouseConfig";
  private _getUserAdvancedSearchConfig: string =
    "Config/GetUserAdvancedSearchConfig";
  private _getClientPostHouses: string = "Config/GetClientPostHouses";
  private _getClientUserGroups: string = "Config/GetClientUserGroups"
  private _getUserHomeConfigEndPoint: string = "Config/GetUserHomeConfig";
  private _getCreateUserConfig: string = "Config/GetCreateUserConfig";
  private _getCurrentClientBrands: string = "Config/GetCurrentClientBrands";
  private _getClientSpecialVisibility: string =
    "Config/GetCurrentClientSpecialVisibilityClients";
  private _searchCurrentBrands: string = "Config/SearchCurrentBrands";
  private _getCreateUserProfileConfig: string =
    "Config/GetCreateUserProfileConfig";
  private _getSearchDestinationConfig: string =
    "Config/GetSearchDestinationConfig";
  private _getDigitalVendorsForDestinationMapping: string =
    "Config/GetDigitalVendorsForDestinationMapping";
  private _getCreateDestinationConfig: string =
    "Config/GetCreateDestinationConfig";
  private _getQuickLinkConfig: string = "Config/GetQuickLinkConfig";
  private _getStatesByCountry: string = "Config/GetStatesByCountry";
  private _getClientCustomTagConfig: string = "Config/GetClientCustomTagConfig";
  private _getClientBundleServicesConfig: string =
    "Config/GetClientBundleServicesConfig";
  private _getOrderedByDataEndPoint: string = "Config/GetOrderedByData";
  private _getDistributionMediaAdvancedSearchConfigEndPoint =
    "Config/GetDistributionMediaAdvancedSearchConfig";
  private _getSearchSpecificationsEndPoint = "Config/SearchAllSpecifications";
  private _getEncryptTextEndPoint = "Config/EncryptText";

  // Aspera external
  private _getUploadSpec: string = "AsperaNode/Spec";

  // user
  private _saveUserPersonalInformation: string =
    "User/SaveUserPersonalInformation";
  //private _getUserDataEndpoint: string = "User/GetUserData";
  private _getUpdatePasswordEndpoint: string = "User/UpdatePassword";
  private _getUpdatePasswordAfterExpiryEndpoint: string =
    "User/UpdatePasswordAfterExpiry";
  private _getUpdateAvatarEndpoint: string = "User/UpdateAvatar";
  private _generateResetPasswordToken: string =
    "User/GenerateResetPasswordToken";
  private _validateResetPasswordLinkEndpoint: string =
    "User/ValidateResetPasswordLink";
  private _updatePasswordAfterTokenAuthEndpoint: string =
    "User/UpdatePasswordAfterTokenAuth";
  private _createUserDistributionGroupEndpoint: string =
    "User/CreateUserDistributionGroup";
  private _userEmailDistributionGroupsEndpoint: string =
    "User/UserEmailDistributionGroups";
  private _userGroupEmailListEndpoint: string = "User/UserGroupEmailList";
  private _updateUserDistributionEmailsFromGroupEndpoint: String =
    "User/UpdateUserDistributionEmailsFromGroup";
  private _getPendingActionCountEndpoint: String = "User/GetPendingActionCount";
  private _searchUserFavoriteEmailsEndpoint: String =
    "User/SearchUserFavoriteEmails";
  private _checkUserExistsEndpoint: String = "User/CheckUserExists";
  private _removeUserDistributionGroupEndpoint: String =
    "User/RemoveUserDistributionGroup";
  private _searchUserEmailDistributionGroupsEndpoint: string =
    "User/SearchUserEmailDistributionGroups";
  private _getUserDisplayDataEndpoint: string = "User/GetDisplayUserData";
  private _getUserSelectionEndpoint: string = "User/GetUserProfiles";
  private _getUserProfilesForInactivateUserEndpoint: string =
    "User/UserProfilesForInactivateUser";
  private _canManagePwdEndpoint: string = "User/CanManagePassword";
  // Notification
  private _getNotificationPreferencesEndpoint: string =
    "Notification/NotificationPreferences";
  private _getCurrentUserNotificaionPreferencesEndpoint: string =
    "Notification/CurrentUserNotificaionPreferences";
  private _saveUserNotificaionPreferencesEndpoint: string =
    "Notification/SaveUserNotificaionPreferences";
  private _removeNotificationPreferenceEndpoint: string =
    "Notification/RemoveNotificationPreference";

  // Notification emails
  private _searchNotificationsEndpoint: string =
    "Notification/GetNotifications";
  private _searchSpotNotificationsEndpoint: string =
    "Notification/GetSpotNotifications";
  private _searchOrderNotificationsEndpoint: string =
    "Notification/GetOrderNotifications";
  private _getNotificationMessageEndpoint: string =
    "Notification/GetNotificationMessage";
  private _setViewedNotificationEndpoint: string =
    "Notification/SetNotificationViewed";
  private _setArchivedNotificationEndpoint: string =
    "Notification/SetNotificationArchived";
  private _getNotificationsCountEndpoint: string =
    "Notification/GetNotificationsCount";

  // Order
  private _getOrderSearchConfigEndpoint: string = "Order/SearchOrderOnInit";
  private _searchOrdersOrderViewEndpoint: string = "Order/SearchOrders";
  private _createOrderEndpoint: string = "Order/CreateOrder";
  private _copyClientLevelOrderFileEndpoint: string = "Order/CopyOrderFile";
  private _editOrderEndPoint: string = "Order/EditOrder";
  //private _addGroupToOrderEndpoint: string = "Order/AddGroupToOrder";
  //private _updateOrderGroupEndpoint: string = "Order/UpdateOrderGroup";
  private _getOrderHistoryOfSpotEndPoint: string = "Spot/GetOrderHistoryOfSpot";
  private _validateSpotsTextEndpoint: string = "Spot/ValidateSpotsFromText";
  private _updateSpotQCCommentEndpoint: string = "Spot/UpdateSpotQCComment";
  private _validateSpotsFileEndpoint: string = "Spot/ValidateSpotsFromFile";
  private _validateTranscodeOrderSpotsFromTextEndPoint: string =
    "Spot/ValidateTranscodeOrderSpotsFromText";
  private _validateTranscodeOrderSpotsFromFileEndPoint: string =
    "Spot/ValidateTranscodeOrderSpotsFromFile";
  private _notifyS3UploadSpotArrivalEndPoint: string =
    "Spot/NotifyS3UploadSpotArrival";
  //private _deleteMediaFromOrderGroupEndpoint: string = "Order/DeleteMediaFromOrderGroup";
  //private _deleteGroupFromOrderEndpoint: string = "Order/DeleteGroupFromOrder";
  private _getOrderAuditLogs = "Order/GetAuditLogs";
  private _getOrderHomeInitLoadEndpoint: string = "Order/OrderHomeOnInit";
  private _saveDraftEndpoint: string = "Order/SaveDraftOrder";
  private _saveUnSavedOrderEndpoint: string = "Order/SaveUnSavedOrder";
  private _getOrderDraftEndpoint: string = "Order/GetDraftOrder";
  private _getUnSavedOrderEndpoint: string = "Order/GetUnSavedOrder";
  private _getAwaitingReviewOrdersEndPoint: string =
    "Order/GetAwaitingReviewOrders";
  private _getVendorSubmissionFailedOrdersEndPoint: string =
    "Order/GetVendorSubmissionFailedOrders";
  private _getOrdersInProgress: string = "Order/GetOrdersInProgress";
  private _getReadyForBillingOrders: string = "Order/GetReadyForBillingOrders";
  private _searchOrdersSpotViewEndpoint: string = "Order/SearchOrdersSpotView";
  private _getDraftOrders: string = "Order/GetDraftOrders";
  private _deleteDraftOrder: string = "Order/DeleteDraftOrder";
  private _copyDraftOrder: string = "Order/CopyDraftOrder";
  private _claimOrderEndPoint: string = "Order/ClaimOrder";
  private _claimOrderInDetailEndPoint: string = "Order/ClaimOrderInDetail";
  private _deleteOrderFile: string = "Order/DeleteOrderFile";
  private _deleteClientBrandAttachment: string = "Client/DeleteClientBrandAttachment";
  private _deleteVaultFile: string = "Vault/DeleteVaultFile";
  private _deleteOrderFtpPod: string = "Order/DeleteOrderFtpPod";
  private _orderPassQC: string = "Order/OrderPassQC";
  private _orderStartProcess: string = "Order/OrderStartProcess";
  private _orderStartProcessInDetail: string =
    "Order/OrderStartProcessInDetail";
  private _orderCompleteProcess: string = "Order/OrderCompleteProcess";
  private _addVendorLineItems: string = "Order/AddVendorLineItems";
  private _flagOrdersEndpoint: string = "Order/FlagOrders";
  private _releaseClaimedOrderEndpoint: string = "Order/ReleaseClaimedOrder";
  private _getCopyOrderDetailEndpoint: string = "Order/GetCopyOrderDetail";
  private _getOrderDetailEndpoint: string = "Order/GetOrderDetail";
  private _getBillingItemsEndpoint: string = "Order/GetBillingItems";

  //private _getOrderGroupDetailEndpoint: string = "Order/GetOrderDetailGroup";
  //private _getOrderServicesEndpoint: string = "Order/GetOrderServices";

  private _getServiceVendorsEndpoint: string = "Order/GetVendorsByService";
  private _updateServiceVendorEndpointEndpoint: string =
    "Order/UpdateServiceVendor";

  //private _updateOrderServicesEndpoint: string = "Order/UpdateOrderServices";
  private _getSpotsFromOrderEndpoint: string = "Order/GetSpotsForOrderDetail";
  private _getReadyToProcessOrdersEndpoint: string =
    "Order/GetReadyToProcessOrders";
  private _getProcessingOrdersEndpoint: string = "Order/GetProcessingOrders";
  private _getDestinationForOrderDetailEndpoint: string =
    "Order/GetDestinationForOrderDetail";
  private _getFtpUploadDetailsForOrderDetailEndpoint: string =
    "Order/GetFtpUploadDetailsForOrderDetail";
  private _getContentDownloadDetailsForOrderDetailEndpoint: string =
    "Order/GetContentDownloadDetailsForOrderDetail";
  private _getShareLinkDetailsForOrderDetailEndpoint: string =
    "Order/GetShareLinkDetailsForOrderDetail";
  private _editOrderHeaderEndpoint: string = "Order/EditOrderHeader";
  private _updateOrderFTPUploadDestination: string =
    "Order/UpdateOrderFTPUploadDestination";
  private _reAssignVendorForOrderLineItems: string =
    "Order/ReAssignVendorForOrderLineItems";
  private _assignVendorForOrderLineItems: string =
    "Order/AssignVendorForOrderLineItems";
  private _approveOrderReviewEndpoint: string = "Order/ApproveOrderReview";
  private _complteMultipleOrderProcess: string =
    "Order/ComplteMultipleOrderProcess";
  private _releaseOrderClaimedForProcessing: string =
    "Order/ReleaseOrderClaimedForProcessing";
  private _sendOrderToAEEndpoint: string = "Order/SendOrderToAE";
  private _setOrderBackToReadyForProcessEndpoint: string =
    "Order/SetOrderBackToReadyForProcess";
  private _flagAndNotifyOrderEndpoint: string = "Order/FlagAndNotifyOrder";
  private _sendOrderForReviewEndpoint: string = "Order/SendOrderForReview";
  private _sendOrderToBillingEndpoint: string = "Order/SendOrderToBilling";
  private _orderEditStartEndpoint: string = "Order/OrderEditStart";
  private _orderEditEndEndpoint: string = "Order/OrderEditEnd";
  private _getOrderGroupServicesForVendorPO: string =
    "Order/GetOrderGroupServicesForVendorPO";
  private _createNonDigitalVendorPO: string = "Order/CreateNonDigitalVendorPO";
  private _viewVendorPO: string = "Order/ViewVendorPO";
  private _updateDeliveryPOD: string = "Order/UpdateDeliveryPOD";
  private _retryVendorLineItemCreation: string =
    "Order/RetryVendorLineItemCreation";
  private _retryVendorOrderStatusCheck: string =
    "Order/RetryVendorOrderStatusCheck";
  private _retryVendorOrderSubmission: string =
    "Order/RetryVendorOrderSubmission";
  private _updateVendorOrderNumber: string = "Order/UpdateVendorOrderNumber";
  private _uploadOrderEndpoint: string = "Order/UploadOrder";
  private _getUploadedOrdersEndpoint: string = "Order/GetUploadedOrders";
  private _getUploadedOrderEndpoint: string = "Order/GetUploadedOrder";
  private _saveUploadedOrderChanges: string = "Order/SaveUploadedOrderChanges";
  private _deleteUploadedOrder: string = "Order/DeleteUploadedOrder";
  private _completeOrderFileUpload: string = "Order/CompleteOrderFileUpload";
  private _completeVaultFileUpload: string = "Vault/CompleteVaultFileUpload";
  private _completeVaultAssetFileUpload: string =
    "Vault/CompleteVaultAssetFileUpload";
  private _initiateOrderFileUpload: string = "Order/InitiateOrderFileUpload";
  private _initiateVaultFileUpload: string = "Vault/InitiateVaultFileUpload";
  private _initiateVaultAssetFileUpload: string =
    "Vault/InitiateVaultAssetFileUpload";
  private _completeUploadOrderFTPPod: string =
    "Order/CompleteUploadOrderFtpPOD";
  private _initiateUploadOrderFTPPod: string =
    "Order/InitiateUploadOrderFtpPOD";
  private _completeUploadOpsAttachment: string =
    "Client/CompleteUploadOpsAttachment";
  private _initiateUploadOpsAttachment: string =
    "Client/InitiateUploadOpsAttachment";
  private _downloadOrderFile: string = "Order/DownloadOrderFile";
  private _downloadOpsAttachment: string = "Client/DownloadOpsAttachment";
  private _getProxyUrlEndpoint: string = "Spot/GetProxyUrl";
  private _checkSpotPattern: string = "Spot/CheckSpotPattern";
  private _getOrderDebugDetailsEndPoint: string = "Order/GetOrderDebugDetails";
  private _resendOrderMessageEndPoint: string = "Order/ResendOrderMessage";
  private _sendNewOrderMessageEndPoint: string = "Order/SendNewOrderMessage";
  private _getWorkflowOperationsEndPoint: string =
    "Order/GetWorkflowOperations";
  private _getOrderQueueMessagesEndPoint: string =
    "Order/GetOrderQueueMessages";
  private _searchOrderWorkflowsEndPoint: string = "Order/SearchWorkflows";
  private _updateWorkflowEndPoint: string = "Order/UpdateWorkflow";
  private _cancelOrderEndPoint: string = "Order/CancelOrder";
  private _updateOrderlineItemsToStatusCheckEndPoint: string =
    "Order/UpdateOrderlineItemsToStatusCheck";
  private _releaseMultipleOrdersClaimedForProcessing: string =
    "Order/ReleaseMultipleOrdersClaimedForProcessing";
  private _reSendMediaToDestinations: string =
    "Order/ReSendMediaToDestinations";
  private _searchRecentTranscodeOrders: string =
    "Order/SearchRecentTranscodeOrders";
  private _forceUpdateOrderStatusEndPoint: string =
    "Order/ForceUpdateOrderStatus";
  private _forceUpdateServiceLevelEndPoint: string =
    "Order/ForceUpdateServiceLevel";
  private _createPurchaseOrderEndPoint: string = "Order/CreatePurchaseOrder";
  private _updateOrderProductEndPoint: string = "Order/UpdateOrderProduct";
  private _sendOrderEmailEndpoint: string = "Order/EmailOrderData";

  // Reports
  private _getVendorPurchaseOrderPDF: string =
    "Reports/GetVendorPurchaseOrderPDF";
  private _getDeliveryConfirmationReport: string =
    "Reports/GetDeliveryConfirmationReport";
  private _getOrderSummaryReportPDF: string =
    "Reports/GetOrderSummaryReportPDF";
  private _getReportConfigEndPoint: string = "Reports/GetReportConfig";
  private _generateOnDemandReportEndPoint: string =
    "Reports/GenerateOnDemandReport";
  private _getScheduledReportFilesEndPoint: string =
    "Reports/GetScheduledReportFiles";
  private _getReportFile: string = "Reports/GetReportFile";

  // Admin
  private _getBrandSelectionEndpoint: string = "Client/GetBrandSelection";
  private _createClientEndpoint: string = "Client/CreateClient";
  private _searchAllClients: string = "Client/SearchAllClients";
  private _getClientView: string = "Client/GetClientView";
  private _createBrandEndpoint: string = "Brand/CreateBrand";
  private _searchAllBrands: string = "Brand/SearchAllBrands";
  private _createProductEndpoint: string = "Brand/CreateProduct";
  private _getClientEditDetail: string = "Client/GetClientEditDetail";
  private _deleteClientBrand: string = "Client/DeleteClientBrand";
  private _deleteClientBrandProduct: string = "Client/DeleteClientBrandProduct";
  private _updateClientLogo: string = "Client/UpdateClientLogo";
  private _removeClientLogo: string = "Client/RemoveClientLogo";
  private _editClient: string = "Client/EditClient";
  private _getBrandDetail: string = "Brand/GetBrandDetail";
  private _deleteBrandProduct: string = "Brand/DeleteBrandProduct";
  private _editBrand: string = "Brand/EditBrand";
  private _getPostHouseClientBrands: string = "Client/GetPostHouseClientBrands";
  private _createPostHouse: string = "Client/CreatePostHouse";
  private _addPostHouseToClient: string = "Client/AddPostHouseToClient";
  private _deletePostHouse: string = "Client/DeletePostHouse";
  private _updateClientStatus: string = "Client/UpdateClientStatus";
  private _deleteDraftClient: string = "Client/DeleteDraftClient";
  private _startClientEditLock: string = "Client/StartClientEditLock";
  private _completeClientEditLock: string = "Client/CompleteClientEditLock";
  private _convertToBillableClient: string = "Client/ConvertToBillableClient";
  private _editProduct: string = "Brand/EditProduct";
  private _updateBrandStatus: string = "Brand/UpdateBrandStatus";
  private _searchAllUsersEndPoint: string = "User/SearchAllUsers";
  private _getBrandsForUserCreationEndPoint: string =
    "User/GetBrandsForUserCreation";
  private _createNewUser: string = "User/CreateNewUser";
  private _createNewUserProfile: string = "User/CreateNewUserProfile";
  private _getUserProfileView: string = "User/GetUserProfileView";
  private _getEditUserProfileConfig: string = "User/GetEditUserProfileConfig";
  private _editUserProfile: string = "User/EditUserProfile";
  private _updateUserProfileStatus: string = "User/UpdateUserProfileStatus";
  private _searchAllDestinations: string = "Destination/SearchAllDestinations";
  private _searchVendorDestination: string =
    "Destination/SearchVendorDestination";
  private _createDestination: string = "Destination/CreateDestination";
  private _isVendorDestinationCodeValid: string =
    "Destination/IsVendorDestinationCodeValid";
  private _isDestinationCodeValid: string =
    "Destination/IsDestinationCodeValid";
  private _getDestinationView: string = "Destination/GetDestinationView";
  private _editDestination: string = "Destination/EditDestination";
  private _updateDestinationStatus: string =
    "Destination/UpdateDestinationStatus";
  private _getAllQueuesEndpoint: string = "Order/GetAllQueues";
  private _removeSpecMappingEndPoint: string =
    "Destination/RemoveSpecificationMapping";
  private _validateBXFFileEndPoint: string = "Destination/ValidateBXFFromFile";
  private _createAuthOrgEndPoint: string = "User/CreateAuthOrg";
  private _getSpecificationsForOrderByDestinationCode: string =
    "Destination/GetSpecificationsForOrderByDestinationCode";

  private _clearCacheEndPoint: string = "Config/ClearCache";
  private _getOrderStatusesForITOPsEndPoint: string =
    "Config/GetOrderStatusesForITOPs";
  private _getServiceLevelsForITOPsEndPoint: string =
    "Config/GetServiceLevelsForItOps";

  //User Mgmt
  private _getTMCRegistrationRequestsEndpoint: string =
    "User/GetTMCRegistrationRequests";
  private _approveTMCRegistrationRequestEndpoint: string =
    "User/ApproveTMCRegistrationRequest";

  //Distribution
  private _distributionSearchEndpoint: string =
    "Distribution/SearchDistributionMedia";
  private _getBxfFileDownloadUrlEndpoint: string =
    "Distribution/GetBxfFileDownloadUrl";
  private _updateDistributionMediaEndpoint: string =
    "Distribution/UpdateDistributionMedia";
  private _repitchDistributionMediaEndpoint: string =
    "Distribution/RepitchDistributionMedia";
  private _getDistributionMediaDownloadUrlEndPoint: string =
    "Distribution/GetDistributionMediaDownloadUrl";
  private _getTMCReleasesUrlEndPoint: string = "Distribution/GetTMCReleases";
  private _getTMCReleaseDownloadUrl: string =
    "Distribution/GetTMCReleaseDownloadUrl";

  //Project
  private _getProjectClients: string = "project/loadClients";
  private _saveProject: string = "project/saveProject";
  private _loadProjFolders: string = "project/loadProjectFolders";
  private _initiateMultipartUpload: string = "project/InitiateMultipartUpload";
  private _getPartUploadPresignedURL: string =
    "project/GetPartUploadPresignedURL";
  private _generateSinglePartSignedURL: string =
    "project/GenerateSinglePartSignedURL";
  private _completeMultipartUpload: string = "project/completeMultipartUpload";
  private _abortMultipartUpload: string = "project/abortMultipartUpload";
  private _addFiles: string = "project/addFiles";
  private _createProjectFolder: string = "Project/CreateProjectFolder";
  private _archiveProjects: string = "Project/ArchiveProjects";
  private _getUsersByClient: string = "Project/GetUsersByClient";

  private _loadProjects: string = "project/searchProject";
  private _deleteFileCollection: string = "project/deleteFileCollection";
  private _assignUserPermission: string = "project/assignUserPermission";
  private _assignExistingUserPermission: string =
    "project/assignExistingUserPermission";
  private _assignNewUserPermission: string = "project/assignNewUserPermission";
  private _updateProject: string = "project/updateProject";
  private _downloadProjectFile: string = "project/downloadProjectFile";
  private _promoteProjectFile: string = "project/PromoteProjectFile";
  private _updateProjectFolder: string = "project/UpdateProjectFolder";
  private _moveFileCollection: string = "project/MoveFileCollection";

  private _searchRightsManagement: string =
    "RightsManagement/SearchRightsManagement";
  private _getTalentRightsConfigEndPoint: string =
    "Config/GetTalentRightsConfig";

  //Transcode
  public _searchSpotsForTranscodeOrderEndpoint: string =
    "Spot/SearchSpotsForTranscodeOrder";
  public _searchTranscodeOrdersEndPoint: string = "Order/SearchTranscodeOrders";
  public _editTranscodeOrderEndPoint: string = "Order/EditTranscodeOrder";
  public _getTranscodeSpecsEndPoint: string = "Config/GetTranscodeSpecs";
  public _getTrasncodeOrderConfigEndpoint: string =
    "Config/GetTranscodeOrderConfig";
  public _createTranscodeOrderEndpoint: string = "Order/CreateTranscodeOrder";
  public _shareTranscodeAssetsEndpoint: string =
    "PlayList/shareTranscodeAssets";
  public _getTranscodeAssetsShareLinkEndpoint: string =
    "PlayList/GetTranscodeAssetsShareLink";
  public _isMaxRequestReachedForTierEndpoint: string =
    "Order/IsMaxRequestReachedForTier";

  //Vault
  private _createVaultEndpoint: string = "Vault/CreateVault";
  private _searchAssetVaultRequestsViewEndpoint: string =
    "Vault/SearchVaultAsset";
  private _searchVaultRequestsViewEndpoint: string = "Vault/SearchVaultRequest";
  private _searchVaultConfigEndpoint: string = "Vault/SearchVaultConfig";
  private _getVaultAssetDetailsEndpoint: string = "Vault/GetVaultAssetDetails";
  private _getVaultDigitalAssetDetailsEndpoint: string = "Vault/GetVaultDigitalAssetDetails";
  private _updateVaultAssetStatusVault: string = "Vault/UpdateVaultAssetStatusVault";
  private _getUpdateVaultAssetsStatusLoginEndpoint: string =
    "Vault/UpdateVaultAssetStatusLogin";
  private _getUpdateVaultAssetsStatusDestroyEndpoint: string =
    "Vault/UpdateVaultAssetStatusDestroy";
  private _getUpdateVaultAssetsStatusStagingEndpoint: string =
    "Vault/UpdateVaultAssetStatusStaging";
  private _getVaultRequestDetailEndpoint: string =
    "Vault/GetVaultRequestDetail";
  private _getVaultAssetEditConfigEndpoint: string =
    "Vault/VaultAssetEditConfig";
  private _updateVaultAssetEndpoint: string = "Vault/UpdateVaultAsset";
  private _updateVaultDigitalAssetEndpoint: string = "Vault/UpdateVaultDigitalAsset";
  private _updateVaultRequestDetailHeaderEndpoint: string =
    "Vault/UpdateVaultRequestDetailHeader";
  private _addVaultAssetNoteEndpoint: string = "Vault/AddVaultAssetNote";
  private _updateVaultRequestDetailEndpoint: string =
    "Vault/UpdateVaultRequestDetail";
  private _getVaultAssetAdditionalEndpoint: string =
    "Vault/GetVaultAssetAdditionalServices";
  private _updateVaultAssetAdditionalServicesEndpoint: string =
    "Vault/UpdateVaultAssetAdditionalServices";
  private _downloadVaultFile: string = "Vault/DownloadVaultFile";
  private _getVaultShippingServicesEndpoint: string =
    "Vault/GetRemoveDestroyeConfig";
  private _updateVaultAssetsStatusRemoveEndpoint: string =
    "Vault/UpdateVaultAssetStatusRemove";
  private _updateVaultAssetsStatusLogoutEndpoint: string =
    "Vault/UpdateVaultAssetStatusLogout";
  private _updateVaultAssetMissingEndpoint: string =
    "Vault/UpdateVaultAssetMissing";
  private _updateVaultAssetAvailableEndpoint: string =
    "Vault/UpdateVaultAssetAvailable";
  private _updateVaultDigitalAssetMissingEndpoint: string =
    "Vault/UpdateVaultDigitalAssetMissing";
  private _updateVaultDigitalAssetAvailableEndpoint: string =
    "Vault/UpdateVaultDigitalAssetAvailable";
  private _searchVaultDigitalStorageEndpoint: string = "Vault/SearchVaultDigitalStorageRequest";

  //Tagger
  private _getTaggedAdsEndpoint: string = "AdCreator/SearchAllTaggedAds";
  private _getDraftTaggersEndpoint: string = "AdCreator/SearchAllDraftOrders";
  public _getAdOrderEndpointEndpoint: string = "AdCreator/GetTaggedAdOrder";
  private _getBaseCreativesEndpoint: string = "AdCreator/GetBaseCreative";
  private _getOfferCardsEndpoint: string = "AdCreator/GetOfferCard";
  private _getEndCardsEndpoint: string = "AdCreator/GetEndCard";
  private _getEndCardVoiceOversEndpoint: string = "AdCreator/GetVoiceOver";
  private _saveAsDraftAdEndpoint: string = "AdCreator/CreateDraft";
  private _updateDraftAdEndpoint: string = "AdCreator/UpdateDraft";
  private _adCreateEndpoint: string = "AdCreator/CreateOrder";
  private _updateAdEndpoint: string = "AdCreator/UpdateOrder";
  private _getEditDraftOrderEndpoint: string = "AdCreator/GetDraftOrder";
  private _addDraftOrderNoteEndpoint: string = "AdCreator/CreateDrafOrderNote";
  private _addOrderNoteEndpoint: string = "AdCreator/CreateOrderNote";
  private _archiveOrderEndpoint: string = "AdCreator/ArchiveOrder";
  private _unArchiveOrderEndpoint: string = "AdCreator/UnArchiveOrder";
  public _getTaggerAdsShareLinkEndpoint: string =
    "PlayList/GetTaggerAdsShareLink";
  public _shareTaggerAdsEndpoint: string = "PlayList/ShareTaggerAds";

  private _registerAdDownloadMediaEndPoint: string =
    "AdCreator/RegisterDownloadMedia";
  private _completeDownloadAdMediaFileEndPoint: string =
    "AdCreator/CompleteDownloadMediaFile";
  private _getUserLocationsEndpoint: string = "AdCreator/GetUserLocations";

  // Tagger Assets
  private _searchTaggerAssetsEndpoint: string = "AdCreator/SearchTaggerAssets";
  private _uploadTaggerAssetsEndpoint: string = "AdCreator/CreateTaggerAssets";
  private _getGroupsEndpoint: string = "AdCreator/GetGroups";
  private _getLocationsEndpoint: string = "AdCreator/GetLocations";
  private _updateTaggerAssetEndpoint: string = "AdCreator/UpdateTaggerAsset";
  private _deleteTaggerAssetEndpoint: string = "AdCreator/DeleteTaggerAsset";
  private _updateUploadAssetStatusEndpoint: string = "AdCreator/UpdateUploadStatus";
  private _validateAssetTitleEndpoint: string =
    "AdCreator/ValidateDuplicateTaggerAsset";
  private _loadFeaturedAssetsEndpoint: string =
    "AdCreator/GetFeaturedCreatives";
  

  //Tagger files upload
  private _generateAdFileSinglePartSignedURL: string =
    "AdCreator/GenerateAdFileSinglePartSignedURL";
  private _initiateAdFileMultipartUpload: string =
    "AdCreator/InitiateAdFileMultipartUpload";
  private _completeAdFileMultipartUpload: string =
    "AdCreator/CompleteAssetMultipartUpload";
  private _abortAdFileMultipartUpload: string =
    "AdCreator/AbortAssetMultipartUpload";
  private _getAdFilePartUploadPresignedURL: string =
    "AdCreator/GetAdFilePartUploadPresignedURL";

  // Tagger Groups
  private _createTaggerGroupEndpoint: string = "AdCreator/CreateGroup";
  private _updateTaggerGroupEndpoint: string = "AdCreator/UpdateGroup";
  private _searchGroupsEndpoint: string = "AdCreator/SearchGroups";
  private _validateGroupNameEndpoint: string =
    "AdCreator/ValidateDuplicateTaggerGroup";
  private _updateTaggerGroupLocationEndpoint: string =
    "AdCreator/UpdateGroupLocations";

  // Tagger Locations and Users
  private _searchLocationsEndpoint: string = "AdCreator/SearchLocations";
  private _getStatesEndpoint: string = "AdCreator/GetStates";
  private _searchGroupLocationsEndpoint: string = "AdCreator/SearchGroupLocations";
  private _addTaggerUserEndpoint: string = "AdCreator/CreateLocationUser";
  private _updateTaggerUserEndpoint: string = "AdCreator/UpdateLocationUser";
  private _addLocationEndpoint: string = "AdCreator/CreateLocation";
  private _updateLocationEndpoint: string = "AdCreator/UpdateLocation";
  private _updateLocationGroupsEndpoint: string = "AdCreator/UpdateLocationGroups";
  private _validateLocationEndpoint: string = "AdCreator/ValidateDuplicateLocation";
  private _updateTaggerUserProfileStatus: string = "AdCreator/UpdateUserProfileStatus";

  //grid
  private _getGridDetailsByUserSettingsEndpoint: string = "TandemGrid/GetGridDetailsByUserSettings";
  private _saveGridDetailsInUserSettingsEndpoint: string = "TandemGrid/SaveGridDetailsInUserSettings";
  private _resetGridDetailsForUserEndpoint: string = "TandemGrid/ResetGridDetailsForUser";


  //User Mgmt
  public getTMCRegistrationRequestsEndpoint(): string {
    return environment.apiBaseUrl + this._getTMCRegistrationRequestsEndpoint;
  }
  public approveTMCRegistrationRequestEndpoint(): string {
    return environment.apiBaseUrl + this._approveTMCRegistrationRequestEndpoint;
  }
  public getUserDisplayDataEndpoint(): string {
    return environment.apiBaseUrl + this._getUserDisplayDataEndpoint;
  }
  public getUserSelectionEndpoint(): string {
    return environment.apiBaseUrl + this._getUserSelectionEndpoint;
  }
  public getUserProfilesForInactivateUserEndpoint(): string {
    return (
      environment.apiBaseUrl + this._getUserProfilesForInactivateUserEndpoint
    );
  }

  //public getTokenEndpoint(): string { return environment.apiBaseUrl + this._tokenEndpoint; }
  public getTokenEndpoint(): string {
    return environment.securityUrl;
  }
  public getLogEndpoint(): string {
    return environment.apiBaseUrl + this._getLogEndpoint;
  }
  public getMediaEndpointQC(): string {
    return environment.apiBaseUrl + this._getMediaEndpointQC;
  }
  public updateqcSpotsEndpoint(): string {
    return environment.apiBaseUrl + this._updateSpotQC;
  }
  //public getUserDataEndpoint(): string { return environment.apiBaseUrl + this._getUserDataEndpoint; }
  public getPendingActionCountEndpoint(): string {
    return environment.apiBaseUrl + this._getPendingActionCountEndpoint;
  }

  public clearCacheEndPoint(): string {
    return environment.apiBaseUrl + this._clearCacheEndPoint;
  }
  public getOrderStatusesForITOPsEndPoint(): string {
    return environment.apiBaseUrl + this._getOrderStatusesForITOPsEndPoint;
  }
  public getServiceLevelsForITOPsEndPoint(): string {
    return environment.apiBaseUrl + this._getServiceLevelsForITOPsEndPoint;
  }

  public checkUserExistsEndpoint(): string {
    return environment.apiBaseUrl + this._checkUserExistsEndpoint;
  }

  public searchUserFavoriteEmailsEndpoint(): string {
    return environment.apiBaseUrl + this._searchUserFavoriteEmailsEndpoint;
  }

  public searchSpotsEndpoint(): string {
    return environment.apiBaseUrl + this._searchSpotsEndpoint;
  }
  public getSpotFilesEndpoint(): string {
    return environment.apiBaseUrl + this._getSpotFilesEndpoint;
  }
  public getClientBrandsEndpoint(): string {
    return environment.apiBaseUrl + this._getclientBrandsEndpoint;
  }
  public getBrandsEndpoint(): string {
    return environment.apiBaseUrl + this._getBrandsEndpoint;
  }
  public getCurrentBrandsEndpoint(): string {
    return environment.apiBaseUrl + this._getCurrentBrandsEndpoint;
  }
  public getProductsEndpoint(): string {
    return environment.apiBaseUrl + this._getProductsEndpoint;
  }
  public getCurrentProductsEndPoint(): string {
    return environment.apiBaseUrl + this._getCurrentProducts;
  }
  public getBrandProductsEndpoint(): string {
    return environment.apiBaseUrl + this._getBrandProductsEndpoint;
  }
  public getCurrentBrandProductsEndpoint(): string {
    return environment.apiBaseUrl + this._getCurrentBrandProductsEndpoint;
  }
  public getCurrentProductAgenciesEndpoint(): string {
    return environment.apiBaseUrl + this._getCurrentProductAgenciesEndpoint;
  }
  public getAdminCurrentBrandProductsEndpoint(): string {
    return environment.apiBaseUrl + this._getAdminCurrentBrandProductsEndpoint;
  }
  public getClientBrandProductsEndpoint(): string {
    return environment.apiBaseUrl + this._getClientBrandProductsEndPoint;
  }
  public getCurrentClientBrandProductsEndpoint(): string {
    return environment.apiBaseUrl + this._getCurrentClientBrandProductsEndPoint;
  }
  public getBrandProductsForListEndpoint(): string {
    return environment.apiBaseUrl + this._getBrandProductsForListEndpoint;
  }
  public searchQCReadySpotsEndpoint(): string {
    return environment.apiBaseUrl + this._searchQCReadySpots;
  }
  public getSpotDetailEndpoint(): string {
    return environment.apiBaseUrl + this._getSpotDetailEndpoint;
  }
  public searchApprovalReadySpotsEndpoint(): string {
    return environment.apiBaseUrl + this._searchApprovalReadySpots;
  }
  public getApprovalReadySpotsEndpoint(): string {
    return environment.apiBaseUrl + this._getApprovalReadySpots;
  }
  public addSpotCommentEndpoint(): string {
    return environment.apiBaseUrl + this._addSpotComment;
  }
  public expireSpotEndPoint(): string {
    return environment.apiBaseUrl + this._expireSpot;
  }
  public restoreSpotsEndPoint(): string {
    return environment.apiBaseUrl + this._restoreSpots;
  }
  public deletePlaceholderEndPoint(): string {
    return environment.apiBaseUrl + this._deletePlaceholder;
  }
  public editSpotEndPoint(): string {
    return environment.apiBaseUrl + this._editSpot;
  }
  public purgeMediaEndPoint(): string {
    return environment.apiBaseUrl + this._purgeMediaEndPoint;
  }
  public spotApprovalSubmitEndPoint(): string {
    return environment.apiBaseUrl + this._spotApprovalSubmit;
  }
  public getAuditLogsEndPoint(): string {
    return environment.apiBaseUrl + this._getAuditLogs;
  }
  public getRecentlyViewedSpotsEndPoint(): string {
    return environment.apiBaseUrl + this._getRecentlyViewedSpots;
  }
  public searchSpotsForOrderEndpoint(): string {
    return environment.apiBaseUrl + this._searchSpotsForOrderEndpoint;
  }
  public getclientProductsEndpoint(): string {
    return environment.apiBaseUrl + this._getClientProductsEndpoint;
  }
  public createQuickLinkRequestEndPoint(): string {
    return environment.apiBaseUrl + this._createQuickLinkRequestEndPoint;
  }
  public getQuickLinksEndPoint(): string {
    return environment.apiBaseUrl + this._getQuickLinks;
  }
  public getQuickLinkDetailEndPoint(): string {
    return environment.apiBaseUrl + this._getQuickLinkDetail;
  }
  public getMediaCustomTagsEndPoint(): string {
    return environment.apiBaseUrl + this._getMediaCustomTags;
  }
  public addEditMediaCustomTagsEndPoint(): string {
    return environment.apiBaseUrl + this._addEditMediaCustomTags;
  }
  public getAllQueuesEndpoint(): string {
    return environment.apiBaseUrl + this._getAllQueuesEndpoint;
  }
  public validateSpotsTextEndpoint(): string {
    return environment.apiBaseUrl + this._validateSpotsTextEndpoint;
  }
  public updateSpotQCCommentEndpoint(): string {
    return environment.apiBaseUrl + this._updateSpotQCCommentEndpoint;
  }
  public validateSpotsFileEndpoint(): string {
    return environment.apiBaseUrl + this._validateSpotsFileEndpoint;
  }
  public validateTranscodeOrderSpotsFromTextEndPoint(): string {
    return (
      environment.apiBaseUrl + this._validateTranscodeOrderSpotsFromTextEndPoint
    );
  }
  public ValidateTranscodeOrderSpotsFromFileEndPoint(): string {
    return (
      environment.apiBaseUrl + this._validateTranscodeOrderSpotsFromFileEndPoint
    );
  }
  public NotifyS3UploadSpotArrivalEndPoint(): string {
    return environment.apiBaseUrl + this._notifyS3UploadSpotArrivalEndPoint;
  }
  // PlayList
  public getPlayListsEndpoint(): string {
    return environment.apiBaseUrl + this._getPlayLists;
  }
  public deletePlayListEndpoint(): string {
    return environment.apiBaseUrl + this._deletePlayList;
  }
  public getPlayListFilesEndpoint(): string {
    return environment.apiBaseUrl + this._getPlayListFiles;
  }
  public addToPlayListEndpoint(): string {
    return environment.apiBaseUrl + this._addToPlayList;
  }
  public removeFromPlayListEndpoint(): string {
    return environment.apiBaseUrl + this._removeFromPlayList;
  }
  public shufflePlayListEndpoint(): string {
    return environment.apiBaseUrl + this._shufflePlayList;
  }
  public createPlayListEndpoint(): string {
    return environment.apiBaseUrl + this._createPlayList;
  }
  public isPasswordRequiredEndpoint(): string {
    return environment.apiBaseUrl + this._isPasswordRequired;
  }
  public isSharedMediaValidEndpoint(): string {
    return environment.apiBaseUrl + this._isSharedMediaValid;
  }
  public showSharedMediaFilesEndpoint(): string {
    return environment.apiBaseUrl + this._showSharedMediaFiles;
  }
  public showSharedMediaFilesWithAuthEndpoint(): string {
    return environment.apiBaseUrl + this._showSharedMediaFilesWithAuth;
  }
  public shareMedia(): string {
    return environment.apiBaseUrl + this._shareMedia;
  }
  public autoGeneratePassword(): string {
    return environment.apiBaseUrl + this._autoGeneratePassword;
  }
  public getShareMediaConfig(): string {
    return environment.apiBaseUrl + this._getShareMediaConfig;
  }
  public generateTokenEndPoint(): string {
    return environment.apiBaseUrl + this._generateToken;
  }

  // Config
  public getUploadConfigEndpoint(): string {
    return environment.apiBaseUrl + this._getUploadConfigEndPoint;
  }
  public getUploadOrderConfigEndpoint(): string {
    return environment.apiBaseUrl + this._getUploadOrderConfigEndpoint;
  }
  public getServiceCategoriesEndpoint(): string {
    return environment.apiBaseUrl + this._getServiceCategoriesEndPoint;
  }
  public getCreateOrderConfigEndpoint(): string {
    return environment.apiBaseUrl + this._getCreateOrderConfigEndpoint;
  }
  public getCreateVaultConfigEndpoint(): string {
    return environment.apiBaseUrl + this._getCreateVaultConfigEndpoint;
  }
  public getTimeZones(): string {
    return environment.apiBaseUrl + this._getTimeZones;
  }
  public searchDestinationsEndpoint(): string {
    return environment.apiBaseUrl + this._searchDestinationsEndpoint;
  }
  public validateDestinationsTextEndpoint(): string {
    return environment.apiBaseUrl + this._validateDestinationsTextEndpoint;
  }
  public validateDestinationsFromFileEndpoint(): string {
    return environment.apiBaseUrl + this._validateDestinationsFromFileEndpoint;
  }
  public getClientFtpDestinationsEndpoint(): string {
    return environment.apiBaseUrl + this._getClientFtpDestinationsEndpoint;
  }
  public createClientFtpDestinationEndpoint(): string {
    return environment.apiBaseUrl + this._createClientFtpDestinationEndpoint;
  }
  public searchUserDestinationEndpoint(): string {
    return environment.apiBaseUrl + this._searchUserDestinationEndpoint;
  }
  public searchFTPDestinationEndpoint(): string {
    return environment.apiBaseUrl + this._searchFTPDestinationEndpoint;
  }
  public searchShareLinkDestinations(): string {
    return environment.apiBaseUrl + this._searchShareLinkDestinations;
  }
  public getUserSavedDestinationListsEndpoint(): string {
    return environment.apiBaseUrl + this._getUserSavedDestinationListsEndpoint;
  }
  public addToUserSavedDestinationListEndpoint(): string {
    return environment.apiBaseUrl + this._addToUserSavedDestinationListEndpoint;
  }
  public createUserSavedDestinationListEndpoint(): string {
    return (
      environment.apiBaseUrl + this._createUserSavedDestinationListEndpoint
    );
  }
  public deleteUserSavedDestinationListEndpoint(): string {
    return (
      environment.apiBaseUrl + this._deleteUserSavedDestinationListEndpoint
    );
  }
  public createUserFtpDestinationEndpoint(): string {
    return environment.apiBaseUrl + this._createUserFtpDestinationEndpoint;
  }
  public deleteUserFtpDestinationEndpoint(): string {
    return environment.apiBaseUrl + this._deleteUserFtpDestinationEndpoint;
  }
  public updateUserFtpDestinationEndpoint(): string {
    return environment.apiBaseUrl + this._updateUserFtpDestinationEndpoint;
  }
  public updateUserSavedDestinationListEndpoint(): string {
    return (
      environment.apiBaseUrl + this._updateUserSavedDestinationListEndpoint
    );
  }
  public getBrandSelectionConfigEndpoint(): string {
    return environment.apiBaseUrl + this._getBrandSelectionConfigEndpoint;
  }
  public getCreateClientConfigEndpoint(): string {
    return environment.apiBaseUrl + this._getCreateClientConfigEndpoint;
  }
  public getFormatsEndpoint(): string {
    return environment.apiBaseUrl + this._getFormat;
  }
  public getClientSearchConfigEndPoint(): string {
    return environment.apiBaseUrl + this._getClientSearchConfig;
  }
  public getBrandSearchConfigEndPoint(): string {
    return environment.apiBaseUrl + this._getBrandSearchConfig;
  }
  public getCreateBrandConfigEndPoint(): string {
    return environment.apiBaseUrl + this._getCreateBrandConfig;
  }
  public getCostCenterAndInvoiceEndPoint(): string {
    return environment.apiBaseUrl + this._getCostCenterAndInvoice;
  }
  public getAssignVendorConfigEndPoint(): string {
    return environment.apiBaseUrl + this._getAssignVendorConfig;
  }
  public getAddPostHouseConfigEndPoint(): string {
    return environment.apiBaseUrl + this._getAddPostHouseConfig;
  }
  public getUserAdvancedSearchConfigEndPoint(): string {
    return environment.apiBaseUrl + this._getUserAdvancedSearchConfig;
  }
  public getClientPostHousesEndPoint(): string {
    return environment.apiBaseUrl + this._getClientPostHouses;
  }
  public getClientUserGrpEndPoint(): string {
    return environment.apiBaseUrl + this._getClientUserGroups;
  }
  public getUserHomeConfigEndPoint(): string {
    return environment.apiBaseUrl + this._getUserHomeConfigEndPoint;
  }
  public getCreateUserConfigEndPoint(): string {
    return environment.apiBaseUrl + this._getCreateUserConfig;
  }
  public getCurrentClientBrandsEndPoint(): string {
    return environment.apiBaseUrl + this._getCurrentClientBrands;
  }
  public getClientSpecialVisibilityClientEndPoint(): string {
    return environment.apiBaseUrl + this._getClientSpecialVisibility;
  }
  public getOrderedByDataEndPoint(): string {
    return environment.apiBaseUrl + this._getOrderedByDataEndPoint;
  }
  public searchCurrentBrandsEndPoint(): string {
    return environment.apiBaseUrl + this._searchCurrentBrands;
  }
  public getCreateUserProfileConfigEndPoint(): string {
    return environment.apiBaseUrl + this._getCreateUserProfileConfig;
  }
  public getSearchDestinationConfigEndPoint(): string {
    return environment.apiBaseUrl + this._getSearchDestinationConfig;
  }
  public getDigitalVendorsForDestinationMappingEndPoint(): string {
    return (
      environment.apiBaseUrl + this._getDigitalVendorsForDestinationMapping
    );
  }
  public getCreateDestinationConfigEndPoint(): string {
    return environment.apiBaseUrl + this._getCreateDestinationConfig;
  }
  public getQuickLinkConfigEndPoint(): string {
    return environment.apiBaseUrl + this._getQuickLinkConfig;
  }
  public getStatesByCountryEndPoint(): string {
    return environment.apiBaseUrl + this._getStatesByCountry;
  }
  public getClientCustomTagConfigEndPoint(): string {
    return environment.apiBaseUrl + this._getClientCustomTagConfig;
  }
  public getClientBundleServicesConfigEndPoint(): string {
    return environment.apiBaseUrl + this._getClientBundleServicesConfig;
  }
  public getDistributionMediaAdvancedSearchConfigEndPoint(): string {
    return (
      environment.apiBaseUrl +
      this._getDistributionMediaAdvancedSearchConfigEndPoint
    );
  }
  public getProxyUrlEndpoint(): string {
    return environment.apiBaseUrl + this._getProxyUrlEndpoint;
  }
  public getSearchSpecificationsEndPoint(): string {
    return environment.apiBaseUrl + this._getSearchSpecificationsEndPoint;
  }
  public getEncryptTextEndPoint(): string {
    return environment.apiBaseUrl + this._getEncryptTextEndPoint;
  }

  public checkSpotPatternEndPoint(): string {
    return environment.apiBaseUrl + this._checkSpotPattern;
  }

  // Upload
  public getUploadSpecEndpoint(): string {
    return environment.apiBaseUrl + this._getUploadSpec;
  }
  public createPlaceHolderEndpoint(): string {
    return environment.apiBaseUrl + this._createPlaceHolder;
  }
  public uploadMetadataEndpoint(): string {
    return environment.apiBaseUrl + this._uploadMetadata;
  }
  public createSpotFileEndpoint(): string {
    return environment.apiBaseUrl + this._createSpotFileEndpoint;
  }

  //Download Media

  public registerDownloadMediaEndPoint(): string {
    return environment.apiBaseUrl + this._registerDownloadMediaEndPoint;
  }
  public completeDownloadMediaFileEndPoint(): string {
    return environment.apiBaseUrl + this._completeDownloadMediaFileEndPoint;
  }
  public downloadMediaUrlEndPoint(): string {
    return environment.apiBaseUrl + this._downloadMediaUrlEndPoint;
  }

  // Settings
  public saveUserPersonalInformationEndpoint(): string {
    return environment.apiBaseUrl + this._saveUserPersonalInformation;
  }
  public updateUserPasswordEndpoint(): string {
    return environment.apiBaseUrl + this._getUpdatePasswordEndpoint;
  }
  public updateUserPasswordAfterExpiryEndpoint(): string {
    return environment.apiBaseUrl + this._getUpdatePasswordAfterExpiryEndpoint;
  }
  public updateUserAvatarEndpoint(): string {
    return environment.apiBaseUrl + this._getUpdateAvatarEndpoint;
  }
  public generateResetPasswordTokenEndPoint(): string {
    return environment.apiBaseUrl + this._generateResetPasswordToken;
  }
  public validateResetPasswordLinkEndpoint(): string {
    return environment.apiBaseUrl + this._validateResetPasswordLinkEndpoint;
  }
  public updatePasswordAfterTokenAuthEndpoint(): string {
    return environment.apiBaseUrl + this._updatePasswordAfterTokenAuthEndpoint;
  }
  public createUserDistributionGroupEndpoint(): string {
    return environment.apiBaseUrl + this._createUserDistributionGroupEndpoint;
  }
  public userEmailDistributionGroupsEndpoint(): string {
    return environment.apiBaseUrl + this._userEmailDistributionGroupsEndpoint;
  }
  public userGroupEmailListEndpoint(): string {
    return environment.apiBaseUrl + this._userGroupEmailListEndpoint;
  }
  public updateUserDistributionEmailsFromGroupEndpoint(): string {
    return (
      environment.apiBaseUrl +
      this._updateUserDistributionEmailsFromGroupEndpoint
    );
  }
  public removeDistributionGroupEndpoint(): string {
    return environment.apiBaseUrl + this._removeUserDistributionGroupEndpoint;
  }
  public searchEmailDistributionGroupsEndpoint(): string {
    return (
      environment.apiBaseUrl + this._searchUserEmailDistributionGroupsEndpoint
    );
  }
  public canManagePwdEndpoint(): string {
    return environment.apiBaseUrl + this._canManagePwdEndpoint;
  }
  // Notification
  public notificationPreferencesEndpoint(): string {
    return environment.apiBaseUrl + this._getNotificationPreferencesEndpoint;
  }
  public currentUserNotificaionPreferencesEndpoint(): string {
    return (
      environment.apiBaseUrl +
      this._getCurrentUserNotificaionPreferencesEndpoint
    );
  }
  public saveUserNotificaionPreferencesEndpoint(): string {
    return (
      environment.apiBaseUrl + this._saveUserNotificaionPreferencesEndpoint
    );
  }
  public removeNotificationPreferenceEndpoint(): string {
    return environment.apiBaseUrl + this._removeNotificationPreferenceEndpoint;
  }

  // Notification Emails
  public searchNotificationsEndPoint(): string {
    return environment.apiBaseUrl + this._searchNotificationsEndpoint;
  }
  public searchSpotNotificationsEndPoint(): string {
    return environment.apiBaseUrl + this._searchSpotNotificationsEndpoint;
  }
  public searchOrderNotificationsEndPoint(): string {
    return environment.apiBaseUrl + this._searchOrderNotificationsEndpoint;
  }
  public getNotificationMessageEndPoint(): string {
    return environment.apiBaseUrl + this._getNotificationMessageEndpoint;
  }
  public setViewedNotificationEndPoint(): string {
    return environment.apiBaseUrl + this._setViewedNotificationEndpoint;
  }
  public setArchivedNotificationEndPoint(): string {
    return environment.apiBaseUrl + this._setArchivedNotificationEndpoint;
  }
  public getNotificationsCountEndPoint(): string {
    return environment.apiBaseUrl + this._getNotificationsCountEndpoint;
  }

  // Orders
  public getOrderSearchConfigEndpointQC(): string {
    return environment.apiBaseUrl + this._getOrderSearchConfigEndpoint;
  }
  public getOrderSearchEndpoint(): string {
    return environment.apiBaseUrl + this._searchOrdersOrderViewEndpoint;
  }
  public getOrderSearchSpotViewEndpoint(): string {
    return environment.apiBaseUrl + this._searchOrdersSpotViewEndpoint;
  }
  public createOrderEndpoint(): string {
    return environment.apiBaseUrl + this._createOrderEndpoint;
  }
  public copyClientLevelOrderFileEndpoint(): string {
    return environment.apiBaseUrl + this._copyClientLevelOrderFileEndpoint;
  }
  //public addGroupToOrderEndpoint(): string { return environment.apiBaseUrl + this._addGroupToOrderEndpoint; }
  //public updateOrderGroupEndpoint(): string { return environment.apiBaseUrl + this._updateOrderGroupEndpoint; }
  public getOrderHistoryOfSpotEndPoint(): string {
    return environment.apiBaseUrl + this._getOrderHistoryOfSpotEndPoint;
  }

  public editOrderEndPoint(): string {
    return environment.apiBaseUrl + this._editOrderEndPoint;
  }

  //public deleteMediaFromOrderGroupEndpoint(): string { return environment.apiBaseUrl + this._deleteMediaFromOrderGroupEndpoint; }
  public getOrderAuditLogs(): string {
    return environment.apiBaseUrl + this._getOrderAuditLogs;
  }
  //public deleteGroupFromOrderEndpoint(): string { return environment.apiBaseUrl + this._deleteGroupFromOrderEndpoint; }
  public getOrderHomeInitLoad(): string {
    return environment.apiBaseUrl + this._getOrderHomeInitLoadEndpoint;
  }
  public saveDraftEndpoint(): string {
    return environment.apiBaseUrl + this._saveDraftEndpoint;
  }
  public saveUnSavedOrderEndpoint(): string {
    return environment.apiBaseUrl + this._saveUnSavedOrderEndpoint;
  }
  public getOrderDraftEndpoint(): string {
    return environment.apiBaseUrl + this._getOrderDraftEndpoint;
  }
  public getUnSavedOrderEndpoint(): string {
    return environment.apiBaseUrl + this._getUnSavedOrderEndpoint;
  }
  public getAwaitingReviewOrdersEndPoint(): string {
    return environment.apiBaseUrl + this._getAwaitingReviewOrdersEndPoint;
  }
  public GetVendorSubmissionFailedOrdersEndPoint(): string {
    return (
      environment.apiBaseUrl + this._getVendorSubmissionFailedOrdersEndPoint
    );
  }
  public getOrdersInProgress(): string {
    return environment.apiBaseUrl + this._getOrdersInProgress;
  }
  public getReadyForBillingOrders(): string {
    return environment.apiBaseUrl + this._getReadyForBillingOrders;
  }
  public getDraftOrders(): string {
    return environment.apiBaseUrl + this._getDraftOrders;
  }
  public deleteDraftOrderEndpoint(): string {
    return environment.apiBaseUrl + this._deleteDraftOrder;
  }
  public copyDraftOrderEndpoint(): string {
    return environment.apiBaseUrl + this._copyDraftOrder;
  }
  public claimOrderEndPoint(): string {
    return environment.apiBaseUrl + this._claimOrderEndPoint;
  }
  public claimOrderInDetailEndPoint(): string {
    return environment.apiBaseUrl + this._claimOrderInDetailEndPoint;
  }
  public deleteOrderFileEndpoint(): string {
    return environment.apiBaseUrl + this._deleteOrderFile;
  }
  public deleteClientBrandAttachmentEndpoint(): string {
    return environment.apiBaseUrl + this._deleteClientBrandAttachment;
  }
  public deleteOrderFtpPodEndpoint(): string {
    return environment.apiBaseUrl + this._deleteOrderFtpPod;
  }
  public orderPassQCEndpoint(): string {
    return environment.apiBaseUrl + this._orderPassQC;
  }
  public orderStartProcessEndpoint(): string {
    return environment.apiBaseUrl + this._orderStartProcess;
  }

  public orderStartProcessInDetailEndpoint(): string {
    return environment.apiBaseUrl + this._orderStartProcessInDetail;
  }

  public orderCompleteProcessEndpoint(): string {
    return environment.apiBaseUrl + this._orderCompleteProcess;
  }
  public addVendorLineItemsEndpoint(): string {
    return environment.apiBaseUrl + this._addVendorLineItems;
  }
  public flagOrdersEndpoint(): string {
    return environment.apiBaseUrl + this._flagOrdersEndpoint;
  }
  public releaseClaimedOrderEndpoint(): string {
    return environment.apiBaseUrl + this._releaseClaimedOrderEndpoint;
  }
  public sendOrderToAEEndpoint(): string {
    return environment.apiBaseUrl + this._sendOrderToAEEndpoint;
  }
  public setOrderBackToReadyForProcessEndpoint(): string {
    return environment.apiBaseUrl + this._setOrderBackToReadyForProcessEndpoint;
  }
  public sendOrderForReviewEndpoint(): string {
    return environment.apiBaseUrl + this._sendOrderForReviewEndpoint;
  }
  public sendOrderToBillingEndpoint(): string {
    return environment.apiBaseUrl + this._sendOrderToBillingEndpoint;
  }

  public flagAndNotifyOrderEndpoint(): string {
    return environment.apiBaseUrl + this._flagAndNotifyOrderEndpoint;
  }
  public orderEditStartEndpoint(): string {
    return environment.apiBaseUrl + this._orderEditStartEndpoint;
  }
  public orderEditEndEndpoint(): string {
    return environment.apiBaseUrl + this._orderEditEndEndpoint;
  }
  public getCopyOrderDetailEndpoint(): string {
    return environment.apiBaseUrl + this._getCopyOrderDetailEndpoint;
  }
  public getOrderDetailEndpoint(): string {
    return environment.apiBaseUrl + this._getOrderDetailEndpoint;
  }

  public getBillingItemsEndpoint(): string {
    return environment.apiBaseUrl + this._getBillingItemsEndpoint;
  }

  public getServiceVendorsEndpoint(): string {
    return environment.apiBaseUrl + this._getServiceVendorsEndpoint;
  }

  //public getOrderServicesEndpoint(): string { return environment.apiBaseUrl + this._getOrderServicesEndpoint };

  public updateServiceVendorEndpoint(): string {
    return environment.apiBaseUrl + this._updateServiceVendorEndpointEndpoint;
  }

  //public updateOrderServicesEndpoint(): string { return environment.apiBaseUrl + this._updateOrderServicesEndpoint };

  //public getOrderGroupDetailEndpoint(): string { return environment.apiBaseUrl + this._getOrderGroupDetailEndpoint };
  public getSpotsFromOrderEndpoint(): string {
    return environment.apiBaseUrl + this._getSpotsFromOrderEndpoint;
  }
  public getReadyToProcessOrdersEndpoint(): string {
    return environment.apiBaseUrl + this._getReadyToProcessOrdersEndpoint;
  }
  public getProcessingOrdersEndpoint(): string {
    return environment.apiBaseUrl + this._getProcessingOrdersEndpoint;
  }
  public getDestinationForOrderDetailEndpoint(): string {
    return environment.apiBaseUrl + this._getDestinationForOrderDetailEndpoint;
  }
  public getFtpUploadDetailsForOrderDetailEndpoint(): string {
    return (
      environment.apiBaseUrl + this._getFtpUploadDetailsForOrderDetailEndpoint
    );
  }
  public getContentDownloadDetailsForOrderDetailEndpoint(): string {
    return (
      environment.apiBaseUrl +
      this._getContentDownloadDetailsForOrderDetailEndpoint
    );
  }
  public getShareLinkDetailsForOrderDetailEndpoint(): string {
    return (
      environment.apiBaseUrl + this._getShareLinkDetailsForOrderDetailEndpoint
    );
  }
  public editOrderHeaderEndPoint(): string {
    return environment.apiBaseUrl + this._editOrderHeaderEndpoint;
  }
  public updateOrderFTPUploadDestinationEndPoint(): string {
    return environment.apiBaseUrl + this._updateOrderFTPUploadDestination;
  }
  public reAssignVendorForOrderLineItemsEndPoint(): string {
    return environment.apiBaseUrl + this._reAssignVendorForOrderLineItems;
  }
  public assignVendorForOrderLineItemsEndPoint(): string {
    return environment.apiBaseUrl + this._assignVendorForOrderLineItems;
  }
  public updateDeliveryPODEndPoint(): string {
    return environment.apiBaseUrl + this._updateDeliveryPOD;
  }
  public approveOrderReviewEndpoint(): string {
    return environment.apiBaseUrl + this._approveOrderReviewEndpoint;
  }
  public complteMultipleOrderProcessingEndPoint(): string {
    return environment.apiBaseUrl + this._complteMultipleOrderProcess;
  }
  public releaseOrderClaimedForProcessingEndPoint(): string {
    return environment.apiBaseUrl + this._releaseOrderClaimedForProcessing;
  }
  public getOrderGroupServicesForVendorPOEndPoint(): string {
    return environment.apiBaseUrl + this._getOrderGroupServicesForVendorPO;
  }
  public viewVendorPOEndPoint(): string {
    return environment.apiBaseUrl + this._viewVendorPO;
  }
  public createNonDigitalVendorPOEndPoint(): string {
    return environment.apiBaseUrl + this._createNonDigitalVendorPO;
  }
  public retryVendorLineItemCreationEndPoint(): string {
    return environment.apiBaseUrl + this._retryVendorLineItemCreation;
  }
  public retryVendorOrderStatusCheckEndPoint(): string {
    return environment.apiBaseUrl + this._retryVendorOrderStatusCheck;
  }
  public retryVendorOrderSubmissionEndPoint(): string {
    return environment.apiBaseUrl + this._retryVendorOrderSubmission;
  }
  public updateVendorOrderNumberEndPoint(): string {
    return environment.apiBaseUrl + this._updateVendorOrderNumber;
  }
  public uploadOrderEndpoint(): string {
    return environment.apiBaseUrl + this._uploadOrderEndpoint;
  }
  public downloadMetadaFileEndpoint(): string {
    return environment.apiBaseUrl + this._downloadMetadaFileEndpoint;
  }
  public reSendMediaToDestinationsEndPoint(): string {
    return environment.apiBaseUrl + this._reSendMediaToDestinations;
  }

  public getUploadedOrdersEndpoint(): string {
    return environment.apiBaseUrl + this._getUploadedOrdersEndpoint;
  }
  public getUploadedOrderEndpoint(): string {
    return environment.apiBaseUrl + this._getUploadedOrderEndpoint;
  }
  public saveUploadedOrderChanges(): string {
    return environment.apiBaseUrl + this._saveUploadedOrderChanges;
  }
  public deleteUploadedOrderEndpoint(): string {
    return environment.apiBaseUrl + this._deleteUploadedOrder;
  }
  public completeOrderFileUploadEndpoint(): string {
    return environment.apiBaseUrl + this._completeOrderFileUpload;
  }
  public completeVaultFileUploadEndpoint(): string {
    return environment.apiBaseUrl + this._completeVaultFileUpload;
  }
  public completeVaultAssetFileUploadEndpoint(): string {
    return environment.apiBaseUrl + this._completeVaultAssetFileUpload;
  }
  public initiateOrderFileUploadEndpoint(): string {
    return environment.apiBaseUrl + this._initiateOrderFileUpload;
  }
  public initiateVaultFileUploadEndpoint(): string {
    return environment.apiBaseUrl + this._initiateVaultFileUpload;
  }
  public initiateVaultAssetFileUploadEndpoint(): string {
    return environment.apiBaseUrl + this._initiateVaultAssetFileUpload;
  }
  public completeUploadOrderFTPPodEndpoint(): string {
    return environment.apiBaseUrl + this._completeUploadOrderFTPPod;
  }
  public initiateUploadOrderFTPPodEndpoint(): string {
    return environment.apiBaseUrl + this._initiateUploadOrderFTPPod;
  }
  public completeUploadOpsAttachmentEndpoint(): string {
    return environment.apiBaseUrl + this._completeUploadOpsAttachment;
  }
  public initiateUploadOpsAttachmentEndpoint(): string {
    return environment.apiBaseUrl + this._initiateUploadOpsAttachment;
  }
  public downloadOrderFileEndpoint(): string {
    return environment.apiBaseUrl + this._downloadOrderFile;
  }
  public downloadOpsAttachmentEndpoint(): string {
    return environment.apiBaseUrl + this._downloadOpsAttachment;
  }
  public getOrderDebugDetailsEndPoint(): string {
    return environment.apiBaseUrl + this._getOrderDebugDetailsEndPoint;
  }
  public resendOrderMessageEndPoint(): string {
    return environment.apiBaseUrl + this._resendOrderMessageEndPoint;
  }
  public sendNewOrderMessageEndPoint(): string {
    return environment.apiBaseUrl + this._sendNewOrderMessageEndPoint;
  }
  public getWorkflowOperationsEndPoint(): string {
    return environment.apiBaseUrl + this._getWorkflowOperationsEndPoint;
  }
  public getOrderQueueMessagesEndPoint(): string {
    return environment.apiBaseUrl + this._getOrderQueueMessagesEndPoint;
  }

  public searchOrderWorkflowsEndPoint(): string {
    return environment.apiBaseUrl + this._searchOrderWorkflowsEndPoint;
  }
  public updateWorkflowEndPoint(): string {
    return environment.apiBaseUrl + this._updateWorkflowEndPoint;
  }
  public cancelOrderEndPoint(): string {
    return environment.apiBaseUrl + this._cancelOrderEndPoint;
  }
  public updateOrderlineItemsToStatusCheckEndPoint(): string {
    return (
      environment.apiBaseUrl + this._updateOrderlineItemsToStatusCheckEndPoint
    );
  }
  public forceUpdateOrderStatusEndPoint(): string {
    return environment.apiBaseUrl + this._forceUpdateOrderStatusEndPoint;
  }
  public forceUpdateServiceLevelEndPoint(): string {
    return environment.apiBaseUrl + this._forceUpdateServiceLevelEndPoint;
  }
  public releaseMultipleOrdersClaimedForProcessingEndPoint(): string {
    return (
      environment.apiBaseUrl + this._releaseMultipleOrdersClaimedForProcessing
    );
  }
  public searchRecentTranscodeOrdersEndPoint(): string {
    return environment.apiBaseUrl + this._searchRecentTranscodeOrders;
  }
  public createPurchaseOrderEndPoint(): string {
    return environment.apiBaseUrl + this._createPurchaseOrderEndPoint;
  }
  
  public updateOrderProductEndPoint(): string {
    return environment.apiBaseUrl + this._updateOrderProductEndPoint;
  }

  public sendOrderEmailEndpoint(): string {
    return environment.apiBaseUrl + this._sendOrderEmailEndpoint;
  }

  // Reports
  public getVendorPurchaseOrderPDFEndPoint(): string {
    return environment.apiBaseUrl + this._getVendorPurchaseOrderPDF;
  }
  public getDeliveryConfirmationReportEndPoint(): string {
    return environment.apiBaseUrl + this._getDeliveryConfirmationReport;
  }
  public getOrderSummaryReportPDFEndPoint(): string {
    return environment.apiBaseUrl + this._getOrderSummaryReportPDF;
  }
  public getReportConfigEndPoint(): string {
    return environment.apiBaseUrl + this._getReportConfigEndPoint;
  }
  public generateOnDemandReportEndPoint(): string {
    return environment.apiBaseUrl + this._generateOnDemandReportEndPoint;
  }
  public getScheduledReportFilesEndPoint(): string {
    return environment.apiBaseUrl + this._getScheduledReportFilesEndPoint;
  }
  public getReportFileEndPoint(): string {
    return environment.apiBaseUrl + this._getReportFile;
  }

  // Admin
  public getBrandSelectionEndPoint(): string {
    return environment.apiBaseUrl + this._getBrandSelectionEndpoint;
  }
  public getPostHouseClientBrandsEndPoint(): string {
    return environment.apiBaseUrl + this._getPostHouseClientBrands;
  }
  public createClientEndPoint(): string {
    return environment.apiBaseUrl + this._createClientEndpoint;
  }
  public createBrandEndPoint(): string {
    return environment.apiBaseUrl + this._createBrandEndpoint;
  }
  public createProductEndPoint(): string {
    return environment.apiBaseUrl + this._createProductEndpoint;
  }
  public getClientViewEndPoint(): string {
    return environment.apiBaseUrl + this._getClientView;
  }
  public getClientEditDetailEndPoint(): string {
    return environment.apiBaseUrl + this._getClientEditDetail;
  }
  public searchAllClientsEndPoint(): string {
    return environment.apiBaseUrl + this._searchAllClients;
  }
  public searchAllBrandsEndPoint(): string {
    return environment.apiBaseUrl + this._searchAllBrands;
  }
  public deleteClientBrandEndPoint(): string {
    return environment.apiBaseUrl + this._deleteClientBrand;
  }
  public deleteClientBrandProductEndPoint(): string {
    return environment.apiBaseUrl + this._deleteClientBrandProduct;
  }
  public updateClientLogoEndPoint(): string {
    return environment.apiBaseUrl + this._updateClientLogo;
  }
  public removeClientLogoEndPoint(): string {
    return environment.apiBaseUrl + this._removeClientLogo;
  }
  public editClientEndPoint(): string {
    return environment.apiBaseUrl + this._editClient;
  }
  public getBrandDetailEndPoint(): string {
    return environment.apiBaseUrl + this._getBrandDetail;
  }
  public deleteBrandProductEndPoint(): string {
    return environment.apiBaseUrl + this._deleteBrandProduct;
  }
  public editBrandEndPoint(): string {
    return environment.apiBaseUrl + this._editBrand;
  }
  public createPostHouseEndPoint(): string {
    return environment.apiBaseUrl + this._createPostHouse;
  }
  public addPostHouseToClientEndPoint(): string {
    return environment.apiBaseUrl + this._addPostHouseToClient;
  }
  public deletePostHouseEndPoint(): string {
    return environment.apiBaseUrl + this._deletePostHouse;
  }
  public updateClientStatusEndPoint(): string {
    return environment.apiBaseUrl + this._updateClientStatus;
  }
  public deleteDraftClientEndPoint(): string {
    return environment.apiBaseUrl + this._deleteDraftClient;
  }
  public startClientEditLockEndPoint(): string {
    return environment.apiBaseUrl + this._startClientEditLock;
  }
  public completeClientEditLockEndPoint(): string {
    return environment.apiBaseUrl + this._completeClientEditLock;
  }
  public makeClientBillableEndPoint(): string {
    return environment.apiBaseUrl + this._convertToBillableClient;
  }
  public editProductEndPoint(): string {
    return environment.apiBaseUrl + this._editProduct;
  }
  public updateBrandStatusEndPoint(): string {
    return environment.apiBaseUrl + this._updateBrandStatus;
  }
  public searchAllUsersEndPoint(): string {
    return environment.apiBaseUrl + this._searchAllUsersEndPoint;
  }
  public getBrandsForUserCreationEndPoint(): string {
    return environment.apiBaseUrl + this._getBrandsForUserCreationEndPoint;
  }
  public createNewUserEndPoint(): string {
    return environment.apiBaseUrl + this._createNewUser;
  }
  public createNewUserProfileEndPoint(): string {
    return environment.apiBaseUrl + this._createNewUserProfile;
  }
  public getUserProfileViewEndPoint(): string {
    return environment.apiBaseUrl + this._getUserProfileView;
  }
  public getEditUserProfileConfigEndPoint(): string {
    return environment.apiBaseUrl + this._getEditUserProfileConfig;
  }
  public editUserProfileEndPoint(): string {
    return environment.apiBaseUrl + this._editUserProfile;
  }
  public updateUserProfileStatusEndPoint(): string {
    return environment.apiBaseUrl + this._updateUserProfileStatus;
  }
  public searchAllDestinationsEndPoint(): string {
    return environment.apiBaseUrl + this._searchAllDestinations;
  }
  public searchVendorDestinationEndPoint(): string {
    return environment.apiBaseUrl + this._searchVendorDestination;
  }
  public createDestinationEndPoint(): string {
    return environment.apiBaseUrl + this._createDestination;
  }
  public isVendorDestinationCodeValidEndPoint(): string {
    return environment.apiBaseUrl + this._isVendorDestinationCodeValid;
  }
  public isDestinationCodeValidEndPoint(): string {
    return environment.apiBaseUrl + this._isDestinationCodeValid;
  }
  public getDestinationViewEndPoint(): string {
    return environment.apiBaseUrl + this._getDestinationView;
  }
  public editDestinationEndPoint(): string {
    return environment.apiBaseUrl + this._editDestination;
  }
  public updateDestinationStatusEndPoint(): string {
    return environment.apiBaseUrl + this._updateDestinationStatus;
  }
  public removeSpecMappingEndPoint(): string {
    return environment.apiBaseUrl + this._removeSpecMappingEndPoint;
  }
  public validateBXFFileEndPoint(): string {
    return environment.apiBaseUrl + this._validateBXFFileEndPoint;
  }
  public createAuthOrgEndPoint(): string {
    return environment.apiBaseUrl + this._createAuthOrgEndPoint;
  }
  public getTranscodeSpecsByDestinationCode(): string {
    return (
      environment.apiBaseUrl + this._getSpecificationsForOrderByDestinationCode
    );
  }

  // distribution
  public distributionSearchEndpoint(): string {
    return environment.apiBaseUrl + this._distributionSearchEndpoint;
  }
  public getBxfFileDownloadUrlEndpoint(): string {
    return environment.apiBaseUrl + this._getBxfFileDownloadUrlEndpoint;
  }
  public getUpdateDistributionMediaEndpoint(): string {
    return environment.apiBaseUrl + this._updateDistributionMediaEndpoint;
  }
  public getrepitchDistributionMediaEndpoint(): string {
    return environment.apiBaseUrl + this._repitchDistributionMediaEndpoint;
  }
  public getDistributionMediaDownloadUrlEndPoint(): string {
    return (
      environment.apiBaseUrl + this._getDistributionMediaDownloadUrlEndPoint
    );
  }
  public getTMCReleasesUrlEndPoint(): string {
    return environment.apiBaseUrl + this._getTMCReleasesUrlEndPoint;
  }
  public getTMCReleaseDownloadUrlEndpoint(): string {
    return environment.apiBaseUrl + this._getTMCReleaseDownloadUrl;
  }

  //transcode
  public searchSpotsForTranscodeOrderEndpoint(): string {
    return environment.apiBaseUrl + this._searchSpotsForTranscodeOrderEndpoint;
  }
  public searchTranscodeOrdersEndPoint(): string {
    return environment.apiBaseUrl + this._searchTranscodeOrdersEndPoint;
  }
  public editTranscodeOrderEndPoint(): string {
    return environment.apiBaseUrl + this._editTranscodeOrderEndPoint;
  }
  public getTranscodeSpecsEndPoint(): string {
    return environment.apiBaseUrl + this._getTranscodeSpecsEndPoint;
  }
  public getTrasncodeOrderConfigEndpoint(): string {
    return environment.apiBaseUrl + this._getTrasncodeOrderConfigEndpoint;
  }
  public createTranscodeOrderEndpoint(): string {
    return environment.apiBaseUrl + this._createTranscodeOrderEndpoint;
  }
  public shareTranscodeAssetsEndpoint(): string {
    return environment.apiBaseUrl + this._shareTranscodeAssetsEndpoint;
  }
  public getTranscodeAssetsShareLinkEndpoint(): string {
    return environment.apiBaseUrl + this._getTranscodeAssetsShareLinkEndpoint;
  }
  public isMaxRequestReachedForTierEndpoint(): string {
    return environment.apiBaseUrl + this._isMaxRequestReachedForTierEndpoint;
  }

  //Project
  public getProjectClients(): string {
    return environment.apiBaseUrl + this._getProjectClients;
  }
  public saveProject(): string {
    return environment.apiBaseUrl + this._saveProject;
  }
  public createProjectFolder(): string {
    return environment.apiBaseUrl + this._createProjectFolder;
  }
  public loadProjFolders(): string {
    return environment.apiBaseUrl + this._loadProjFolders;
  }
  public archiveProjects(): string {
    return environment.apiBaseUrl + this._archiveProjects;
  }
  public getUsersByClient(): string {
    return environment.apiBaseUrl + this._getUsersByClient;
  }
  public loadProjects(): string {
    return environment.apiBaseUrl + this._loadProjects;
  }
  public deleteProject(): string {
    return environment.apiBaseUrl + this._deleteFileCollection;
  }
  public assignUserPermission(): string {
    return environment.apiBaseUrl + this._assignUserPermission;
  }
  public initiateMultipartUpload(): string {
    return environment.apiBaseUrl + this._initiateMultipartUpload;
  }
  public getPartUploadPresignedURL(): string {
    return environment.apiBaseUrl + this._getPartUploadPresignedURL;
  }
  public completeMultipartUpload(): string {
    return environment.apiBaseUrl + this._completeMultipartUpload;
  }
  public abortMultipartUpload(): string {
    return environment.apiBaseUrl + this._abortMultipartUpload;
  }
  public getSinglePartSignedURL(): string {
    return environment.apiBaseUrl + this._generateSinglePartSignedURL;
  }
  public addFiles(): string {
    return environment.apiBaseUrl + this._addFiles;
  }
  public updateProject(): string {
    return environment.apiBaseUrl + this._updateProject;
  }
  public updateProjectFolder(): string {
    return environment.apiBaseUrl + this._updateProjectFolder;
  }
  public downloadProjectFile(): string {
    return environment.apiBaseUrl + this._downloadProjectFile;
  }
  public promoteProjectFile(): string {
    return environment.apiBaseUrl + this._promoteProjectFile;
  }
  public assignExistingUserPermission(): string {
    return environment.apiBaseUrl + this._assignExistingUserPermission;
  }
  public assignNewUserPermission(): string {
    return environment.apiBaseUrl + this._assignNewUserPermission;
  }
  public moveFileCollection(): string {
    return environment.apiBaseUrl + this._moveFileCollection;
  }

  //RightsManagement
  public searchRightManagement(): string {
    return environment.apiBaseUrl + this._searchRightsManagement;
  }
  public getTalentRightsConfigEndpoint(): string {
    return environment.apiBaseUrl + this._getTalentRightsConfigEndPoint;
  }

  public initiateAssetMultipartUpload(): string {
    return environment.apiBaseUrl + this._initiateAssetMultipartUpload;
  }
  public getAssetPartUploadPresignedURL(): string {
    return environment.apiBaseUrl + this._getAssetPartUploadPresignedURL;
  }
  public completeAssetMultipartUpload(): string {
    return environment.apiBaseUrl + this._completeAssetMultipartUpload;
  }
  public abortAssetMultipartUpload(): string {
    return environment.apiBaseUrl + this._abortAssetMultipartUpload;
  }
  public getAssetSinglePartSignedURL(): string {
    return environment.apiBaseUrl + this._generateAssetSinglePartSignedURL;
  }

  //Vault
  public createVaultEndpoint(): string {
    return environment.apiBaseUrl + this._createVaultEndpoint;
  }
  public deleteVaultFileEndpoint(): string {
    return environment.apiBaseUrl + this._deleteVaultFile;
  }
  public getVaultAssetSearchEndpoint(): string {
    return environment.apiBaseUrl + this._searchAssetVaultRequestsViewEndpoint;
  }
  public getVaultRequestSearchEndpoint(): string {
    return environment.apiBaseUrl + this._searchVaultRequestsViewEndpoint;
  }
  public getVaultSearchConfigEndpoint(): string {
    return environment.apiBaseUrl + this._searchVaultConfigEndpoint;
  }
  public getVaultAssetDetailsEndpoint(): string {
    return environment.apiBaseUrl + this._getVaultAssetDetailsEndpoint;
  }
  public getVaultDigitalAssetDetailsEndpoint(): string {
    return environment.apiBaseUrl + this._getVaultDigitalAssetDetailsEndpoint;
  }
  public updateVaultAssetStatusVault(): string {
    return environment.apiBaseUrl + this._updateVaultAssetStatusVault;
  }
  public getUpdateVaultAssetsStatusLoginEndpoint(): string {
    return (
      environment.apiBaseUrl + this._getUpdateVaultAssetsStatusLoginEndpoint
    );
  }
  public getUpdateVaultAssetsStatusDestroyEndpoint(): string {
    return (
      environment.apiBaseUrl + this._getUpdateVaultAssetsStatusDestroyEndpoint
    );
  }
  public getUpdateVaultAssetsStatusStagingEndpoint(): string {
    return (
      environment.apiBaseUrl + this._getUpdateVaultAssetsStatusStagingEndpoint
    );
  }
  public getVaultRequestDetailEndpoint(): string {
    return environment.apiBaseUrl + this._getVaultRequestDetailEndpoint;
  }
  public getVaultAssetEditConfigEndpoint(): string {
    return environment.apiBaseUrl + this._getVaultAssetEditConfigEndpoint;
  }
  public updateVaultAssetEndpoint(): string {
    return environment.apiBaseUrl + this._updateVaultAssetEndpoint;
  }
  public updateVaultDigitalAssetEndpoint(): string {
    return environment.apiBaseUrl + this._updateVaultDigitalAssetEndpoint;
  }
  public updateVaultRequestDetailHeaderEndpoint(): string {
    return (
      environment.apiBaseUrl + this._updateVaultRequestDetailHeaderEndpoint
    );
  }
  public updateVaultRequestDetailEndpoint(): string {
    return environment.apiBaseUrl + this._updateVaultRequestDetailEndpoint;
  }
  public addVaultAssetNoteEndpoint(): string {
    return environment.apiBaseUrl + this._addVaultAssetNoteEndpoint;
  }
  public getVaultAssetAdditionalServicesEndpoint(): string {
    return environment.apiBaseUrl + this._getVaultAssetAdditionalEndpoint;
  }
  public updateVaultAssetAdditionalServicesEndpoint(): string {
    return (
      environment.apiBaseUrl + this._updateVaultAssetAdditionalServicesEndpoint
    );
  }
  public downloadVaultFileEndpoint(): string {
    return environment.apiBaseUrl + this._downloadVaultFile;
  }
  public getVaultShippingServicesEndpoint(): string {
    return environment.apiBaseUrl + this._getVaultShippingServicesEndpoint;
  }
  public updateVaultAssetsStatusRemoveEndpoint(): string {
    return environment.apiBaseUrl + this._updateVaultAssetsStatusRemoveEndpoint;
  }
  public updateVaultAssetsStatusLogoutEndpoint(): string {
    return environment.apiBaseUrl + this._updateVaultAssetsStatusLogoutEndpoint;
  }
  public updateVaultAssetMissingEndpoint(): string {
    return environment.apiBaseUrl + this._updateVaultAssetMissingEndpoint;
  }
  public updateVaultAssetAvailableEndpoint(): string {
    return environment.apiBaseUrl + this._updateVaultAssetAvailableEndpoint;
  }
  public updateVaultDigitalAssetMissingEndpoint(): string {
    return environment.apiBaseUrl + this._updateVaultDigitalAssetMissingEndpoint;
  }
  public updateVaultDigitalAssetAvailableEndpoint(): string {
    return environment.apiBaseUrl + this._updateVaultDigitalAssetAvailableEndpoint;
  }

  public searchVaultDigitalStorageEndpoint(): string {
    return environment.apiBaseUrl + this._searchVaultDigitalStorageEndpoint;
  }

  //Tagger
  public getTaggedAdsEndpoint(): string {
    return environment.apiBaseUrl + this._getTaggedAdsEndpoint;
  }
  public getDraftTaggersEndpoint(): string {
    return environment.apiBaseUrl + this._getDraftTaggersEndpoint;
  }
  public getAdOrderEndpoint(): string {
    return environment.apiBaseUrl + this._getAdOrderEndpointEndpoint;
  }
  public getBaseCreativesEndpoint(): string {
    return environment.apiBaseUrl + this._getBaseCreativesEndpoint;
  }
  public getOfferCardsEndpoint(): string {
    return environment.apiBaseUrl + this._getOfferCardsEndpoint;
  }
  public getEndCardsEndpoint(): string {
    return environment.apiBaseUrl + this._getEndCardsEndpoint;
  }
  public getEndCardVoiceOversEndpoint(): string {
    return environment.apiBaseUrl + this._getEndCardVoiceOversEndpoint;
  }
  public saveAsDraftAdEndpoint(): string {
    return environment.apiBaseUrl + this._saveAsDraftAdEndpoint;
  }
  public updateDraftAdEndpoint(): string {
    return environment.apiBaseUrl + this._updateDraftAdEndpoint;
  }
  public adCreateEndpoint(): string {
    return environment.apiBaseUrl + this._adCreateEndpoint;
  }
  public updateAdEndpoint(): string {
    return environment.apiBaseUrl + this._updateAdEndpoint;
  }
  public getEditDraftOrder(): string {
    return environment.apiBaseUrl + this._getEditDraftOrderEndpoint;
  }
  public addDraftOrderNoteEndpoint(): string {
    return environment.apiBaseUrl + this._addDraftOrderNoteEndpoint;
  }
  public addOrderNoteEndpoint(): string {
    return environment.apiBaseUrl + this._addOrderNoteEndpoint;
  }
  public archiveOrderEndpoint(): string {
    return environment.apiBaseUrl + this._archiveOrderEndpoint;
  }
  public unArchiveOrderEndpoint(): string {
    return environment.apiBaseUrl + this._unArchiveOrderEndpoint;
  }
  public getTaggerAdsShareLinkEndpoint(): string {
    return environment.apiBaseUrl + this._getTaggerAdsShareLinkEndpoint;
  }
  public shareTaggerAdsEndpoint(): string {
    return environment.apiBaseUrl + this._shareTaggerAdsEndpoint;
  }

  //Download files
  public registerAdDownloadMediaEndPoint(): string {
    return environment.apiBaseUrl + this._registerAdDownloadMediaEndPoint;
  }
  public completeDownloadAdMediaFileEndPoint(): string {
    return environment.apiBaseUrl + this._completeDownloadAdMediaFileEndPoint;
  }

  // Tagger Assets
  public searchTaggerAssetsEndpoint(): string {
    return environment.apiBaseUrl + this._searchTaggerAssetsEndpoint;
  }
  public uploadTaggerAssetsEndpoint(): string {
    return environment.apiBaseUrl + this._uploadTaggerAssetsEndpoint;
  }
  public getGroupsEndpoint(): string {
    return environment.apiBaseUrl + this._getGroupsEndpoint;
  }
  public getLocationsEndpoint(): string {
    return environment.apiBaseUrl + this._getLocationsEndpoint;
  }
  public updateTaggerAssetEndpoint(): string {
    return environment.apiBaseUrl + this._updateTaggerAssetEndpoint;
  }
  public validateAssetTitleEndpoint(): string {
    return environment.apiBaseUrl + this._validateAssetTitleEndpoint;
  }

  //Tagger Files upload
  public getAdFileSinglePartSignedURL(): string {
    return environment.apiBaseUrl + this._generateAdFileSinglePartSignedURL;
  }
  public initiateAdFileMultipartUpload(): string {
    return environment.apiBaseUrl + this._initiateAdFileMultipartUpload;
  }
  public completeAdFileMultipartUpload(): string {
    return environment.apiBaseUrl + this._completeAdFileMultipartUpload;
  }
  public abortAdFileMultipartUpload(): string {
    return environment.apiBaseUrl + this._abortAdFileMultipartUpload;
  }
  public getAdFilePartUploadPresignedURL(): string {
    return environment.apiBaseUrl + this._getAdFilePartUploadPresignedURL;
  }

  // Tagger Groups
  public createTaggerGroupEndpoint(): string {
    return environment.apiBaseUrl + this._createTaggerGroupEndpoint;
  }
  public updateTaggerGroupEndpoint(): string {
    return environment.apiBaseUrl + this._updateTaggerGroupEndpoint;
  }
  public updateTaggerGroupLocationEndpoint(): string {
    return environment.apiBaseUrl + this._updateTaggerGroupLocationEndpoint;
  }
  public searchGroupsEndpoint(): string {
    return environment.apiBaseUrl + this._searchGroupsEndpoint;
  }
  public validateGroupNameEndpoint(): string {
    return environment.apiBaseUrl + this._validateGroupNameEndpoint;
  }

  // Tagger Locations and Users
  public searchLocationsEndpoint(): string {
    return environment.apiBaseUrl + this._searchLocationsEndpoint;
  }
  public getStatesEndpoint(): string {
    return environment.apiBaseUrl + this._getStatesEndpoint;
  }
  public searchGroupLocationsEndpoint(): string {
    return environment.apiBaseUrl + this._searchGroupLocationsEndpoint;
  }

  public addTaggerUserEndpoint(): string {
    return environment.apiBaseUrl + this._addTaggerUserEndpoint;
  }

  public updateTaggerUserEndpoint(): string {
    return environment.apiBaseUrl + this._updateTaggerUserEndpoint;
  }

  public addLocationEndpoint(): string {
    return environment.apiBaseUrl + this._addLocationEndpoint;
  }

  public updateLocationEndpoint(): string {
    return environment.apiBaseUrl + this._updateLocationEndpoint;
  }

  public updateLocationGroupsEndpoint(): string {
    return environment.apiBaseUrl + this._updateLocationGroupsEndpoint;
  }

  public validateLocationEndpoint(): string {
    return environment.apiBaseUrl + this._validateLocationEndpoint;
  }

  public updateTaggerUserProfileStatusEndPoint(): string {
    return environment.apiBaseUrl + this._updateTaggerUserProfileStatus;
  }

  public loadFeaturedAssetsEndpoint(): string {
    return environment.apiBaseUrl + this._loadFeaturedAssetsEndpoint;
  }

  public deleteTaggerAssetEndpoint(): string {
    return environment.apiBaseUrl + this._deleteTaggerAssetEndpoint;
  }

  public updateUploadAssetStatusEndpoint(): string {
    return environment.apiBaseUrl + this._updateUploadAssetStatusEndpoint;
  }

  public getUserLocationsEndpoint(): string {
    return environment.apiBaseUrl + this._getUserLocationsEndpoint;
  }

  //grid
  public getGridDetailsByUserSettingsEndpoint(): string {
    return environment.apiBaseUrl + this._getGridDetailsByUserSettingsEndpoint;
  }

  public saveGridDetailsInUserSettingsEndpoint(): string {
    return environment.apiBaseUrl + this._saveGridDetailsInUserSettingsEndpoint;
  }

  public resetGridDetailsForUserSettingsEndpoint(): string {
    return environment.apiBaseUrl + this._resetGridDetailsForUserEndpoint;
  }

  public getVendorsForSendOrerEmailEndpoint(): string {
    return environment.apiBaseUrl + this._getVendorsForSendOrerEmailEndpoint;
  }

}
