import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { Constants } from "../../../configurations/constants";
import { commentTypeEnum } from "../../../configurations/enums/enums";
import { SpotComment } from "../../../models/spot/spotdetail-model";
import { AlertService, MessageSeverity } from "../../../services/core/alert.service";
import { Utilities } from "../../../services/core/utilities";
import { SpotService } from "../../../services/media/spot.service";
import { MediaDetailService } from "../../../services/mediadetail/mediadetail.service";
import { BasePopupComponent } from "../basepopup/basepopup.component";

@Component({
  selector: 'spotqccommenteditpopup',
  templateUrl: './spotqccommenteditpopup.component.html',
  //styleUrls: ['./spotqccommenteditpopup.component.css'],
})
export class SpotQCCommenteditPopup extends BasePopupComponent {

  public spotCommentGuid: string;
  public comments: string;
  public commentType: string;
  public spotFileGuid: string;
  public isRequired: boolean = true;
  public onQCCommentEditComplete: Subject<SpotComment>;
  public editRequest: FormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
    private spotService: SpotService,
    private util: Utilities,
    private mediaDetailService: MediaDetailService,
  ){
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.onQCCommentEditComplete = new Subject();
    console.log(this.spotCommentGuid);
    console.log(this.spotFileGuid);
    console.log(this.comments);
    console.log(this.commentType);

    this.editRequest = new FormGroup({
      newValue: (this.isRequired) ? new FormControl(this.comments, Validators.required) : new FormControl(this.comments)
    });

  }

  public onSave({ value, valid }: { value: any, valid: boolean }) {
    if (!valid) {
      return;
    }
    if (this.spotCommentGuid == null) {
      this.addQCComments(value.newValue);
    } else {
      this.updateQCComments(value.newValue);
    }
  }

  public addQCComments(comments: string) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    var spotComment = new SpotComment();
    spotComment.comments = comments;
    spotComment.commentType = (commentTypeEnum[commentTypeEnum.QC]);
    console.log(comments);

    this.mediaDetailService.addSpotComment(this.spotFileGuid, spotComment).subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {
        var spotComment = res.result;
        this.alertService.showMessage("SUCCESS", Constants.spotCommentAdded, MessageSeverity.success);
        this.onQCCommentEditComplete.next(spotComment);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.closePopup();
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
        this.closePopup();
      });
  }

  public updateQCComments(comments: string) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    var spotComment = new SpotComment();
    spotComment.comments = comments;
    spotComment.spotCommentGuid = this.spotCommentGuid;
    console.log(comments);

    this.spotService.updateSpotQCComment(spotComment).subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {
        var spotComment = res.result;
        this.alertService.showMessage("SUCCESS", Constants.spotCommentUpdated, MessageSeverity.success);
        this.onQCCommentEditComplete.next(spotComment);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.closePopup();
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
        this.closePopup();
      });
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

}
