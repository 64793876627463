import { Component, OnInit, ViewChild } from '@angular/core';
import { Inject } from '@angular/core';
import { Utilities } from "../../../services/core/utilities";
import { Router } from '@angular/router';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { UserProfileService } from '../../../services/user/user-profile.service';
import { Constants } from "../../../configurations/constants";
import { UploadService } from '../../../services/upload/upload.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SpotUploadMetadataRequest } from '../../../models/upload/uploadMetadata-request.model';
import { SpotS3UploadRequest, SpotUploadRequest } from '../../../models/upload/upload-request.model';
import { IAsperaConnect } from "../../../models/upload/asperaConnect.model";
import { IAsperaTransferFileStatus, IAsperaStatus } from "../../../models/upload/transferFileStatus.model";
import * as moment from 'moment-timezone';
import { APP_CONFIG, AppConfig } from "../../../app.module.config";
import { SpotUploadConfig } from "../../../models/upload/upload-config.model";
import { ConfigService } from "../../../services/core/config.service";
import { RouteConstants } from "../../../configurations/route-constants";
import { environment } from '../../../../environments/environment';
import { SpotPatternCheck, SpotPatternCheckFile } from '../../../models/spot/spot-pattern.model';
import { Client } from '../../../models/admin/client/client';
import { SinglePartFileUploadService } from '../../../services/project/singlepart-file-upload-handler.service';
import { Subscription } from 'rxjs';
import { AssetUploadQueue } from '../../../services/project/multipart-file-upload-handler.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { ApiEndPoint } from '../../../configurations/api-endpoint.service';
import { SpotService } from '../../../services/media/spot.service';
import { DxTreeViewComponent } from 'devextreme-angular';

declare var AW4: any;

@Component({
  selector: 'spot-upload',
  templateUrl: './spotupload.component.html',
  styleUrls: ['./spotupload.component.css'],
})

export class SpotUploadComponent implements OnInit {
  @ViewChild('asset_file', { static: true }) asset_file;
  @ViewChild('treeUserGrps', { static: false }) treeAddl: DxTreeViewComponent;

  public clientDataSource: any = [];
  public agencyDataSource: any = [];
  public brandDataSource: any = [];
  public formatDataSource: any = [];
  public productDataSource: any = [];
  public userGrpDataSource: any[] = [];

  metadataExcelFile: File;
  uploadConfig: SpotUploadConfig;
  currentAsperaConnector: IAsperaConnect = null;
  currentS3UploadId = null;
  subscriptions: Subscription[] = [];

  fileUploadModel = {} as SpotS3UploadRequest;

  public uploadType = "aspera";

  public uploadRequest: FormGroup;

  public noFilesChosen: boolean = false;
  public maxFilesUploadError: string;
  public maxSessionsUploadError: string;
  public isMaxSessionsExceeded: boolean = false;

  public isClientTemplateAvailable: boolean = false;
  public isAgencySelectionAvailable: boolean = false;
  public selectedClient: Client;
  public isUploading = false;
  public isUserGroupsVisible = true;
  public userGrpsValue: Array<number>;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    public util: Utilities,
    private userProfileService: UserProfileService,
    private uploadService: UploadService,
    private alertService: AlertService,
    private router: Router,
    private configService: ConfigService,
    private singlePartUploadService: SinglePartFileUploadService,
    private http: HttpClient,
    private apiendPoint: ApiEndPoint,
    private spotService: SpotService) {
  }

  ngOnInit() {
    this.getUploadConfig();

    this.uploadRequest = new FormGroup({
      client: new FormControl(null, Validators.required),
      brand: new FormControl(null, Validators.required),
      productId: new FormControl(null),
      agency: new FormControl(null),
      uploadType: new FormControl(),
      templateType: new FormControl(),
      title: new FormControl('', Validators.required),
      format: new FormControl(),
      length: new FormControl('', Validators.required),
      adID: new FormControl('', Validators.required),
      userGroups: new FormControl()
    });

    this.uploadRequest.controls['uploadType'].setValue('media');

    this.uploadRequest.controls['templateType'].setValue('tylie');

    this.clearScheduledFiles();
  }

  getUploadConfig() {
    this.configService.getUploadConfig().subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.uploadConfig = res.result as SpotUploadConfig;

        this.maxFilesUploadError = "You can only upload a max of " + this.uploadConfig.maxAsperaFilesPerSession + " files in a session";
        this.maxSessionsUploadError = "You can only initiate a max of " + this.uploadConfig.maxAsperaSessions + " sessions";

        this.clientDataSource = this.uploadConfig.clients;
        this.brandDataSource = this.uploadConfig.brands;
        this.formatDataSource = this.uploadConfig.formats;
        this.isAgencySelectionAvailable = this.uploadConfig.isAgencySelectionAvailable;

        if (this.clientDataSource.length == 1) {
          this.uploadRequest.controls['client'].setValue(this.clientDataSource[0].id);
          this.uploadType = this.clientDataSource[0].uploadType;
        }
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  private getBrandProducts(brandId: number) {

    if (brandId === null || brandId <= 0) {
      return;
    }

    this.configService.getCurrentBrandProducts(brandId, this.uploadRequest.value.client).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var products = res.result;
        this.productDataSource = products;

        if (this.productDataSource.length == 1) {
          this.uploadRequest.controls['productId'].setValue(this.productDataSource[0].id);
        }
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.checkClientTemplateAvailability();
    },
      error => {
        this.util.handleError(error);
        this.checkClientTemplateAvailability();
      });
  }

  private getProductAgencies(productId: number) {
    if (productId === null || productId < 0) {
      return;
    }
    this.configService.getCurrentProductAgencies(this.uploadRequest.value.brand, productId).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var agencies = res.result;
        this.agencyDataSource = agencies;

        if (this.agencyDataSource.length == 1)
          this.uploadRequest.controls['agency'].setValue(this.agencyDataSource[0].id);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.checkClientTemplateAvailability();
    },
      error => {
        this.util.handleError(error);
        this.checkClientTemplateAvailability();
      });
  }

  onClientValueChanged(e) {
    this.uploadRequest.controls['brand'].setValue(null);
    this.uploadRequest.controls['productId'].setValue(null);
    this.uploadRequest.controls['agency'].setValue(null);
    this.productDataSource = [];
    this.brandDataSource = [];
    this.agencyDataSource = [];

    this.uploadType = this.clientDataSource.filter(t => t.id == this.uploadRequest.controls['client'].value)[0].uploadType;
    this.getClientBrands(e.value);
    this.util.asperaFileStatus = this.util.asperaFileStatus.filter(t => t.status != "scheduled");
    this.currentAsperaConnector = null;

    this.getClientUserGroups(e.value);
  }

  private getClientUserGroups(id: number) {
    this.userGrpDataSource = [];
    this.userGrpsValue = [];
    this.configService.getClientUserGroups(id).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var userGrps = res.result;
        this.userGrpDataSource = userGrps;
      }
      else {
        this.userGrpDataSource = [];
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.userGrpDataSource = [];
      });
  }

  public syncTreeViewSelection(type, e = null) {
    var component = (e && e.component) || (this.treeAddl && this.treeAddl.instance);

    if (!component) {
      return;
    }

    if (this.userGrpsValue == null || !this.userGrpsValue.length) {
      component.unselectAll();
    }

    if (this.userGrpsValue != null) {
      this.userGrpsValue.forEach((value) => {
        component.selectItem(value);
      });
    }
  }

  public treeView_itemSelectionChanged(e, type) {
    this.userGrpsValue = e.component.getSelectedNodesKeys();
  }

  onBrandValueChanged(e) {
    this.uploadRequest.controls['productId'].setValue(null);
    this.uploadRequest.controls['agency'].setValue(null);
    this.productDataSource = [];
    this.agencyDataSource = [];

    this.getBrandProducts(e.value);
    this.util.asperaFileStatus = this.util.asperaFileStatus.filter(t => t.status != "scheduled");
    this.currentAsperaConnector = null;
  }

  onProductValueChanged(e) {
    this.uploadRequest.controls['agency'].setValue(null);
    this.agencyDataSource = [];
    if (this.isAgencySelectionAvailable) {
      this.getProductAgencies(e.value);
    }

    this.util.asperaFileStatus = this.util.asperaFileStatus.filter(t => t.status != "scheduled");
    this.currentAsperaConnector = null;
  }

  getClientBrands(id: number) {

    if (id == null || id <= 0)
      return;

    this.configService.getCurrentClientBrands(id).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var brands = res.result;
        this.brandDataSource = brands;

        if (this.brandDataSource.length == 1)
          this.uploadRequest.controls['brand'].setValue(this.brandDataSource[0].id);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.checkClientTemplateAvailability();
    },
      error => {
        this.util.handleError(error);
        this.brandDataSource = [];
        this.checkClientTemplateAvailability();
      });
  }

  onUploadFormSubmit({ value, valid }: { value: SpotUploadRequest, valid: boolean }) {

    //if (this.uploadRequest.controls['brand'].errors || this.uploadRequest.controls['client'].errors || this.uploadRequest.controls['productId'].errors)
    //  return;
    console.log(value);
    if (this.uploadRequest.controls['brand'].errors || this.uploadRequest.controls['client'].errors)
      return;

    if (value.uploadType == 'metadata') {
      if (this.metadataExcelFile == null)
        return;

      this.uploadMetadata(value);
    }
    else if (value.uploadType == 'placeholder') {
      if (this.uploadRequest.controls['title'].errors || this.uploadRequest.controls['adID'].errors || this.uploadRequest.controls['length'].errors)
        return;

      this.createPlaceHolder(value);
    }
    else if (value.uploadType == 'media') {
      this.addFiles(value);
    }
  }

  incomingfile(event) {
    this.metadataExcelFile = event.target.files[0];
  }

  incomingAssetsForS3Upload(event, { value, valid }: { value: SpotUploadRequest, valid: boolean }) {
    if (this.uploadRequest.controls['brand'].errors || this.uploadRequest.controls['client'].errors)
      return;

    if (!event.target.files.length) {
      return;
    }
    else {
      this.selectedClient = this.clientDataSource.filter(t => t.id == this.uploadRequest.controls['client'].value)[0];
      this.composeUploadFileModel(value);
      var connectorId = Math.floor((Math.random() * 10000) + 1).toString();
      this.util.asperaFileStatus.push({ id: connectorId, status: "scheduled", groupHeader: moment().format("MM/DD/YYYY HH:mm:ss").toString(), fileStatus: [] });
      var allStatusWithCurrentId = this.util.asperaFileStatus.filter(t => t.id == connectorId);
      this.currentS3UploadId = allStatusWithCurrentId;
      var fileStatus = [];
      for (const uploadFile of event.target.files) {
        if (allStatusWithCurrentId != null && allStatusWithCurrentId != undefined) {
          var filteredFiles = allStatusWithCurrentId[0].fileStatus.filter(f => f.file == uploadFile.name);
          if (filteredFiles == null || filteredFiles.length == 0) {
            fileStatus.push({ file: uploadFile.name, status: "scheduled", percentage: 0, show: true, uploadFile: uploadFile });
          }

          allStatusWithCurrentId[0].fileStatus = fileStatus;
        }
      }
    }
  }

  uploadMetadata(uploadRequest: SpotUploadRequest) {

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    var productId: number = this.getDefaultProductIfNotSelected(uploadRequest);

    let reader = new FileReader();
    if (this.metadataExcelFile) {
      reader.readAsDataURL(this.metadataExcelFile);

      reader.onload = () => {
        var uploadMetadataRequest: SpotUploadMetadataRequest = {
          metadataFileType: this.metadataExcelFile.type,
          metadataFileName: this.metadataExcelFile.name,
          metadataFile: (<string>reader.result).split(',')[1],
          client: uploadRequest.client,
          brand: uploadRequest.brand,
          product: productId,
          agency: uploadRequest.agency,
          isClientTemplate: (uploadRequest.templateType == 'client')
        };

        this.uploadService.uploadMetadata(uploadMetadataRequest).subscribe((res: any) => {
          if (res.isSuccess == true) {
            this.alertService.showMessage("SUCCESS", Constants.uploadedMetadataSuccessful, MessageSeverity.success);
            this.clear();
            this.uploadRequest.controls['uploadType'].setValue('metadata');
          }
          else {
            this.util.handleIsNotSuccess(res.errors);
          }
          this.alertService.ShowLoader(false);
        },
          error => {
            this.util.handleError(error);
            this.alertService.ShowLoader(false);
          });
      };
    }
  }

  createPlaceHolder(uploadRequest: SpotUploadRequest): void {

    var productId: number = this.getDefaultProductIfNotSelected(uploadRequest);
    uploadRequest.productId = productId;
    uploadRequest.userGroupIds = this.userGrpsValue;

    this.uploadService.createPlaceHolder(uploadRequest).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESS", Constants.createPlaceHolderSuccessful, MessageSeverity.success);
        this.clear();
        this.uploadRequest.controls['uploadType'].setValue('placeholder');
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  clear() {

  }

  clearCompleted() {
    for (let ft of this.util.asperaFileStatus) {
      for (let f of ft.fileStatus) {
        if (f.status == "completed")
          f.show = false;
      }
    }
  }

  changeOption({ value, valid }: { value: SpotUploadRequest, valid: boolean }) {
    console.log(value);
    if (value.uploadType == 'metadata') {
      this.isUserGroupsVisible = false;
    }
    else if (value.uploadType == 'placeholder') {
      this.isUserGroupsVisible = true;
    }
    else if (value.uploadType == 'media') {
      this.isUserGroupsVisible = true;
    }
  }

  private getDefaultProductIfNotSelected(uploadRequest: SpotUploadRequest): number {
    let productId: number = 0;
    if (uploadRequest.productId != null && uploadRequest.productId > 0)
      productId = this.productDataSource.filter(t => t.id == uploadRequest.productId)[0].id
    else {
      var defaultProduct = this.productDataSource.filter(t => t.isDefault == true);

      if (defaultProduct != null && defaultProduct.length > 0)
        productId = defaultProduct[0].id;
    }

    return productId;
  }

  addFiles(uploadRequest: SpotUploadRequest) {
    this.selectedClient = this.clientDataSource.filter(t => t.id == uploadRequest.client)[0];

    var selectedBrand = this.brandDataSource.filter(t => t.id == uploadRequest.brand)[0];

    var productId: number = this.getDefaultProductIfNotSelected(uploadRequest);

    var selectedProduct = this.productDataSource.filter(t => t.id == productId)[0];

    var selectedAgency = uploadRequest.agency != null && uploadRequest.agency > 0 ? this.agencyDataSource.filter(t => t.id == uploadRequest.agency)[0] : null;

    var selectedBrandDestination = selectedBrand.destination;

    if (this.uploadType == "aspera") {
      if (this.currentAsperaConnector != null && this.currentAsperaConnector.specs != null && this.currentAsperaConnector.specs.paths != null) {
        for (let path of this.currentAsperaConnector.specs.paths)
          this.currentAsperaConnector.files.push(path.source);
      }
      else {
        this.currentAsperaConnector = { cookie: "", specs: null, web: null, id: "", status: "scheduled", files: [] };

        var userProfile = this.userProfileService.getUserProfile();

        this.currentAsperaConnector.cookie = "identifier:" + userProfile.userProfileGuid
          + "|email:" + userProfile.email
          + "|destination:" + selectedBrandDestination
          + "|location:" + selectedBrand.location
          + "|client:" + this.selectedClient.id
          + "|brand:" + selectedBrand.id
          + "|product:" + selectedProduct.id
          + "|clientname:" + this.selectedClient.name
          + "|brandname:" + selectedBrand.name
          + "|productname:" + selectedProduct.name
          + "|editorialHouse:" + (this.uploadConfig.editorialHouseId != null && this.uploadConfig.editorialHouseId > 0 ? this.uploadConfig.editorialHouseId.toString() : "")
          + "|agency:" + (selectedAgency != null ? selectedAgency.id.toString() : "")
          + "|subClient:" + (this.uploadConfig.subClientId != null && this.uploadConfig.subClientId > 0 ? this.uploadConfig.subClientId.toString() : "");

        console.log("cookie", this.currentAsperaConnector.cookie);
      }



      this.getAsperaSpecs(selectedBrandDestination);
    }
    else if (this.uploadType == "s3") {

      this.setMaxSessionsExceeded();
    }
  }

  getAsperaSpecs(destination: string) {

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.uploadService.getUploadSpec(destination).subscribe((res: any) => {
      if (res.isSuccess == true) {
        console.log(transferSpecArray);
        var transferSpecArray = JSON.parse(res.result);
        if (transferSpecArray.transfer_specs[0].transfer_spec == null) {

          var error = transferSpecArray.transfer_specs[0].error;

          if (error != null)
            this.alertService.logError(error);
          else
            this.alertService.logError("transfer_spec is null and no error tag found");

          this.util.handleIsNotSuccess(["Unable to initiate the Aspera Connect. Please try again later."]);
        }
        else
          this.initiateAsperaConnect(res.result);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.alertService.ShowLoader(false);
        this.util.handleError(error);
      });
  }

  initiateAsperaConnect(spec: any) {

    if (this.currentAsperaConnector.id == "")
      this.currentAsperaConnector.id = Math.floor((Math.random() * 10000) + 1).toString();

    this.currentAsperaConnector.web = new AW4.Connect({
      sdkLocation: environment.asperaConnectPath,
      minVersion: this.config.asperaMinVersion,
      id: "aspera_web_transfers-" + this.currentAsperaConnector.id
    });

    var asperaInstaller = new AW4.ConnectInstaller({
      sdkLocation: environment.asperaConnectPath
    });

    var transferSpecArray = JSON.parse(spec);

    this.currentAsperaConnector.specs = transferSpecArray.transfer_specs[0].transfer_spec;
    this.currentAsperaConnector.specs.authentication = "token";
    this.currentAsperaConnector.specs.cookie = this.currentAsperaConnector.cookie;

    var callback = this.onSuccessAsperaConnect;
    var currentConnector: IAsperaConnect = this.currentAsperaConnector;
    var allAsperaFileStatsus = this.util.asperaFileStatus;

    var statusEventListener = function (eventType, data) {
      var status = AW4.Connect.STATUS;
      if (eventType === AW4.Connect.EVENT.STATUS) {
        if (data === status.INITIALIZING) {
          asperaInstaller.showLaunching();
        }
        if (data === status.FAILED) {
          asperaInstaller.showDownload();
        }
        if (data === status.OUTDATED) {
          asperaInstaller.showUpdate();
        }
        if (data === status.RUNNING) {
          asperaInstaller.connected();
          callback(currentConnector, allAsperaFileStatsus);
        }
      }
    };

    this.currentAsperaConnector.web.addEventListener(AW4.Connect.EVENT.STATUS, statusEventListener);

    this.currentAsperaConnector.web.initSession(this.currentAsperaConnector.id);
  }

  onSuccessAsperaConnect(currentConnector, allAsperaFileStatsus) {
    var fileControls: any = {};
    var uready = false;
    var currentAsperaConnector: IAsperaConnect = currentConnector;

    var currentFileTransferStatus: IAsperaStatus = null;
    var allStatusWithCurrentId = allAsperaFileStatsus.filter(t => t.id == currentAsperaConnector.id);

    if (allStatusWithCurrentId == null || allStatusWithCurrentId.length == 0) {
      currentFileTransferStatus = { fileStatus: [], id: currentAsperaConnector.id, status: "scheduled", groupHeader: "" };
      allAsperaFileStatsus.push(currentFileTransferStatus);
    }
    else
      currentFileTransferStatus = allStatusWithCurrentId[0];

    fileControls.handleTransferEvents = function (event, returnObj) {

      var obj = returnObj.transfers[0];

      if (!obj) {
        obj = {};
        uready = false;
        //console.log("Not ready yet");
      }

      //console.log(obj);

      //Wait for initiating status to avoid reading past events, 'uready' lets us know when we start
      if (obj.status == 'initiating' || obj.previous_status == 'initiating') {
        uready = true;
        //console.log("initiating !!!");
      }

      if (uready) {

        //Received failed, make background red and print error message.  Operation is done.
        if (obj.status == 'failed') {
          console.log("Upload Failed", obj);
          uready = false;

          //when failed, at times there is no files in the array and currentfile is empty then update the error to all the files
          if (obj.current_file == '' && obj.files && obj.files.length == 0) {
            for (var i = 0; i < currentFileTransferStatus.fileStatus.length; i++) {
              var fileToUpdate = currentFileTransferStatus.fileStatus[i];
              fileToUpdate.status = obj.status;
              fileToUpdate.percentage = Math.floor(obj.percentage * 100);
              fileToUpdate.bytesExpected = Math.floor(obj.bytes_expected / (1024 * 1024));
              fileToUpdate.bytesWritten = Math.floor(obj.bytes_written / (1024 * 1024));
              fileToUpdate.calculatedRateKbps = Math.floor(obj.calculated_rate_kbps / (1000));
              fileToUpdate.targetRateKbps = Math.floor(obj.target_rate_kbps / (1000));
              fileToUpdate.error = obj.error_desc;
            }
          }
          else if (obj.files) {
            for (var i = 0; i < obj.files.length; i++) {
              var fl = obj.files[i];

              var filteredFiles = currentFileTransferStatus.fileStatus.filter(f => f.file == fl.file);

              if (filteredFiles == null || filteredFiles.length == 0)
                continue;

              var fileToUpdate = filteredFiles[0];
              //var fileToUpdate = currentFileTransferStatus.fileStatus.filter(f => f.file == fl.file)[0];

              var fileToUpdateR = fileToUpdate.file.replace(/\\/g, "/");
              var isCurrent = (fileToUpdate.file === obj.current_file || fileToUpdateR === obj.current_file);

              if (isCurrent) {
                fileToUpdate.status = obj.status;
                fileToUpdate.percentage = Math.floor(obj.percentage * 100);
                //fileToUpdate.bytesExpected = Math.floor(obj.bytes_expected / (1024 * 1024));
                //fileToUpdate.bytesWritten = Math.floor(obj.bytes_written / (1024 * 1024));
                fileToUpdate.bytesExpected = Math.floor(fl.bytes_expected / (1024 * 1024));
                fileToUpdate.bytesWritten = Math.floor(fl.bytes_written / (1024 * 1024));

                fileToUpdate.calculatedRateKbps = Math.floor(obj.calculated_rate_kbps / (1000));
                fileToUpdate.targetRateKbps = Math.floor(obj.target_rate_kbps / (1000));
                fileToUpdate.error = obj.error_desc;
              }

              //if (!isCurrent && fileToUpdate && fileToUpdate.status != "completed" && fileToUpdate.status != "failed") {
              if (!isCurrent && fileToUpdate && fileToUpdate.status != "completed") {
                fileToUpdate.bytesExpected = Math.floor(fl.bytes_expected / (1024 * 1024));
                fileToUpdate.bytesWritten = Math.floor(fl.bytes_written / (1024 * 1024));

                if (fl.error && fl.error.description && fl.error.description !== '') {
                  fileToUpdate.error = fl.error.description;
                  fileToUpdate.status = "failed";
                }
                else {
                  if (fl.bytes_expected === fl.bytes_written) {
                    fileToUpdate.status = "completed";
                    fileToUpdate.percentage = 100;
                  }
                  else {
                    fileToUpdate.percentage = Math.floor((fl.bytes_written / fl.bytes_expected) * 100);
                  }
                }
              }
            };
          }
        }
        //Received completed, make background green and print complete message.  Operation is done.
        else if (obj.status == 'completed') {
          console.log("Upload Complete", obj);
          uready = false;

          if (obj.files) {
            for (var i = 0; i < obj.files.length; i++) {
              var fl = obj.files[i];

              var filteredFiles = currentFileTransferStatus.fileStatus.filter(f => f.file == fl.file);

              if (filteredFiles == null || filteredFiles.length == 0)
                continue;

              var fileToUpdate = filteredFiles[0];
              //var fileToUpdate = currentFileTransferStatus.fileStatus.filter(f => f.file == fl.file)[0];

              var fileToUpdateR = fileToUpdate.file.replace(/\\/g, "/");
              var isCurrent = (fileToUpdate.file === obj.current_file || fileToUpdateR === obj.current_file);

              if (isCurrent) {
                fileToUpdate.status = obj.status;
                fileToUpdate.percentage = Math.floor(obj.percentage * 100);
                //fileToUpdate.bytesExpected = Math.floor(obj.bytes_expected / (1024 * 1024));
                //fileToUpdate.bytesWritten = Math.floor(obj.bytes_written / (1024 * 1024));
                fileToUpdate.bytesExpected = Math.floor(fl.bytes_expected / (1024 * 1024));
                fileToUpdate.bytesWritten = Math.floor(fl.bytes_written / (1024 * 1024));

                fileToUpdate.calculatedRateKbps = Math.floor(obj.calculated_rate_kbps / (1000));
                fileToUpdate.targetRateKbps = Math.floor(obj.target_rate_kbps / (1000));
              }

              //if (!isCurrent && fileToUpdate && fileToUpdate.status != "completed" && fileToUpdate.status != "failed")
              if (!isCurrent && fileToUpdate && fileToUpdate.status != "completed") {
                fileToUpdate.bytesExpected = Math.floor(fl.bytes_expected / (1024 * 1024));
                fileToUpdate.bytesWritten = Math.floor(fl.bytes_written / (1024 * 1024));

                if (fl.bytes_expected === fl.bytes_written) {
                  fileToUpdate.status = "completed";
                  fileToUpdate.percentage = 100;
                }
                else {
                  fileToUpdate.percentage = Math.floor((fl.bytes_written / fl.bytes_expected) * 100);
                }
              }
            };
          }

          //Reimplement list of files
          //changeDirectory(jQuery("#currentDirectory").text());
          currentAsperaConnector.web.removeEventListener('transfer');
        }
        //Transfer in progress, use gradient effect to show background moving (like loading bar).  Need to include gradient for all browsers.
        else {
          switch (event) {
            case 'transfer':
              {
                console.log("Uploading (" + Math.floor(obj.percentage * 100) + "%)", obj);

                if (obj.files) {
                  for (var i = 0; i < obj.files.length; i++) {
                    var fl = obj.files[i];

                    var filteredFiles = currentFileTransferStatus.fileStatus.filter(f => f.file == fl.file);

                    if (filteredFiles == null || filteredFiles.length == 0)
                      continue;

                    var fileToUpdate = filteredFiles[0];
                    //var fileToUpdate = currentFileTransferStatus.fileStatus.filter(f => f.file == fl.file)[0];

                    var fileToUpdateR = fileToUpdate.file.replace(/\\/g, "/");
                    var isCurrent = (fileToUpdate.file === obj.current_file || fileToUpdateR === obj.current_file);

                    if (isCurrent) {
                      fileToUpdate.status = obj.status;
                      fileToUpdate.percentage = Math.floor(obj.percentage * 100);
                      //fileToUpdate.bytesExpected = Math.floor(obj.bytes_expected / (1024 * 1024));
                      //fileToUpdate.bytesWritten = Math.floor(obj.bytes_written / (1024 * 1024));
                      fileToUpdate.bytesExpected = Math.floor(fl.bytes_expected / (1024 * 1024));
                      fileToUpdate.bytesWritten = Math.floor(fl.bytes_written / (1024 * 1024));

                      fileToUpdate.calculatedRateKbps = Math.floor(obj.calculated_rate_kbps / (1000));
                      fileToUpdate.targetRateKbps = Math.floor(obj.target_rate_kbps / (1000));
                    }

                    //if (!isCurrent && fileToUpdate.status != "completed" && fileToUpdate.status != "failed") {
                    if (!isCurrent && fileToUpdate.status != "completed") {
                      fileToUpdate.bytesExpected = Math.floor(fl.bytes_expected / (1024 * 1024));
                      fileToUpdate.bytesWritten = Math.floor(fl.bytes_written / (1024 * 1024));

                      if (fl.bytes_expected === fl.bytes_written) {
                        fileToUpdate.status = "completed";
                        fileToUpdate.percentage = 100;
                      }
                      else {
                        fileToUpdate.percentage = Math.floor((fl.bytes_written / fl.bytes_expected) * 100);
                      }
                    }
                  };
                }

                break;
              }
          }
        }
      }
    };

    currentAsperaConnector.web.addEventListener('transfer', fileControls.handleTransferEvents);

    var existingOnes = currentAsperaConnector.specs.paths;

    currentAsperaConnector.web.showSelectFileDialog({
      success: function (pathArray) {
        var fileArray = pathArray.dataTransfer.files

        console.log("files chosen: ", fileArray);

        if (fileArray.length == 0)
          currentAsperaConnector.specs.paths = [];

        for (var i = 0; i < fileArray.length; i++) {
          if (fileArray[i].name != "")
            currentAsperaConnector.specs.paths[i] = { source: fileArray[i].name };

          var existingItems = currentFileTransferStatus.fileStatus.filter(t => t.file == fileArray[i].name);
          if (existingItems != null && existingItems.length > 0) {
            var existingItem = existingItems[0];
            existingItem.bytesExpected = 0;
            existingItem.bytesWritten = 0;
            existingItem.percentage = 0;
            existingItem.calculatedRateKbps = 0;
            existingItem.targetRateKbps = 0;
            existingItem.status = 're-scheduled';
            existingItem.error = '';
            existingItem.show = true;
          }
          else
            currentFileTransferStatus.fileStatus.push({ file: fileArray[i].name, bytesExpected: 0, bytesWritten: 0, percentage: 0, calculatedRateKbps: 0, targetRateKbps: 0, status: 'scheduled', error: '', show: true, uploadFile: null });
        }

        if (currentAsperaConnector.files != null && currentAsperaConnector.files.length > 0) {
          for (let eF of currentAsperaConnector.files) {
            var exists = false;
            for (let path of currentAsperaConnector.specs.paths) {
              if (path.source == eF)
                exists = true;
            }
            if (!exists)
              currentAsperaConnector.specs.paths.push({ source: eF });
          }

          currentAsperaConnector.files = [];
        }

        console.log("showSelectFileDialog transferfiles: ", allAsperaFileStatsus);
        console.log("showSelectFileDialog asp: ", currentAsperaConnector);
      }
    });
  }

  clearScheduledFiles() {
    for (let aft of this.util.asperaFileStatus) {
      if (aft.status == 'scheduled')
        aft.status = "cleared";

      if (aft.fileStatus != null && aft.fileStatus.length > 0) {
        for (let f of aft.fileStatus) {
          if (f.status == 'scheduled' || f.status == 're-scheduled')
            f.status = "cleared";
        }
      }
    }
  }

  public checkSpotPattern() {
    var spotPatternCheck = new SpotPatternCheck();
    var files: Array<SpotPatternCheckFile> = [];
    for (let sf of this.getScheduledFiles()) {
      var s = new SpotPatternCheckFile();
      s.file = sf.file;
      files.push(s);
    }

    if (files.length > 0) {
      if (this.selectedClient != null && this.selectedClient != undefined) {
        spotPatternCheck.clientGuid = this.selectedClient.clientGuid;

        if (this.fileUploadModel != null) {
          spotPatternCheck.ClientId = this.selectedClient.id;
          spotPatternCheck.BrandId = this.fileUploadModel.brandId;
          spotPatternCheck.ProductId = this.fileUploadModel.productId;
          spotPatternCheck.EditorialHouseId = this.fileUploadModel.editorialHouseId;
          spotPatternCheck.AgencyId = this.fileUploadModel.agency;
          spotPatternCheck.UserGroupIds = this.userGrpsValue;
          spotPatternCheck.isUploadTypeS3 = true;
        }
      }

      spotPatternCheck.spotPatternCheckFiles = files;

      this.configService.checkSpotPattern(spotPatternCheck).subscribe((res: any) => {
        var errorCount = 0;
        if (res.isSuccess == true) {
          console.log(res.result.pattern);
          var spcfs = res.result.spotPatternCheckFiles;
          for (let sf of this.getScheduledFiles()) {
            for (let sp of spcfs) {
              if (sf.file == sp.file) {
                sf.error = sp.error.toString();
                errorCount += sp.error;
                break;
              }
            }
          }
          if (errorCount == 0) {
            if (this.fileUploadModel != null) {
              this.fileUploadModel.uploadDestination = res.result.uploadDestination;
            }
            this.startUpload()
          }
        }
        else {
          this.util.handleError(res.errors);
        }
      });
    } else {
      this.alertService.showMessage("ERROR", Constants.fileNotSelected, MessageSeverity.error, false);
    }
  }

  public startUpload() {

    console.log("startUpload transferfiles: ", this.util.asperaFileStatus);

    if (this.uploadType == 'aspera') {
      console.log("startUpload asp: ", this.currentAsperaConnector);

      if (this.currentAsperaConnector != null
        && this.currentAsperaConnector.specs != null
        && this.currentAsperaConnector.specs.paths != null
        && this.currentAsperaConnector.specs.paths.length > 0) {
        if (this.currentAsperaConnector.specs.paths.length > this.uploadConfig.maxAsperaFilesPerSession)
          return;

        this.setMaxSessionsExceeded();

        if (this.isMaxSessionsExceeded)
          return;

        this.isMaxSessionsExceeded = false;

        var connectSettings: any = {
          "allow_dialogs": "no"
        };

        this.currentAsperaConnector.status = "submitted";

        this.currentAsperaConnector.web.startTransfer(this.currentAsperaConnector.specs, connectSettings);

        for (let aft of this.util.asperaFileStatus) {
          if (aft.status == "scheduled") {
            aft.status = "submitted";
            aft.groupHeader = moment().format("MM/DD/YYYY HH:mm:ss").toString();

            for (let f of aft.fileStatus) {
              if (f.status == 'scheduled')
                f.status = "submitted";

              if (f.status == 're-scheduled')
                f.status = "re-submitted";
            }
          }
        }

        this.currentAsperaConnector = null;

        this.noFilesChosen = false;
      }
    }
    else if (this.uploadType == 's3') {
      var currentUploadId = this.currentS3UploadId;
      // upload to s3 single or multipart

      for (let aft of this.util.asperaFileStatus) {
        if (aft.status == "scheduled") {
          aft.status = "submitted";
          aft.groupHeader = moment().format("MM/DD/YYYY HH:mm:ss").toString();

          for (let f of aft.fileStatus) {
            if (f.status == 'scheduled')
              f.status = "submitted";

            if (f.status == 're-scheduled')
              f.status = "re-submitted";

            f.show == true
            this.uploadAssetViaS3(f.uploadFile, aft.id, f.file, this.fileUploadModel);
          }
        }
      }

      this.fileUploadModel = null;

      this.noFilesChosen = false;
    }
    else {
      this.noFilesChosen = true;
    }
  }

  uploadAssetViaS3(currentBrowsedFile, id, fileName, fileUploadModel) {
    this.isUploading = true;
    var fileToUpdate = null;
    fileUploadModel.fileName = currentBrowsedFile.name;

    var currentFileUploadModel =  {
      fileName: currentBrowsedFile.name,
      clientId: fileUploadModel.clientId,
      brandId: fileUploadModel.brandId,
      productId: fileUploadModel.productId,
      clientName: fileUploadModel.clientName,
      brandName: fileUploadModel.brandName,
      productName: fileUploadModel.productName,
      agency: fileUploadModel.agency,
      editorialHouseId: fileUploadModel.editorialHouseId,
      uploadDestination: fileUploadModel.uploadDestination,
      asperaId: id,
      isS3AccelerationEnabled: this.selectedClient.isS3AccelerationEnabled,
      userGroups: this.userGrpsValue
    }

    var currentFileTransferStatus = this.util.asperaFileStatus.filter(t => t.id == id);

    if (currentFileTransferStatus != null && currentFileTransferStatus.length > 0) {
      var filteredFiles = currentFileTransferStatus[0].fileStatus.filter(f => f.file == fileName);

      if (filteredFiles != null && filteredFiles.length > 0) {
        fileToUpdate = filteredFiles[0];
      }
    }

    if (currentBrowsedFile.size <= Constants.singlepart_max_file_size) // Single part upload
    {
      this.subscriptions.push(this.singlePartUploadService.uploadAsset(currentFileUploadModel, currentBrowsedFile).subscribe((x: any) => {
        if (x == null) {
          this.alertService.showMessage("ERROR", Constants.error500, MessageSeverity.error, true);
          this.isUploading = false;
        }
        if (x.type === HttpEventType.UploadProgress) {
          if (fileToUpdate != null) {
            fileToUpdate.bytesExpected = Math.floor(x.total / (1024 * 1024));
            fileToUpdate.bytesWritten = Math.floor(x.loaded / (1024 * 1024));
            fileToUpdate.percentage = Math.round((100 * x.loaded) / x.total);
            fileToUpdate.status = 'running';
          }
        }
        else if (x.type === HttpEventType.Response) {
          // 200 or not set failed or completed
          this.isUploading = false;
          if (x.status == 200 && fileToUpdate != null) {
            fileToUpdate.status = 'completed';
            this.isSessionUploadComplete(currentFileUploadModel.asperaId, currentFileUploadModel);
          }
          else {
            if (fileToUpdate != null) {
              fileToUpdate.status = 'failed';
              this.isSessionUploadComplete(currentFileUploadModel.asperaId, currentFileUploadModel);
            }
          }
        }

        if (this.asset_file != null) {
          this.asset_file.nativeElement.value = null;
        }
      }, error => {
        this.util.handleError(error);
      }));
    }
    else { // Multi part upload
      const file = new AssetUploadQueue(currentFileUploadModel, currentBrowsedFile, this.http, this.apiendPoint, this.util);
      this.subscriptions.push(file.assetstatus.subscribe(val => {

        var currentFileTransferStatusForUpdate = this.util.asperaFileStatus.filter(t => t.id == file.initiateMultipartRequest.asperaId);

        if (currentFileTransferStatusForUpdate != null && currentFileTransferStatusForUpdate.length > 0) {
          var filteredFiles = currentFileTransferStatusForUpdate[0].fileStatus.filter(f => f.file == file.file.name);

          if (filteredFiles != null && filteredFiles.length > 0) {
            fileToUpdate = filteredFiles[0];
          }
        }

        if (val.type === "running") {
          if (fileToUpdate != null) {
            fileToUpdate.bytesExpected = Math.floor(val.total / (1024 * 1024));
            fileToUpdate.bytesWritten = Math.floor(val.loaded / (1024 * 1024));
            fileToUpdate.percentage = Math.round((100 * val.loaded) / val.total);
            fileToUpdate.status = 'running';
          }
        }
        else if (val.type === "completed") {
          this.isUploading = false;
          if (fileToUpdate != null) {
            fileToUpdate.bytesExpected = Math.floor(val.total / (1024 * 1024));
            fileToUpdate.bytesWritten = Math.floor(val.loaded / (1024 * 1024));
            fileToUpdate.percentage = Math.round((100 * val.loaded) / val.total);
            fileToUpdate.status = 'completed';
          }
          this.isSessionUploadComplete(file.initiateMultipartRequest.asperaId, currentFileUploadModel);
        }
        else if (val.type === "failed") {
          this.isUploading = false;
          if (fileToUpdate != null) {
            fileToUpdate.bytesExpected = Math.floor(val.total / (1024 * 1024));
            fileToUpdate.bytesWritten = Math.floor(val.loaded / (1024 * 1024));
            fileToUpdate.percentage = Math.round((100 * val.loaded) / val.total);
            fileToUpdate.status = 'failed';
          }
          this.isSessionUploadComplete(file.initiateMultipartRequest.asperaId, currentFileUploadModel);
        }
        if (this.asset_file != null) {
          this.asset_file.nativeElement.value = null;
        }
        this.isUploading = false;
      }));
    }
  }

  isSessionUploadComplete(uploadSessionId, uploadModel) {
    var allStatusWithCurrentId = this.util.asperaFileStatus.filter(t => t.id == uploadSessionId);

    if (allStatusWithCurrentId != null && allStatusWithCurrentId.length > 0) {
      var filesInProgress = allStatusWithCurrentId[0].fileStatus.filter(f => f.status != 'failed' && f.status != 'completed');

      console.log(filesCompletedUpload);
      if (filesInProgress == null || filesInProgress.length == 0) {
        var filesCompletedUpload = allStatusWithCurrentId[0].fileStatus.filter(f => f.status == 'completed');

        var notifyModel = uploadModel;
        notifyModel.UploadedFiles = filesCompletedUpload.map(fs => fs.file);

        //NotifyS3UploadSpotArrival
        this.spotService.NotifyS3UploadSpotArrival(notifyModel).subscribe((res: any) => {
          if (res.isSuccess == true) {
            this.clear();
          }
          else {
            this.util.handleIsNotSuccess(res.errors);
          }
        },
          error => {
            this.util.handleError(error);
          });
      }
    }
  }

  composeUploadFileModel(uploadRequest) {
    this.selectedClient = this.clientDataSource.filter(t => t.id == uploadRequest.client)[0];

    var selectedBrand = this.brandDataSource.filter(t => t.id == uploadRequest.brand)[0];

    var productId: number = this.getDefaultProductIfNotSelected(uploadRequest);

    var selectedAgency = uploadRequest.agency != null && uploadRequest.agency > 0 ? this.agencyDataSource.filter(t => t.id == uploadRequest.agency)[0] : null;
    var selectedProduct = this.productDataSource.filter(t => t.id == productId)[0];

    this.fileUploadModel = {
      fileName: "",
      clientId: this.selectedClient.id,
      brandId: selectedBrand.id,
      productId: productId,
      productName: selectedProduct.name,
      clientName: this.selectedClient.name,
      brandName: selectedBrand.name,
      agency: selectedAgency != null ? selectedAgency.id : null,
      editorialHouseId: (this.uploadConfig.editorialHouseId != null && this.uploadConfig.editorialHouseId > 0 ? this.uploadConfig.editorialHouseId : null),
      uploadDestination: "",
      asperaId: "",
      isS3AccelerationEnabled: false,
      userGroups: this.userGrpsValue
    }
  }


  public removeFromFiles(fileName: string) {
    if (this.currentAsperaConnector != null) {
      var allStatusWithCurrentId = this.util.asperaFileStatus.filter(t => t.id == this.currentAsperaConnector.id);

      if (allStatusWithCurrentId != null && allStatusWithCurrentId.length > 0) {
        var currentFileTransferStatus = allStatusWithCurrentId[0];
        currentFileTransferStatus.fileStatus = currentFileTransferStatus.fileStatus.filter(t => t.file != fileName)
      }

      this.currentAsperaConnector.specs.paths = this.currentAsperaConnector.specs.paths.filter(f => f.source != fileName);
    }
    else if (this.uploadType == 's3') {
      var allStatusWithCurrentId = this.util.asperaFileStatus.filter(t => t.id == this.currentS3UploadId[0].id);

      if (allStatusWithCurrentId != null && allStatusWithCurrentId.length > 0) {
        var currentFileTransferStatus = allStatusWithCurrentId[0];
        currentFileTransferStatus.fileStatus = currentFileTransferStatus.fileStatus.filter(t => t.file != fileName)
      }
    }

    console.log("delete transferfiles: ", this.util.asperaFileStatus);
    console.log("delete asp: ", this.currentAsperaConnector);
  }

  public anyScheduledFiles(): boolean {
    for (let aft of this.util.asperaFileStatus) {
      if (aft.fileStatus != null && aft.fileStatus.length > 0) {
        var anySubmittedFiles = aft.fileStatus.filter(t => (t.status == 'scheduled' || t.status == 're-scheduled'));

        if (anySubmittedFiles.length > 0)
          return true;
      }
    }

    return false;
  }

  public anySubmittedFiles(): boolean {
    for (let aft of this.util.asperaFileStatus) {
      if (aft.fileStatus != null && aft.fileStatus.length > 0) {
        var anySubmittedFiles = aft.fileStatus.filter(t => t.status != 'scheduled' && t.status != 're-scheduled' && t.status != 'cleared' && t.show == true);

        if (anySubmittedFiles.length > 0)
          return true;
      }
    }

    return false;
  }

  public getScheduledFiles(): Array<IAsperaTransferFileStatus> {
    var fileStatus: Array<IAsperaTransferFileStatus> = [];

    for (let aft of this.util.asperaFileStatus) {
      if (aft.fileStatus != null && aft.fileStatus.length > 0) {
        var anySubmittedFiles = aft.fileStatus.filter(t => t.status == 'scheduled' || t.status == 're-scheduled');
        for (let sFile of anySubmittedFiles)
          fileStatus.push(sFile);
      }
    }

    return fileStatus;
  }

  public getSubmittedFiles(): Array<IAsperaStatus> {
    var asperaFileStatus: Array<IAsperaStatus> = [];

    for (let aft of this.util.asperaFileStatus) {
      if (aft.fileStatus != null && aft.fileStatus.length > 0) {
        var anyFiles = aft.fileStatus.filter(t => t.status != 'scheduled' && t.status != 're-scheduled' && t.status != 'cleared' && t.show == true);

        if (anyFiles.length > 0) {
          var asp: IAsperaStatus = { fileStatus: anyFiles, groupHeader: aft.groupHeader, id: aft.id, status: aft.status };

          asperaFileStatus.push(asp);
        }
      }
    }

    return asperaFileStatus;
  }

  public isMaxFilesExceeded() {
    if (this.currentAsperaConnector != null
      && this.currentAsperaConnector.specs != null
      && this.currentAsperaConnector.specs.paths != null
      && this.currentAsperaConnector.specs.paths.length > 0
      && this.currentAsperaConnector.specs.paths.length > this.uploadConfig.maxAsperaFilesPerSession)
      return true;

    return false;
  }

  private setMaxSessionsExceeded() {
    var sessionsRunning = 0;
    for (let asp of this.util.asperaFileStatus) {
      if (asp.fileStatus != null && asp.fileStatus.length > 0) {
        var runningFiles = asp.fileStatus.filter(t => t.status != 'completed' && t.status != 'failed');
        if (runningFiles != null && runningFiles.length > 0)
          sessionsRunning++;
      }
    }

    if (sessionsRunning > this.uploadConfig.maxAsperaSessions)
      this.isMaxSessionsExceeded = true;
    else
      this.isMaxSessionsExceeded = false;
  }

  public gotoMedia() {
    this.router.navigate([RouteConstants.mediaRoute]);
  }

  public onDownloadMetadataTemplate() {

    var request = { isSpotMetadataUpload: true, isClientTemplate: (this.uploadRequest.controls['templateType'].value == 'client'), client: this.uploadRequest.controls['client'].value, brand: this.uploadRequest.controls['brand'].value };

    this.uploadService.downloadMetadaTemplate(request)
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          var presignedUrl = res.result;
          this.util.downloadFile(this.util.getFileUrl(presignedUrl)).subscribe(
            fileData => {
              //let fileURL = window.URL.createObjectURL(fileData);
              //window.open(fileURL);
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.href = window.URL.createObjectURL(fileData);
              a.download = "Metadata Template.xlsx";
              a.click();
            });
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });

  }

  public onDownloadTylieGuide() {

    var request = { isTylieSpecificationGuide: true };

    this.uploadService.downloadMetadaTemplate(request)
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          var presignedUrl = res.result;
          this.util.downloadFile(this.util.getFileUrl(presignedUrl)).subscribe(
            fileData => {
              //let fileURL = window.URL.createObjectURL(fileData);
              //window.open(fileURL);
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.href = window.URL.createObjectURL(fileData);
              a.download = "Tandem-Upload-Guide.pdf";
              a.click();
            });
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });

  }

  private checkClientTemplateAvailability() {

    this.uploadRequest.controls['templateType'].setValue('tylie');

    if (this.uploadRequest.controls['brand'].errors || this.uploadRequest.controls['client'].errors)
      this.isClientTemplateAvailable = false;
    else {

      var selectedBrand = this.brandDataSource.filter(t => t.id == this.uploadRequest.controls['brand'].value)[0];

      this.isClientTemplateAvailable = (selectedBrand.isUploadTemplateAvailable == true) ? true : false;
    }
  }
}
