<div class="modal-header">
  <button type="button" class="modal-close delete" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  <h4 class="modal-title">Order Comments History</h4>
</div>
<div class="modal-body">
  <ng-container *ngFor="let cm of this.orderCommentsHistoryData?.slice()">
    <div class="black">
      <p [innerHTML]="cm.comments | multiLine"></p>
      <!--{{cm.comments}}-->
      <div class="tylie-type-fs-12">
        <i>
          by {{cm.user}},
          on {{(cm.created | usertimezone: 'll')}}
          at {{(cm.created | usertimezone: 'HH:mm')}}
        </i>
      </div>
    </div>
  </ng-container>
</div>
