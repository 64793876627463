import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { Utilities } from "../../../../services/core/utilities";
import { AlertService, MessageSeverity } from '../../../../services/core/alert.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ServiceData, ServiceMetadata, SpotServiceData, SelectedOrderDestinations, OrderGroup, SelectedGroupMedia, SpotDestinationAddlInfo, DestinationAddlInfoMetadata, DestinationAddlInfo } from '../../../../models/order/order-create.model';
import { metadataCategoryEnum, deliveryMethodEnum, destinationTypeEnum } from '../../../../configurations/enums/enums';
import { JsPlayerComponent } from '../../../videoplayer/videojs/jsplayer.component';
import { OrderDetailGroup, OrderDetailSpotServiceData, OrderDetailServiceData, OrderDetailHeader, OrderDetail, OrderDetailConfig, OrderDetailAction, OrderProgress, RouteToTranscodeEmitData } from '../../../../models/order/order-detail.model';
import { OrderAddServicePopupComponent } from '../../../popups/orderaddservicepopup/orderaddservicepopup.component';
import { OrderAdditionalMetadata } from '../../../../models/order/order-additionalmetadata';
import { SpotSelectedServicesRequest, SpotServicesOptionsRequest } from '../../../../models/order/order-spotselectedservice-request.model';
import { SpotFile } from '../../../../models/spot/spot-file.model';
import { OrderAddSpotPopupComponent } from '../../../popups/orderaddspotpopup/orderaddspotpopup.component';
import { OrderService } from '../../../../services/order/order.service';
import { OrderDetailHeaderEditPopupComponent } from '../../../popups/orderdetailheadereditpopup/orderdetailheadereditpopup.component';
import { EditOrderHeaderRequest } from '../../../../models/order/order-edit-header.model';
import { OrderAddDestinationListPopupComponent } from '../../../popups/orderadddestinationlistpopup/orderadddestinationlistpopup.component';
import { OrderAddFTPDestinationPopupComponent } from '../../../popups/orderaddftpdestinationpopup/orderaddftpdestinationpopup.component';
import { OrderAddShareLinksPopupComponent } from '../../../popups/orderaddsharelinkspopup/orderaddsharelinkspopup.component';
import { OrderDestination } from '../../../../models/order/order-destinations.model';
import { OrderUserDestination } from '../../../../models/order/order-userdestination';
import { UserEmailDistributionGroups } from '../../../../models/user/user-email-distribution-group.model';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Constants } from '../../../../configurations/constants';
import { GroupError } from '../../../../models/order/ordererror.model';
import { UpdateOrderGroupDestinationRequest } from '../../../../models/order/order-deletegroupdestination';
import { SpotResendConfirm } from '../../../../models/order/spot-onhand.model';
import { SpotResendPopupComponent } from '../../../popups/spotresendpopup/spotresendpopup.component';
import { CurrencyPipe } from '@angular/common'
import { MediaDetailComponent } from '../../../mediadetail/mediadetail.component';
import { ErrorPopupComponent } from '../../../popups/errorpopup/errorpopup.component';
import { MediaDetailPopupComponent } from '../../../popups/mediadetailpopup/mediadetailpopup.component';
import { UUID } from 'angular2-uuid';
import { Spot } from '../../../../models/spot/spot.model';
import { OrderDestinationInfoPopupComponent } from '../../../popups/orderadestinationinfopopup/orderadestinationinfopopup.component';
import { FileNameOption, MediaTranscode, MediaTranscodeFileNaming, OrderDestinationTranscodeModel, TranscodeFileNaming, TranscodeMediaOrderRequest } from '../../../../models/order/transcodeorder-config.model';
import { forEach } from 'core-js/fn/dict';
import { RouteConstants } from '../../../../configurations/route-constants';
import { Router } from '@angular/router';
import { AdminService } from '../../../../services/admin/admin.service';
import { QuickplayerComponent } from '../../../videoplayer/quickplayer/quickplayer.component';

@Component({
  selector: 'order-group-detail',
  templateUrl: './ordergroupdetail.component.html',
  styleUrls: ['./ordergroupdetail.component.css'],
  providers: [CurrencyPipe]
})
export class OrderGroupDetailComponent implements OnInit {

  @Input() group: OrderDetailGroup;
  @Input() header: OrderDetailHeader;
  @Input() config: OrderDetailConfig;
  @Input() totalSavedGroups: number;
  @Input() orderGuid: string;

  @Input() newGroupServiceLevel: string;

  @Output() groupDeleted = new EventEmitter<void>();
  @Output() groupUpdated = new EventEmitter<OrderDetailGroup>();
  @Output() progressUpdated = new EventEmitter<Array<OrderProgress>>();

  @Output() addMediaToAllGroups = new EventEmitter<Array<SpotFile>>();
  @Output() routeToTranscodeRequestsClicked = new EventEmitter<RouteToTranscodeEmitData>();


  @ViewChild('delpop', { static: false }) delpop: PopoverDirective;
  public modalRef: BsModalRef;

  public static readonly DIGITALDELIVERY: string = "digital delivery";
  public static readonly POSTPRODUCTION: string = "post production";
  public static readonly ADDITIONAL: string = "additional";

  public destinationErrors: Array<string> = [];
  public serviceErrors: Array<string> = [];

  public triedToSave: boolean = false;

  public groupErrors: Array<string> = [];

  @ViewChild('gpnamepop', { static: false }) gpnamepop: PopoverDirective;
  public groupName: string = '';

  public anySpotDeliveryExists: boolean = false;

  constructor(public util: Utilities,
    private alertService: AlertService,
    private orderService: OrderService,
    private cp: CurrencyPipe,
    private modalService: BsModalService,
    private router: Router,
    public adminService: AdminService) {
  }

  ngOnInit() {
    this.groupName = this.group.name;

    var transcodeData = this.group.transcodeRequest;

    if (transcodeData != null && transcodeData != undefined) {

      var transcodeMap = new Map();
      transcodeData.spotFileTranscodes.forEach(t => { transcodeMap.set(t.spotFileGuid, t.transcodes) });
      transcodeData.spotFileTranscodesData = transcodeMap;

      var fileNamingMap = new Map();
      transcodeData.spotFileNaming.forEach(t => { fileNamingMap.set(t.spotFileGuid, t.fileNaming) });
      transcodeData.spotFileNamingData = fileNamingMap;

      this.group.transcodeRequest = transcodeData;
    }

    this.checkForDestinationAddlInfo();
  }

  public isNonTextItemsExists(services: Array<ServiceData>): boolean {
    var exists = false;

    if (services == null)
      return exists;

    services.forEach(sdata => {
      sdata.serviceMetadata.forEach(mt => {
        if (mt.category === metadataCategoryEnum[metadataCategoryEnum.File]
          || mt.category === metadataCategoryEnum[metadataCategoryEnum.BigText]
          || (mt.category === metadataCategoryEnum[metadataCategoryEnum.Special] && this.config.orderActions.showSpecialFlags == true)
          || mt.category === metadataCategoryEnum[metadataCategoryEnum.BigMultiple]
          || mt.category === metadataCategoryEnum[metadataCategoryEnum.CopyText])
          exists = true;
      });
    });

    return exists;
  }

  public isNonTextItem(mt: ServiceMetadata): boolean {
    return (mt.category === metadataCategoryEnum[metadataCategoryEnum.File]
      || mt.category === metadataCategoryEnum[metadataCategoryEnum.BigText]
      || (mt.category === metadataCategoryEnum[metadataCategoryEnum.Special])
      || mt.category === metadataCategoryEnum[metadataCategoryEnum.BigMultiple]
      || mt.category === metadataCategoryEnum[metadataCategoryEnum.CopyText]);
  }

  public getTextData(mdata: Array<ServiceMetadata>): string {
    var textData: Array<string> = [];

    mdata.forEach(mt => {
      if (this.isNonTextItem(mt) === false && this.isHiddenItem(mt) === false) {
        if (mt.textValue !== null && mt.textValue != '') {
          if (mt.category === metadataCategoryEnum[metadataCategoryEnum.HoursMins]) {
            if (mt.textValue != '0')
              textData.push(this.util.convertStringToHoursMins(mt.textValue));
          }
          else if (mt.category === metadataCategoryEnum[metadataCategoryEnum.Dollar]) {
            textData.push(this.cp.transform(mt.textValue, 'USD', true));
          }
          else if (mt.category === metadataCategoryEnum[metadataCategoryEnum.CustomFileName]) {
            textData.push(mt.textValue.split('|').join('-'));
          }
          else {
            if (mt.metadataKey == 'approvalRequired' && mt.textValue == 'false') {
              textData.push('Approval Not Required');
            }
            else if (mt.metadataKey == 'approvalRequired' && mt.textValue == 'true') {
              textData.push('Approval Required');
            }
            else if (mt.metadataKey == 'keepClientSlate' && mt.textValue == 'true') {
              textData.push('KEEP CLIENT SLATE');
            }
            else if (mt.metadataKey == 'keepClientSlate' && mt.textValue == 'false') {
              // don't push
            }
            else {
              textData.push(mt.textValue);
            }
          }
        }
        else if (mt.arrayValue !== null && mt.arrayValue != '')
          textData.push(mt.arrayValue);
        else if (mt.objectValue !== null && mt.objectValue != '') {
          if (mt.category === metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleMinsTransfers]) {

            var arr = this.util.convertStringToObjectMultiple(mt.objectValue);

            textData.push(arr.map(item => this.util.convertStringToHoursMins(item.val1) + ' - ' + item.val2).join(' , '));
          }
          else if (mt.category === metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleThreeRequired]) {
            var res = this.util.convertStringToThreeRequiredObject(mt.objectValue);
            textData.push(res.map(item => item.val1 + ' - ' + item.val2 + ' - ' + item.val3).join(' , '));
          }
          else if (mt.category === metadataCategoryEnum[metadataCategoryEnum.ObjectMultipleFourRequired]) {
            var resu = this.util.convertStringToFourRequiredObject(mt.objectValue);
            textData.push(resu.map(item => item.val1 + ' - ' + item.val2 + ' - ' + item.val3 + ' - ' + item.val4).join(' , '));
          }
          else {
            var arr = this.util.convertStringToObjectMultiple(mt.objectValue);

            textData.push(arr.map(item => item.val1 + ' - ' + item.val2).join(' , '));
          }
        }
      }
    });

    if (textData.length > 0)
      return "(Options : " + textData.join(", ") + ")";
  }

  public quickPlay(proxy, title, thumbnail, format): void {

    if (proxy == null || proxy == '')
      return;

    var initialState = {
      videoSource: proxy,
      title: title,
      thumbnail: thumbnail,
      format: format
    };

    this.modalRef = this.modalService.show(QuickplayerComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  public onEditServicesOfMedia(spotFileGuid: string, groupSpotId: string) {

    var currentSpot = this.group.spotData.find(t => t.groupSpotId == groupSpotId) as OrderDetailSpotServiceData;

    if (currentSpot == null)
      return;

    var additionalMetadata: OrderAdditionalMetadata = {
      orderGuid: this.orderGuid,
      spotTracCode: '',
      allowDestinationHub: false,
      allowSpecialOptions: this.config.orderActions.showSpecialFlags,
      ppFileSpecs: [],
      transcodePredefinedSpecs: this.config.transcodePredefinedSpecs,
      customFileNameOptions: this.config.customFileNameOptions
    };

    if (currentSpot.ppSpotFile == null) {
      additionalMetadata.ppFileSpecs = [{
        spotFileGuid: currentSpot.spotFile.spotFileGuid,
        groupSpotId: currentSpot.groupSpotId,
        adId: currentSpot.spotFile.adId,
        title: currentSpot.spotFile.title,
        ppFileAdId: currentSpot.spotFile.adId,
        ppFileTitle: currentSpot.spotFile.title,
        aspectRatio: currentSpot.spotFile.aspectRatio,
        format: currentSpot.spotFile.format.toLowerCase(),
        isAudio: (currentSpot.spotFile.format.toLowerCase() == 'audio')
      }];
    }
    else {
      additionalMetadata.ppFileSpecs = [{
        spotFileGuid: currentSpot.spotFile.spotFileGuid,
        groupSpotId: currentSpot.groupSpotId,
        adId: currentSpot.spotFile.adId,
        title: currentSpot.spotFile.title,
        ppFileAdId: currentSpot.ppSpotFile.adId,
        ppFileTitle: currentSpot.ppSpotFile.title,
        aspectRatio: currentSpot.ppSpotFile.aspectRatio,
        format: currentSpot.spotFile.format.toLowerCase(),
        isAudio: (currentSpot.spotFile.format.toLowerCase() == 'audio')
      }];
    }

    var initialState = {
      spotFileGuid: spotFileGuid,
      groupSpotId: groupSpotId,
      applyAllMode: false,
      editMode: true,
      orderGuid: this.orderGuid,
      serviceData: this.copyServiceDataArray(currentSpot.serviceData),
      additionalMetadata: additionalMetadata,
      orderEditMode: true,
      showInEditMode: (this.config.orderActions.showInEditMode && (this.config.orderActions.canUpdateSpecialServices === true || this.config.orderActions.canAddMedia === true)),
      specialServiceMode: this.config.orderActions.canUpdateSpecialServices,
      spotLevel: true,
      orderLevel: false,
      groupLevel: false
    };

    this.modalRef = this.modalService.show(OrderAddServicePopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));

    this.modalRef.content.onSelectServicesComplete.subscribe(result => {
      this.onApplyServicesSubscriptionRaised(result);
      this.checkForDestinationAddlInfo();
    });

    this.modalRef.content.onServiceSubmitOptionsComplete.subscribe(result => {
      this.onSubmitServiceOptionsRaised(result, spotFileGuid);
      this.checkForDestinationAddlInfo();
    });
  }

  public onAddMedia() {

    var initialState = {
      clientId: this.header.clientId,
      brandId: this.header.brandId,
      productId: this.header.productId,
      specialVisibilityClientId: this.header.specialVisibilityClient
    };

    this.modalRef = this.modalService.show(OrderAddSpotPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, 'modal-dialog--w70'));

    this.modalRef.content.onClose.subscribe(result => {
      if (result.applyToAllGroups == false)
        this.onSelectSpotsSubscriptionRaised(result.selectedSpotFiles);
      else
        this.addMediaToAllGroups.emit(result.selectedSpotFiles);
    });
  }

  public onAddServicesToMedia(spotFileGuid: string, groupSpotId: string) {

    var existingSpots = this.group.spotData;
    //var currentSpot = existingSpots.find(t => t.spotFile.spotFileGuid.toLowerCase() === spotFileGuid.toLowerCase() && t.groupSpotId == groupSpotId) as SpotServiceData;
    var currentSpot = existingSpots.find(t => t.groupSpotId == groupSpotId) as SpotServiceData;

    if (currentSpot == null)
      return;

    var additionalMetadata: OrderAdditionalMetadata = {
      orderGuid: this.orderGuid,
      spotTracCode: '',
      allowDestinationHub: false,
      allowSpecialOptions: this.config.orderActions.showSpecialFlags,
      ppFileSpecs: [{
        spotFileGuid: spotFileGuid,
        groupSpotId: currentSpot.groupSpotId,
        adId: currentSpot.spotFile.adId,
        title: currentSpot.spotFile.title,
        ppFileAdId: currentSpot.spotFile.adId,
        ppFileTitle: currentSpot.spotFile.title,
        aspectRatio: currentSpot.spotFile.aspectRatio,
        format: currentSpot.spotFile.format.toLowerCase(),
        isAudio: (currentSpot.spotFile.format.toLowerCase() == 'audio')
      }],
      transcodePredefinedSpecs: this.config.transcodePredefinedSpecs,
      customFileNameOptions: this.config.customFileNameOptions
    };

    var initialState = {
      spotFileGuid: spotFileGuid,
      groupSpotId: groupSpotId,
      applyAllMode: false,
      editMode: false,
      orderGuid: this.orderGuid,
      serviceData: [],
      additionalMetadata: additionalMetadata,
      orderEditMode: true,
      showInEditMode: (this.config.orderActions.showInEditMode && (this.config.orderActions.canUpdateSpecialServices === false && this.config.orderActions.canAddMedia === true)),
      specialServiceMode: this.config.orderActions.canUpdateSpecialServices,
      spotLevel: true,
      orderLevel: false,
      groupLevel: false
    };

    this.modalRef = this.modalService.show(OrderAddServicePopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));

    this.modalRef.content.onSelectServicesComplete.subscribe(result => {
      this.onApplyServicesSubscriptionRaised(result);
      this.checkForDestinationAddlInfo();
    });

    this.modalRef.content.onServiceSubmitOptionsComplete.subscribe(result => {
      this.onSubmitServiceOptionsRaised(result, spotFileGuid);
      this.checkForDestinationAddlInfo();
    });
  }

  public onDeleteMedia(spotFileGuid: string, groupSpotId: string): void {

    var idxOfSpotToDelete = this.group.spotData.findIndex(t => t.spotFile.spotFileGuid.toLowerCase() == spotFileGuid.toLowerCase() && t.groupSpotId == groupSpotId);

    if (idxOfSpotToDelete >= 0)
      this.group.spotData.splice(idxOfSpotToDelete, 1);

    this.setGroupError();

    this.checkForDestinationAddlInfo();

    this.onTranscodeAssetDelete(spotFileGuid);
  }

  public onServiceLevelEdit() {

    var initialState = {
      orderGuid: this.orderGuid,
      header: null,
      config: null,
      editCategory: "SERVICELEVEL",
      editValue: this.group.isRush,
      orderGroupId: this.group.orderGroupId
    };

    this.modalRef = this.modalService.show(OrderDetailHeaderEditPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

    this.modalRef.content.onEditComplete.subscribe(result => {
      this.onServiceLevelEditCompleteSubscriptionRaised(result);
    });
  }

  public onAddDestination() {
    var initialState = {
      isEdit: true
    };

    this.modalRef = this.modalService.show(OrderAddDestinationListPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, 'modal-dialog--w75'));

    this.modalRef.content.onClose.subscribe(result => {
      this.onSelectDestinationsSubscriptionRaised(result);
      this.checkForDestinationAddlInfo();
    });
  }

  public onAddFtpDestination() {
    var initialState = {
      allowAllFTP: this.config.orderActions.showSpecialFlags,
      isEdit: true
    };

    this.modalRef = this.modalService.show(OrderAddFTPDestinationPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, "modal-dialog--w60"));

    this.modalRef.content.onClose.subscribe(result => {
      this.onSelectDestinationsSubscriptionRaised(result);
    });
  }

  public onAddShareLinks() {
    var initialState = {
      allowAllSharedLinks: this.config.orderActions.showSpecialFlags,
      isEdit: true
    };

    this.modalRef = this.modalService.show(OrderAddShareLinksPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, "modal-dialog--w60"));

    this.modalRef.content.onClose.subscribe(result => {
      this.onSelectDestinationsSubscriptionRaised(result);
    });
  }

  public onRushChanged(option) {
    if (option == 'true')
      this.group.isRush = true;
    else if (option == 'false')
      this.group.isRush = false;
  }

  public onGroupDeleteConfirm() {

    this.onHidePop();

    this.group.isEdited = true;

    this.groupDeleted.emit();
  }

  public onDestinationDelete(code: string, orderDestinationId: number) {

    this.group.destinations = this.group.destinations.filter(t => t.code.toLowerCase() != code.toLowerCase() || (t.primaryDestinationCode != null && t.primaryDestinationCode.toLowerCase() != code.toLowerCase()));
    this.group.ftpDestinations = this.group.ftpDestinations.filter(t => (t.primaryDestinationCode != null && t.primaryDestinationCode.toLowerCase() != code.toLowerCase()));
    this.group.shareLinks = this.group.shareLinks.filter(t => (t.primaryDestinationCode != null && t.primaryDestinationCode.toLowerCase() != code.toLowerCase()));

    this.setGroupError();

    this.checkForDestinationAddlInfo();

    this.deleteSpotDestinationAddlInfo(code);
  }

  public onFtpDestinationDelete(id: number, orderDestinationId: number, name:string) {
    var deletedDestinations = this.group.ftpDestinations.filter(t => t.id == id);
    this.group.ftpDestinations = this.group.ftpDestinations.filter(t => t.id != id || (t.primaryDestinationCode != null && t.primaryDestinationCode.toLowerCase() != name.toLowerCase()));
    this.group.shareLinks = this.group.shareLinks.filter(t => (t.primaryDestinationCode != null && t.primaryDestinationCode.toLowerCase() != name.toLowerCase()));
    this.group.destinations = this.group.destinations.filter(t => (t.primaryDestinationCode.toLowerCase() != name.toLowerCase()));

    if (deletedDestinations != null && deletedDestinations.length > 0) {
      this.removeDestinationSpecsForAllSpots(deletedDestinations[0].name);
    }

    this.setGroupError();

    //this.group.isEdited = true;
  }

  public onShareLinksDelete(groupGuid: string, orderDestinationId: number, groupName:string) {
    var deletedDestinations = this.group.shareLinks.filter(t => t.groupGuid.toLowerCase() == groupGuid.toLowerCase());
    this.group.shareLinks = this.group.shareLinks.filter(t => t.groupGuid.toLowerCase() != groupGuid.toLowerCase() || (t.primaryDestinationCode != null && t.primaryDestinationCode.toLowerCase() != groupName.toLowerCase()));
    this.group.destinations = this.group.destinations.filter(t => (t.primaryDestinationCode != null && t.primaryDestinationCode.toLowerCase() != groupName.toLowerCase()));
    this.group.ftpDestinations = this.group.ftpDestinations.filter(t => (t.primaryDestinationCode != null && t.primaryDestinationCode.toLowerCase() != groupName.toLowerCase()));


    if (deletedDestinations != null && deletedDestinations.length > 0) {
      this.removeDestinationSpecsForAllSpots(deletedDestinations[0].groupName);
    }

    this.setGroupError();

    this.group.isEdited = true;
  }

  public onDestinationMark(orderDestinationId: number) {
    var dest = this.group.destinations.find(t => t.orderDestinationId == orderDestinationId);
    if (dest != null)
      dest.isMarked = !dest.isMarked;

    var ftpdest = this.group.ftpDestinations.find(t => t.orderDestinationId == orderDestinationId);
    if (ftpdest != null)
      ftpdest.isMarked = !ftpdest.isMarked;

    var sharedest = this.group.shareLinks.find(t => t.orderDestinationId == orderDestinationId);
    if (sharedest != null)
      sharedest.isMarked = !sharedest.isMarked;
  }

  public onAddEditGroupServices(editMode: boolean) {
    var additionalMetadata: OrderAdditionalMetadata = {
      orderGuid: this.orderGuid,
      spotTracCode: '',
      allowDestinationHub: false,
      allowSpecialOptions: this.config.orderActions.showSpecialFlags,
      //ppFileTitle: '',
      //ppFileAdId: '',
      //ppFileSpecs: [{ spotFileGuid: '', ppFileAdId: '', ppFileTitle: '' }],
      ppFileSpecs: [],
      transcodePredefinedSpecs: this.config.transcodePredefinedSpecs,
      customFileNameOptions: this.config.customFileNameOptions
    };

    console.log(additionalMetadata);

    var initialState = {
      spotFileGuid: '',
      applyAllMode: false,
      editMode: editMode,
      orderGuid: this.orderGuid,
      serviceData: this.copyServiceDataArray(this.group.serviceData),
      additionalMetadata: additionalMetadata,
      orderEditMode: true,
      showInEditMode: (this.config.orderActions.showInEditMode && (this.config.orderActions.canAddOrderLevelServices === true && this.config.orderActions.canUpdateSpecialServices == false)),
      specialServiceMode: this.config.orderActions.canUpdateSpecialServices,
      spotLevel: false,
      orderLevel: false,
      groupLevel: true
    };

    this.modalRef = this.modalService.show(OrderAddServicePopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));

    this.modalRef.content.onSelectServicesComplete.subscribe(result => {
      this.onApplyOrderGroupServicesSubscriptionRaised(result);
    });

    this.modalRef.content.onServiceSubmitOptionsComplete.subscribe(result => {
      this.onSubmitOrderGroupServiceOptionsRaised(result);
    });
  }

  public onGroupServiceDelete(id: number) {
    var existingServices = this.group.serviceData;

    if (existingServices && existingServices != null && existingServices.length > 0) {
      var newServicesAfterDelete = existingServices.filter(t => t.serviceId != id);

      this.group.serviceData = newServicesAfterDelete;
    }

    this.setGroupError();

    //this.group.isEdited = true;
  }

  private onApplyOrderGroupServicesSubscriptionRaised(selectedServicesRequest: SpotSelectedServicesRequest) {
    var existingServices = this.group.serviceData;

    selectedServicesRequest.serviceData.forEach(sdata => {
      if (existingServices.find(t => t.service.id === sdata.service.id) == null) {
        existingServices.push(this.copyServiceData(sdata));
      }
    });

    existingServices = existingServices.filter(t => selectedServicesRequest.serviceData.some(f => f.service.id === t.service.id));

    this.group.serviceData = existingServices;

    this.setGroupError();

    //this.group.isEdited = true;
  }

  public onGroupNameEditConfirm(val: string) {
    this.gpnamepop.hide();

    if (this.group.orderGroupId == 0) {
      this.group.name = this.groupName;
      return;
    }

    var request: EditOrderHeaderRequest = { airDateEnd: null, airDateStart: null, editCategory: 'GROUPNAME', orderGroupId: this.group.orderGroupId, newValue: this.groupName, orderActions: null };

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.orderService.editOrderHeader(this.orderGuid, request).subscribe((res: any) => {
      if (res.isSuccess == true) {

        this.alertService.showMessage("SUCCESS", Constants.orderGpNameUpdatedSuccessfully, MessageSeverity.success);

        this.group.name = this.groupName;

        this.groupUpdated.emit(res.result.orderActions);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public onCopyFileName(fileName: string): void {
    var dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.setAttribute('value', fileName);
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

  public onHideGroupNamePop() {
    this.gpnamepop.hide();
  }

  private onSubmitOrderGroupServiceOptionsRaised(spotServicesOptionsRequest: SpotServicesOptionsRequest) {
    var existingServices = this.group.serviceData;

    var newServiceDataAfterDelete = existingServices.filter(t => spotServicesOptionsRequest.deletedServices.find(s => s == t.service.id) == null);

    spotServicesOptionsRequest.addedServices.forEach(aSer => {
      if (newServiceDataAfterDelete.find(t => t.serviceId == aSer.serviceId) == null) {
        if (this.config.orderActions.canUpdateSpecialServices == false
          || (this.config.orderActions.canUpdateSpecialServices == true && (aSer.service.isGroupSpecialEdit || aSer.service.isSpecialOptionEdit))) {
          newServiceDataAfterDelete.push(this.copyServiceData(aSer));
        }
      }
    });

    spotServicesOptionsRequest.serviceData.forEach(servItem => {
      var serviceData = newServiceDataAfterDelete.find(t => t.service.id === servItem.service.id);

      if (serviceData != null) {

        if (this.config.orderActions.canUpdateSpecialServices == false
          || (this.config.orderActions.canUpdateSpecialServices == true && (serviceData.service.isGroupSpecialEdit || serviceData.service.isSpecialOptionEdit))) {

          let tagsToRemove: Array<string> = [];

          if (this.config.orderActions.canUpdateSpecialServices == true && (serviceData.service.isGroupSpecialEdit || serviceData.service.isSpecialOptionEdit)) {
            if (serviceData.service.isSpecialOptionEdit)
              tagsToRemove = serviceData.service.serviceConfig.options.filter(t => t.isSpecialOption == true).map(a => a.tag);
            else if (serviceData.service.isGroupSpecialEdit)
              tagsToRemove = serviceData.service.serviceConfig.options.map(a => a.tag);

            serviceData.serviceMetadata = serviceData.serviceMetadata.filter(t => !tagsToRemove.includes(t.metadataKey));
          }
          else {
            serviceData.serviceMetadata = [];
          }

          servItem.serviceMetadata.forEach(sm => {
            if (this.config.orderActions.canUpdateSpecialServices == true && (serviceData.service.isGroupSpecialEdit || serviceData.service.isSpecialOptionEdit)) {

              if (tagsToRemove.includes(sm.metadataKey))
                serviceData.serviceMetadata.push({ category: sm.category, fileValue: sm.fileValue, objectValue: sm.objectValue, arrayValue: sm.arrayValue, metadataKey: sm.metadataKey, textValue: sm.textValue, display: sm.display });
            }
            else {
              serviceData.serviceMetadata.push({ category: sm.category, fileValue: sm.fileValue, objectValue: sm.objectValue, arrayValue: sm.arrayValue, metadataKey: sm.metadataKey, textValue: sm.textValue, display: sm.display });
            }
          });
        }

        serviceData.optionsSubmitted = true;
      }
      else {
        console.log("not exist", servItem.service);
      }
    });

    this.group.serviceData = newServiceDataAfterDelete;

    this.setGroupError();

    //this.group.isEdited = true;
  }

  public setAddGroupError(grpError: GroupError) {

    var existingSpots = this.group.spotData;
    var existingDestinations = this.group.destinations;
    this.groupErrors = [];

    if (existingSpots != null) {

      if (grpError.archivedSpotFiles != null && grpError.archivedSpotFiles.length > 0) {

        grpError.archivedSpotFiles.forEach(archivedSpotFileGuid => {

          var currentSpot = existingSpots.find(t => t.spotFile.spotFileGuid.toLowerCase() === archivedSpotFileGuid.toLowerCase()) as SpotServiceData;

          if (currentSpot != null)
            currentSpot.spotFile.isArchived = true;

        });
      }

      if (grpError.expiredSpotFiles != null && grpError.expiredSpotFiles.length > 0) {

        grpError.expiredSpotFiles.forEach(expiredSpotFileGuid => {

          var currentSpot = existingSpots.find(t => t.spotFile.spotFileGuid.toLowerCase() === expiredSpotFileGuid.toLowerCase()) as SpotServiceData;

          if (currentSpot != null)
            currentSpot.spotFile.isExpired = true;

        });
      }

      if (grpError.inActiveSpotFiles != null && grpError.inActiveSpotFiles.length > 0) {

        grpError.inActiveSpotFiles.forEach(inactiveSpotFileGuid => {

          var currentSpot = existingSpots.find(t => t.spotFile.spotFileGuid.toLowerCase() === inactiveSpotFileGuid.toLowerCase()) as SpotServiceData;

          if (currentSpot != null)
            currentSpot.spotFile.isActive = false;

        });
      }

      if (grpError.spotDataErrors != null && grpError.spotDataErrors.length > 0) {
        grpError.spotDataErrors.forEach(spotErr => {

          var currentSpot = existingSpots.find(t => t.spotFile.spotFileGuid.toLowerCase() === spotErr.spotFileGuid.toLowerCase()) as SpotServiceData;

          if (currentSpot != null && currentSpot.ppSpotFile != null) {
            if (spotErr.ppSpotFileAdIdInValid == true)
              currentSpot.ppSpotFile.adIdInValid = true;

            if (spotErr.ppSpotFileTitleInValid == true)
              currentSpot.ppSpotFile.titleInValid = true;
          }

          if (spotErr.inValidServices != null && spotErr.inValidServices.length > 0) {
            spotErr.inValidServices.forEach(srv => {
              this.groupErrors.push(`Invalid Service added ${srv}`);
            });
          }

          if (spotErr.errors != null && spotErr.errors.length > 0) {
            this.groupErrors = this.groupErrors.concat(spotErr.errors);
          }

        });
      }

      this.group.spotData = existingSpots;
    }

    if (existingDestinations != null) {
      if (grpError.inActiveDestinations != null && grpError.inActiveDestinations.length > 0) {

        grpError.inActiveDestinations.forEach(inactiveDest => {

          var currentDest = existingDestinations.find(t => t.code.toLowerCase() === inactiveDest.toLowerCase()) as OrderDestination;

          if (currentDest != null)
            currentDest.isActive = false;
        });
      }

      if (grpError.destinationDataErrors != null && grpError.destinationDataErrors.length > 0) {
        grpError.destinationDataErrors.forEach(desErr => {

          var currentDest = existingDestinations.find(t => t.code.toLowerCase() === desErr.destinationCode.toLowerCase()) as OrderDestination;

          if (currentDest != null) {
            if (desErr.noVendorDestinationMappingAvailable == true)
              currentDest.noVendorDestinationMappingAvailable = true;

            if (desErr.unableToChooseVendor == true)
              currentDest.unableToChooseVendor = true;

            if (desErr.errors != null && desErr.errors.length > 0 && desErr.formatInCompatible == true) {
              currentDest.destinationFormatErrors = desErr.errors.toString();
              currentDest.destinationFormatInCompatible = true;
            }
            else {
              currentDest.destinationFormatInCompatible = false;
              currentDest.destinationFormatErrors = "";
            }
          }
        });
      }

      this.group.destinations = existingDestinations;
    }

    if (grpError.inValidSpotFiles != null && grpError.inValidSpotFiles.length > 0) {
      grpError.inValidSpotFiles.forEach(sp => {
        this.groupErrors.push(`Invalid Spot added ${sp}`);
      });
    }

    if (grpError.inValidDestinations != null && grpError.inValidDestinations.length > 0) {
      grpError.inValidDestinations.forEach(des => {
        this.groupErrors.push(`Invalid Destination added ${des}`);
      });
    }

    if (grpError.inValidFtpDestinations != null && grpError.inValidFtpDestinations.length > 0) {
      grpError.inValidFtpDestinations.forEach(des => {
        this.groupErrors.push(`Invalid FTP Destination added ${des}`);
      });
    }

    if (grpError.inValidShareLinks != null && grpError.inValidShareLinks.length > 0) {
      grpError.inValidShareLinks.forEach(des => {
        this.groupErrors.push(`Invalid Share Links added ${des}`);
      });
    }

    if (grpError.inValidServices != null && grpError.inValidServices.length > 0) {
      grpError.inValidServices.forEach(srv => {
        this.groupErrors.push(`Invalid Service added ${srv}`);
      });
    }

    if (grpError.noDigitalDestinations == true)
      this.groupErrors.push("No Digital Destinations added.");

    if (grpError.noFtpDestinations == true)
      this.groupErrors.push("No Ftp Destinations added.");

    if (grpError.noSpots == true)
      this.groupErrors.push("No Asset added.");

    if (grpError.noNetworkDestinationRelatedService == true)
      this.groupErrors.push("One or more Digital destinations were added with no relevant Service.");

    if (grpError.noFtpDestinationRelatedService == true)
      this.groupErrors.push("One or more FTP destinations were added with no relevant Service.");

    if (grpError.errors != null && grpError.errors.length > 0)
      this.groupErrors = this.groupErrors.concat(grpError.errors);

    this.setGroupError(true);
  }

  private onHidePop() {
    this.delpop.hide();
  }

  private onSelectDestinationsSubscriptionRaised(allDestinationsSelected: SelectedOrderDestinations) {

    if (allDestinationsSelected === null)
      return;

    var selectedDestinations = allDestinationsSelected.destinations;
    var selectedFtpDestinations = allDestinationsSelected.ftpDestinations;
    var selectedShareLinks = allDestinationsSelected.shareLinks;

    if (selectedDestinations != null && selectedDestinations.length > 0) {

      var selectedDestWithSpecs = selectedDestinations.filter(dest => dest.hasSpecMappings == true);

      //if (selectedDestWithSpecs.length > 1) {
      //  this.util.addError(this.groupErrors, "Multiple Destinations with Specification Mappings cannot be added to same group.");
      //  this.group.containsGroupErrors = true;
      //  return;
      //}
      //else if ((
      //  this.group.ftpDestinations.length > 0
      //  || this.group.ftpDestinations.length > 0) && selectedDestWithSpecs.length > 0) {
      //  this.util.addError(this.groupErrors, "Multiple Destinations with Specification Mappings cannot be added to same group.");
      //  this.group.containsGroupErrors = true;
      //  return;
      //}

      if ((selectedDestWithSpecs.length > 0) && ((this.group.ftpDestinations != null && this.group.ftpDestinations.length > 0)
        || (this.group.shareLinks != null && this.group.shareLinks.length > 0))) {
        this.util.addError(this.groupErrors, "Network Destinations with Specification Mappings and Sharelink/FTP Destinations Cannot be added to the same group.");
        this.group.containsGroupErrors = true;
        return;
      }
      else if ((selectedDestWithSpecs.length > 0) && ((this.group.ftpDestinations != null && this.group.ftpDestinations.length > 0)
        || (this.group.shareLinks != null && this.group.shareLinks.length > 0))) {
        this.util.addError(this.groupErrors, "Network Destinations with Specification Mappings and Sharelink/FTP Destinations Cannot be added to the same group.");
        this.group.containsGroupErrors = true;
        return;
      }

      selectedDestinations.forEach(item => {
        if (this.group.destinations.find(t => t.code.toLowerCase() === item.code.toLowerCase()) == null) {
          this.getTranscodeSpecsByDestinationCodeForAllSpots(item.code);
          this.group.destinations.push(item);
        }
      });
    }

    if (selectedFtpDestinations != null && selectedFtpDestinations.length > 0) {

      var selectedFTPDestWithSpecs = selectedFtpDestinations.filter(dest => dest.hasSpecMappings == true);

      if (selectedFTPDestWithSpecs.length > 1) {
        this.util.addError(this.groupErrors, "Multiple Destinations with Specification Mappings cannot be added to same group.");
        this.group.containsGroupErrors = true;
        return;
      }
      else if ((this.group.destinations != null && this.group.destinations.find(dest => dest.hasSpecMappings == true) != undefined) &&
        ((this.group.ftpDestinations != null && this.group.ftpDestinations.length > 0)
        || (this.group.shareLinks != null && this.group.shareLinks.length > 0) || (selectedFtpDestinations.length > 0))) {
        this.util.addError(this.groupErrors, "Network Destinations with Specification Mappings and Sharelink/FTP Destinations Cannot be added to the same group.");
        this.group.containsGroupErrors = true;
        return;
      }
      else if (selectedFTPDestWithSpecs.length >= 1 && ((this.group.ftpDestinations != null && this.group.ftpDestinations.length > 0)
        || (this.group.shareLinks != null && this.group.shareLinks.length > 0))) {
        this.util.addError(this.groupErrors, "Multiple Destinations with Specification Mappings cannot be added to same group.");
        this.group.containsGroupErrors = true;
        return;
      }

      selectedFtpDestinations.forEach(item => {
        this.getTranscodeSpecsByDestinationCodeForAllSpots(item.name);
        this.group.ftpDestinations.push(item);
      });
    }

    if (selectedShareLinks != null && selectedShareLinks.length > 0) {

      var selectedShareLinkPDestWithSpecs = selectedShareLinks.filter(dest => dest.hasSpecMappings == true);

      if (selectedShareLinkPDestWithSpecs.length > 1) {
        this.util.addError(this.groupErrors, "Multiple Destinations with Specification Mappings cannot be added to same group.");
        this.group.containsGroupErrors = true;
        return;
      }
      else if ((this.group.destinations != null && this.group.destinations.find(dest => dest.hasSpecMappings == true) != undefined) &&
        ((this.group.ftpDestinations != null && this.group.ftpDestinations.length > 0)
        || (this.group.shareLinks != null && this.group.shareLinks.length > 0) || selectedShareLinks.length > 0)) {
        this.util.addError(this.groupErrors, "Network Destinations with Specification Mappings and Sharelink/FTP Destinations Cannot be added to the same group.");
        this.group.containsGroupErrors = true;
        return;
      }
      else if (selectedShareLinkPDestWithSpecs.length >= 1 && ((this.group.ftpDestinations != null && this.group.ftpDestinations.length > 0)
        || (this.group.shareLinks != null && this.group.shareLinks.length > 0))) {
        this.util.addError(this.groupErrors, "Multiple Destinations with Specification Mappings cannot be added to same group.");
        this.group.containsGroupErrors = true;
        return;
      }

      selectedShareLinks.forEach(item => {
        this.getTranscodeSpecsByDestinationCodeForAllSpots(item.groupName);
        this.group.shareLinks.push(item);
      });
    }

    this.setGroupError();

    //this.group.isEdited = true;
  }

  private onServiceLevelEditCompleteSubscriptionRaised(result: any) {
    console.log(result)
    this.group.isRush = (result.newValue == "true" ? true : false);
    this.groupUpdated.emit(result.orderActions);
  }

  private isHiddenItem(mt: ServiceMetadata): boolean {
    return mt.category.toLowerCase() === metadataCategoryEnum[metadataCategoryEnum.NA].toLowerCase();
  }

  public onSelectSpotsSubscriptionRaised(selectedSpotFiles: Array<SpotFile>) {

    if (selectedSpotFiles && selectedSpotFiles != null && selectedSpotFiles.length > 0) {

      selectedSpotFiles.forEach(item => {

        var newSpotToAdd = this.toSpotServiceData(item);

        newSpotToAdd.groupSpotId = UUID.UUID();

        this.group.spotData.push(newSpotToAdd);
      });
    }

    this.setGroupError();
  }

  private onApplyServicesSubscriptionRaised(selectedServicesRequest: SpotSelectedServicesRequest) {

    //var spotItem = this.group.spotData.find(t => t.spotFile.spotFileGuid.toLowerCase() === selectedServicesRequest.spotFileGuid.toLowerCase()) as OrderDetailSpotServiceData;
    // var spotItem = this.group.spotData.find(t => t.spotFile.spotFileGuid.toLowerCase() === selectedServicesRequest.spotFileGuid.toLowerCase() && selectedServicesRequest.groupSpotId == t.groupSpotId) as OrderDetailSpotServiceData;
    var spotItem = this.group.spotData.find(t => selectedServicesRequest.groupSpotId == t.groupSpotId) as OrderDetailSpotServiceData;
    var isDestinationTrnascodesApplied = false;

    if (spotItem == null)
      return;

    let ppFileSuffix: string = '';
    var serviceFormatErrors: Array<string> = [];

    selectedServicesRequest.serviceData.forEach(sdata => {
      if (spotItem.serviceData.find(t => t.service.id === sdata.service.id) == null) {

        var isServicFormatCombinationValid = true;

        if (sdata.service.isAudio == true && spotItem.spotFile.format.toLowerCase() != 'audio')
          isServicFormatCombinationValid = false;
        else if (sdata.service.isVideo == true && spotItem.spotFile.format.toLowerCase() == 'audio')
          isServicFormatCombinationValid = false;

        if (isServicFormatCombinationValid) {

          spotItem.serviceData.push(this.copyServiceData(sdata));

          if (sdata.service.category.toLowerCase() == 'post production')
            ppFileSuffix = ppFileSuffix.concat(sdata.service.titleSuffix);
        }
        else {
          serviceFormatErrors.push(sdata.service.name + " cannot be applied to " + spotItem.spotFile.adId);
        }
      }
    });

    spotItem.serviceData = spotItem.serviceData.filter(t => selectedServicesRequest.serviceData.some(f => f.service.id === t.service.id));

    spotItem.isPpExists = (spotItem.serviceData.some(t => t.service.category.toLowerCase() === 'post production'));
    spotItem.isAddlExists = (spotItem.serviceData.some(t => t.service.category.toLowerCase() === 'additional'));
    spotItem.isDeliveryExists = (spotItem.serviceData.some(t => t.service.category.toLowerCase() === 'digital delivery'));

    var spotItemThatRequiresNewPPFile = (spotItem.serviceData.some(t => t.service.isPostProdFileCreated === true));

    if (spotItemThatRequiresNewPPFile) {

      if (selectedServicesRequest.ppFileSpecs == null)
        selectedServicesRequest.ppFileSpecs = [];

      //var ppFileSpec = selectedServicesRequest.ppFileSpecs.find(t => t.spotFileGuid.toLowerCase() == spotItem.spotFile.spotFileGuid.toLowerCase() && t.groupSpotId == spotItem.groupSpotId);
      var ppFileSpec = selectedServicesRequest.ppFileSpecs.find(t => t.groupSpotId == spotItem.groupSpotId);

      if (spotItem.ppSpotFile === null) {
        spotItem.ppSpotFile = new SpotFile();
        spotItem.ppSpotFile.title = (ppFileSpec != null && ppFileSpec.ppFileTitle != '') ? ppFileSpec.ppFileTitle : spotItem.spotFile.title.concat(ppFileSuffix);
        spotItem.ppSpotFile.adId = (ppFileSpec != null && ppFileSpec.ppFileAdId != '') ? ppFileSpec.ppFileAdId : spotItem.spotFile.adId;

        spotItem.ppSpotFile.thumbnailUrl = '';
        spotItem.ppSpotFile.showCreatingMediaBanner = true;
      }
      else {
        spotItem.ppSpotFile.title = (ppFileSpec != null && ppFileSpec.ppFileTitle != '') ? ppFileSpec.ppFileTitle : spotItem.ppSpotFile.title.concat(ppFileSuffix);
        spotItem.ppSpotFile.adId = (ppFileSpec != null && ppFileSpec.ppFileAdId != '') ? ppFileSpec.ppFileAdId : spotItem.ppSpotFile.adId;
      }
    }
    else
      spotItem.ppSpotFile = null;

    /*adding  Transcodes from destination*/
    var existingDests = this.group.destinations;
    var shareLinkDestinations = this.group.shareLinks;
    var ftpDestinations = this.group.ftpDestinations;

    if (existingDests != null || shareLinkDestinations != null || ftpDestinations != null) {
      if (spotItem.isDeliveryExists || spotItem.serviceData.some(t => t.service.isFtp == true)) {
        if (selectedServicesRequest.applyToAllMedia && isDestinationTrnascodesApplied == false) {
          existingDests.forEach(dest => { this.getTranscodeSpecsByDestinationCodeForAllSpots(dest.code); });
          shareLinkDestinations.forEach(dest => { this.getTranscodeSpecsByDestinationCodeForAllSpots(dest.groupName); });
          ftpDestinations.forEach(dest => { this.getTranscodeSpecsByDestinationCodeForAllSpots(dest.name); });
          isDestinationTrnascodesApplied = true;
        }
        else {
          existingDests.forEach(dest => { this.getTranscodeSpecsByDestinationCodePerSpot(dest.code, spotItem.spotFileGuid, spotItem.spotFile); });
          shareLinkDestinations.forEach(dest => { this.getTranscodeSpecsByDestinationCodePerSpot(dest.groupName, spotItem.spotFileGuid, spotItem.spotFile); });
          ftpDestinations.forEach(dest => { this.getTranscodeSpecsByDestinationCodePerSpot(dest.name, spotItem.spotFileGuid, spotItem.spotFile); });
        }
      }
    }

    this.setGroupError();

    //this.group.isEdited = true;

    if (serviceFormatErrors.length > 0) {
      var initialState = {
        errors: serviceFormatErrors
      };
      this.modalService.show(ErrorPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
    }
  }

  private onSubmitServiceOptionsRaised(spotServicesOptionsRequest: SpotServicesOptionsRequest, spotFileGuid: string) {

    //var spotItem = this.group.spotData.find(t => t.spotFile.spotFileGuid.toLowerCase() === spotServicesOptionsRequest.spotFileGuid.toLowerCase()) as OrderDetailSpotServiceData;

    //var spotItem = this.group.spotData.find(t => t.spotFile.spotFileGuid.toLowerCase() === spotServicesOptionsRequest.spotFileGuid.toLowerCase() && t.groupSpotId == spotServicesOptionsRequest.groupSpotId) as OrderDetailSpotServiceData;
    var spotItem = this.group.spotData.find(t => t.groupSpotId == spotServicesOptionsRequest.groupSpotId) as OrderDetailSpotServiceData;
    var isDigitalServiceExistsBeforeFilter = false;
    var isDestinationTrnascodesApplied = false;

    if (spotItem == null)
      return;

    isDigitalServiceExistsBeforeFilter = (spotItem.serviceData.some(t => t.service.category.toLowerCase() === 'digital delivery') || spotItem.serviceData.some(t => t.service.isFtp == true));

    spotItem.serviceData = spotItem.serviceData.filter(t => spotServicesOptionsRequest.deletedServices.find(s => s == t.service.id) == null);

    spotServicesOptionsRequest.addedServices.forEach(aSer => {
      if (spotItem.serviceData.find(t => t.serviceId == aSer.serviceId) == null) {
        if (this.config.orderActions.canUpdateSpecialServices == false || (this.config.orderActions.canUpdateSpecialServices == true && (aSer.service.isSpotSpecialEdit || aSer.service.isSpecialOptionEdit))) {
          spotItem.serviceData.push(this.copyServiceData(aSer));
        }
      }
    });

    spotServicesOptionsRequest.serviceData.forEach(servItem => {

      var serviceData = spotItem.serviceData.find(t => t.service.id === servItem.service.id);

      if (serviceData != null) {
        if (this.config.orderActions.canUpdateSpecialServices == false
          || (this.config.orderActions.canUpdateSpecialServices == true && (serviceData.service.isSpotSpecialEdit || serviceData.service.isSpecialOptionEdit))) {
          let tagsToRemove: Array<string> = [];

          if (this.config.orderActions.canUpdateSpecialServices == true && (serviceData.service.isSpotSpecialEdit || serviceData.service.isSpecialOptionEdit)) {
            if (serviceData.service.isSpecialOptionEdit)
              tagsToRemove = serviceData.service.serviceConfig.options.filter(t => t.isSpecialOption == true).map(a => a.tag);
            else if (serviceData.service.isSpotSpecialEdit)
              tagsToRemove = serviceData.service.serviceConfig.options.map(a => a.tag);

            serviceData.serviceMetadata = serviceData.serviceMetadata.filter(t => !tagsToRemove.includes(t.metadataKey));
          }
          else {
            serviceData.serviceMetadata = [];
          }

          servItem.serviceMetadata.forEach(sm => {
            if (this.config.orderActions.canUpdateSpecialServices == true && (serviceData.service.isSpotSpecialEdit || serviceData.service.isSpecialOptionEdit)) {

              if (tagsToRemove.includes(sm.metadataKey))
                serviceData.serviceMetadata.push({ category: sm.category, fileValue: sm.fileValue, objectValue: sm.objectValue, arrayValue: sm.arrayValue, metadataKey: sm.metadataKey, textValue: sm.textValue, display: sm.display });
            }
            else {
              serviceData.serviceMetadata.push({ category: sm.category, fileValue: sm.fileValue, objectValue: sm.objectValue, arrayValue: sm.arrayValue, metadataKey: sm.metadataKey, textValue: sm.textValue, display: sm.display });
            }
          });
        }

        serviceData.optionsSubmitted = true;
      }
      else {
        console.log("not exist", servItem.service);
      }
    });

    spotItem.isPpExists = (spotItem.serviceData.some(t => t.service.category.toLowerCase() === 'post production'));
    spotItem.isAddlExists = (spotItem.serviceData.some(t => t.service.category.toLowerCase() === 'additional'));
    spotItem.isDeliveryExists = (spotItem.serviceData.some(t => t.service.category.toLowerCase() === 'digital delivery'));

    if (this.config.orderActions.canUpdateSpecialServices == false) {
      var ppfileServices = (spotItem.serviceData.filter(t => t.service.isPostProdFileCreated === true));
      var ppFileNeededForThisSpot = false;

      if (ppfileServices.length == 0) {
        ppFileNeededForThisSpot = false;
      }
      else {
        if (ppfileServices.length == 1) {
          var removeSlateKey = ppfileServices[0].serviceMetadata.find(t => t.metadataKey != null && t.metadataKey.toLowerCase() == 'option');

          if (removeSlateKey != null && removeSlateKey.textValue != null && removeSlateKey.textValue.toLowerCase() == 'removeslate')
            ppFileNeededForThisSpot = false;
          else
            ppFileNeededForThisSpot = true;
        }
        else
          ppFileNeededForThisSpot = true;
      }

      if (ppFileNeededForThisSpot == false)
        spotItem.ppSpotFile = null;
      else {
        if (spotServicesOptionsRequest.ppFileSpecs == null)
          spotServicesOptionsRequest.ppFileSpecs = [];

        if (spotItem.ppSpotFile === null) {
          spotItem.ppSpotFile = new SpotFile();
          spotItem.ppSpotFile.thumbnailUrl = '';
          spotItem.ppSpotFile.showCreatingMediaBanner = true;
        }

        //var ppFileSpec = spotServicesOptionsRequest.ppFileSpecs.find(t => t.spotFileGuid.toLowerCase() == spotItem.spotFile.spotFileGuid.toLowerCase());

        //var ppFileSpec = spotServicesOptionsRequest.ppFileSpecs.find(t => t.spotFileGuid.toLowerCase() == spotItem.spotFile.spotFileGuid.toLowerCase() && t.groupSpotId == spotItem.groupSpotId);
        var ppFileSpec = spotServicesOptionsRequest.ppFileSpecs.find(t => t.groupSpotId == spotItem.groupSpotId);

        //console.log("ppFileSpec", ppFileSpec, spotServicesOptionsRequest.ppFileSpecs, spotItem);

        //spotItem.ppSpotFile.title = (ppFileSpec != null && ppFileSpec.ppFileTitle != '') ? ppFileSpec.ppFileTitle : spotItem.spotFile.title;
        //spotItem.ppSpotFile.adId = (ppFileSpec != null && ppFileSpec.ppFileAdId != '') ? ppFileSpec.ppFileAdId : spotItem.spotFile.adId;

        spotItem.ppSpotFile.title = (ppFileSpec != null && ppFileSpec.ppFileTitle != '') ? ppFileSpec.ppFileTitle : (spotItem.ppSpotFile.title != '' ? spotItem.ppSpotFile.title : spotItem.spotFile.title);
        spotItem.ppSpotFile.adId = (ppFileSpec != null && ppFileSpec.ppFileAdId != '') ? ppFileSpec.ppFileAdId : (spotItem.ppSpotFile.adId != '' ? spotItem.ppSpotFile.adId : spotItem.spotFile.adId);


        spotItem.ppSpotFile.adIdInValid = false;
        spotItem.ppSpotFile.titleInValid = false;
      }
    }
    else if (spotItem.ppSpotFile != null) {
      spotItem.ppSpotFile.adIdInValid = false;
      spotItem.ppSpotFile.titleInValid = false;
    }

    /*adding  Transcodes from destination*/
    var existingDests = this.group.destinations;
    var shareLinkDestinations = this.group.shareLinks;
    var ftpDestinations = this.group.ftpDestinations;

    if (existingDests != null || shareLinkDestinations != null || ftpDestinations != null) {
      if (spotItem.isDeliveryExists || spotItem.serviceData.some(t => t.service.isFtp == true)) {
        if (spotServicesOptionsRequest.applyToAllMedia && isDestinationTrnascodesApplied == false) {
          existingDests.forEach(dest => { this.getTranscodeSpecsByDestinationCodeForAllSpots(dest.code); });
          shareLinkDestinations.forEach(dest => { this.getTranscodeSpecsByDestinationCodeForAllSpots(dest.groupName); });
          ftpDestinations.forEach(dest => { this.getTranscodeSpecsByDestinationCodeForAllSpots(dest.name); });
          isDestinationTrnascodesApplied = true;
        }
        else {
          existingDests.forEach(dest => { this.getTranscodeSpecsByDestinationCodePerSpot(dest.code, spotItem.spotFileGuid, spotItem.spotFile); });
          shareLinkDestinations.forEach(dest => { this.getTranscodeSpecsByDestinationCodePerSpot(dest.groupName, spotItem.spotFileGuid, spotItem.spotFile); });
          ftpDestinations.forEach(dest => { this.getTranscodeSpecsByDestinationCodePerSpot(dest.name, spotItem.spotFileGuid, spotItem.spotFile); });
        }
      }
      else {
        if (isDigitalServiceExistsBeforeFilter) {
          existingDests.forEach(dest => { this.removeDestinationSpecsFromSpot(dest.code, spotFileGuid); });
          shareLinkDestinations.forEach(dest => { this.removeDestinationSpecsFromSpot(dest.groupName, spotFileGuid); });
          ftpDestinations.forEach(dest => { this.removeDestinationSpecsFromSpot(dest.name, spotFileGuid); });
        }
      }
    }

    this.setGroupError();
  }

  private copyServiceDataArray(sdataArray: Array<OrderDetailServiceData>): Array<ServiceData> {
    var newSdataArray: Array<ServiceData> = [];

    sdataArray.forEach(sdata => {

      let newSdata: ServiceData = {
        service: sdata.service, serviceMetadata: [], recentlyAdded: false, serviceId: sdata.service.id,
        containsServiceMetadataDataErrors: sdata.containsServiceMetadataDataErrors, errorMsg: sdata.errorMsg,
        optionsSubmitted: sdata.optionsSubmitted,
        parentServices: sdata.parentServices,
        displaySequence: sdata.displaySequence
      };

      sdata.serviceMetadata.forEach(sm => {
        newSdata.serviceMetadata.push({ category: sm.category, fileValue: sm.fileValue, objectValue: sm.objectValue, arrayValue: sm.arrayValue, metadataKey: sm.metadataKey, textValue: sm.textValue, display: sm.display });
      });

      newSdataArray.push(newSdata);
    });

    return newSdataArray;
  }

  private copyServiceData(sdata: ServiceData): OrderDetailServiceData {
    let newSdata: OrderDetailServiceData = {
      service: sdata.service, serviceMetadata: [], recentlyAdded: false, serviceId: sdata.service.id,
      containsServiceMetadataDataErrors: sdata.containsServiceMetadataDataErrors, errorMsg: sdata.errorMsg,
      optionsSubmitted: sdata.optionsSubmitted,
      parentServices: sdata.parentServices,
      displaySequence: sdata.displaySequence,
      orderServiceId: 0
    };

    sdata.serviceMetadata.forEach(sm => {
      newSdata.serviceMetadata.push({ category: sm.category, fileValue: sm.fileValue, objectValue: sm.objectValue, arrayValue: sm.arrayValue, metadataKey: sm.metadataKey, textValue: sm.textValue, display: sm.display });
    });

    return newSdata;
  }

  public setGroupError(fromSubmit: boolean = false): void {

    if (fromSubmit == false) {
      this.group.isEdited = true;
      this.groupUpdated.emit();
    }

    var containsError: boolean = false;
    var containsGroupServiceErrors: boolean = false;
    var containsGroupAdIdErrors: boolean = false;
    var containsAdIDWarnings: boolean = false;
    var containsGroupTitleErrors: boolean = false;
    var containsGroupDestinationErrors: boolean = false;
    var containsGroupServiceLevelErrors: boolean = false;
    var noMedia: boolean = false;
    var isAudioSpotsExist: boolean = false;
    var isVideoSpotsExist: boolean = false;
    var inValidAudioVideoCombinationSpotsExists: boolean = false;
    var inValidServiceCombinationExists: boolean = false;
    var inValidServiceDestinationCombinationExists: boolean = false;

    var containsDeliveryService = false;
    var containsFtpService = false;
    var containsClearanceService = false;
    var containsNotificationService = false;
    var containsOtherMiscService = false;
    var containsExpiredAssetAllowedService = false;

    if (this.group.isRush == null)
      containsError = true;

    var groupServices = this.group.serviceData;

    if (groupServices.findIndex(t => t.service.isNotification === true) >= 0)
      containsNotificationService = true;

    groupServices.forEach(s => {

      s.errorMsg = '';

      if (s.service.isAdditionalDataNeeded == true && this.util.isServiceMetadataValid(s.service.code, s.serviceMetadata, false, true, false, false, this.config.orderActions.showSpecialFlags) == false) {
        s.containsServiceMetadataDataErrors = true;
        containsGroupServiceLevelErrors = true;
        s.errorMsg = Constants.orderError_OptionsMissing;
        containsError = true;
      }
      else
        s.containsServiceMetadataDataErrors = false;
    });

    var existingSpots = this.group.spotData;
    noMedia = (existingSpots == null || existingSpots.length == 0);

    if (noMedia == true && containsNotificationService == false)
      containsError = true;

    existingSpots.forEach(sd => {

      if (sd.spotFile.format.toLowerCase() == 'audio')
        isAudioSpotsExist = true;
      if (sd.spotFile.format.toLowerCase() != 'audio' && sd.spotFile.format.toLowerCase() != '' && sd.spotFile.format.toLowerCase() != 'unknown')
        isVideoSpotsExist = true;

      sd.errorMsgSpot = '';
      sd.containsSpotErrors = false;

      if (sd.serviceData.length == 0) {
        containsError = true;
        containsGroupServiceErrors = true;
      }

      if (sd.spotFile.isArchived == true && sd.serviceData.find(serv => serv.service.isArchivedAssetAllowed == false) != null) {
        sd.containsSpotErrors = true;
        containsError = true;
        sd.errorMsgSpot = Constants.orderError_AssetArchived;
        containsGroupAdIdErrors = true;
      }

      if (sd.spotFile.isExpired == true && sd.serviceData.find(serv => serv.service.isExpiredAssetAllowed == false) != null) {
        sd.containsSpotErrors = true;
        sd.errorMsgSpot = Constants.orderError_AssetExpired;
        containsError = true;
        containsGroupAdIdErrors = true;
      }

      if (sd.spotFile.status.toLowerCase() == 'qc fail')
        containsAdIDWarnings = true;

      if (sd.spotFile.isActive == false) {
        sd.containsSpotErrors = true;
        sd.errorMsgSpot = Constants.orderError_AssetInActive;
        containsError = true;
        containsGroupAdIdErrors = true;
      }

      sd.containsPpSpotAdIdErrors = false;
      sd.containsPpSpotTitleErrors = false;
      sd.errorMsgPpSpotAdId = '';
      sd.errorMsgPpSpotTitle = '';

      if (sd.ppSpotFile != null) {

        if (fromSubmit == false) {
          sd.ppSpotFile.adIdInValid = false;
          sd.ppSpotFile.titleInValid = false;
        }

        if (sd.ppSpotFile.adId != null
          && sd.ppSpotFile.title != null
          && sd.spotFile.adId.toLowerCase() == sd.ppSpotFile.adId.toLowerCase()
          && sd.spotFile.title.toLowerCase() == sd.ppSpotFile.title.toLowerCase()) {
          sd.ppSpotFile.adIdInValid = true;
          sd.ppSpotFile.titleInValid = true;
        }

        if (sd.ppSpotFile.adIdInValid == true) {
          containsError = true;
          containsGroupAdIdErrors = true;
          sd.containsPpSpotAdIdErrors = true;
          sd.errorMsgPpSpotAdId = sd.errorMsgPpSpotAdId + Constants.orderError_AdIdInvalid;
        }

        if (sd.ppSpotFile.titleInValid == true) {
          containsError = true;
          containsGroupTitleErrors = true;
          sd.containsPpSpotTitleErrors = true;
          sd.errorMsgPpSpotTitle = sd.errorMsgPpSpotTitle + Constants.orderError_TitleInvalid;
        }
      }

      sd.serviceData.forEach(s => {

        //if (sd.isDeliveryExists)
        //  containsDeliveryService = true;

        if (sd.serviceData.findIndex(t => t.service.isDigital === true && t.service.category.toLowerCase() === "digital delivery") >= 0)
          containsDeliveryService = true;

        if (sd.serviceData.findIndex(t => t.service.isFtp === true) >= 0)
          containsFtpService = true;

        if (sd.serviceData.findIndex(t => t.service.isClearance === true) >= 0)
          containsClearanceService = true;

        if (sd.serviceData.findIndex(t => t.service.assignVendorWithNoDestination === true) >= 0)
          containsOtherMiscService = true;

        if (sd.serviceData.findIndex(t => t.service.isNotification === true) >= 0)
          containsNotificationService = true;

        if (sd.serviceData.findIndex(t => t.service.isExpiredAssetAllowed === true) >= 0)
          containsExpiredAssetAllowedService = true;

        s.errorMsg = '';

        if (s.service.isAdditionalDataNeeded == true && this.util.isServiceMetadataValid(s.service.code, s.serviceMetadata, false, false, true, (sd.spotFile.format == 'audio'), this.config.orderActions.showSpecialFlags) == false) {
          s.containsServiceMetadataDataErrors = true;
          s.errorMsg = Constants.orderError_OptionsMissing;
          containsError = true;
        }
        else
          s.containsServiceMetadataDataErrors = false;
      });

      if (sd.containsPpSpotAdIdErrors == true || sd.containsSpotErrors == true)
        sd.containsAdIdErrors = true;
      else
        sd.containsAdIdErrors = false;

      if (sd.containsPpSpotTitleErrors == true)
        sd.containsTitleErrors = true;
      else
        sd.containsTitleErrors = false;

      if (sd.serviceData.some(t => t.containsServiceMetadataDataErrors == true)) {
        sd.containsSpotServiceDataErrors = true;
        containsError = true;
        containsGroupServiceErrors = true;
      }
      else
        sd.containsSpotServiceDataErrors = false;
    });

    this.group.spotData = existingSpots;

    var allNetworkDestinations = this.group.destinations;
    var shareLinkDestinations = this.group.shareLinks;
    var ftpDestinations = this.group.ftpDestinations;

    /**tylie File Distribution changes**/
    //var digitalNetworkDestinations = allNetworkDestinations.filter(t => t.fileDeliveryType == null || t.fileDeliveryType == '' || t.fileDeliveryType.toUpperCase() == 'BILLBOARD');
    //var radioNetworkDestinations = allNetworkDestinations.filter(t => t.fileDeliveryType != null && t.fileDeliveryType.toUpperCase() == 'RADIO');
    //var clearanceNetworkDestinations = allNetworkDestinations.filter(t => t.fileDeliveryType != null && t.fileDeliveryType.toUpperCase() == 'CLEARANCE');
    //var trafficOnlyNetworkDestinations = allNetworkDestinations.filter(t => t.fileDeliveryType != null && t.fileDeliveryType.toUpperCase() == 'TRAFFICONLY');
    var digitalNetworkDestinations = allNetworkDestinations.filter(t => t.isFTP == false && t.isShareLinks == false && t.isEmailAddressableOnly == false && t.isTrafficOnly == false && t.destinationType.toUpperCase() != 'CLEARANCE' && t.destinationType.toUpperCase() != 'RADIO');
    var radioNetworkDestinations = allNetworkDestinations.filter(t => t.destinationType != null && t.destinationType.toUpperCase() == 'RADIO');
    var clearanceNetworkDestinations = allNetworkDestinations.filter(t => t.destinationType != null && t.destinationType.toUpperCase() == 'CLEARANCE');
    var trafficOnlyNetworkDestinations = allNetworkDestinations.filter(t => t.isTrafficOnly == true);

    //Billboard can be sent to Network/Radio/BillBoard/Traffic destinations
    //Traffic/SpotExpiration can be sent to Network/Radio/BillBoard/Traffic destinations
    //Billboard can be mixed with other delivery services
    //Traffic/SpotExpiration cannot be mixed with other delivery services

    //Clearance service cannot be combined with other services in the same group
    if ((containsDeliveryService || containsFtpService || containsNotificationService || containsOtherMiscService) && containsClearanceService) {

      this.util.addError(this.serviceErrors, Constants.orderError_USClearanceServiceCombination);

      containsError = true;

      inValidServiceCombinationExists = true;
    }
    else
      this.serviceErrors = this.util.removeError(this.serviceErrors, Constants.orderError_USClearanceServiceCombination);

    //If there are any traffic only destinations then it cannot be combined with other spot deliveries
    if (trafficOnlyNetworkDestinations.length > 0 && (containsDeliveryService || containsFtpService)) {

      var trafficOnlyDests = trafficOnlyNetworkDestinations.map(o => o.code).join(', ');

      this.serviceErrors = this.util.removeErrorStartingWith(this.serviceErrors, Constants.orderError_TrafficOnlyDestinationCombination);

      this.util.addError(this.serviceErrors, Constants.orderError_TrafficOnlyDestinationCombination + ' (' + trafficOnlyDests + ')');

      containsError = true;

      inValidServiceDestinationCombinationExists = true;
    }
    else
      this.serviceErrors = this.util.removeErrorStartingWith(this.serviceErrors, Constants.orderError_TrafficOnlyDestinationCombination);

    //Audio & Video spots cannot be in the same group but allowed only for FTP
    if (isAudioSpotsExist && isVideoSpotsExist && (containsDeliveryService || containsNotificationService)) {

      this.util.addError(this.serviceErrors, Constants.orderError_AudioVideoCombination);

      containsError = true;

      inValidAudioVideoCombinationSpotsExists = true;
    }
    else
      this.serviceErrors = this.util.removeError(this.serviceErrors, Constants.orderError_AudioVideoCombination);

    if (!inValidServiceCombinationExists && !inValidServiceDestinationCombinationExists && !inValidAudioVideoCombinationSpotsExists) {

      //Digital destinations or sharedlinks needed for delivery service & Digital destinations or traffic only destinations needed for notification services like traffic
      if ((digitalNetworkDestinations.length == 0 && shareLinkDestinations.length == 0 && radioNetworkDestinations.length == 0 && containsDeliveryService)
        || (digitalNetworkDestinations.length == 0 && trafficOnlyNetworkDestinations.length == 0 && radioNetworkDestinations.length == 0 && shareLinkDestinations.length == 0 && containsNotificationService && !containsExpiredAssetAllowedService)) {

        this.util.addError(this.destinationErrors, Constants.orderError_NoDigitalDestinations);

        containsError = true;
      }
      else
        this.destinationErrors = this.util.removeError(this.destinationErrors, Constants.orderError_NoDigitalDestinations);

      //ftp destinations needed for ftp service
      if (ftpDestinations.length == 0 && containsFtpService) {

        this.util.addError(this.destinationErrors, Constants.orderError_NoFTPDestinations);

        containsError = true;
      }
      else
        this.destinationErrors = this.util.removeError(this.destinationErrors, Constants.orderError_NoFTPDestinations);

      //clearance destinations or sharedlinks needed for clearance service
      if (clearanceNetworkDestinations.length == 0 && shareLinkDestinations.length == 0 && containsClearanceService) {
        this.util.addError(this.destinationErrors, Constants.orderError_NoClrDestinations);

        containsError = true;
      }
      else
        this.destinationErrors = this.util.removeError(this.destinationErrors, Constants.orderError_NoClrDestinations);

      allNetworkDestinations.forEach(des => {
        des.containsDestinationError = false;
        des.errorMsg = '';

        if (des.destinationFormatInCompatible == true) {
          containsError = true;
          des.containsDestinationError = true;
          des.errorMsg = des.errorMsg + des.destinationFormatErrors;

          des.destinationFormatInCompatible = false;
          des.destinationFormatErrors = "";
        }

        if (containsDeliveryService == false && containsNotificationService == false) {
          /**tylie File Distribution changes**/
          //if ((des.fileDeliveryType == null || des.fileDeliveryType == '') || (des.fileDeliveryType != null && des.fileDeliveryType.toUpperCase() == 'RADIO')) {
          if (des.isFTP == false && des.isShareLinks == false && des.isTrafficOnly == false && des.isEmailAddressableOnly == false && des.destinationType.toUpperCase() != 'BILLBOARD' && des.destinationType.toUpperCase() != 'CLEARANCE') {
            containsError = true;
            containsGroupDestinationErrors = true;

            des.containsDestinationError = true;
            des.errorMsg = des.errorMsg + Constants.orderError_NoRelevantService;
          }
        }

        if (containsNotificationService == false) {
          /**tylie File Distribution changes**/
          //if (des.fileDeliveryType != null && des.fileDeliveryType.toUpperCase() == 'TRAFFICONLY') {
          if (des.isTrafficOnly) {
            containsError = true;
            containsGroupDestinationErrors = true;

            des.containsDestinationError = true;
            des.errorMsg = des.errorMsg + Constants.orderError_NoRelevantService;
          }
        }

        /**tylie File Distribution changes**/
        //if (des.fileDeliveryType != null && des.fileDeliveryType.toUpperCase() == 'RADIO' && isVideoSpotsExist) {
        if (des.destinationType != null && des.destinationType.toUpperCase() == 'RADIO' && isVideoSpotsExist) {
          containsError = true;
          containsGroupDestinationErrors = true;

          des.containsDestinationError = true;
          des.errorMsg = des.errorMsg + Constants.orderError_DestinationAudioSpotOnly;
        }

        /**tylie File Distribution changes**/
        //if ((des.fileDeliveryType == null || des.fileDeliveryType == '') && isAudioSpotsExist) {
        if ((des.isFTP == false && des.isShareLinks == false && des.isTrafficOnly == false && des.isEmailAddressableOnly == false && des.destinationType.toUpperCase() != 'BILLBOARD'
          && des.destinationType.toUpperCase() != 'CLEARANCE' && des.destinationType.toUpperCase() != 'RADIO'
          && des.destinationType.toUpperCase() != 'INTERNATIONAL') && isAudioSpotsExist) {
          containsError = true;
          containsGroupDestinationErrors = true;

          des.containsDestinationError = true;
          des.errorMsg = des.errorMsg + Constants.orderError_DestinationVideoSpotOnly;
        }
        /**tylie File Distribution changes**/
        //if (des.fileDeliveryType != null && des.fileDeliveryType.toUpperCase() == 'CLEARANCE' && containsClearanceService == false) {
        if (des.destinationType != null && des.destinationType.toUpperCase() == 'CLEARANCE' && containsClearanceService == false) {
          containsError = true;
          containsGroupDestinationErrors = true;

          des.containsDestinationError = true;
          des.errorMsg = des.errorMsg + Constants.orderError_NoRelevantService;
        }

        if (des.isActive == false) {

          containsError = true;
          containsGroupDestinationErrors = true;

          des.containsDestinationError = true;
          des.errorMsg = des.errorMsg + Constants.orderError_DestinationInActive;
        }
      });

      shareLinkDestinations.forEach(shr => {
        shr.containsDestinationError = false;
        shr.errorMsg = '';

        if (containsDeliveryService == false && containsClearanceService == false && containsNotificationService == false) {

          containsError = true;
          containsGroupDestinationErrors = true;

          shr.containsDestinationError = true;
          shr.errorMsg = shr.errorMsg + Constants.orderError_NoRelevantService;
        }
      });

      ftpDestinations.forEach(shr => {
        shr.containsDestinationError = false;
        shr.errorMsg = '';

        if (containsFtpService == false) {

          containsError = true;
          containsGroupDestinationErrors = true;

          shr.containsDestinationError = true;
          shr.errorMsg = shr.errorMsg + Constants.orderError_NoRelevantService;
        }
      });
    }
    else {
      this.destinationErrors = this.util.removeError(this.destinationErrors, Constants.orderError_NoClrDestinations);
      this.destinationErrors = this.util.removeError(this.destinationErrors, Constants.orderError_NoFTPDestinations);
      this.destinationErrors = this.util.removeError(this.destinationErrors, Constants.orderError_NoDigitalDestinations);

      allNetworkDestinations.forEach(des => {
        des.containsDestinationError = false;
        des.errorMsg = ''
      });

      shareLinkDestinations.forEach(des => {
        des.containsDestinationError = false;
        des.errorMsg = ''
      });

      ftpDestinations.forEach(des => {
        des.containsDestinationError = false;
        des.errorMsg = ''
      });
    }

    digitalNetworkDestinations.forEach(dest => {
      if (dest.isAdditionalInfoRequired == true) {
        existingSpots.forEach(sd => {
          sd.serviceData.forEach(s => {

            if (sd.serviceData.findIndex(t => t.service.isDigital === true && t.service.category.toLowerCase() === "digital delivery") >= 0) {

              let errExists: boolean = true;

              if (sd.destinationAddlInfo != null) {
                var destInfo = sd.destinationAddlInfo.find(t => t.destinationCode == dest.code && dest.fileDeliveryType == t.fileDeliveryType);

                if (destInfo != null)
                  errExists = !this.util.isDestinationAddlInfoValid(dest.fileDeliveryType, destInfo.addlInfoMetadata);
              }

              if (errExists) {
                dest.containsDestinationError = true;
                containsError = true;
                containsGroupDestinationErrors = true;

                let err: string = `${dest.fileDeliveryType} data is missing for one or more Assets`;

                if (dest.errorMsg.indexOf(err) < 0)
                  dest.errorMsg = dest.errorMsg + err;
              }
            }
          });
        });
      }
    });

    if (this.groupErrors.length > 0 && fromSubmit == true)
      containsError = true;
    else
      this.groupErrors = [];

    this.group.containsGroupErrors = containsError;
    this.group.containsGroupServiceErrors = containsGroupServiceErrors;
    this.group.containsAdIDWarnings = containsAdIDWarnings;
    this.group.containsGroupAdIdErrors = containsGroupAdIdErrors;
    this.group.containsGroupTitleErrors = containsGroupTitleErrors;
    this.group.containsGroupDestinationErrors = containsGroupDestinationErrors;
    this.group.containsGroupServiceLevelErrors = containsGroupServiceLevelErrors;
  }

  private toSpotServiceData(item: SpotFile): OrderDetailSpotServiceData {
    var newSpotData = new OrderDetailSpotServiceData();
    newSpotData.spotFile = item;
    newSpotData.ppSpotFile = null;
    newSpotData.spotFileGuid = item.spotFileGuid;
    newSpotData.serviceData = [];
    return newSpotData;
  }

  public onDownloadFile(orderFileGuid: string, fileName: string) {

    if (orderFileGuid == null || orderFileGuid == '00000000-0000-0000-0000-000000000000' || orderFileGuid == '')
      return;

    this.orderService.downloadOrderFile(orderFileGuid)
      .subscribe((res: any) => {
        if (res.isSuccess == true) {
          var presignedUrl = res.result;
          this.util.downloadFile(this.util.getFileUrl(presignedUrl)).subscribe(
            fileData => {
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.href = window.URL.createObjectURL(fileData);
              a.download = fileName;
              a.click();
            });
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error);
        });
  }

  public showDetail(spotFileGuid: string) {
    var initialState = {
      spotFileIdentifier: spotFileGuid,
    };

    this.modalRef = this.modalService.show(MediaDetailPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  public onAddEditDestinationInfo(destinationCode: string) {

    let existingSpots: Array<OrderDetailSpotServiceData> = this.group.spotData as Array<OrderDetailSpotServiceData>;
    let existingDestinations = this.group.destinations as Array<OrderDestination>;
    let destination = existingDestinations.find(t => t.code.toLowerCase() == destinationCode.toLowerCase());

    let spotDestinationAddlInfo: SpotDestinationAddlInfo = { sets: [], destination: destination };

    existingSpots.forEach(spotItem => {
      if (spotItem.isDeliveryExists) {

        if (spotItem.destinationAddlInfo == null)
          spotItem.destinationAddlInfo = [];

        let destinationAddlInfo = spotItem.destinationAddlInfo.find(t => t.destinationCode.toLowerCase() == destination.code.toLowerCase() && t.fileDeliveryType.toLowerCase() == destination.fileDeliveryType.toLowerCase());

        if (destinationAddlInfo == null)
          spotDestinationAddlInfo.sets.push({ spotFile: spotItem.spotFile, addlInfoMetadata: [] });
        else {
          let metadata: Array<DestinationAddlInfoMetadata> = [];

          destinationAddlInfo.addlInfoMetadata.forEach(t => {
            metadata.push({ metadataKey: t.metadataKey, metadataValue: t.metadataValue });
          });

          spotDestinationAddlInfo.sets.push({ spotFile: spotItem.spotFile, addlInfoMetadata: metadata });
        }
      }
    });

    var initialState = {
      spotDestinationAddlInfo: spotDestinationAddlInfo,
      readOnlyMode: !(this.config.orderActions.showInEditMode && this.config.orderActions.canAddDestination)
    };

    this.modalRef = this.modalService.show(OrderDestinationInfoPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));

    this.modalRef.content.onAddlInfoSubmitted.subscribe(result => {
      this.onSubmitAddlInfoRaised(result);
    });
  }

  private getTranscodeSpecsByDestinationCodeForAllSpots(destCode: string) {
    this.adminService.getTranscodeSpecsByDestinationCode({ "destinationCode": destCode }).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var specs = res.result as OrderDestinationTranscodeModel[];
        console.log(specs);
        // get all spots in grp and add transcodes
        var existingSpots = this.group.spotData;

        if (existingSpots != null && specs != null && specs.length > 0) {
          var transcodes = [];

          var transcodeData = this.group.transcodeRequest;

          var tRequest = {
            selectionType: "different", spotFilesData: [], spotFiles: [], spotFileTranscodes: [], spotFileNaming: [],
            fileNameOptions: [], spotFileTranscodesData: new Map(), spotFileNamingData: new Map(), customFileName: "",
            customFileNaming:[]
          } as TranscodeMediaOrderRequest;

          specs.forEach(spec => {
            transcodes.push(spec.spec);
          });

          existingSpots.forEach(sp => {
            if (sp.isDeliveryExists || sp.serviceData.some(t => t.service.isFtp == true)) {
              if (transcodeData == null) {
                tRequest.spotFilesData.push(sp.spotFile);
                tRequest.spotFiles.push(sp.spotFileGuid);

                var mediaTranscode = { spotFileGuid: sp.spotFileGuid, transcodes: transcodes } as MediaTranscode;
                var mediaTranscodeFileNaming = { spotFileGuid: sp.spotFileGuid, fileNaming: specs[0].transcodeFileMetaData } as MediaTranscodeFileNaming;

                tRequest.fileNameOptions = specs[0].transcodeFileMetaData.fileNameOptions
                tRequest.spotFileTranscodes.push(mediaTranscode);
                tRequest.spotFileNaming.push(mediaTranscodeFileNaming);
                tRequest.spotFileTranscodesData.set(sp.spotFileGuid, transcodes);
                tRequest.spotFileNamingData.set(sp.spotFileGuid, specs[0].transcodeFileMetaData);

                this.group.transcodeRequest = tRequest;
              }
              else {
                if (transcodeData.spotFiles.includes(sp.spotFileGuid)) {
                  transcodeData.spotFileTranscodes.forEach(tr => {
                    if (tr.spotFileGuid == sp.spotFileGuid) {
                      var transcodesSp = tr.transcodes;

                      specs.forEach(spec => {
                        if (transcodesSp.find(specname => specname.toLowerCase() == spec.spec.toLowerCase()) == undefined) {
                          transcodesSp.push(spec.spec);
                        }
                      });
                      tr.transcodes = transcodesSp;
                      transcodeData.spotFileTranscodesData.set(sp.spotFileGuid, transcodesSp);
                    }

                  });
                }
                else {
                  transcodeData.spotFilesData.push(sp.spotFile);
                  transcodeData.spotFiles.push(sp.spotFileGuid);

                  var mediaTranscode = { spotFileGuid: sp.spotFileGuid, transcodes: transcodes } as MediaTranscode;
                  var mediaTranscodeFileNaming = { spotFileGuid: sp.spotFileGuid, fileNaming: specs[0].transcodeFileMetaData } as MediaTranscodeFileNaming;

                  transcodeData.fileNameOptions = specs[0].transcodeFileMetaData.fileNameOptions
                  transcodeData.spotFileTranscodes.push(mediaTranscode);
                  transcodeData.spotFileNaming.push(mediaTranscodeFileNaming);
                  transcodeData.spotFileTranscodesData.set(sp.spotFileGuid, transcodes);
                  transcodeData.spotFileNamingData.set(sp.spotFileGuid, specs[0].transcodeFileMetaData);
                }
              }
            }
          });
        }
      }
      else {
        this.alertService.showMessage("WARNING", "Couldn't add destination transcodes for : " + destCode + "Please add manually.", MessageSeverity.warn);
      }
    },
      error => {
        this.alertService.showMessage("WARNING", "Couldn't add destination transcodes for : " + destCode + "Please add manually.", MessageSeverity.warn);
      });
  }

  private getTranscodeSpecsByDestinationCodePerSpot(destCode: string, spotFileGuid: string, spotData: SpotFile) {
    this.adminService.getTranscodeSpecsByDestinationCode({ "destinationCode": destCode }).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var specs = res.result as OrderDestinationTranscodeModel[];
        console.log(specs);
        console.log(spotData);
        // get all spots in grp and add transcodes

        if (specs != null && specs.length > 0) {
          var transcodeData = this.group.transcodeRequest;

          var tRequest = {
            selectionType: "different", spotFilesData: [], spotFiles: [], spotFileTranscodes: [], spotFileNaming: [],
            fileNameOptions: [], spotFileTranscodesData: new Map(), spotFileNamingData: new Map(), customFileName: "",
            customFileNaming:[]
          } as TranscodeMediaOrderRequest

          var transcodes: string[] = [];

          specs.forEach(spec => {
            transcodes.push(spec.spec);
          });

          if (transcodeData == null) {
            tRequest.spotFilesData.push(spotData);
            tRequest.spotFiles.push(spotFileGuid);

            var mediaTranscode = { spotFileGuid: spotFileGuid, transcodes: transcodes } as MediaTranscode;
            var mediaTranscodeFileNaming = { spotFileGuid: spotFileGuid, fileNaming: specs[0].transcodeFileMetaData } as MediaTranscodeFileNaming;

            tRequest.fileNameOptions = specs[0].transcodeFileMetaData.fileNameOptions
            tRequest.spotFileTranscodes.push(mediaTranscode);
            tRequest.spotFileNaming.push(mediaTranscodeFileNaming);
            tRequest.spotFileTranscodesData.set(spotFileGuid, transcodes);
            tRequest.spotFileNamingData.set(spotFileGuid, specs[0].transcodeFileMetaData);

            this.group.transcodeRequest = tRequest;
          }
          else {
            if (transcodeData.spotFiles.includes(spotFileGuid)) {
              transcodeData.spotFileTranscodes.forEach(tr => {
                if (tr.spotFileGuid == spotFileGuid) {
                  var transcodesSp = tr.transcodes;

                  specs.forEach(spec => {
                    if (transcodesSp.find(specname => specname.toLowerCase() == spec.spec.toLowerCase()) == undefined) {
                      transcodesSp.push(spec.spec);
                    }
                  });
                  transcodeData.spotFileTranscodesData.set(spotFileGuid, transcodesSp);
                  tr.transcodes = transcodesSp;
                }
              });
            }
            else {
              transcodeData.spotFilesData.push(spotData);
              transcodeData.spotFiles.push(spotFileGuid);

              var mediaTranscode = { spotFileGuid: spotFileGuid, transcodes: transcodes } as MediaTranscode;
              var mediaTranscodeFileNaming = { spotFileGuid: spotFileGuid, fileNaming: specs[0].transcodeFileMetaData } as MediaTranscodeFileNaming;

              transcodeData.fileNameOptions = specs[0].transcodeFileMetaData.fileNameOptions
              transcodeData.spotFileTranscodes.push(mediaTranscode);
              transcodeData.spotFileNaming.push(mediaTranscodeFileNaming);
              transcodeData.spotFileTranscodesData.set(spotFileGuid, transcodes);
              transcodeData.spotFileNamingData.set(spotFileGuid, specs[0].transcodeFileMetaData);
            }
          }
        }
      }
      else {
        this.alertService.showMessage("WARNING", "Couldn't add destination transcodes for : " + destCode + "Please add manually.", MessageSeverity.warn);
      }
    },
      error => {
        this.alertService.showMessage("WARNING", "Couldn't add destination transcodes for : " + destCode + "Please add manually.", MessageSeverity.warn);
      });
  }

  private removeDestinationSpecsFromSpot(destCode: string, spotFileGuid: string) {
    this.adminService.getTranscodeSpecsByDestinationCode({ "destinationCode": destCode }).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var specs = res.result as OrderDestinationTranscodeModel[];
        console.log(specs);

        var transcodeData = this.group.transcodeRequest;

        if (transcodeData != null) {
          var transcodeDataSpotTranscodes = transcodeData.spotFileTranscodes;

          var transcodes: string[] = [];

          specs.forEach(spec => {
            transcodes.push(spec.spec);
          });

          if (transcodeData.spotFiles.includes(spotFileGuid)) {
            transcodeDataSpotTranscodes.forEach(tr => {
              if (tr.spotFileGuid == spotFileGuid) {
                var transcodesSp = tr.transcodes;
                transcodesSp = transcodesSp.filter(t => transcodes.includes(t) == false);
                console.log(transcodesSp)
                console.log(transcodesSp.length)
                if (transcodesSp.length > 0) {
                  transcodeData.spotFileTranscodesData.set(spotFileGuid, transcodesSp);
                  var spTranscode = transcodeData.spotFileTranscodes.find(sptData => sptData.spotFileGuid == spotFileGuid);
                  if (spTranscode != undefined) {
                    spTranscode.transcodes = transcodesSp;
                  }
                }
                else {
                  transcodeData.spotFileTranscodesData.delete(spotFileGuid);
                  transcodeData.spotFileNamingData.delete(spotFileGuid);
                  transcodeData.spotFiles = transcodeData.spotFiles.filter(spGuid => spGuid != spotFileGuid);
                  transcodeData.spotFilesData = transcodeData.spotFilesData.filter(spData => spData.spotFileGuid != spotFileGuid);
                  transcodeData.spotFileNaming = transcodeData.spotFileNaming.filter(spNaming => spNaming.spotFileGuid != spotFileGuid);
                  transcodeData.spotFileTranscodes = transcodeData.spotFileTranscodes.filter(sptData => sptData.spotFileGuid != spotFileGuid);
                  console.log(transcodeData.spotFileTranscodes)
                }
              }
            });
            console.log(transcodeData);
          }
        }
      }
      else {
        this.alertService.showMessage("WARNING", "Couldn't remove destination transcodes for : " + destCode + "Please remove manually.", MessageSeverity.warn);
      }
    },
      error => {
        this.alertService.showMessage("WARNING", "Couldn't remove destination transcodes for : " + destCode + "Please remove manually.", MessageSeverity.warn);
      });
  }

  private removeDestinationSpecsForAllSpots(destCode: string) {
    this.adminService.getTranscodeSpecsByDestinationCode({ "destinationCode": destCode }).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var specs = res.result as OrderDestinationTranscodeModel[];
        console.log(specs);

        var transcodeData = this.group.transcodeRequest;

        var transcodes: string[] = [];
        if (transcodeData != null) {
          specs.forEach(spec => {
            transcodes.push(spec.spec);
          });

          var existingSpots = this.group.spotData;

          if (existingSpots != null) {
            existingSpots.forEach(sp => {
              if (sp.isDeliveryExists || sp.serviceData.some(t => t.service.isFtp == true)) {
                transcodeData.spotFileTranscodes.forEach(tr => {
                  if (tr.spotFileGuid == sp.spotFileGuid) {
                    var transcodesSp = tr.transcodes;
                    transcodesSp = transcodesSp.filter(t => transcodes.includes(t) == false);

                    if (transcodesSp.length > 0) {
                      transcodeData.spotFileTranscodesData.set(sp.spotFileGuid, transcodesSp);
                      var spTranscode = transcodeData.spotFileTranscodes.find(sptData => sptData.spotFileGuid == sp.spotFileGuid);
                      if (spTranscode != undefined) {
                        spTranscode.transcodes = transcodesSp;
                      }
                    }
                    else {
                      transcodeData.spotFileTranscodesData.delete(sp.spotFileGuid);
                      transcodeData.spotFileNamingData.delete(sp.spotFileGuid);
                      transcodeData.spotFiles = transcodeData.spotFiles.filter(spGuid => spGuid != sp.spotFileGuid);
                      transcodeData.spotFilesData = transcodeData.spotFilesData.filter(spData => spData.spotFileGuid != sp.spotFileGuid);
                      transcodeData.spotFileNaming = transcodeData.spotFileNaming.filter(spNaming => spNaming.spotFileGuid != sp.spotFileGuid);
                      transcodeData.spotFileTranscodes = transcodeData.spotFileTranscodes.filter(sptData => sptData.spotFileGuid != sp.spotFileGuid);
                    }
                  }
                });
              }
            });
          }
        }
      }
      else {
        this.alertService.showMessage("WARNING", "Couldn't remove destination transcodes for : " + destCode + "Please remove manually.", MessageSeverity.warn);
      }
    },
      error => {
        this.alertService.showMessage("WARNING", "Couldn't remove destination transcodes for : " + destCode + "Please remove manually.", MessageSeverity.warn);
      });
  }

  private onSubmitAddlInfoRaised(spotDestinationAddlInfo: SpotDestinationAddlInfo) {
    console.log("onSubmitDestinationInfoRaised", spotDestinationAddlInfo);

    let existingSpots: Array<OrderDetailSpotServiceData> = this.group.spotData as Array<OrderDetailSpotServiceData>;

    spotDestinationAddlInfo.sets.forEach(addlInfo => {
      let spotData = existingSpots.find(t => t.spotFileGuid.toLowerCase() == addlInfo.spotFile.spotFileGuid.toLowerCase());

      if (spotData.destinationAddlInfo == null)
        spotData.destinationAddlInfo = [];

      let destinationAddlInfo = spotData.destinationAddlInfo.find(t => t.destinationCode.toLowerCase() == spotDestinationAddlInfo.destination.code.toLowerCase() && t.fileDeliveryType.toLowerCase() == spotDestinationAddlInfo.destination.fileDeliveryType.toLowerCase());

      if (destinationAddlInfo == null) {
        let newDestinationAddlInfo: DestinationAddlInfo = { addlInfoMetadata: [], destinationCode: spotDestinationAddlInfo.destination.code, fileDeliveryType: spotDestinationAddlInfo.destination.fileDeliveryType };

        addlInfo.addlInfoMetadata.forEach(dt => {
          newDestinationAddlInfo.addlInfoMetadata.push({ metadataKey: dt.metadataKey, metadataValue: dt.metadataValue });
        });

        spotData.destinationAddlInfo.push(newDestinationAddlInfo);
      }
      else {
        destinationAddlInfo.addlInfoMetadata = [];

        addlInfo.addlInfoMetadata.forEach(dt => {
          destinationAddlInfo.addlInfoMetadata.push({ metadataKey: dt.metadataKey, metadataValue: dt.metadataValue });
        });
      }
    });

    let existingDestinations = this.group.destinations as Array<OrderDestination>;
    let destination = existingDestinations.find(t => t.code == spotDestinationAddlInfo.destination.code);

    destination.applyAddlInfoToAllAssets = spotDestinationAddlInfo.destination.applyAddlInfoToAllAssets;

    this.group.destinations = existingDestinations;

    this.group.spotData = existingSpots;

    this.setGroupError();
  }

  private checkForDestinationAddlInfo() {
    var existingSpots = this.group.spotData as Array<SpotServiceData>;
    var existsAny: boolean = false;

    existingSpots.forEach(spotData => {
      if (spotData.isDeliveryExists)
        existsAny = true;
    });

    this.anySpotDeliveryExists = existsAny;
  }

  private deleteSpotDestinationAddlInfo(destinationCode: string) {
    var existingSpots = this.group.spotData as Array<OrderDetailSpotServiceData>;

    existingSpots.forEach(t => {
      if (t.destinationAddlInfo != null)
        t.destinationAddlInfo = t.destinationAddlInfo.filter(t => t.destinationCode.toLowerCase() != destinationCode.toLowerCase());
    });

    this.removeDestinationSpecsForAllSpots(destinationCode);

    this.group.spotData = existingSpots;

    this.setGroupError();
  }

  public onTranscodeAssetDelete(spotFileGuid: string) {
    var grpTranscodeRequest = this.group.transcodeRequest;

    if (grpTranscodeRequest != null && grpTranscodeRequest.spotFilesData != null) {
      grpTranscodeRequest.spotFilesData = grpTranscodeRequest.spotFilesData.filter(s => s.spotFileGuid != spotFileGuid);
      grpTranscodeRequest.spotFiles = grpTranscodeRequest.spotFiles.filter(s => s != spotFileGuid);
      grpTranscodeRequest.spotFileTranscodes = grpTranscodeRequest.spotFileTranscodes.filter(s => s.spotFileGuid != spotFileGuid);
      grpTranscodeRequest.spotFileNaming = grpTranscodeRequest.spotFileNaming.filter(s => s.spotFileGuid != spotFileGuid);

      if (grpTranscodeRequest.spotFileTranscodesData != null && grpTranscodeRequest.spotFileTranscodesData != undefined) {
        grpTranscodeRequest.spotFileTranscodesData.delete(spotFileGuid);
      }
      if (grpTranscodeRequest.spotFileNamingData != null && grpTranscodeRequest.spotFileNamingData != undefined) {
        grpTranscodeRequest.spotFileNamingData.delete(spotFileGuid);
      }

      if (grpTranscodeRequest.spotFilesData != null && grpTranscodeRequest.spotFilesData.length > 0) {
        this.group.transcodeRequest = grpTranscodeRequest;
      }
      else {
        this.group.transcodeRequest = null;
      }
    }
  }

  private getOLVProfilesForSpot(spotFileGuid: string) {
    // move this to nginit
    var olvProfiles = this.group.transcodeRequest.spotFileTranscodesData.get(spotFileGuid);
    return olvProfiles;
  }

  public gotoTranscodeFiles() {
    if (this.group.spotData == null || this.group.spotData.length <= 0 || this.getGroupVideoSpotsCount() <= 0) {
      this.alertService.showMessage("ERROR", Constants.selectMediaToApplyTranscodes, MessageSeverity.error);
      return;
    }


    var nonDistinctGrps = this.getNonDistinctSpotsInGroup();

    if (nonDistinctGrps.length > 0) {
      this.alertService.showMessage("ERROR", nonDistinctGrps.join(" ,") + " exists multiple times in the group.", MessageSeverity.error);
      return;
    }

    this.routeToTranscodeRequestsClicked.emit({ isSpotEdit: false, spotFileGuid: null });
  }

  public editGroupSpotMediaTranscode(spotFileGuid: string) {
    if (this.group.spotData == null || this.group.spotData.length <= 0 || this.getGroupVideoSpotsCount() <= 0) {
      this.alertService.showMessage("ERROR", Constants.selectMediaToApplyTranscodes, MessageSeverity.error);
      return;
    }

    this.routeToTranscodeRequestsClicked.emit({ isSpotEdit: true, spotFileGuid: spotFileGuid });
  }

  private getFileNamingForSpot(spotFileGuid: string, transcode: string) {

    var transcodeData = this.group.transcodeRequest as TranscodeMediaOrderRequest;
    var fileNamingData = transcodeData.spotFileNamingData.get(spotFileGuid);

    if (fileNamingData.fileNamingType == 'basic') {
      return 'Basic'
    }
    else {
      return this.showCustomFileNameOptions(fileNamingData, transcodeData, transcode, spotFileGuid);
    }
  }

  private showCustomFileNameOptions(fileNamingData: TranscodeFileNaming, transcodeData, transcode, spotFileGuid) {
    if (transcodeData.customFileNaming != null && transcodeData.customFileNaming != undefined && transcodeData.customFileNaming.length > 0) {
      var customFileName = this.getcustomSpecFileName(transcode, spotFileGuid, transcodeData);
      if (customFileName == null || customFileName == '') {
        var selectedFileNameOptions = fileNamingData.fileNameOptions as Array<FileNameOption>;
        var examples = [];

        selectedFileNameOptions.forEach(item => {
          examples.push(item.name);
        });

        if (examples.length == 0)
          examples.push("N/A");

        return examples.join(fileNamingData.fileSeperator);
      }
      else {
        return customFileName;
      }
    }
    else {
      var selectedFileNameOptions = fileNamingData.fileNameOptions as Array<FileNameOption>;
      var examples = [];

      selectedFileNameOptions.forEach(item => {
        examples.push(item.name);
      });

      if (examples.length == 0)
        examples.push("N/A");

      return examples.join(fileNamingData.fileSeperator);
    }
  }

  private getcustomSpecFileName(transcode, spotfileGuid, transcodeData) {
    if (transcodeData.customFileNaming != null) {
      var customNameObj = transcodeData.customFileNaming.find(t => t.spotFileGuid == spotfileGuid) != null ? transcodeData.customFileNaming.find(t => t.spotFileGuid == spotfileGuid).specFileNames : null;
      var transcodeFileName = customNameObj != null ? customNameObj.find(t => t.spec == transcode) : null;
      return transcodeFileName != null ? transcodeFileName.customSpecFileName : null;
    }
    else {
      return null;
    }
  }

  private getGroupVideoSpotsCount() {
    var grpSpots = this.group.spotData as Array<SpotServiceData>;

    var videoSpots = grpSpots.filter(t => t.spotFile.format.toLowerCase() != 'audio');

    return videoSpots.length;
  }

  private getNonDistinctSpotsInGroup() {
    var spotFileGuids = [];
    var nonDistinctSpotFiles = [];

    var grpSpots = this.group.spotData as Array<SpotServiceData>;

    var videoSpots = grpSpots.forEach(t => {
      if (spotFileGuids.find(s => s == t.spotFileGuid) == undefined) {
        spotFileGuids.push(t.spotFileGuid)
      }
      else {
        nonDistinctSpotFiles.push(t.spotFile.adId);
      }
    });

    return nonDistinctSpotFiles;
  }

  public stopTogglePropogation(e) {
    e.stopPropagation();
  }
}
