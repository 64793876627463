import { UploadAssetFormModel } from "./../../../models/upload/upload-asset-form.model";
import { Component, OnInit, ViewChild } from "@angular/core";
import { Utilities } from "../../../services/core/utilities";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { FormControl, FormGroup } from "@angular/forms";
import * as moment from "moment";
import {
  GroupModel,
  LocationModel,
  TaggerAssetModel,
  TaggerAssetSearchRequest,
  UpdateTaggerAssetStatusRequestModel,
  UploadStatusRequestViewModel,
} from "../../../models/tagger/tagger.model";
import {
  AlertService,
  MessageSeverity,
} from "../../../services/core/alert.service";
import { TaggerService } from "../../../services/tagger/tagger.service";
import { JsPlayerComponent } from "../../videoplayer/videojs/jsplayer.component";
import {
  AssetType,
  Statuses,
  TaggerAssetSort,
} from "../../../configurations/enums/enums";
import { UUID } from "angular2-uuid";
import { UploadTaggerFileRequest } from "../../../models/order/order-uploadfile.model";
import { AdminAssetUploadPopUpComponent } from "../../popups/admin-asset-upload-pop-up/admin-asset-upload-pop-up.component";
import { UploadVaultFileService } from "../../../services/vault/uploadvaultfile.service";
import { UploadVaultAssetFileRequest } from "../../../models/vault/vault-upload.model";
import { finalize } from "rxjs/operators";
import { Subject, Subscription } from "rxjs";
import { VaultFile } from "../../../models/vault/vault-create.model";
import { Constants } from "../../../configurations/constants";
import { SinglePartFileUploadService } from "../../../services/project/singlepart-file-upload-handler.service";
import { HttpClient, HttpEventType } from "@angular/common/http";
import {
  AdFileUploadQueue,
  AssetUploadQueue,
} from "../../../services/project/multipart-file-upload-handler.service";
import { ApiEndPoint } from "../../../configurations/api-endpoint.service";
import { AdFileS3UploadRequest } from "../../../models/upload/upload-request.model";
import { QuickplayerComponent } from "../../videoplayer/quickplayer/quickplayer.component";

@Component({
  selector: "app-tagger-asset",
  templateUrl: "./tagger-asset.component.html",
  styleUrls: ["./tagger-asset.component.css"],
})
export class TaggerAssetComponent implements OnInit {
  @ViewChild("asset_file", { static: true }) asset_file;

  public quickSearchValue: string = "";
  public oldQuickSearchValue: string = "";
  public selectedSortOrder: string = "";
  public modalRef: BsModalRef;
  public sortDataSource: any[];
  public searchRequestFormGroup: FormGroup;
  public assetTypeDataSource: any[];
  public groupDataSource: GroupModel[];
  public locationDataSource: LocationModel[];
  public allLocations: LocationModel[];
  public taggerAssets: TaggerAssetModel[];
  public acceptFileTypes: string[] = new Array(
    "image/png",
    "audio/mpeg",
    "audio/wav",
    "video/quicktime"
  );
  public reUploadAcceptFileTypes: string[] = new Array(
    "image/png",
    "audio/mpeg",
    "audio/wav",
    "video/quicktime"
  );
  public errorMsgs: string = "";
  public uploadedAssetFiles: UploadAssetFormModel[] = [];
  public unSupportAssetFiles: UploadAssetFormModel[] = [];

  currentS3UploadId = null;
  subscriptions: Subscription[] = [];
  public isUploading = false;
  fileUploadModel = {} as AdFileS3UploadRequest;
  public isUploadedAgain = false;

  constructor(
    public util: Utilities,
    private alertService: AlertService,
    private taggerService: TaggerService,
    private modalService: BsModalService,
    private singlePartUploadService: SinglePartFileUploadService,
    private http: HttpClient,
    private apiendPoint: ApiEndPoint
  ) {}

  ngOnInit() {
    this.sortDataSource = Object.keys(TaggerAssetSort).map((key) => ({
      id: key,
      name: TaggerAssetSort[key],
    }));

    this.selectedSortOrder = this.sortDataSource[0].id;

    this.assetTypeDataSource = Object.keys(AssetType).map((key) => ({
      id: AssetType[key],
      name: AssetType[key],
    }));

    this.searchRequestFormGroup = new FormGroup({
      assetType: new FormControl(AssetType.All),
      startDate: new FormControl(null),
      endDate: new FormControl(null),
      group: new FormControl(null),
      location: new FormControl(null),
      unassignedGroup: new FormControl(false),
      availableOnly: new FormControl(false),
      expiredOnly: new FormControl(false),
      featuredOnly: new FormControl(false),
    });

    this.getGroups();
    this.getLocations();
    this.searchTaggerAssets();

    this.searchRequestFormGroup.get("group").valueChanges.subscribe((value) => {
      if (value && value !== "") {
        const group = this.groupDataSource.find((g) => g.groupGuid === value);
        if (group) {
          this.locationDataSource = group.locations || [];
        }
      } else {
        this.locationDataSource = this.allLocations;
      }
      this.searchRequestFormGroup.get("location").setValue(null);
    });
  }

  public onRefresh() {
    this.quickSearchValue = "";
    this.selectedSortOrder = this.sortDataSource[0].id;
    this.searchRequestFormGroup.reset();
    this.searchTaggerAssets();
  }

  public getGroups() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.taggerService.getGroups().subscribe(
      (res: any) => {
        if (res.isSuccess == true) {
          this.groupDataSource = res.result;
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }

  public getLocations() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    this.taggerService.getLocations().subscribe(
      (res: any) => {
        if (res.isSuccess == true) {
          this.locationDataSource = res.result;
          this.allLocations = res.result;
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }

  public searchTaggerAssets() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    const payload: TaggerAssetSearchRequest = this.getSearchRequestPayload();
    this.taggerService.searchTaggerAssets(payload).subscribe(
      (res: any) => {
        console.log(res);
        if (res.isSuccess == true) {
          this.taggerAssets = res.result.searchResults;
          console.log(this.taggerAssets);
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }

  private getSearchRequestPayload() {
    const startDate =
      this.searchRequestFormGroup.get("startDate").value || null;
    const endDate = this.searchRequestFormGroup.get("endDate").value || null;

    const payload: TaggerAssetSearchRequest = {
      search: this.quickSearchValue,
      sortOrder: this.selectedSortOrder,
      assetType:
        this.searchRequestFormGroup.get("assetType").value || AssetType.All,
      startDate: (startDate && moment(startDate).toISOString()) || null,
      endDate: (endDate && moment(endDate).toISOString()) || null,
      group: this.searchRequestFormGroup.get("group").value || null,
      location: this.searchRequestFormGroup.get("location").value || null,
      unassignedGroup:
        this.searchRequestFormGroup.get("unassignedGroup").value || false,
      availableOnly:
        this.searchRequestFormGroup.get("availableOnly").value || false,
      expiredOnly:
        this.searchRequestFormGroup.get("expiredOnly").value || false,
      featuredOnly:
        this.searchRequestFormGroup.get("featuredOnly").value || false,
    };

    return payload;
  }

  public onSortValueChanged(e: any) {
    this.selectedSortOrder = e.value;
    this.searchTaggerAssets();
  }

  public onEnterQuickSearch(e: any) {
    this.quickSearch();
  }

  public quickSearch() {
    if (this.oldQuickSearchValue != this.quickSearchValue) {
      this.oldQuickSearchValue = this.quickSearchValue;
      this.searchTaggerAssets();
    }
  }

  clearSearch() {
    this.searchRequestFormGroup.reset();
    this.quickSearchValue = "";
    this.selectedSortOrder = this.sortDataSource[0].id;
    this.searchTaggerAssets();
  }

  clearQuickSearch() {
    if (this.quickSearchValue == "") {
      this.searchTaggerAssets();
    }
  }

  onFormSubmit({ value, valid }: { value: any; valid: boolean }) {
    // TODO:
    this.searchTaggerAssets();
  }

  public quickPlay(proxy, title, thumbnail, format) {
    if (proxy == null || proxy == "") return;

    const initialState = {
      videoSource: proxy,
      thumbnail: thumbnail,
      title: title,
      format: format,
    };

    this.modalRef = this.modalService.show(
      QuickplayerComponent,
      this.util.getModalComponentOptions(initialState, false, false, true)
    );
  }

  public statusClass(status: string) {
    let cls = "";
    if (status == Statuses.AvailableSoon) {
      cls += " ";
    } else if (status == Statuses.Current) {
      cls += " tylie-badge--success";
    } else if (status == Statuses.ExpireSoon) {
      cls += " tylie-badge--warning";
    } else if (status == Statuses.Expired) {
      cls += " tylie-badge--error";
    }
    return cls;
  }

  public statusClassPlaceholder(status: string, baseCreativeGuid) {
    let cls = "";

    if (this.util.taggingFileStatus && this.util.taggingFileStatus.length) {
      const taggerAssetGuid = baseCreativeGuid.split(".")[0];
      const fileStatus = this.util.taggingFileStatus.find((fileStatusItem) => {
        return (
          fileStatusItem.fileStatus &&
          fileStatusItem.fileStatus.some((fileStatus) =>
            fileStatus.file.startsWith(baseCreativeGuid)
          )
        );
      });

      if (fileStatus && fileStatus.fileStatus && fileStatus.fileStatus.length) {
        //console.log(fileStatus)
        var fileStatusList = fileStatus.fileStatus;
        var fileUpdatedStatus = fileStatusList.filter((fileStatus) =>
          fileStatus.file.startsWith(baseCreativeGuid)
        );

        //console.log(fileUpdatedStatus);

        const status =
          fileUpdatedStatus !== null && fileUpdatedStatus !== undefined
            ? fileUpdatedStatus[0].status
            : "failed";

        //console.log(fileUpdatedStatus[0]);
        console.log(status); // Output: status of the file
        console.log(baseCreativeGuid);

        // Add your logic to update the class based on the file status// filestatus has assetgui with ext. spliting here instead of in status pass method
        var displayStatus = status;
        if (status === "completed") {
          displayStatus = "UPLOADED";
          cls += " tylie-badge--success";
        } else if (status === "uploading" || status === "running") {
          displayStatus = "UPLOADING...";
          cls += " tylie-badge--warning";
        } else if (status === "Processing") {
          displayStatus = "PROCESSING...";
          cls += " tylie-badge--warning";
        } else if (status === "failed") {
          displayStatus = "UPLOAD FAILED";
          cls += " tylie-badge--error";
        }
      } else {
        //console.log("File not found in taggingFileStatus list");
        displayStatus = "NO STATUS";
        cls += " tylie-badge--error";
      }

      var currentAsset = this.taggerAssets.filter(
        (t) => t.assetGuid == taggerAssetGuid
      );

      if (
        currentAsset != null &&
        currentAsset != undefined &&
        currentAsset.length > 0
      ) {
        currentAsset[0].displayStatus = displayStatus;
      }
    } else {
      displayStatus = "NO STATUS";
      cls += " tylie-badge--error";
    }
    //console.log(displayStatus);
    return cls;
  }

  //public taggerNameStyle(taggerAsset: TaggerAssetModel) {
  //  let cls = '';
  //  if ((taggerAsset.assetType.toLowerCase() == AssetType.BaseCreative.toLowerCase() || taggerAsset.assetType.toLowerCase() == AssetType.VoiceOver.toLowerCase())
  //    && taggerAsset.isPlaceHolder && this.findDifferenceTimeHours(taggerAsset.createdDate) > 1) {
  //    cls += " tagger-asset-fialed-title";
  //  } else {
  //    cls += "tagger-asset-title";
  //  }
  //  return cls;
  //}

  //public taggerUploadFailed(taggerAsset: TaggerAssetModel) {
  //  if (taggerAsset.receievedDate == null && this.findDifferenceTimeHours(taggerAsset.createdDate) > 1) {
  //    return true;
  //  }
  //  return false;
  //}

  //public findDifferenceTimeHours(createTime: any) {
  //  let date1 = new Date(createTime);
  //  let date2 = new Date();
  //  date1.setTime(date1.getTime() - date1.getTimezoneOffset() * 60 * 1000);
  //  let time = date2.getTime() - date1.getTime();
  //  return time / (3600 * 1000);
  //}

  public onAttachFile(event: any) {
    console.log(event.target.files);
    this.uploadedAssetFiles = [];
    this.unSupportAssetFiles = [];
    if (event.target.files && event.target.files.length > 0) {
      if (!event.target.files.length) {
        return;
      } else {
        var connectorId = Math.floor(Math.random() * 10000 + 1).toString();
        this.util.taggingFileStatus.push({
          id: connectorId,
          status: "scheduled",
          groupHeader: moment().format("MM/DD/YYYY HH:mm:ss").toString(),
          fileStatus: [],
        });
        var allStatusWithCurrentId = this.util.taggingFileStatus.filter(
          (t) => t.id == connectorId
        );
        console.log(connectorId);
        console.log(this.util.taggingFileStatus);
        this.currentS3UploadId = allStatusWithCurrentId;
        var fileStatus = [];
        for (const uploadFile of event.target.files) {
          if (this.acceptFileTypes.indexOf(uploadFile.type) < 0) {
            const assetFile: UploadAssetFormModel = {
              fileName: uploadFile.name,
              fileExtension: uploadFile.extension,
            };
            this.unSupportAssetFiles.push(assetFile);
          } else {
            const assetFile: UploadAssetFormModel = {
              fileName: uploadFile.name,
              fileExtension: uploadFile.extension,
              assetGuid: UUID.UUID(),
            };

            this.uploadedAssetFiles.push(assetFile);
            console.log(allStatusWithCurrentId);

            if (
              allStatusWithCurrentId != null &&
              allStatusWithCurrentId != undefined
            ) {
              var filteredFiles = allStatusWithCurrentId[0].fileStatus.filter(
                (f) => f.uploadFile.name == uploadFile.name
              );

              var asset = this.uploadedAssetFiles.find(
                (i) => i.fileName == uploadFile.name
              );

              console.log(asset);

              if (filteredFiles == null || filteredFiles.length == 0) {
                fileStatus.push({
                  file: `${asset.assetGuid}.${asset.fileName.split(".").pop()}`,
                  status: "scheduled",
                  percentage: 0,
                  show: true,
                  uploadFile: uploadFile,
                });
              }

              allStatusWithCurrentId[0].fileStatus = fileStatus;
            }
          }
        }

        const initialState = {
          uploadedAssetFiles: this.uploadedAssetFiles,
          unSupportedFiles: this.unSupportAssetFiles,
        };

        console.log(initialState);

        this.modalRef = this.modalService.show(
          AdminAssetUploadPopUpComponent,
          this.util.getModalComponentOptions(initialState, false, false, true)
        );
        this.modalRef.content.onClose.subscribe((result) => {
          if (result == true) {
            // refresh grid to show new assets created
            this.searchTaggerAssets();
            this.startUpload(allStatusWithCurrentId[0].id, false);
          }
        });
      }
    }
  }

  public updateUploadStatus(payload: UploadStatusRequestViewModel) {
    this.taggerService.updateUploadAssetStatus(payload).subscribe(
      (res: any) => {
        console.log(res);
        if (res.isSuccess == true) {
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
      (error) => {
        this.util.handleError(error);
      }
    );
  }

  public startUpload(allStatusWithCurrentId, isReupload) {
    // upload to s3 single or multipart
    var asperaFileStatus = this.util.taggingFileStatus.filter(
      (u) => u.id == allStatusWithCurrentId
    );
    for (let aft of asperaFileStatus) {
      if (aft.status == "scheduled") {
        aft.status = "submitted";
        aft.groupHeader = moment().format("MM/DD/YYYY HH:mm:ss").toString();

        for (let f of aft.fileStatus) {
          if (f.status == "scheduled") f.status = "submitted";

          if (f.status == "re-scheduled") f.status = "re-submitted";

          f.show == true;
          this.uploadAssetViaS3(f.uploadFile, aft.id, f.file, this.fileUploadModel, f.file.split(".")[0], isReupload);
        }
      }
    }
  }

  uploadAssetViaS3(currentBrowsedFile, id, fileName, fileUploadModel, assetGuid, isReupload) {
    this.isUploading = true;
    var fileToUpdate = null;
    fileUploadModel.fileName = currentBrowsedFile.name;

    var currentFileUploadModel = {
      fileName: currentBrowsedFile.name,
      uploadDestination: fileUploadModel.uploadDestination,
      asperaId: id,
      isS3AccelerationEnabled: false,
      assetGuid: assetGuid,
    };

    var currentFileTransferStatus = this.util.taggingFileStatus.filter(
      (t) => t.id == id
    );

    if (
      currentFileTransferStatus != null &&
      currentFileTransferStatus.length > 0
    ) {
      var filteredFiles = currentFileTransferStatus[0].fileStatus.filter(
        (f) => f.file == fileName
      );

      if (filteredFiles != null && filteredFiles.length > 0) {
        fileToUpdate = filteredFiles[0];
      }
    }

    let uploadStatusRequest: UploadStatusRequestViewModel = {
      assetGuid: currentFileUploadModel.assetGuid,
      fileType: currentFileUploadModel.fileName.substring(
        currentFileUploadModel.fileName.lastIndexOf(".") + 1
      ),
    };
    if (isReupload) {
      this.updateUploadStatus(uploadStatusRequest);
    }

    if (currentBrowsedFile.size <= Constants.singlepart_max_file_size) {
      // Single part upload
      this.subscriptions.push(
        this.singlePartUploadService
          .uploadAdFile(currentFileUploadModel, currentBrowsedFile)
          .subscribe(
            (x: any) => {
              if (x == null) {
                this.alertService.showMessage(
                  "ERROR",
                  Constants.error500,
                  MessageSeverity.error,
                  true
                );
                this.isUploading = false;
              }
              if (x.type === HttpEventType.UploadProgress) {
                if (fileToUpdate != null) {
                  fileToUpdate.bytesExpected = Math.floor(
                    x.total / (1024 * 1024)
                  );
                  fileToUpdate.bytesWritten = Math.floor(
                    x.loaded / (1024 * 1024)
                  );
                  fileToUpdate.percentage = Math.round(
                    (100 * x.loaded) / x.total
                  );
                  fileToUpdate.status = "uploading";

                  this.statusClassPlaceholder("uploading", fileToUpdate.file);
                  console.log(this.statusClassPlaceholder);
                }
              } else if (x.type === HttpEventType.Response) {
                // 200 or not set failed or completed
                this.isUploading = false;
                if (x.status == 200 && fileToUpdate != null) {
                  fileToUpdate.status = "completed";
                  this.isSessionUploadComplete(
                    currentFileUploadModel.asperaId,
                    currentFileUploadModel
                  );
                  this.statusClassPlaceholder("completed", fileToUpdate.file);
                  if (this.isUploadedAgain) {
                    this.searchTaggerAssets();
                    this.isUploadedAgain = false;
                  }
                } else {
                  if (fileToUpdate != null) {
                    fileToUpdate.status = "failed";
                    this.isSessionUploadComplete(
                      currentFileUploadModel.asperaId,
                      currentFileUploadModel
                    );

                    this.statusClassPlaceholder("failed", fileToUpdate.file);
                  }
                }
              }
              if (this.asset_file != null) {
                this.asset_file.nativeElement.value = null;
              }
            },
            (error) => {
              console.log(error);
              this.util.handleError(error);
            }
          )
      );
    } else {
      // Multi part upload
      const file = new AdFileUploadQueue(
        currentFileUploadModel,
        currentBrowsedFile,
        this.http,
        this.apiendPoint,
        this.util
      );
      this.subscriptions.push(
        file.assetstatus.subscribe((val) => {
          var currentFileTransferStatusForUpdate =
            this.util.taggingFileStatus.filter(
              (t) => t.id == file.initiateMultipartRequest.asperaId
            );

          if (
            currentFileTransferStatusForUpdate != null &&
            currentFileTransferStatusForUpdate.length > 0
          ) {
            var filteredFiles =
              currentFileTransferStatusForUpdate[0].fileStatus.filter(
                (f) => f.uploadFile.name == file.file.name
              );

            if (filteredFiles != null && filteredFiles.length > 0) {
              fileToUpdate = filteredFiles[0];
            }
          }

          if (val.type === "running") {
            if (fileToUpdate != null) {
              fileToUpdate.bytesExpected = Math.floor(
                val.total / (1024 * 1024)
              );
              fileToUpdate.bytesWritten = Math.floor(
                val.loaded / (1024 * 1024)
              );
              fileToUpdate.percentage = Math.round(
                (100 * val.loaded) / val.total
              );
              fileToUpdate.status = "running";

              console.log(`Calling status update running ${fileToUpdate.file}`);

              this.statusClassPlaceholder("uploading", fileToUpdate.file);
            }
          } else if (val.type === "completed") {
            this.isUploading = false;
            if (fileToUpdate != null) {
              fileToUpdate.bytesExpected = Math.floor(
                val.total / (1024 * 1024)
              );
              fileToUpdate.bytesWritten = Math.floor(
                val.loaded / (1024 * 1024)
              );
              fileToUpdate.percentage = Math.round(
                (100 * val.loaded) / val.total
              );
              fileToUpdate.status = "completed";

              console.log(
                `Calling status update completed ${fileToUpdate.file}`
              );

              this.statusClassPlaceholder("completed", fileToUpdate.file);
              if (this.isUploadedAgain) {
                this.searchTaggerAssets();
                this.isUploadedAgain = false;
              }
            }
            this.isSessionUploadComplete(
              file.initiateMultipartRequest.asperaId,
              currentFileUploadModel
            );
          } else if (val.type === "failed") {
            this.isUploading = false;
            if (fileToUpdate != null) {
              fileToUpdate.bytesExpected = Math.floor(
                val.total / (1024 * 1024)
              );
              fileToUpdate.bytesWritten = Math.floor(
                val.loaded / (1024 * 1024)
              );
              fileToUpdate.percentage = Math.round(
                (100 * val.loaded) / val.total
              );
              fileToUpdate.status = "failed";

              console.log(`Calling status update failed ${fileToUpdate.file}`);

              this.statusClassPlaceholder("failed", fileToUpdate.file);
            }
            this.isSessionUploadComplete(
              file.initiateMultipartRequest.asperaId,
              currentFileUploadModel
            );
          }
          if (this.asset_file != null) {
            this.asset_file.nativeElement.value = null;
          }
          this.isUploading = false;
        })
      );
    }
  }

  isSessionUploadComplete(uploadSessionId, uploadModel) {
    var allStatusWithCurrentId = this.util.taggingFileStatus.filter(
      (t) => t.id == uploadSessionId
    );

    if (allStatusWithCurrentId != null && allStatusWithCurrentId.length > 0) {
      var filesInProgress = allStatusWithCurrentId[0].fileStatus.filter(
        (f) => f.status != "failed" && f.status != "completed"
      );

      if (filesInProgress == null || filesInProgress.length == 0) {
        var filesCompletedUpload = allStatusWithCurrentId[0].fileStatus.filter(
          (f) => f.status == "completed"
        );

        var notifyModel = uploadModel;
        notifyModel.UploadedFiles = filesCompletedUpload.map((fs) => fs.file);
      }
    }
  }

  public onEditTaggerAsset(taggerAsset: TaggerAssetModel) {
    const uploadedAssetFiles: UploadAssetFormModel[] = [
      {
        fileName: taggerAsset.fileName,
        fileExtension: taggerAsset.fileExtension,
        assetTitleName: taggerAsset.assetName,
        assetGuid: taggerAsset.assetGuid,
        availableStartDate: taggerAsset.availableStartDate,
        availableEndDate: taggerAsset.availableEndDate,
        availableGroups: taggerAsset.availableGroups,
        availableLocations: taggerAsset.availableLocations,
        assetType: taggerAsset.assetType,
        isFeatured: taggerAsset.isFeatured,
        taggableDurationInSecs: taggerAsset.taggableDurationInSecs,
      },
    ];
    var initialState = {
      uploadedAssetFiles,
      isEditAsset: true,
    };

    this.modalRef = this.modalService.show(
      AdminAssetUploadPopUpComponent,
      this.util.getModalComponentOptions(initialState, false, false, true)
    );
    this.modalRef.content.onClose.subscribe((result) => {
      if (result == true) {
        this.searchTaggerAssets();
      }
    });
  }

  public getThumbnail() {
    return this.util.getThumbnailUrl("", false, false, false);
  }

  public reUploadClicked(taggerAsset: TaggerAssetModel) {
    if (
      taggerAsset.assetType.toLowerCase() ==
      AssetType.BaseCreative.toLowerCase()
    ) {
      this.reUploadAcceptFileTypes = new Array("video/quicktime");
    } else if (
      taggerAsset.assetType.toLowerCase() == AssetType.OfferTag.toLowerCase() ||
      taggerAsset.assetType.toLowerCase() == AssetType.EndTag.toLowerCase()
    ) {
      this.reUploadAcceptFileTypes = new Array("image/png");
    } else if (
      taggerAsset.assetType.toLowerCase() == AssetType.VoiceOver.toLowerCase()
    ) {
      this.reUploadAcceptFileTypes = new Array("audio/mpeg", "audio/wav");
    }
  }

  public onReuploadFile(event: any, taggerAsset: TaggerAssetModel) {
    console.log(taggerAsset);
    if (event.target.files && event.target.files.length > 0) {
      if (!event.target.files.length) {
        return;
      } else {
        var connectorId = Math.floor(Math.random() * 10000 + 1).toString();
        this.util.taggingFileStatus.push({
          id: connectorId,
          status: "scheduled",
          groupHeader: moment().format("MM/DD/YYYY HH:mm:ss").toString(),
          fileStatus: [],
        });
        var allStatusWithCurrentId = this.util.taggingFileStatus.filter(
          (t) => t.id == connectorId
        );
        this.currentS3UploadId = allStatusWithCurrentId;
        var fileStatus = [];
        for (const uploadFile of event.target.files) {
          if (this.reUploadAcceptFileTypes.indexOf(uploadFile.type) < 0) {
            this.alertService.showMessage(
              "ERROR",
              Constants.error_file_format,
              MessageSeverity.error,
              true
            );
            return;
          } else {
            const assetFile: UploadAssetFormModel = {
              fileName: uploadFile.name,
              fileExtension: uploadFile.extension,
              assetGuid: taggerAsset.assetGuid,
            };

            this.uploadedAssetFiles.push(assetFile);
            console.log(allStatusWithCurrentId);

            if (
              allStatusWithCurrentId != null &&
              allStatusWithCurrentId != undefined
            ) {
              var filteredFiles = allStatusWithCurrentId[0].fileStatus.filter(
                (f) => f.uploadFile.name == uploadFile.name
              );

              var asset = this.uploadedAssetFiles.find(
                (i) => i.fileName == uploadFile.name
              );

              console.log(asset);

              if (filteredFiles == null || filteredFiles.length == 0) {
                fileStatus.push({
                  file: `${asset.assetGuid}.${asset.fileName.split(".").pop()}`,
                  status: "scheduled",
                  percentage: 0,
                  show: true,
                  uploadFile: uploadFile,
                });
              }

              allStatusWithCurrentId[0].fileStatus = fileStatus;
            }
          }
        }
        this.isUploadedAgain = true;
        taggerAsset.isUploading = true;
        taggerAsset.isUploadSuccess = false;

        this.startUpload(allStatusWithCurrentId[0].id, true);
      }
    }
  }

  public onDeleteTaggerAsset(taggerAsset: TaggerAssetModel) {
    console.log(taggerAsset);
    const deletedFile: UpdateTaggerAssetStatusRequestModel = {
      assetGuid: taggerAsset.assetGuid,
      assetType: taggerAsset.assetType,
    };

    this.alertService.ShowLoader(true);
    this.taggerService.deleteTaggerAsset(deletedFile).subscribe(
      (res: any) => {
        console.log(res);
        if (res.isSuccess == true) {
          this.alertService.showMessage(
            "SUCCESS",
            Constants.deleteTaggerAssetSuccessful,
            MessageSeverity.success
          );
          const index: number = this.taggerAssets.indexOf(taggerAsset);
          if (index !== -1) {
            this.taggerAssets.splice(index, 1);
          }
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }
}
