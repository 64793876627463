import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Constants } from '../../../configurations/constants';
import { VaultAssetStages } from '../../../configurations/enums/enums';
import { CreateVaultRequest, UpdateVaultAssetDestroyRemove, VaultAssetRecipientInfoViewModel, VaultAssetRemoveDestroyConfig, VaultAssetRemoveDestroyRequest, VaultAssetShippingInfoViewModel, VaultShippingCompanyViewModel } from '../../../models/vault/vault-create.model';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { VaultService } from '../../../services/vault/vault.service';
import { BasePopupComponent } from '../basepopup/basepopup.component';

@Component({
  selector: 'app-vaultassetsremovedestroypopup',
  templateUrl: './vaultassetsremovedestroypopup.component.html',
  styleUrls: ['./vaultassetsremovedestroypopup.component.css']
})
export class VaultassetsremovedestroypopupComponent extends BasePopupComponent {

  @Input() selectedAssets: Array<any>;
  @Input() source: number;
  @Input('isVaultDigitalAsset') isVaultDigitalAsset: boolean = false;

  public txtNotes: string;
  public isNotesEmpty: boolean;
  public selectedAssetIds: Array<number>;
  public title: string;
  public headerText: string;
  public functionButtonText: string;
  //public states: any[] = [];
  public vaultRemoveDestroyConfig: VaultAssetRemoveDestroyConfig;
  public stateDataSource: any = {};
  public shippingcompaniesDataSource: any = [];
  public requestModel: VaultAssetRemoveDestroyRequest;
  public recipientInfo: VaultAssetRecipientInfoViewModel;
  public shippingInfo: VaultAssetShippingInfoViewModel;

  public onVaultAssetStatusUpdated: Subject<boolean>;
  public IsEstimateVisible: boolean;
  public totalEstimate : number = 0.00;

  public editRequest: FormGroup;
  public isStagingClicked: boolean;
  public isRemoveClicked: boolean;
  public isCalculateClicked: boolean;


  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private vaultService: VaultService,
    private alertService: AlertService,
  ) {
    super();
  }

  ngOnInit() {
    console.log(this.selectedAssets);
    console.log(this.source);

    this.editRequest = this.fb.group({
      companyName: this.isVaultDigitalAsset ? new FormControl(null) : new FormControl(null, Validators.required),
      recipientName: this.isVaultDigitalAsset ? new FormControl(null) : new FormControl(null, Validators.required),
      address: this.isVaultDigitalAsset ? new FormControl(null) : new FormControl(null, Validators.required),
      city: this.isVaultDigitalAsset ? new FormControl(null) : new FormControl(null, Validators.required),
      state: this.isVaultDigitalAsset ? new FormControl(null) : new FormControl(null, Validators.required),
      zip: this.isVaultDigitalAsset ? new FormControl(null) : new FormControl(null, Validators.required),
      contactEmail: this.isVaultDigitalAsset ? new FormControl(null) : new FormControl(null, Validators.required),
      contactPhone: this.isVaultDigitalAsset ? new FormControl(null) : new FormControl(null, Validators.required),
      shippingcompany: new FormControl(null),
      manualShippingCompany: new FormControl(null),
      shippingAccountNumber: new FormControl(null),
      shippingTrackingNumber: new FormControl(null),
      deliveryConfirmation: new FormControl(null),
      notes: new FormControl(null, Validators.required),
      shippingestimate: new FormControl(null),
      tylieestimate: new FormControl(null),
      discount: new FormControl(null),
      expectedreturndate: new FormControl(null),
    });

    this.onVaultAssetStatusUpdated = new Subject();

    if (this.source == VaultAssetStages.remove) {
      this.title = "PERMANENTLY REMOVE VAULT ASSET";
      this.headerText = "Selected Assets to Permanently Remove";
      this.functionButtonText = "Confirm Permanent Removal";
      this.IsEstimateVisible = false;
    } else if (this.source == VaultAssetStages.logout) {
      this.title = "LOG OUT VAULT ASSET";
      this.headerText = "Selected Assets to Log Out";
      this.functionButtonText = "Confirm Log out";
      this.IsEstimateVisible = true;
    }

    this.findAssetIds();
    this.alertService.ShowLoader(true);
    this.getShippingCompanies();
  }

  public closePopup() {
    this.bsModalRef.hide();
  }

  public onCancelClicked() {
    this.closePopup();
  }

  public onSubmit({ value, valid }: { value: UpdateVaultAssetDestroyRemove, valid: boolean }) {
    console.log(value);
    
    if (this.isCalculateClicked) {
      this.totalEstimate = value.shippingestimate + value.tylieestimate - value.discount;
      return
    }

    if (!valid) {
      return;
    }

    this.recipientInfo = new VaultAssetRecipientInfoViewModel();
    this.recipientInfo.recipientname = value.recipientName;
    this.recipientInfo.companyname = value.companyName;
    this.recipientInfo.address = value.address;
    this.recipientInfo.city = value.city;
    this.recipientInfo.contactemail = value.contactEmail;
    this.recipientInfo.contactphone = value.contactPhone;
    this.recipientInfo.state = value.state;
    this.recipientInfo.zip = value.zip;

    this.shippingInfo = new VaultAssetShippingInfoViewModel();
    this.shippingInfo.deliveryconfirmation = value.deliveryConfirmation;
    this.shippingInfo.existingshippingcompanyid = value.shippingcompany;
    this.shippingInfo.manuallyentershippingcompany = value.manualShippingCompany;
    this.shippingInfo.shippingaccountnumber = value.shippingAccountNumber;
    this.shippingInfo.shippingtrackingnumber = value.shippingTrackingNumber;



    if (this.source == VaultAssetStages.remove) {
      this.requestModel = new VaultAssetRemoveDestroyRequest();
      this.requestModel.recipientInfo = JSON.stringify(this.recipientInfo);
      this.requestModel.shippingInfo = JSON.stringify(this.shippingInfo);
      this.requestModel.vaultAssetIds = this.selectedAssetIds;
      this.requestModel.notes = value.notes;
      this.requestModel.shippingEsimate = null;
      this.requestModel.tylieEstimate = null;
      this.requestModel.discount = null;
      this.requestModel.totalEstimate = null;
      this.requestModel.expectedReturnDate = null;

      this.requestModel.isVaultDigitalAsset = this.isVaultDigitalAsset;

      console.log(this.requestModel);
      if (this.isStagingClicked) {
        this.updateVaultAssetsToStage();
      } else if (this.isRemoveClicked) {
        this.updateVaultAssetsToRemove();
      }
    } else if (this.source == VaultAssetStages.logout) {
      this.requestModel = new VaultAssetRemoveDestroyRequest();
      this.requestModel.recipientInfo = JSON.stringify(this.recipientInfo);
      this.requestModel.shippingInfo = JSON.stringify(this.shippingInfo);
      this.requestModel.vaultAssetIds = this.selectedAssetIds;
      this.requestModel.notes = value.notes;
      this.requestModel.shippingEsimate = value.shippingestimate;
      this.requestModel.tylieEstimate = value.tylieestimate;
      this.requestModel.discount = value.discount;
      this.requestModel.totalEstimate = this.totalEstimate;
      this.requestModel.expectedReturnDate = value.expectedreturndate;

      this.requestModel.isVaultDigitalAsset = this.isVaultDigitalAsset;

      console.log(this.requestModel);
      if (this.isStagingClicked) {
        this.updateVaultAssetsToStage();
      } else if (this.isRemoveClicked) {
        this.updateVaultAssetsToLogout();
      }
    }
  }

  public findAssetIds() {
    this.selectedAssetIds = new Array<number>();
    this.selectedAssets.forEach(r => {
      this.selectedAssetIds.push(r.vaultAssetId);
    });
  }

  public updateVaultAssetsToRemove() {
    this.alertService.ShowLoader(true);
    this.vaultService.updateVaultAssetsStatusRemove(this.requestModel).subscribe((res: any) => {
      this.alertService.ShowLoader(false);
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESSS", Constants.assetStatusRemoveUpdated, MessageSeverity.success, true);
        this.onVaultAssetStatusUpdated.next(true);
        this.closePopup();
      } else {
        this.alertService.showMessage("ERROR", Constants.assetStatusUpdatedFail, MessageSeverity.error, true);
      }
    });
  }

  public updateVaultAssetsToLogout() {
    this.alertService.ShowLoader(true);
    this.vaultService.updateVaultAssetsStatusLogout(this.requestModel).subscribe((res: any) => {
      this.alertService.ShowLoader(false);
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESSS", Constants.assetStatusLogoutUpdated, MessageSeverity.success, true);
        this.onVaultAssetStatusUpdated.next(true);
        this.closePopup();
      } else {
        this.alertService.showMessage("ERROR", Constants.assetStatusUpdatedFail, MessageSeverity.error, true);
      }
    });
  }

  public updateVaultAssetsToStage() {
    console.log(this.requestModel);
    this.alertService.ShowLoader(true);
    this.vaultService.updateVaultAssetsStatusStaging(this.requestModel).subscribe((res: any) => {
      this.alertService.ShowLoader(false);
      if (res.isSuccess == true) {
        this.alertService.showMessage("SUCCESSS", Constants.assetStatusStagingUpdated, MessageSeverity.success, true);
        this.onVaultAssetStatusUpdated.next(true);
        this.closePopup();
      } else {
        this.alertService.showMessage("ERROR", Constants.assetStatusUpdatedFail, MessageSeverity.error, true);
      }
    });
  }

  public getShippingCompanies() {
    this.vaultService.getVaultShippingServices().subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {
        this.alertService.ShowLoader(false);
        this.vaultRemoveDestroyConfig = res.result as VaultAssetRemoveDestroyConfig;
        this.shippingcompaniesDataSource = this.vaultRemoveDestroyConfig.shippingCompanies;
        this.stateDataSource = this.vaultRemoveDestroyConfig.states;
      } else {
        this.alertService.ShowLoader(false);
      }
    });
  }

  public onCalculateClicked() {
    this.isStagingClicked = false;
    this.isRemoveClicked = false;
    this.isCalculateClicked = true;
  }

  public onStagingClicked() {
    this.isStagingClicked = true;
    this.isRemoveClicked = false;
    this.isCalculateClicked = false;
  }

  public onConfirmClicked() {
    this.isStagingClicked = false;
    this.isCalculateClicked = false;
    this.isRemoveClicked = true;
  }
}
