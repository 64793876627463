<div class="tylie-accordion accordion">
  <div class="tylie-accordion__panel accordion-item">
    
    <h2 class="tylie-accordion__heading accordion-header">
      <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#vaultSearchCollapseOne" aria-expanded="true" aria-controls="collapseOne">
        SEARCH THE VAULT
      </button>
    </h2>
   
    <div id="vaultSearchCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">
      <div class="tylie-accordion__body accordion-body">
        <div class="tylie-accordion__body-bar tylie-accordion__body-bar--b-bordered">
          <div class="row">
            <div class="col-6">
              <section class="tylie-input-search">
                <div class="tylie-input-search__inner">
                  <div class="tylie-input-search__icon"><icon-search></icon-search></div>
                  <input class="tylie-input-search__text" [placeholder]="this.activeGrid === 'PHYSICAL STORAGE' ? 'Search By Ad-ID, Vault Request Number or Reference' : 'Search By Asset Identifier, Title'" name="srch-term" id="srch-term" type="search" [(ngModel)]="quickSearchValue"
                         (ngModelChange)="clearQuickSearch()" (keydown.enter)="onEnterQuickSearch($event)">

                </div>
                <div class="tylie-input-search__btn">
                  <button class="tylie-button tylie-button--sm" type="button" (click)="quickSearch()">Search</button>
                </div>
              </section>
            </div>
            <div class="col-6">
              <button type="button" class="tylie-button tylie-button--link tylie-button--xs mt-3 collapsed" data-bs-toggle="collapse" href="#advancedSearchContainer" role="button" aria-expanded="false" aria-controls="collapseExample">Advanced Search<span class="tylie-button__icon e"><icon-arrow-simple-down height="12px" width="12px" color="#1e90ff"></icon-arrow-simple-down></span></button>
            </div>
          </div>
        </div>

        <div class="tylie-section collapse collapse" id="advancedSearchContainer">
          <div class="d-flex justify-content-center text-uppercase mb-3"><strong>Advanced Search</strong> </div>
          <form novalidate (ngSubmit)="onFormSubmit(vaultSearchRequest)" [formGroup]="vaultSearchRequest" role="search">
            <div class="row">
              <div class="col-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="vaultRequestJobNumber">Vault Request Number</label>
                  <input type="text" name="vaultRequestJobNumber" id="vaultRequestJobNumber" class="tylie-text form-control" formControlName="vaultRequestJobNumber">
                </div>
              </div>

              <div class="col-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="client">Client</label>
                  <dx-select-box [dataSource]="clientDataSource"
                                 id="client"
                                 name="client"
                                 class="tylie-select form-control"
                                 formControlName="clientId"
                                 [searchEnabled]="true"
                                 valueExpr="id"
                                 displayExpr="name"
                                 (onValueChanged)="onClientValueChanged($event)"
                                 [showClearButton]="true"
                                 placeholder="Select a client...">
                  </dx-select-box>
                </div>
              </div>
              <div class="col-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="brand">Brand</label>
                  <dx-select-box [dataSource]="brandDataSource"
                                 class="tylie-select form-control"
                                 id="brand"
                                 name="brand"
                                 formControlName="brandId"
                                 [searchEnabled]="true"
                                 valueExpr="id"
                                 displayExpr="name"
                                 [showClearButton]="true"
                                 noDataText="Please choose a brand"
                                 (onValueChanged)="onBrandValueChanged($event)"
                                 placeholder="Select a brand...">
                  </dx-select-box>
                </div>
              </div>

              <div class="col-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="product">Product</label>
                  <dx-select-box [dataSource]="productDataSource"
                                 class="tylie-select form-control"
                                 id="product"
                                 name="product"
                                 [searchEnabled]="true"
                                 formControlName="productId"
                                 valueExpr="id"
                                 [showClearButton]="true"
                                 displayExpr="name"
                                 placeholder="Select a product...">
                  </dx-select-box>
                </div>
              </div>
            <!--</div>

            <div class="row">-->
              <div class="col-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="adId">Asset-ID</label>
                  <input type="text" name="adId" id="adId" class="tylie-text form-control" formControlName="adId">
                </div>
              </div>
              <div class="col-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="title">Title</label>
                  <input type="text" name="title" id="title" class="tylie-text form-control" formControlName="title">
                </div>
              </div>
              <div class="col-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="clientJobNumber">Client Job Number</label>
                  <input type="text" name="clientJobNumber" id="clientJobNumber" class="tylie-text form-control" formControlName="clientJobNumber">
                </div>
              </div>
              <div class="col-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="reference">Reference</label>
                  <input type="text" name="reference" id="reference" class="tylie-text form-control" formControlName="reference">
                </div>
              </div>
            <!--</div>

            <div class="row">-->
              <div class="col-3" *ngIf="this.activeGrid === 'PHYSICAL STORAGE'">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="warehouse">Warehouse</label>
                  <dx-select-box [dataSource]="warehouseDataSource"
                                 id="warehouseId"
                                 name="warehouse"
                                 class="tylie-select form-control"
                                 formControlName="warehouseId"
                                 [searchEnabled]="true"
                                 valueExpr="id"
                                 displayExpr="name"
                                 [showClearButton]="true"
                                 placeholder="Select a warehouse...">
                  </dx-select-box>
                </div>
              </div>
              <div class="col-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="status">Status</label>
                  <dx-select-box [dataSource]="statusDataSource"
                                 id="vaultStatusId"
                                 name="status"
                                 class="tylie-select form-control"
                                 formControlName="vaultStatusId"
                                 [searchEnabled]="true"
                                 valueExpr="vaultStatusId"
                                 displayExpr="name"
                                 [showClearButton]="true"
                                 placeholder="Select status...">
                  </dx-select-box>
                </div>
              </div>
              <div class="col-3" *ngIf="this.activeGrid === 'PHYSICAL STORAGE'">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="assetBarCode">Asset BarCode</label>
                  <input type="text" name="assetBarCode" id="assetBarCode" class="tylie-text form-control" formControlName="assetBarCode">
                </div>
              </div>
              <div class="col-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="assetDescription">Asset Description</label>
                  <input type="text" name="assetDescription" id="assetDescription" class="tylie-text form-control" formControlName="assetDescription">
                </div>
              </div>
            <!--</div>

            <div class="row">-->
              <div class="col-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="bjvvtm" *ngIf="this.activeGrid === 'PHYSICAL STORAGE'">BJV Number</label>
                  <label class="tylie-form-group__lbl" for="bjvvtm" *ngIf="this.activeGrid === 'DIGITAL STORAGE'">Master ID</label>
                  <input type="text" name="bjvvtm" id="bjvvtm" class="tylie-text form-control" formControlName="bjvvtm">
                </div>
              </div>
              <div class="col-3" *ngIf="this.activeGrid === 'PHYSICAL STORAGE'">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="cartonBarcode">Carton BarCode</label>
                  <input type="text" name="cartonBarcode" id="cartonBarcode" class="tylie-text form-control" formControlName="cartonBarcode">
                </div>
              </div>
              <div class="col-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="createdDateFrom">Create Date From</label>
                  <dx-date-box type="date" width="100%" formControlName="createdDateFrom"
                               displayFormat="MM/dd/yyyy"
                               id="createdDateFrom" class="tylie-select form-control" [showClearButton]="true" [max]="now"></dx-date-box>
                </div>
              </div>
              <div class="col-3">
                <div class="tylie-form-group form-group">
                  <label class="tylie-form-group__lbl" for="createdDateTo">Create Date To</label>
                  <dx-date-box type="date" width="100%" formControlName="createdDateTo"
                               displayFormat="MM/dd/yyyy"
                               id="createdDateTo" class="tylie-select form-control" [showClearButton]="true" [max]="now"></dx-date-box>
                </div>
              </div>
              <div class="col-3">
                <div class="tyllie-form-group form-check mt-4">
                  <input type="checkbox" class="form-check-input" formControlName="missingAssets" name="missingAssets" id="missingAssets">
                  <label class="form-check-label" for="flaggedonly">Missing Assets</label>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end">
              <button class="tylie-button tylie-button--xs me-3 tylie-button--icon" type="submit"><span class="tylie-button__icon"><icon-search></icon-search></span><span class="tylie-button__text">Search</span></button>
              <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="clearSearch()">Clear Search</button>
            </div>
          </form>
        </div>

        <div class="d-flex justify-content-between">

          <div>
            <ul class="tylie-tab-nav nav nav-tabs mx-4 mt-3" id="myTab">
              <li class="tylie-tab-nav__itm nav-item" role="presentation">
                <button class="tylie-tab-nav__lnk nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#vaultSearchGridView"
                        type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true" (click)="onClickPhysicalStorage()">
                  PHYSICAL STORAGE
                </button>
              </li>
              <li class="tylie-tab-nav__itm nav-item" role="presentation">
                <button class="tylie-tab-nav__lnk nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#digitalStorageGridView"
                        type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false" (click)="onClickDigitalStorage()">
                  DIGITAL STORAGE
                </button>
              </li>
            </ul>
          </div>

          <div class="d-flex justify-content-end mx-4 my-2">
            <button class="tylie-button tylie-button--xs tylie-button--icon me-2" type="button" (click)="reportClicked()">
              <span class="tylie-button__icon"><icon-reports></icon-reports></span>
              <span class="tylie-button__text">Report</span>
            </button>
            <button class="tylie-button tylie-button--xs tylie-button--icon me-2" type="button" (click)="stagingClicked()">
              <span class="tylie-button__icon"><icon-vault-stagging></icon-vault-stagging></span>
              <span class="tylie-button__text">Staging</span>
            </button>
            <button class="tylie-button tylie-button--xs tylie-button--icon me-2" type="button" (click)="logoutClicked()">
              <span class="tylie-button__icon"><icon-vault-logout></icon-vault-logout></span>
              <span class="tylie-button__text">Log out</span>
            </button>
            <button class="tylie-button tylie-button--xs tylie-button--icon me-2" type="button" (click)="loginClicked()">
              <span class="tylie-button__icon"><icon-vault-login></icon-vault-login></span>
              <span class="tylie-button__text">Log In</span>
            </button>
            <button class="tylie-button tylie-button--xs tylie-button--icon me-2" type="button" (click)="removeClicked()">
              <span class="tylie-button__icon"><icon-delete></icon-delete></span>
              <span class="tylie-button__text">Remove</span>
            </button>
            <button class="tylie-button tylie-button--xs tylie-button--icon" type="button" (click)="destroyClicked()" *ngIf="this.activeGrid === 'PHYSICAL STORAGE'">
              <span class="tylie-button__icon"><icon-delete></icon-delete></span>
              <span class="tylie-button__text">Destroy</span>
            </button>
          </div>
        </div>

        <div class="tylie-tab-content tab-content">
          <!--<div class="tylie-accordion__body-content tylie-accordion__body-content--t-bordered">-->
            <div id="vaultSearchGridView" class="tylie-tab-content__pane tab-pane fade show active">
              <div class="tylie-grid-wrapper" [hidden]="isReport == true">
                <dx-data-grid id="vaultSearchGrid"
                              #vaultSearchGrid
                              class="tylie-grid"
                              [dataSource]="resultVaultSearchStore"
                              [allowColumnReordering]="true"
                              [allowColumnResizing]="true"
                              [columnAutoWidth]="true"
                              [showColumnLines]="true"
                              [showRowLines]="true"
                              [hoverStateEnabled]="true"
                              keyExpr="vaultAssetGuid"
                              [rowAlternationEnabled]="true"
                              [showBorders]="true"
                              (onRowPrepared)="onRowPrepared($event)">
                  <dxo-selection mode="multiple"
                                 selectAllMode="page"
                                 allowSelectAll="Select"
                                 showCheckBoxesMode="always">
                  </dxo-selection>
                  <dxi-column dataField="createdDate" cellTemplate="datetimeTemplate" caption="Created" cssClass="gridStyle tyl-nopadding tyl-grid-padding" sortOrder="desc" width="10%"></dxi-column>
                  <dxi-column dataField="assetIngestedDate" cellTemplate="datetimeTemplate" caption="Ingested" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
                  <dxi-column dataField="assetbarcode" caption="Asset Barcode" cellTemplate="vaultAssetBarCodeTemplate" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
                  <dxi-column dataField="cartonBarcode" caption="Carton Barcode" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
                  <dxi-column dataField="client" caption="Client" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
                  <dxi-column dataField="brand" caption="Brand" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
                  <dxi-column dataField="product" caption="Product" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
                  <dxi-column dataField="warehouse" caption="Warehouse" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
                  <dxi-column dataField="location" caption="Location" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
                  <dxi-column dataField="status" cellTemplate="vaultAssetMissingTemplate" caption="Status" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>

                  <div *dxTemplate="let t of 'vaultAssetBarCodeTemplate'">
                    <span class="quickLink" (click)='showVaultRequestDetail(t.data.vaultAssetGuid)'>{{t.data.assetBarcode}}</span>
                  </div>

                  <div *dxTemplate="let data of 'datetimeTemplate'">
                    <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY'}}</div>
                  </div>

                  <div *dxTemplate="let t of 'vaultAssetMissingTemplate'">
                    <span>{{ t.data.status }}</span>
                    <span *ngIf="t.data.isAssetMissinginVault" class="tylie-button__icon" [ngStyle]="{'padding-left': '10px'}">
                      <icon-question-mark [color]="'#1e90ff'" [height]="'18px'" [width]="'18px'"></icon-question-mark>
                    </span>
                  </div>

                  <dxo-remote-operations [sorting]="true"
                                         [paging]="true">
                  </dxo-remote-operations>
                  <dxo-paging [pageSize]="10"></dxo-paging>
                  <dxo-pager [showPageSizeSelector]="true"
                             [allowedPageSizes]="[10,20, 50, 100]"
                             infoText="Page {0} of {1} ({2} items)"
                             [showNavigationButtons]="true"
                             [showInfo]="true">
                  </dxo-pager>
                </dx-data-grid>
              </div>

              <div class="tylie-grid-wrapper" [hidden]="isReport == false">

                <dx-data-grid id="vaultSearchGridAssets"
                              #vaultSearchGridAssets
                              class="tylie-grid"
                              [dataSource]="resultVaultSearchStore"
                              [allowColumnReordering]="true"
                              [allowColumnResizing]="true"
                              [columnAutoWidth]="true"
                              [showColumnLines]="true"
                              [showRowLines]="true"
                              [hoverStateEnabled]="true"
                              keyExpr="Vaultrequestid"
                              [showBorders]="true"
                              (onExporting)="onExporting()"
                              (onExported)="onExported()">
                  <dxo-selection mode="multiple"
                                 selectAllMode="page"
                                 allowSelectAll="Select"
                                 showCheckBoxesMode="always">
                  </dxo-selection>
                  <dxi-column dataField="createdDate" cellTemplate="datetimeTemplate" caption="CreatedDate" cssClass="gridStyle tyl-nopadding tyl-grid-padding" sortOrder="desc" width="11%"></dxi-column>
                  <dxi-column dataField="assetIngestedDate" cellTemplate="datetimeTemplate" caption="Ingested" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="11%"></dxi-column>
                  <dxi-column dataField="assetBarcode" caption="Asset Barcode" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="12%"></dxi-column>
                  <dxi-column dataField="client" caption="Client" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
                  <dxi-column dataField="brand" caption="Brand" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
                  <dxi-column dataField="product" caption="Product" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%" [allowSorting]="false"></dxi-column>
                  <dxi-column dataField="warehouse" caption="Warehouse" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="12%"></dxi-column>
                  <dxi-column dataField="location" caption="Location (Carton Column - Position)" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="12%"></dxi-column>
                  <dxi-column dataField="adID" caption="Asset-ID" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="12%"></dxi-column>
                  <dxi-column dataField="title" caption="Title" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="12%"></dxi-column>
                  <dxi-column dataField="length" caption="Length" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="12%"></dxi-column>
                  <dxi-column dataField="assetDescription" caption="Description" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="12%"></dxi-column>
                  <dxi-column dataField="format" caption="Format" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="12%"></dxi-column>
                  <dxi-column dataField="isAssetMissinginVault" cellTemplate="vaultAssetMissingTemplate" caption="Is Asset Missing" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="12%"></dxi-column>
                  <dxi-column dataField="status" caption="Status" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="12%"></dxi-column>
                  <dxi-column dataField="loggedOutTo" caption="Logged Out To" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="12%"></dxi-column>
                  <dxi-column dataField="expectedReturnDate" caption="Expected To Return on" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="12%"></dxi-column>
                  <dxi-column dataField="assetLastLoggedoutDate" caption="Asset Last Logged Out on" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="12%"></dxi-column>

                  <div *dxTemplate="let t of 'vaultAssetMissingTemplate'">
                    <span class="quickLink">{{t.data.isAssetMissinginVault == true? 'Yes' :'No'}}</span>
                  </div>

                  <div *dxTemplate="let data of 'datetimeTemplate'">
                    <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY'}}</div>
                  </div>

                  <dxo-remote-operations [sorting]="true"
                                         [paging]="true">
                  </dxo-remote-operations>
                  <dxo-paging [pageSize]="10"></dxo-paging>
                  <dxo-pager [showPageSizeSelector]="true"
                             [allowedPageSizes]="[10,20, 50, 100]"
                             infoText="Page {0} of {1} ({2} items)"
                             [showNavigationButtons]="true"
                             [showInfo]="true">
                  </dxo-pager>
                </dx-data-grid>
              </div>
              </div>

            <div id="digitalStorageGridView" class="tylie-tab-content__pane tab-pane tab-pane fade">
              <dx-data-grid id="digitalStorageGrid"
                            #digitalStorageGrid
                            class="tylie-grid"
                            [dataSource]="resultDigitalStorageStore"
                            [allowColumnReordering]="true"
                            [allowColumnResizing]="true"
                            [columnAutoWidth]="true"
                            [showColumnLines]="true"
                            [showRowLines]="true"
                            [hoverStateEnabled]="true"
                            keyExpr="vaultDigitalAssetLineItemId"
                            [rowAlternationEnabled]="true"
                            [showBorders]="true"
                            (onExporting)="onDigitalStorageExporting()"
                            (onExported)="onDigitalStorageExported()">
                <dxo-selection mode="multiple"
                               selectAllMode="page"
                               allowSelectAll="Select"
                               showCheckBoxesMode="always">
                </dxo-selection>
                <dxi-column dataField="createdDate" cellTemplate="datetimeTemplate" caption="Created" cssClass="gridStyle tyl-nopadding tyl-grid-padding" sortOrder="desc" width="10%"></dxi-column>
                <dxi-column dataField="assetIdentifier" cellTemplate="digitalAssetIdentifierTemplate" caption="Asset Identifier" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
                <dxi-column dataField="title" caption="Title" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="17%"></dxi-column>
                <dxi-column dataField="client" caption="Client" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
                <dxi-column dataField="brand" caption="Brand" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
                <dxi-column dataField="product" caption="Product" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="8%" [allowSorting]="false"></dxi-column>
                <dxi-column dataField="length" caption="Length" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="7%"></dxi-column>
                <dxi-column dataField="storageType" caption="Storage Type" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="8%"></dxi-column>
                <dxi-column dataField="legacyMasterId" caption="Master Number" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
                <dxi-column dataField="status" cellTemplate="vaultAssetMissingTemplate" caption="Status" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>

                <div *dxTemplate="let data of 'datetimeTemplate'">
                  <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY hh:mm A'}}</div>
                </div>

                <div *dxTemplate="let t of 'vaultAssetMissingTemplate'">
                  <span>{{t.data.status}}</span>
                  <span *ngIf="t.data.isAssetMissingInVault" class="tylie-button__icon" [ngStyle]="{'padding-left': '10px'}">
                    <icon-question-mark [color]="'#1e90ff'" [height]="'18px'" [width]="'18px'"></icon-question-mark>
                  </span>
                </div>
                

                <div *dxTemplate="let t of 'digitalAssetIdentifierTemplate'">
                  <span class="quickLink" (click)='showDigitalRequestDetail(t.data.vaultDigitalAssetLineItemId)'>{{t.data.assetIdentifier}}</span>
                </div>

                <dxo-remote-operations [sorting]="true"
                                       [paging]="true">
                </dxo-remote-operations>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showPageSizeSelector]="true"
                           [allowedPageSizes]="[10,20, 50, 100]"
                           infoText="Page {0} of {1} ({2} items)"
                           [showNavigationButtons]="true"
                           [showInfo]="true">
                </dxo-pager>
              </dx-data-grid>
            </div>
            <!--</div>-->

          </div>
        </div>
      </div>
  </div>
</div>
