import { Component, Input, OnInit } from "@angular/core";
import { Utilities } from "../../../services/core/utilities";
import { UserRoles } from "../../../configurations/user-roles";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, MessageSeverity } from "../../../services/core/alert.service";
import { TaggerService } from "../../../services/tagger/tagger.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { JsPlayerComponent } from "../../videoplayer/videojs/jsplayer.component";
import { OrderNoteResponse, SearchTaggedAdRequestModel, TaggedAdResponseModel, TaggerAd } from "../../../models/tagger/tagger.model";
import { RouteConstants } from "../../../configurations/route-constants";
import { DraftOrderSort, Statuses, TaggerTabs, TaggingStatus } from "../../../configurations/enums/enums";
import { TaggerOrderNotepopupComponent } from "../../popups/tagger-order-notepopup/tagger-order-notepopup.component";
import { Constants } from "../../../configurations/constants";
import { ShareTaggerAdPopupComponent } from "../../popups/share-tagger-ad-popup/share-tagger-ad-popup.component";
import { DownloadMediaService } from "../../../services/media/media-download.service";
import { ViewTaggerOrderAdpopupComponent } from "../../popups/view-tagger-order-adpopup/view-tagger-order-adpopup.component";
import { TranscodeAssetFile } from "../../../models/order/transcodeorder.model";
import { QuickplayerComponent } from "../../videoplayer/quickplayer/quickplayer.component";

@Component({
  selector: "app-tagger-search",
  templateUrl: "./tagger-search.component.html",
  styleUrls: ["./tagger-search.component.css"],
})
export class TaggerSearchComponent implements OnInit {
  public taggerAds: TaggedAdResponseModel[] = [];
  public quickSearchValue: string = "";
  public oldQuickSearchValue: string = "";
  public selectedSortOrder: string = "";
  public modalRef: BsModalRef;
  public sortDataSource: any[];
  public taggingStatus = TaggingStatus;

  constructor(
    public util: Utilities,
    public userRoles: UserRoles,
    private router: Router,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private taggerService: TaggerService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private ds: DownloadMediaService,
  ) { }

  ngOnInit() {
    this.sortDataSource = Object.keys(DraftOrderSort).map(key => ({
      id: key,
      name: DraftOrderSort[key]
    }));
    this.selectedSortOrder = this.sortDataSource[0].id;
    this.loadTaggerAds();
  }

  public onRefresh() {
    this.quickSearchValue = "";
    this.selectedSortOrder = this.sortDataSource[0].id;
    this.loadTaggerAds();
  }

  public loadTaggerAds() {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    const payload: SearchTaggedAdRequestModel = {
      search: this.quickSearchValue,
      sortOrder: this.selectedSortOrder,
      isArchive: false,
    }
    this.taggerService.getTaggedAds(payload).subscribe(
      (res: any) => {
        console.log(res);
        if (res.isSuccess == true) {
          this.taggerAds = res.result.searchResults;
        } else {
          this.util.handleIsNotSuccess(res.errors);
        }

        this.alertService.ShowLoader(false);
      },
      (error) => {
        // this.util.handleError(error);
        this.alertService.ShowLoader(false);
      }
    );
  }

  public onSortValueChanged(e: any) {
    this.selectedSortOrder = e.value;
    this.loadTaggerAds();
  }

  public onEnterQuickSearch(e: any) {
    this.quickSearch();
  }

  public quickSearch() {
    if (this.oldQuickSearchValue != this.quickSearchValue) {
      this.oldQuickSearchValue = this.quickSearchValue;
      this.loadTaggerAds();
    }
  }

  public onEditTaggedAd(taggerAd: TaggedAdResponseModel) {
    this.router.navigate([RouteConstants.adCreatorRoute], {
      queryParams: {
        isEdit: true,
        adGuid: taggerAd.adGuid,
        orderGuid: taggerAd.orderGuid,
        activeTab: TaggerTabs.taggedAds
      },
    });
  }

  public quickPlay(proxy, title, thumbnail, format) {
    if (proxy == null || proxy == '')
      return;

    const initialState = {
      videoSource: proxy,
      thumbnail: thumbnail,
      title: title,
      format: format,
    };

    this.modalRef = this.modalService.show(QuickplayerComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  public statusClass(status: string) {
    let cls = '';
    if (status == Statuses.Current) {
      cls += " tylie-badge--success"
    } else if (status == Statuses.ExpireSoon) {
      cls += " tylie-badge--warning"
    } else if (status == Statuses.Expired) {
      cls += " tylie-badge--error"
    }
    return cls;
  }

  public onShare(taggerAd: TaggedAdResponseModel) {
    var initialState = {
      taggerAds: this.getSharableTaggerAds(taggerAd.adGuid, taggerAd.ad.fileName, taggerAd.ad.fileExtension, taggerAd.ad.s3StoragePath)
    };

    this.modalRef = this.modalService.show(ShareTaggerAdPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true, "shareTaggerAdPopup"));
  }

  private getSharableTaggerAds(adGuid: string, fileName: string, fileExtension: string, filePath: string): Array<TaggerAd> {
    let taggerAds: TaggerAd[] = [];

    let ad = new TaggerAd();
    ad.adGuid = adGuid;
    ad.filePath = filePath;
    ad.fileName = fileName;
    ad.fileExtension = fileExtension;

    taggerAds.push(ad);
    return taggerAds;
  }

  public onDownload(taggerAd: TaggedAdResponseModel) {
    console.log(taggerAd);

    var response = this.ds.initiateS3TaggerAdFileDownload(this.getDownloadableTaggerAds(taggerAd.adGuid, taggerAd.ad.adName, this.getFileExtention(taggerAd.ad.s3StoragePath), this.getFileName(taggerAd.ad.s3StoragePath)), taggerAd.adGuid);
    //var response = this.ds.initiateS3TaggerAdFileDownload(this.getDownloadableTaggerAds(taggerAd.adGuid, "5fb23266-125c-4182-9e2d-36a1cff260df", "mp4", "5fb23266-125c-4182-9e2d-36a1cff260df"), taggerAd.adGuid);

     if (response == true)
       this.onGotoDownload();
  }

  public getFileName(path: string): string {
    return path.substring(0, path.lastIndexOf("."));
  }

  public getFileExtention(path: string): string {
    return path.substring(path.lastIndexOf(".")+1, path.length);
  }

  public onGotoDownload() {
    this.router.navigate([RouteConstants.taggerDownloadRoute], { queryParams: { t: "tagger" } });
  }

  private getDownloadableTaggerAds(assetFileGuid: string, fileName: string, fileExtension: string, filePath: string): Array<TranscodeAssetFile> {
    let assetFiles: TranscodeAssetFile[] = [];

    let af = new TranscodeAssetFile();
    af.assetFileGuid = assetFileGuid;
    af.filePath = filePath;
    af.fileName = fileName;
    af.fileExtension = fileExtension;
    assetFiles.push(af);

    console.log(assetFiles);

    return assetFiles;
  }

  public editNote(taggerAd: TaggedAdResponseModel) {
    const initialState = {
      orderGuid: taggerAd.orderGuid,
      payload: taggerAd.note? taggerAd.note.payload : ""
    };
    this.bsModalRef = this.modalService.show(TaggerOrderNotepopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
    this.bsModalRef.content.onSaveVaultAssetNote.subscribe((result: OrderNoteResponse) => {
      if (result != null && result != undefined) {
        var dOrder = this.taggerAds.find(d => d.orderGuid = taggerAd.orderGuid);
        dOrder['note'] = {
          payload: result.payload,
          createdBy: result.createdBy,
          createdDate: result.createdDate
        }
      }
    });
  }

  public onArchive(taggerAd: TaggedAdResponseModel) {
    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
    const payload: any = {
      orderGuid: taggerAd.orderGuid,
      lineItemGuid: taggerAd.lineItemGuid,
      adGuid: taggerAd.adGuid,
    }
    this.taggerService.archiveOrder(payload).subscribe(
      (res: any) => {
        if (res.isSuccess == true) {
          this.alertService.ShowLoader(false);
          this.alertService.showMessage("Success", Constants.success_archive_order, MessageSeverity.success, true);
          this.loadTaggerAds();
        } else {
          this.alertService.ShowLoader(false);
          this.alertService.showMessage("Failed", Constants.failed_archive_order, MessageSeverity.error, true);
        }
      },
      (error) => {
        this.util.handleError(error);
        this.alertService.showMessage("Failed", Constants.failed_archive_order, MessageSeverity.error, true);
        this.alertService.ShowLoader(false);
      }
    );
  }

  public onViewAd(taggerAd: TaggedAdResponseModel) {
    const initialState = {
      videoSource: taggerAd.ad.proxyUrl,
      thumbnail: taggerAd.ad.thumbnail,
      modalTitle: "VIEW TAGGED AD",
      title: taggerAd.ad.adName,
      id: taggerAd.ad.adGuid
    };
    this.bsModalRef = this.modalService.show(ViewTaggerOrderAdpopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }
}
