<div class="tylie-step-progress mt-3 mb-4">
  <header class="tylie-step-progress__title">Order Status</header>
  <div class="tylie-step-progress__steps" [ngClass]="(header.progress.length == 3)?'col-md-3':'col-md-9'">
    <ng-container *ngFor="let pp of header.progress; let i = index;">
      <div class="tylie-step-progress__step" [ngClass]="{'active': pp.isActive == true }" [style.left.%]="progressBarDivider * i"></div>
      <div class="tylie-step-progress__step-txt" [ngClass]="{'active': pp.isActive == true }" [style.left.%]="progressBarDivider * i">{{pp.progressText}}</div>
    </ng-container>
    <div class="tylie-step-progress__bar"></div>
  </div>
</div>

<div class="tylie-panel tylie-panel--clean">
  <div class="tylie-panel__body">
    <div class="row">
      <div class="col-md-3">
        <div class="tylie-data tylie-data--block">
          <div class="tylie-data__lbl">Client</div>
          <div class="tylie-data__txt">{{header.client}}</div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="tylie-data tylie-data--block">
          <div class="tylie-data__lbl">Brand</div>
          <div class="tylie-data__txt">{{header.brand}}</div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="tylie-data tylie-data--block">
          <div class="tylie-data__lbl">Product</div>
          <div class="tylie-data__txt">{{header.product}}</div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="tylie-data tylie-data--block">
          <div class="tylie-data__lbl">Agency</div>
          <div class="tylie-data__txt">{{header.specialVisibilityClientName}}</div>
        </div>
      </div>
    </div>
  </div>
  
</div>

<div class="tylie-accordion accordion" id="search-all-media-section">
  <div class="tylie-accordion__panel accordion-item" id="mediaSearchPanel">
    <h2 class="tylie-accordion__heading accordion-header">
      <button class="tylie-accordion__heading-btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#mediaSearchCollapseOne" aria-expanded="true" aria-controls="collapseOne">
        Order Header
      </button>
    </h2>
    <div id="mediaSearchCollapseOne" class="tylie-accordion__content accordion-collapse collapse show">
      <div class="tylie-accordion__body accordion-body">
        <div class="tylie-accordion__body-content">
          <div class="row">
            <div class="col-md-3">
              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl">Client Purchase Order</label>
                <div class="tylie-form-group__txt">{{header.clientPurchaseOrder}}</div>
                <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="config.orderActions.canEditHeader === true || config.orderActions.canEditSpecialHeader === true" (click)="onEdit('PO')">Edit</button>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl">Estimate#</label>
                <div class="tylie-form-group__txt">{{header.estimate}}</div>
                <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="config.orderActions.canEditHeader === true" (click)="onEdit('ESTIMATE')">Edit</button>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl">Job#</label>
                <div class="tylie-form-group__txt">{{header.jobNumber}}</div>
                <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="config.orderActions.canEditHeader === true || config.orderActions.canEditSpecialHeader === true" (click)="onEdit('JOB')">Edit</button>
              </div>
            </div>
            <div class="col-md-3" *ngIf="config.orderActions.showSpecialFlags === true">
              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl">Consolidated Order</label>
                <div class="tylie-form-group__txt">{{header.isConsolidatedOrder == true ? 'Yes' : 'No'}}</div>
                <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="config.orderActions.canEditHeader === true" (click)="onEdit('CONSOLIDATEDORDER')">Edit</button>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl">Air Date Start</label>
                <div class="tylie-form-group__txt">{{header.airDateStart ? (header.airDateStart |  date: 'MM/dd/yyyy') : '' }}</div>
                <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="config.orderActions.canEditHeader === true" (click)="onEdit('AIRDATE')">Edit</button>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl">Air Date End</label>
                <div class="tylie-form-group__txt">{{header.airDateEnd ? (header.airDateEnd | date: 'MM/dd/yyyy') :  '' }}</div>
                <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="config.orderActions.canEditHeader === true" (click)="onEdit('AIRDATE')">Edit</button>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl">Reference/Keywords</label>
                <div class="tylie-form-group__txt">{{header.refKeywords}}</div>
                <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="config.orderActions.canEditHeader === true" (click)="onEdit('REFERENCE/KEYWORDS')">Edit</button>
              </div>
            </div>
            <div class="col-md-3" *ngIf="config.orderActions.showSpecialFlags === true">
              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl">Hub Destinations</label>
                <div class="tylie-form-group__txt">{{header.isDestinationsHubbed == true ? 'Yes' : 'No'}}</div>
                <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="config.orderActions.canEditHubbedDestination === true" (click)="onEdit('HUBDESTINATIONS')">Edit</button>
                <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="config.orderActions.canEditHubbedDestination === false && config.orderActions.canEditHeader === true">&nbsp;</button>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl">Ordered By</label>
                <div class="tylie-form-group__txt">{{header.orderedBy}}</div>
                <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="config.orderActions.canEditHeader === true" (click)="onEdit('ORDEREDBY')">Edit</button>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl">Bill To</label>
                <div class="tylie-form-group__txt">{{header.inVoiceTo}}</div>
                <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="config.orderActions.canEditHeader === true" (click)="onEdit('INVOICETO')">Edit</button>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl">Cost Center</label>
                <div class="tylie-form-group__txt">{{header.costCenter}}</div>
                <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="config.orderActions.canEditHeader === true" (click)="onEdit('COSTCENTER')">Edit</button>
              </div>
            </div>
            <div class="col-md-3" *ngIf="config.orderActions.showSpecialFlags === true">
              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl">Final Review Required</label>
                <div class="tylie-form-group__txt">{{header.isFinalReviewRequired == true ? 'Yes' : 'No'}}</div>
                <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="config.orderActions.canEditHeader === true" (click)="onEdit('FINALREVIEWREQUIRED')">Edit</button>
              </div>
            </div>
            <div class="col-md-3" *ngIf="config.orderActions.showSpecialFlags === true">
              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl">Pro Bono</label>
                <div class="tylie-form-group__txt">{{header.isProBono == true ? 'Yes' : 'No'}}</div>
                <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="config.orderActions.canEditHeader === true" (click)="onEdit('PROBONO')">Edit</button>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl">Billed Date</label>
                <div class="tylie-form-group__txt">{{header.billedDate ? (header.billedDate | usertimezone : 'll') :  '' }}</div>
              </div>
            </div>
            <div class="col-md-3" *ngIf="config.orderActions.showSpecialFlags === true">
              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl">Non-Billable Order</label>
                <div class="tylie-form-group__txt">{{header.originalWorkOrder}}</div>
                <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="config.orderActions.canEditHeader === true" (click)="onEdit('ORIGINALWORKORDER')">Edit</button>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl">POD Email Recipients</label>
                <div class="tylie-form-group__txt">{{header.podEmails}}</div>
                <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="config.orderActions.canEditHeader === true" (click)="onEdit('PODEMAILS')">Edit</button>
              </div>
            </div>
            <div class="col-md-3" *ngIf="config.orderActions.showSpecialFlags === true">
              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl">Billing Only Order</label>
                <div class="tylie-form-group__txt">{{header.isPostDatedOrder == true ? 'Yes' : 'No'}}</div>
                <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="config.orderActions.canEditBillingOnlyOrder === true" (click)="onEdit('BILLINGONLYORDER')">Edit</button>
                <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="config.orderActions.canEditBillingOnlyOrder === false && config.orderActions.canEditHeader === true">&nbsp;</button>
              </div>
            </div>
            <div class="col-md-9 comment">
              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl">Notes</label>
                <div class="tylie-form-group__txt mb-1" *ngIf="header.notes.length > 0">
                  {{header.notes[0].comments}}
                  <div class="tylie-type-fs-12">
                    <i>
                      by {{header.notes[0].user}},
                      on {{header.notes[0].created | usertimezone: 'll' }}
                      at {{header.notes[0].created | usertimezone: 'HH:mm'}}
                    </i>
                  </div>
                </div>
                <p class="black" *ngIf="header.notes.length == 0">&nbsp;&nbsp;</p>
              </div>
            </div>
            <div class="col-md-12 comment" *ngIf="config.orderActions.showSpecialFlags === true">
              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl" *ngIf="this.header.systemComments.length > 0">System Comments</label>
                <ng-container *ngFor="let cm of header.systemComments?.slice().reverse()">
                  <div class="tylie-form-group__txt mb-1">
                    {{cm.comments}}
                    <div class="tylie-type-fs-12">
                      <i>
                        by {{cm.user}},
                        on {{(cm.created | usertimezone: 'll')}}
                        at {{(cm.created | usertimezone: 'HH:mm')}}
                      </i>
                    </div>
                  </div>
                </ng-container>
              </div>

              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl" *ngIf="this.header.reviewComments.length > 0">Review Comments</label>
                <ng-container *ngFor="let cm of header.reviewComments?.slice().reverse()">
                  <div class="tylie-form-group__txt mb-1">
                    {{cm.comments}}
                    <div class="tylie-type-fs-12">
                      <i>
                        by {{cm.user}},
                        on {{(cm.created | usertimezone: 'll')}}
                        at {{(cm.created | usertimezone: 'HH:mm')}}
                      </i>
                    </div>
                  </div>
                </ng-container>
              </div>

              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl" *ngIf="this.header.sendToAEComments.length > 0">OPs Comments</label>
                <ng-container *ngFor="let cm of header.sendToAEComments?.slice().reverse()">
                  <div class="tylie-form-group__txt mb-1">
                    {{cm.comments}}
                    <div class="tylie-type-fs-12">
                      <i>
                        by {{cm.user}},
                        on {{(cm.created | usertimezone: 'll')}}
                        at {{(cm.created | usertimezone: 'HH:mm')}}
                      </i>
                    </div>
                  </div>
                </ng-container>
              </div>

              <div class="tylie-form-group">
                <label class="tylie-form-group__lbl">CX Comments</label>
                <ng-container *ngIf="this.header.orderComments.length > 0">
                  <div class="tylie-form-group__txt mb-1">
                    <p [innerHTML]="header.orderComments?.slice()[0].comments | multiLine"></p>
                    <!--<p style="white-space: pre-line;">
                      {{header.orderComments?.slice()[0].comments}}
                    </p>-->
                    <div class="tylie-type-fs-12">
                      <i>
                        by {{header.orderComments?.slice()[0].user}},
                        on {{(header.orderComments?.slice()[0].created | usertimezone: 'll')}}
                        at {{(header.orderComments?.slice()[0].created | usertimezone: 'HH:mm')}}
                      </i>
                    </div>
                  </div>
                </ng-container>
              </div>
              <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="this.header.orderComments.length > 0 && config.orderActions.canAddCXOpsComments === true" (click)="onEdit('COMMENT')">Edit Comment</button>
              <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="this.header.orderComments.length == 0 && config.orderActions.canAddCXOpsComments === true" (click)="onEdit('COMMENT')">Add Comment</button>
              <button type="button" class="tylie-button tylie-button--link tylie-button--xs" *ngIf="this.header.orderComments.length > 0 && config.orderActions.canAddCXOpsComments != true" (click)="onViewAllCxComments()">View Comments History</button>

            </div>
          </div>
           

            <div class="row">
              <div class="col-md-12" *ngIf="header.consolidatedOrders != null && header.consolidatedOrders.length > 0">
                <section class="tylie-panel tylie-panel--secondary">
                  <div class="tylie-panel__header tylie-panel__header--br-thick">
                    <h4 class="tylie-panel__title">
                      Consolidated Orders
                    </h4>
                  </div>
                  <div class="tylie-panel__body">
                    <table class="tylie-table table table-bordered">
                      <thead class="tylie-table__head">
                        <tr>
                          <th width="25%">OrderID</th>
                          <th width="25%">Status</th>
                          <th width="25%">SubmittedOn</th>
                          <th width="25%">SubmittedBy</th>
                        </tr>
                      </thead>
                      <tbody class="tylie-table__body">
                        <ng-container *ngFor="let co of header.consolidatedOrders; let i = index">
                          <tr [ngClass]="{'highlight-row': (co.isParent === true)}">
                            <td class="blue-text" (click)='showOrderDetail(co.orderGuid)'>{{co.workOrder}}</td>
                            <td>{{co.status}}</td>
                            <td>{{co.createdOn | usertimezone: 'll'}}</td>
                            <td>{{co.createdBy}}</td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <section class="tylie-panel tylie-panel--secondary">
                  <div class="tylie-panel__header tylie-panel__header--br-thick">
                    <h4 class="tylie-panel__title">
                      ATTACHMENTS
                      <a class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon" *ngIf="config.orderActions.canEditHeader === true" (click)="onAddAttachments()">
                        <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
                        ADD ATTACHMENTS
                      </a>
                      <a class="tylie-button tylie-button--xs tylie-button--link tylie-button--icon ms-3" *ngIf="config.orderActions.canEditSpecialHeader === true" (click)="onAddAttachments()">
                        <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
                        ADD PO
                      </a>
                    </h4>
                  </div>
                  <div class="tylie-panel__body" *ngIf="header.attachments.length === 0">There are no attachments yet.</div>
                  <div class="tylie-panel__body" *ngIf="header.attachments.length > 0">
                    <table class="tylie-table table table-bordered">
                      <thead class="tylie-table__head">
                        <tr>
                          <th width="20%">File Type</th>
                          <th width="60%">File Name</th>
                          <th width="10%">Uploaded On</th>
                          <th width="10%" *ngIf="config.orderActions.canEditHeader === true"></th>
                        </tr>
                      </thead>
                      <tbody class="tylie-table__body">
                        <tr *ngFor="let att of header.attachments; let i = index">
                          <td>{{att.fileCategory}}</td>
                          <td class="blue-text"><a (click)="onDownloadFile(att.orderFileGuid, att.fileName);">{{att.fileName}}</a></td>
                          <td>{{att.uploadedOn | usertimezone: 'll'}}</td>
                          <td align="center" *ngIf="config.orderActions.canEditHeader === true">
                            <a class="tylie-button tylie-button--xs tylie-button--link" outsideClick="true" placement="left" #pop="bs-popover" container="body" [popoverContext]="att" [popover]="deleteAttTemplate">
                              <icon-trash-can color="#1e90ff"></icon-trash-can>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #deleteAttTemplate let-fileName="fileName" let-orderFileGuid="orderFileGuid">
  <!-- <div class='p-normal'>
    <p class='black'>
      Are you sure you'd like to delete{{fileName}}?
    </p>
    <i (click)="onHidePop()"></i>
  </div>
  <button class='btn tyl-btn btn-extra-small' (click)="onAttDelete(fileName, orderFileGuid)">Delete</button> -->



  <button class="popover__close" (click)="onHidePop()">
    <icon-delete color="#000" height="14px" width="14px"></icon-delete>
  </button>
  <header class="popover__header">Are you sure you'd like to delete{{fileName}}?</header>
  <button type="button" class='tylie-button tylie-button--xs' (click)="onAttDelete(fileName, orderFileGuid)">Delete</button>
</ng-template>
