import {map} from 'rxjs/operators';
import { Component, Inject, ViewChild, Input, OnInit, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Spot } from '../../../models/spot/spot.model';
import { PlayList } from '../../../models/playlist/playlist.model';
import { SpotSearchRequest } from '../../../models/spot/spot-search-request.model';
import { DxTreeViewComponent, DxDataGridComponent } from 'devextreme-angular';
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { SpotService } from '../../../services/media/spot.service';
import { Utilities } from "../../../services/core/utilities";
import { Constants } from "../../../configurations/constants";
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddToPlayListComponent } from "../../popups/addtoplaylist/addtoplaylist.component";
import * as moment from 'moment';
import { SubService } from "../../../services/media/sub.service";
import { ShareMediaPopupComponent } from "../../popups/sharemediapopup/sharemediapopup.component";
import { JsPlayerComponent } from '../../videoplayer/videojs/jsplayer.component';
import { PlayListFileNew } from "../../../models/playlist/playlist-file-new.model";
import { UserRoles } from "../../../configurations/user-roles";
import { ExpirePopupComponent } from "../../popups/expire/expirepopup.component";
import { QuickLinkPopUp } from '../../popups/quicklinkpopup/quicklinkpopup.component';
import 'devextreme/integration/jquery';
import { DeletePlaceholderPopupComponent } from '../../popups/deleteplaceholderpopup/deleteplaceholderpopup.component';
import { ConfigService } from "../../../services/core/config.service";
import { RouteConstants } from "../../../configurations/route-constants";
import CustomStore from 'devextreme/data/custom_store';
import { GridParams } from '../../../models/config/gridparams.model';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { QuickLinkConfig } from '../../../models/spot/quicklink.model';
import { Observable } from 'rxjs';
import { GlobalService } from '../../../services/core/global.service';
import { DownloadMediaService } from '../../../services/media/media-download.service';
import { SpotFile } from '../../../models/spot/spot-file.model';
import { SpotRestoreResult } from '../../../models/spot/spot-restore.result.model';
import { ErrorPopupComponent } from '../../popups/errorpopup/errorpopup.component';
import { TandemGridService } from '../../../services/grid/tandem-grid.service';
import { TandemGrid } from '../../../models/tandem-grid/tandem-grid';
import { ManageGridButtonsComponent } from '../../popups/manage-grid-buttons/manage-grid-buttons.component';
import { GridButton } from '../../../models/tandem-grid/grid-button';
import { SearchAllAssetGridButton } from '../../../configurations/enums/enums';
import { QuickplayerComponent } from '../../videoplayer/quickplayer/quickplayer.component';

@Component({
  selector: 'spot-search',
  templateUrl: './spotsearch.component.html',
  styleUrls: ['./spotsearch.component.css'],
})

export class SpotSearchComponent implements OnInit {

  public statusDataSource: any;
  public clientDataSource: any;
  public brandDataSource: any;
  public formatDataSource: any;
  public productDataSource: any;
  public mediatypeDataSource: any;
  public showClientClear: boolean = true;
  public objForCacheUpdate: SpotSearchRequest;
  public disableProducts: boolean = false;
  public isSearching: boolean = false;

  @ViewChild('spotSearch', { static: true }) gridSpotSearch: DxDataGridComponent;
  @ViewChild('treeBrand', { static: false }) treeBrand: DxTreeViewComponent;
  @ViewChild('treeBrandProduct', { static: false }) treeBrandProduct: DxTreeViewComponent;
  @Input('searchConfig') searchConfig: any;

  public brandValue: number[] = [];
  public productValue: number[] = [];
  public searchRequest: FormGroup;
  public quickSearchValue: string = '';
  public now: Date = new Date();
  public sub;

  public selectedMediaFiles: Array<string>;

  public userPlayLists: PlayList[];
  public modalRef: BsModalRef;

  public resultSpotsStore: any = {};
  public searchSpotsCriteriaObj: SpotSearchRequest = <SpotSearchRequest>{};
  public scroll: boolean = false;
  public quickLinkSelectedSpotData: any[] = [];
  public quickLinkSelectedDetailSpotData: any[] = [];
  public dataGridColumnsAdded: string[] = [];

  public gridColumns = [];
  public allowMultiSelection: boolean = false
  public hearderMenuItems = [];
  public tandemGrid: TandemGrid;

  detailGridInstances: Array<any>;

  @ViewChildren('delDownloadpop') delDownloadpop: QueryList<PopoverDirective>;

  //public gridButtons: GridButton[] = [];
  public IsAddToPlayListVisible: boolean = false;
  public IsShareVisible: boolean = false;
  public IsExportVisible: boolean = false;
  constructor(
    public alertService: AlertService,
    private router: Router,
    public userRoles: UserRoles,
    private spotService: SpotService,
    private modalService: BsModalService,
    public util: Utilities,
    private subService: SubService,
    private ds: DownloadMediaService,
    public configService: ConfigService,
    public tandemGridService: TandemGridService,
    private gs: GlobalService) {

    this.selectedMediaFiles = [];
    this.detailGridInstances = [];

    this.subService.refreshPlayListSearchMethodCalled.subscribe(
      () => {
        this.deSelectRows();
      }
    );

    this.subService.deselectSearchGridRowsCalled.subscribe(
      () => {
        this.deSelectRows();
      }
    );

    this.sub = this.subService.refreshSearchSpotsBacktoMediaMethodCalled.subscribe(
      () => {
        if (this.objForCacheUpdate != null || this.objForCacheUpdate != undefined) {
          this.refreshSearchResults(this.objForCacheUpdate, true, true);
        }
        else {
          this.refreshSearchResults(null, false, true);
        }
      }
    );

    this.subService.refreshSearchSpotsCalled.subscribe(
      () => {
        if (this.objForCacheUpdate != null || this.objForCacheUpdate != undefined) {
          this.refreshSearchResults(this.objForCacheUpdate, false, true);
        }
        else {
          this.refreshSearchResults(null, false, true);
        }
      }
    );
  }

  ngAfterViewInit() {

  }

  ngOnInit() {
    console.log(this.searchConfig);
    this.statusDataSource = this.searchConfig.statuses;
    this.clientDataSource = this.searchConfig.clients;
    this.brandDataSource = this.searchConfig.brands;
    this.formatDataSource = this.searchConfig.formats;
    this.mediatypeDataSource = this.searchConfig.mediaTypes;
    this.productDataSource = this.searchConfig.products;

    this.searchRequest = new FormGroup({
      adID: new FormControl(),
      title: new FormControl(),
      createdFrom: new FormControl(),
      createdTo: new FormControl(),
      status: new FormControl(),
      brands: new FormControl(),
      client: new FormControl(),
      products: new FormControl(),
      mediaType: new FormControl(),
      expiredOnly: new FormControl(),
      activeOnly: new FormControl(),
      archivedOnly: new FormControl(),
      purgedOnly: new FormControl(),
      format: new FormControl(),
      customTagValue: new FormControl(),
      qcCompletedBy: new FormControl()
    });

    if (this.clientDataSource.length == 1) {
      this.searchRequest.controls['client'].setValue(this.clientDataSource[0].id);
      this.showClientClear = false;
    }

    this.searchRequest.controls['expiredOnly'].setValue(false);
    this.searchRequest.controls['activeOnly'].setValue(false);
    this.searchRequest.controls['archivedOnly'].setValue(false);
    this.searchRequest.controls['purgedOnly'].setValue(false);

    // active spot meaning spots that are not expired so they are mutually exclusive
    this.searchRequest.get('expiredOnly').valueChanges.subscribe(
      (expiredOnly: boolean) => {
        if (expiredOnly === true)
          this.searchRequest.get('activeOnly').setValue(false);
      });

    this.searchRequest.get('activeOnly').valueChanges.subscribe(
      (activeOnly: boolean) => {
        if (activeOnly === true)
          this.searchRequest.get('expiredOnly').setValue(false);
      });

    this.refreshSearchResults(null, false, false);

    this.util.scrollIfNeededTo();

    this.getGridDetailsByUserSettings();
    this.gridSpotSearch.onContextMenuPreparing.subscribe((e) => this.onContextMenuPreparing(e));

    //this.gridButtons.push(this.gb1);
    //this.gridButtons.push(this.gb2);
    //this.gridButtons.push(this.gb3);
    //this.gridButtons.push(this.gb4);
    //this.gridButtons.push(this.gb5);
    //this.gridButtons.push(this.gb6);
    //this.gridButtons.push(this.gb7);

    //this.handleGridButtonVisibilities();

  }

  // search on enter click
  public onEnterQuickSearch(e: any) {
    this.quickSearch();
  }

  quickSearch() {
    if (this.quickSearchValue === null || this.quickSearchValue.trim() === '') {
      return;
    }
    //enable below code for Ranking
    // this.gridSpotSearch.instance.clearSorting();
    var request = <SpotSearchRequest>{};
    request.quickSearch = this.quickSearchValue;
    this.refreshSearchResults(request, false, true);
  }

  clearQuickSearch() {
    if (this.quickSearchValue == '') {
      this.refreshSearchResults(null, false, true);
    }
  }

  clearSearch() {
    this.searchRequest.reset();
    this.quickSearchValue = "";
    this.brandDataSource = [];
    this.disableProducts = false;

    this.selectedMediaFiles = [];

    if (this.clientDataSource.length == 1)
      this.searchRequest.controls['client'].setValue(this.clientDataSource[0].id);
    else
      this.getClientBrands(0);

    this.getBrandProducts(0);

    this.refreshSearchResults(null, false, true);
  }

  onFormSubmit({ value, valid }: { value: SpotSearchRequest, valid: boolean }) {
    if (value.adID === null && value.brands === null && value.client === null && value.products === null
      && value.createdFrom === null && value.createdTo === null && value.format === null && value.mediaType === null
      && (value.expiredOnly === null || value.expiredOnly === false) && (value.activeOnly === null || value.activeOnly === false)
      && (value.purgedOnly === null || value.purgedOnly === false)
      && (value.archivedOnly === null || value.archivedOnly === false)
      && value.status === null && value.title === null && value.customTagValue === null && value.qcCompletedBy == null) {
      return;
    }

    if (value.createdFrom != null)
      value.createdFrom = moment(value.createdFrom).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format();

    if (value.createdTo != null)
      value.createdTo = moment(value.createdTo).set({ hour: 24, minute: 0, second: 0, millisecond: 0 }).format();

    this.refreshSearchResults(value, false, true);
  }

  private refreshSearchResults(obj: SpotSearchRequest, scroll: boolean, isRefresh: boolean): void {

    if (obj != null) {
      this.searchSpotsCriteriaObj.activeOnly = obj.activeOnly;
      this.searchSpotsCriteriaObj.adID = obj.adID;
      this.searchSpotsCriteriaObj.brands = obj.brands;
      this.searchSpotsCriteriaObj.client = obj.client;
      this.searchSpotsCriteriaObj.createdFrom = obj.createdFrom;
      this.searchSpotsCriteriaObj.createdTo = obj.createdTo;
      this.searchSpotsCriteriaObj.expiredOnly = obj.expiredOnly;
      this.searchSpotsCriteriaObj.format = obj.format;
      this.searchSpotsCriteriaObj.mediaType = obj.mediaType;
      this.searchSpotsCriteriaObj.products = obj.products;
      this.searchSpotsCriteriaObj.quickSearch = obj.quickSearch;
      this.searchSpotsCriteriaObj.status = obj.status;
      this.searchSpotsCriteriaObj.title = obj.title;
      this.searchSpotsCriteriaObj.customTagValue = obj.customTagValue;
      this.searchSpotsCriteriaObj.purgedOnly = obj.purgedOnly;
      this.searchSpotsCriteriaObj.qcCompletedBy = obj.qcCompletedBy;
      this.searchSpotsCriteriaObj.archivedOnly = obj.archivedOnly;
      this.objForCacheUpdate = obj;
    }
    else {
      this.searchSpotsCriteriaObj.activeOnly = null;
      this.searchSpotsCriteriaObj.adID = null;
      this.searchSpotsCriteriaObj.brands = null;
      this.searchSpotsCriteriaObj.client = null;
      this.searchSpotsCriteriaObj.createdFrom = null;
      this.searchSpotsCriteriaObj.createdTo = null;
      this.searchSpotsCriteriaObj.expiredOnly = null;
      this.searchSpotsCriteriaObj.format = null;
      this.searchSpotsCriteriaObj.mediaType = null;
      this.searchSpotsCriteriaObj.products = null;
      this.searchSpotsCriteriaObj.quickSearch = null;
      this.searchSpotsCriteriaObj.status = null;
      this.searchSpotsCriteriaObj.title = null;
      this.searchSpotsCriteriaObj.customTagValue = null;
      this.searchSpotsCriteriaObj.purgedOnly = null;
      this.searchSpotsCriteriaObj.qcCompletedBy = null;
      this.searchSpotsCriteriaObj.archivedOnly = null;

      this.objForCacheUpdate = null;
    }

    this.scroll = scroll;

    if (isRefresh) {
      this.gridSpotSearch.instance.refresh();
    }
    else {
      this.searchSpots();
    }

    this.selectedMediaFiles = [];
  }

  getClientBrands(id: number) {
    if (this.treeBrand != null && this.treeBrand != undefined && this.treeBrand.instance != undefined && this.treeBrand.instance != null) {
      this.treeBrand.instance.unselectAll();
      this.brandValue = [];
    }

    this.configService.getClientBrands(id).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var brands = res.result;
        this.brandDataSource = brands;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.brandDataSource = [];
      });
  }

  onClientValueChanged(e) {
    this.getClientBrands(e.value);
    this.getClientProducts(e.value);
  }

  public getClientProducts(id: number) {
    //this.productDataSource = [];
    //this.searchRequest.controls['products'].setValue("");

    //this.configService.getClientProducts(id).subscribe((res: any) => {
    //  if (res.isSuccess == true) {
    //    var products = res.result;
    //    this.productDataSource = products;
    //  }
    //  else {
    //    this.util.handleIsNotSuccess(res.errors);
    //  }
    //},
    //  error => {
    //    this.util.handleError(error);
    //    this.productDataSource = [];
    //  });

    if (this.treeBrandProduct != null && this.treeBrandProduct != undefined && this.treeBrandProduct.instance != undefined && this.treeBrandProduct.instance != null) {
      this.treeBrandProduct.instance.unselectAll();
      this.productValue = [];
    }

    this.configService.getClientProducts(id).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var products = res.result;
        this.productDataSource = products;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.productDataSource = [];
      });
  }

  syncTreeViewSelection(e = null) {
    var component = (e && e.component) || (this.treeBrand && this.treeBrand.instance);

    if (!component) return;

    if (this.brandValue == null || this.brandValue.length <= 0) {
      component.unselectAll();
    }

    if (this.brandValue) {
      this.brandValue.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
  }

  syncTreeViewSelectionProduct(e = null) {
    var component = (e && e.component) || (this.treeBrandProduct && this.treeBrandProduct.instance);

    if (!component) return;

    if (this.productValue == null || this.productValue.length <= 0) {
      component.unselectAll();
    }

    if (this.productValue) {
      this.productValue.forEach((function (value) {
        component.selectItem(value);
      }).bind(this));
    }
  }

  clearProductSelection(e) {
    if (e.value == null || e.value == undefined || (e.value != null && e.value.length == 0)) {
      this.disableProducts = false;
      console.log((this.searchRequest.value.client));
      this.getBrandProducts(0);

    }
    else if (e.value != null && e.value.length == 1 && (e.previousValue == null || e.previousValue.length != e.length)) {
      this.getBrandProducts(e.value[0]);
      this.disableProducts = false;
    }
    else {
      this.productDataSource = [];
      this.disableProducts = true;
    }
  }

  treeView_itemSelectionChanged(e) {
    var newValues = e.component.getSelectedNodesKeys() as Array<number>;
    this.brandValue = newValues;
  }

  treeView_productSelectionChanged(e) {
    var newValues = e.component.getSelectedNodesKeys() as Array<number>;
    this.productValue = newValues;
  }

  compareArraysForEquality(brandValue: any[], selectedValues: any[]) {
    //console.log("called compare");
    if ((brandValue != null || brandValue != undefined)) {
      if (brandValue.length == 1) {
        if (brandValue[0] == selectedValues[0]) { return false; }
      }
    }

    return true;
  }

  getBrandProducts(id: number) {
    //this.productDataSource = [];
    //this.searchRequest.controls['products'].setValue("");
    //this.configService.getBrandProducts(id, this.searchRequest.value.client).subscribe((res: any) => {
    //  if (res.isSuccess == true) {
    //    var products = res.result;
    //    this.productDataSource = products;
    //    this.productDataSource = this.productDataSource.filter(pds => pds.id != 0);
    //  }
    //  else {
    //    this.util.handleIsNotSuccess(res.errors);
    //  }
    //},
    //  error => {
    //    this.util.handleError(error);
    //    this.productDataSource = [];
    //  });

    if (this.treeBrandProduct != null && this.treeBrandProduct != undefined && this.treeBrandProduct.instance != undefined && this.treeBrandProduct.instance != null) {
      this.treeBrandProduct.instance.unselectAll();
      this.productValue = [];
    }

    this.configService.getBrandProducts(id, this.searchRequest.value.client).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var products = res.result;
        this.productDataSource = products;
        this.productDataSource = this.productDataSource.filter(pds => pds.id != 0);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        this.productDataSource = [];
      });
  }

  searchSpots() {

    var spotService = this.spotService;
    var util = this.util;
    var criteriaObj = this.searchSpotsCriteriaObj;
    var scroll = this.scroll;

    this.resultSpotsStore.store = new CustomStore({
      key: "spotFileGuid",
      load: function (loadOptions: any) {
        var skip = loadOptions.skip;
        var take = loadOptions.take;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "[{'selector':'createdDate','desc':true}]";

        let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: (loadOptions.isLoadingAll && loadOptions.isLoadingAll === true) }
        let request: any = { SearchCriteria: criteriaObj, GridParams: gridParams, isSpotSearch: false };

        return spotService.searchSpots(request)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess == true) {
              var spotSearchResponse = response.result;
              var spots = spotSearchResponse.searchResults as Spot[];

              for (let s of spots) {
                s.thumbnailUrl = util.getThumbnailUrl(s.thumbnailUrl, (s.format.toLowerCase() == 'audio'), (s.qcStatus.toLowerCase() == 'awaiting media'), (s.qcStatus.toLowerCase() == 'creating media'));
                s.proxyUrl = util.getProxyUrl(s.proxyUrl);

                s.formatSources = util.getAllIconSources(s.format, s.audioFormat, s.spotTrac, s.veilEncode, s.subtitles, s.centerCutProtected, s.descriptiveVideo,
                  s.broadcast, s.web, s.taggable, s.generic, s.runningFootage, s.cc, s.sap, s.vchip, s.letterBox, s.surround, s.mono);

                for (let sf of s.spotFiles) {
                  sf.proxyUrl = util.getProxyUrl(sf.proxyUrl);
                }
              }

              var obj: any = {
                data: spots,
                totalCount: spotSearchResponse.totalCount
              };

              if (scroll)
                util.scrollIfNeededTo();

              return obj;
            }
            else {
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch(error => {
            util.handleError(error);
            throw 'Data Loading Error';
          });
      }
    });
  }

  onRowExpanding(e: any) {
    //let x: Spot[] = e.component.option("dataSource");

    //this.spotService.getSpotFiles(e.key.toString()).subscribe(res => {
    //  var jsonRes = res.json();

    //  if (jsonRes.isSuccess == true) {
    //    var spotFiles = jsonRes.result;
    //    this.alertService.logInfo(spotFiles);
    //    let s = x.filter(t => t.spotId.toString() === e.key.toString())[0];
    //    s.spotFiles = spotFiles as SpotFile[];
    //    this.alertService.ShowLoader(false);
    //  }
    //  else {
    //    this.alertService.logInfo("isSuccess false");
    //    this.alertService.logInfo(jsonRes.errors);
    //    this.alertService.showDialog(true, "Error", Constants.error400, DialogType.error)
    //  }
    //},
    //  error => {
    //    this.util.handleError(error);
    //    this.alertService.ShowLoader(false);
    //  });
  }

  showChoosePlayListPopup() {

    if (this.gridSpotSearch.instance.getSelectedRowKeys().length == 0 && this.selectedMediaFiles.length == 0) {
      this.alertService.showMessage("ERROR", Constants.nospotsSelected, MessageSeverity.error);
      return;
    }

    if (this.gridSpotSearch.instance.getSelectedRowKeys().length > 0 && this.checkIfSelectedSpotsGotVideoFiles() === false) {
      this.alertService.showMessage("ERROR", Constants.noVideoFilesForSelectedSpots, MessageSeverity.error);
      return;
    }

    if (this.selectedMediaFiles.length > 0 && this.checkIfSelectedSpotFilesGotVideoFiles() === false) {
      this.alertService.showMessage("ERROR", Constants.noVideoFilesForSelectedSpots, MessageSeverity.error);
      return;
    }
debugger
    var initialState = {
      selectedPlayListFiles: this.getSelectedSpotsAsPlayListFiles(),
      isPopup: true,
      source: "search"
    };

    this.modalRef = this.modalService.show(AddToPlayListComponent, this.util.getModalComponentOptions(initialState, false, true, false));
  }

  checkIfSelectedSpotsGotVideoFiles(): boolean {
    var grid = this.gridSpotSearch.instance;
    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      if (grid.getSelectedRowsData()[i].isPlayable == false)
        return false;
    }
    return true;
  }

  checkIfSelectedSpotsArePlaceHolders(): boolean {
    var grid = this.gridSpotSearch.instance;
    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      if (grid.getSelectedRowsData()[i].isPlaceholder == false)
        return false;
    }
    return true;
  }

  checkIfSelectedSpotsAreNotPlaceHolders(): boolean {
    var grid = this.gridSpotSearch.instance;
    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      if (grid.getSelectedRowsData()[i].isPlaceholder == true)
        return false;
    }
    return true;
  }

  getSelectedSpotsAsPlayListFiles(): PlayListFileNew[] {
    let selectedSpotFiles: string[] = [];
    let playListFiles: PlayListFileNew[] = [];
    var grid = this.gridSpotSearch.instance;

    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      selectedSpotFiles.push(grid.getSelectedRowsData()[i].spotFileGuid);
    }

    this.selectedMediaFiles.forEach((item, i) => {
      selectedSpotFiles.push(item);
    });

    for (var i = 1; i <= selectedSpotFiles.length; i++)
      playListFiles.push({ sequence: i, spotFileGuid: selectedSpotFiles[i - 1] });

    return playListFiles;
  }

  getSelectedSpotsAsArray(): string[] {
    let selectedSpotFiles: string[] = [];
    var grid = this.gridSpotSearch.instance;

    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      selectedSpotFiles.push(grid.getSelectedRowsData()[i].spotFileGuid);
    }

    this.selectedMediaFiles.forEach((item, i) => {
      selectedSpotFiles.push(item);
    });

    return selectedSpotFiles;
  }

  getSelectedParentSpotsAsArray(): string[] {
    let selectedSpotFiles: string[] = [];
    var grid = this.gridSpotSearch.instance;

    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      selectedSpotFiles.push(grid.getSelectedRowsData()[i].spotGuid);
    }

    return selectedSpotFiles;
  }

  getSelectedSpotsShareTitle(): string {
    var grid = this.gridSpotSearch.instance;
    return grid.getSelectedRowsData()[0].title;
  }

  exportSpots() {
    //if (this.gridSpotSearch.instance.getSelectedRowKeys().length == 0)
    //  this.alertService.showMessage("ERROR", Constants.nospotsSelected, MessageSeverity.error);
    //else {
    //  this.gridSpotSearch.instance.exportToExcel(true);
    //}
    this.gridSpotSearch.instance.exportToExcel(false);

    //var dataSource = this.gridSpotSearch.instance.getDataSource();
    //console.log(dataSource.items());
    //dataSource.items()[0].customTags.forEach(t => {   
    //    this.gridSpotSearch.instance.addColumn({ "caption":t.tag, "dataField":t.tagValue,"isBand":false });
    //  });
  }

  shareSpots() {

    if (this.gridSpotSearch.instance.getSelectedRowKeys().length == 0 && this.selectedMediaFiles.length == 0) {
      this.alertService.showMessage("ERROR", Constants.nospotsSelected, MessageSeverity.error);
      return;
    }

    if (this.gridSpotSearch.instance.getSelectedRowKeys().length > 0 && this.checkIfSelectedSpotsGotVideoFiles() === false) {
      this.alertService.showMessage("ERROR", Constants.noVideoFilesForSelectedSpots, MessageSeverity.error);
      return;
    }

    if (this.selectedMediaFiles.length > 0 && this.checkIfSelectedSpotFilesGotVideoFiles() === false) {
      this.alertService.showMessage("ERROR", Constants.noVideoFilesForSelectedSpots, MessageSeverity.error);
      return;
    }

    var initialState = {
      spotFileGuids: this.getSelectedSpotsAsArray(),
      isMasterMediaShare: false,
      albumName: null,
      source: "search"
    };

    console.log(initialState);

    this.modalRef = this.modalService.show(ShareMediaPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  shareMasterMedia() {
    if (this.gridSpotSearch.instance.getSelectedRowKeys().length == 0) {
      this.alertService.showMessage("ERROR", Constants.nospotsSelected, MessageSeverity.error);
      return;
    }
    else {
      var invalidSpots = this.getSpotsThanCannotBeDownloaded();

      if (invalidSpots.length > 0) {

        var initialState = {
          errors: invalidSpots,
          title: 'The following asset cannot be shared'
        };

        this.modalService.show(ErrorPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

        return;
      }
    }

    var initialStateShare = {
      spotFileGuids: this.getSelectedSpotsAsArray(),
      isMasterMediaShare: true,
      albumName: null,
      source: "search",
      showOnlyBasicActions: this.searchConfig.showOnlyBasicActions
    };

    console.log(initialStateShare);

    this.modalRef = this.modalService.show(ShareMediaPopupComponent, this.util.getModalComponentOptions(initialStateShare, false, false, true));
  }

  expireSpots() {

    //if (this.gridSpotSearch.instance.getSelectedRowKeys().length == 0)
    //  this.alertService.showMessage("ERROR", Constants.nospotsSelected, MessageSeverity.error);
    //else if (this.checkIfSelectedSpotsAreNotPlaceHolders() == false)
    //  this.alertService.showMessage("ERROR", Constants.fewSelectedSpotArePlaceholders, MessageSeverity.error);
    //else {

      var initialState = {
        spots: this.getSelectedParentSpotsAsArray()
      };

      this.modalRef = this.modalService.show(ExpirePopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
    //}
  }

  deletePlaceholders() {
    if (this.gridSpotSearch.instance.getSelectedRowKeys().length == 0)
      this.alertService.showMessage("ERROR", Constants.nospotsSelected, MessageSeverity.error);
    else if (this.gridSpotSearch.instance.getSelectedRowKeys().length > 0 && this.checkIfSelectedSpotsArePlaceHolders() === false) {
      this.alertService.showMessage("ERROR", Constants.notPlaceholdersInSelectedSpots, MessageSeverity.error);
    }
    else {
      var initialState = {
        spots: this.getSelectedParentSpotsAsArray()
      };

      this.modalRef = this.modalService.show(DeletePlaceholderPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
    }
  }

  onClickQuickLink() {

    if (this.quickLinkSelectedDetailSpotData.length == 0 && this.quickLinkSelectedSpotData.length == 0) {
      this.alertService.showMessage("ERROR", Constants.nospotsSelected, MessageSeverity.error);
      return;
    }

    //if (this.gridSpotSearch.selectedRowKeys.length == 0) {
    //  this.alertService.showMessage("ERROR", Constants.nospotsSelected, MessageSeverity.error);
    //  return;
    //}

    var config = this.getQuickLinkConfig().subscribe(t => {

      var selectedSpots: any[] = [];
      this.quickLinkSelectedDetailSpotData.forEach((item) => {
        selectedSpots.push(item);
      });

      this.quickLinkSelectedSpotData.forEach((item) => {
        selectedSpots.push(item);
      });

      var initialState = {
        spotData: selectedSpots,
        config: t
      };

      this.modalRef = this.modalService.show(QuickLinkPopUp, this.util.getModalComponentOptions(initialState, false, false, true));

      this.modalRef.content.onSubmitSuccess.subscribe(result => {
        if (result) {
          this.deSelectRows();
          this.subService.quickLinkSubmit();
        }
      });

      this.modalRef.content.onClose.subscribe((removedSpotFileIds: string[]) => {
        if (removedSpotFileIds.length > 0) {
          removedSpotFileIds.forEach((deSelectedItem) => {
            this.isSearching = true;
            this.gridSpotSearch.instance.deselectRows([deSelectedItem]);
            this.quickLinkSelectedSpotData.forEach((item, j) => {
              if (item.spotFileGuid === deSelectedItem) this.quickLinkSelectedSpotData.splice(j, 1);
            });
          });
        }
      });
    });
  }

  onSpotFilesGridSelectionChanged(e) {

    e.currentSelectedRowKeys.forEach((item, i) => {
      if (this.selectedMediaFiles.indexOf(item) == -1)
        this.selectedMediaFiles.push(item);
    });

    e.currentDeselectedRowKeys.forEach((deSelectedItem, i) => {
      this.selectedMediaFiles.forEach((item, j) => {
        if (item === deSelectedItem)
          this.selectedMediaFiles.splice(j, 1);
      });
    });

    e.selectedRowsData.forEach((item, i) => {
      if (this.quickLinkSelectedDetailSpotData.filter(sd => sd.spotFileGuid == item.spotFileGuid).length == 0)
        this.quickLinkSelectedDetailSpotData.push(item);
    });

    e.currentDeselectedRowKeys.forEach((deSelectedItem) => {
      this.quickLinkSelectedDetailSpotData.forEach((item, j) => {
        if (item.spotFileGuid === deSelectedItem)
          this.quickLinkSelectedDetailSpotData.splice(j, 1);
      });
    });
  }

  onSpotFilesGridParentSelectionChanged(e) {
    //(onSelectionChanged)
    this.addParentGridSpotsData();

    if (this.isSearching == false) {
      e.currentDeselectedRowKeys.forEach((deSelectedItem) => {
        this.quickLinkSelectedSpotData.forEach((item, j) => {
          if (item.spotFileGuid === deSelectedItem) this.quickLinkSelectedSpotData.splice(j, 1);
        });
      });
    }

    this.isSearching = false;
  }

  addParentGridSpotsData() {
    this.gridSpotSearch.instance.getSelectedRowsData().forEach((item) => {
      if (this.quickLinkSelectedSpotData.filter(sd => sd.spotFileGuid == item.spotFileGuid).length <= 0) {
        this.quickLinkSelectedSpotData.push(item);
      }
    });
  }

  onSpotFilesGridContentReady(e) {
    this.detailGridInstances.push(e.component);

    this.quickLinkSelectedDetailSpotData.forEach((item, i) => {
      if (!e.component.isRowSelected(item))
        e.component.selectRows([item.spotFileGuid], true);
    });
  }

  onSpotFilesParentGridContentReady(e) {
    //(onContentReady)
    this.quickLinkSelectedSpotData.forEach((item, i) => {
      if (!this.gridSpotSearch.instance.isRowSelected(item)) {
        this.isSearching = true;
        this.gridSpotSearch.instance.selectRows([item.spotFileGuid], true);
      }
    });
  }

  checkIfSelectedSpotFilesGotVideoFiles(): boolean {

    var returnValue = true;

    this.detailGridInstances.forEach((g, i) => {
      for (var i = 0; i < g.getSelectedRowKeys().length; i++) {
        if (g.getSelectedRowsData()[i].isPlayable == false)
          returnValue = false;
      }
    });

    return returnValue;
  }

  onCellPrepared(e: any) {

    if (e.rowType === "data" && e.column.command === "expand") {

      if (e.data != null) {
        if (e.data.spotFiles.length == 0) {
          e.cellElement.removeClass("dx-datagrid-expand");
          e.cellElement.empty();
        }
      }
    }
  }

  public deSelectRows() {
    this.quickLinkSelectedSpotData = [];
    this.quickLinkSelectedDetailSpotData = [];

    this.gridSpotSearch.instance.clearSelection();
    //this.gridSpotSearch.instance.deselectRows(this.gridSpotSearch.instance.getSelectedRowKeys());

    this.detailGridInstances.forEach((g) => {
      console.log(g);
      g.deselectRows(g.getSelectedRowKeys());
    });
  }

  public showDetail(id) {

    if (id == "00000000-0000-0000-0000-000000000000")
      return;

    this.gs.openSpotFile(id);
    //this.router.navigate([routeEnum[routeEnum.mediadetail], id]);
    //this.router.navigate([RouteConstants.mediaDetailRoute, id], { queryParams: { source: mediaDetailSource[mediaDetailSource.Search] } });
  }

  public quickPlay(proxy, title, thumbnail, format) {

    if (proxy == null || proxy == '')
      return;

    const initialState = {
      videoSource: proxy,
      thumbnail: thumbnail,
      title: title,
      format: format
    };

    this.modalRef = this.modalService.show(QuickplayerComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  public downloadOtherFile(storageKey: string, storageType: string, fileName: string) {
    this.onHideDownloadPop();

    if (storageType != null && storageType.toLowerCase() == 's3')
      this.ds.initiateQuickS3Download(storageKey, fileName, false, false, true, null);
    else
      this.ds.initiateQuickAsperaDownload(storageKey);
  }

  public modalClose() {
    this.modalRef.hide();
  }

  ngOnDestroy() {
    //console.log("destroyed search");
    this.sub.unsubscribe();
  }

  public onHideDownloadPop() {
    this.delDownloadpop.forEach(p => {
      p.hide();
    });
  }

  public onExporting() {
    this.gridSpotSearch.instance.beginUpdate();

    this.gridSpotSearch.instance.columnOption("thumbnailUrl", "visible", false);

    this.gridSpotSearch.instance.columnOption("createdDate", "visible", false);
    this.gridSpotSearch.instance.columnOption("expiryDate", "visible", false);

    this.gridSpotSearch.instance.columnOption("exportCreatedDate", "visible", true);
    this.gridSpotSearch.instance.columnOption("exportExpiryDate", "visible", true);

    this.gridSpotSearch.instance.columnOption("audioFormat", "visible", true);
    this.gridSpotSearch.instance.columnOption("spotTrac", "visible", true);
    this.gridSpotSearch.instance.columnOption("veilEncode", "visible", true);
    this.gridSpotSearch.instance.columnOption("subtitles", "visible", true);
    this.gridSpotSearch.instance.columnOption("centerCutProtected", "visible", true);
    this.gridSpotSearch.instance.columnOption("descriptiveVideo", "visible", true);
    this.gridSpotSearch.instance.columnOption("broadcast", "visible", true);
    this.gridSpotSearch.instance.columnOption("web", "visible", true);
    this.gridSpotSearch.instance.columnOption("taggable", "visible", true);
    this.gridSpotSearch.instance.columnOption("generic", "visible", true);
    this.gridSpotSearch.instance.columnOption("runningFootage", "visible", true);

    var dataSource = this.gridSpotSearch.instance.getDataSource();
    if (dataSource.items().length > 0) {
      var item = dataSource.items()[0];
      if (item.customTags != null && item.customTags.length > 0) {
        for (let i = 0; i < item.customTags.length; i++) {
          var dataField = `customTag${i + 1}`;
          this.gridSpotSearch.instance.addColumn({ "caption": item.customTags[i].tag, "dataField": dataField });
          this.dataGridColumnsAdded.push(item.customTags[i].tag);
        }
      }
    }
  }

  public onExported() {
    this.gridSpotSearch.instance.columnOption("thumbnailUrl", "visible", true);

    this.gridSpotSearch.instance.columnOption("createdDate", "visible", true);
    this.gridSpotSearch.instance.columnOption("expiryDate", "visible", true);

    this.gridSpotSearch.instance.columnOption("exportCreatedDate", "visible", false);
    this.gridSpotSearch.instance.columnOption("exportExpiryDate", "visible", false);

    this.gridSpotSearch.instance.columnOption("audioFormat", "visible", false);
    this.gridSpotSearch.instance.columnOption("spotTrac", "visible", false);
    this.gridSpotSearch.instance.columnOption("veilEncode", "visible", false);
    this.gridSpotSearch.instance.columnOption("subtitles", "visible", false);
    this.gridSpotSearch.instance.columnOption("centerCutProtected", "visible", false);
    this.gridSpotSearch.instance.columnOption("descriptiveVideo", "visible", false);
    this.gridSpotSearch.instance.columnOption("broadcast", "visible", false);
    this.gridSpotSearch.instance.columnOption("web", "visible", false);
    this.gridSpotSearch.instance.columnOption("taggable", "visible", false);
    this.gridSpotSearch.instance.columnOption("generic", "visible", false);
    this.gridSpotSearch.instance.columnOption("runningFootage", "visible", false);

    this.dataGridColumnsAdded.forEach(col => {
      this.gridSpotSearch.instance.deleteColumn(col);
    });

    this.gridSpotSearch.instance.endUpdate();
  }

  private getQuickLinkConfig(): Observable<QuickLinkConfig> {
    return this.configService.getQuickLinkConfig().pipe(map((res: any) => {
      if (res.isSuccess == true) {
        return (res.result as QuickLinkConfig);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
        this.alertService.ShowLoader(false);
        return { transcodePredefinedSpecs: [], AllowSpecialOptions: false };
      }
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
        return { transcodePredefinedSpecs: [], AllowSpecialOptions: false };
      }));
  }

  public downloadSpots() {

    if (this.gridSpotSearch.instance.getSelectedRowKeys().length == 0) {
      this.alertService.showMessage("ERROR", Constants.nospotsSelected, MessageSeverity.error);
      return;
    }
    else {

      var invalidSpots = this.getSpotsThanCannotBeDownloaded();

      if (invalidSpots.length > 0) {

        var initialState = {
          errors: invalidSpots,
          title: 'The following asset are not available to download'
        };

        this.modalService.show(ErrorPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

        return;
      }
    }

    var response = this.ds.initiateS3Download(this.getSelectedDownloadableSpots());

    if (response == true)
      this.router.navigate([RouteConstants.mediaDownloadRoute], { queryParams: { t: "media" } });
  }

  public restoreFiles() {

    if (this.gridSpotSearch.instance.getSelectedRowKeys().length == 0) {
      this.alertService.showMessage("ERROR", Constants.nospotsSelected, MessageSeverity.error);
      return;
    }
    else {

      var invalidSpots = this.getSpotsThatCannotBeRestored();

      if (invalidSpots.length > 0) {

        var initialState = {
          errors: invalidSpots,
          title: 'The following asset are not eligible for restore'
        };

        this.modalService.show(ErrorPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

        return;
      }
      var restoreRequest = {
        spots: this.getSelectedRestorableSpots()
      };
     
      this.spotService.restoreSpots(restoreRequest).subscribe((res: any) => {
        if (res.isSuccess == true) {
          var results = res.result as Array<SpotRestoreResult>;
          var inValidResults = results.filter(t => t.isSuccess == false);

          if (inValidResults.length == 0) {
            this.alertService.showMessage("SUCCESS", Constants.spotsRestoreRequestSuccessful, MessageSeverity.success);
          }
          else {
            let updateMessageList: string[] = [];
            for (let res of inValidResults) {
              updateMessageList.push(`${res.spotIdentifier}: ${res.error}`);
            }

            var initialState = {
              errors: updateMessageList
            };

            this.modalService.show(ErrorPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

          }
          this.subService.callDeselectSearchGridRowsMethod();
        }
        else {
          this.util.handleIsNotSuccess(res.errors);
        }
      },
        error => {
          this.util.handleError(error)
        });
    }
  }

  private getSelectedDownloadableSpots(): Array<SpotFile> {
    let selectedSpotFiles: SpotFile[] = [];
    var grid = this.gridSpotSearch.instance;

    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      let sf = new SpotFile();
      sf.spotFileGuid = grid.getSelectedRowsData()[i].spotFileGuid;
      sf.adId = grid.getSelectedRowsData()[i].adId;
      sf.title = grid.getSelectedRowsData()[i].title;
      sf.storageKey = grid.getSelectedRowsData()[i].storageKey;
      sf.fileName = grid.getSelectedRowsData()[i].masterFileName;

      selectedSpotFiles.push(sf);
    }

    return selectedSpotFiles;
  }

  private getSpotsThanCannotBeDownloaded(): Array<string> {

    var spotsThatCannotBeDownloaded: Array<string> = [];
    var grid = this.gridSpotSearch.instance;

    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      if (grid.getSelectedRowsData()[i].isMasterDownloadable == false || grid.getSelectedRowsData()[i].storageKey == '')
        spotsThatCannotBeDownloaded.push(grid.getSelectedRowsData()[i].adId + " - " + grid.getSelectedRowsData()[i].title);
    }

    return spotsThatCannotBeDownloaded;
  }

  private getSpotsThatCannotBeRestored(): Array<string> {

    var spotsThatCannotBeRestored: Array<string> = [];
    var grid = this.gridSpotSearch.instance;

    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      if (grid.getSelectedRowsData()[i].isArchived == false)
        spotsThatCannotBeRestored.push(grid.getSelectedRowsData()[i].adId + " - " + grid.getSelectedRowsData()[i].title);
    }

    return spotsThatCannotBeRestored;
  }

  private getSelectedRestorableSpots(): Array<string> {
    var selectedSpotFiles: Array<string> = [];
    var grid = this.gridSpotSearch.instance;

    for (var i = 0; i < grid.getSelectedRowKeys().length; i++) {
      selectedSpotFiles.push(grid.getSelectedRowsData()[i].spotFileGuid);
    }

    return selectedSpotFiles;
  }

  public getGridDetailsByUserSettings() {
    this.tandemGridService.getGridDetailsByUserSettings(Constants.SEARCHALLASSETS).subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {
        this.tandemGrid = res.result as TandemGrid;
        this.setGridColumns();
      }
      else {
        this.alertService.showMessage("ERROR", Constants.failed_grid_columns, MessageSeverity.error);
      }
    },
      error => {
        this.alertService.showMessage("ERROR", Constants.failed_grid_columns, MessageSeverity.error);
      });
  }

  public setGridColumns() {
    this.gridColumns = [];
    this.allowMultiSelection = this.tandemGrid.allowMultipleSelection;
    var columns = this.tandemGrid.tandemGridColumns;
    for (var i = 0; i < columns.length; i++) {
      if (columns[i].isActive) {
        this.gridColumns.push(
          {
            dataField: columns[i].dataField,
            caption: columns[i].caption != null ? columns[i].caption : columns[i].dataField,
            cellTemplate: columns[i].cellTemplate != null ? columns[i].cellTemplate : '',
            width: (columns[i].width != null && columns[i].width != 0) ? columns[i].width + "%" : null,
            cssClass: columns[i].cssClass,
            visible: columns[i].visible,
            allowFiltering: columns[i].allowFiltering,
            allowSorting: columns[i].allowSorting,
            sortOrder: columns[i].sortOrder,
            isExportColumn: columns[i].isExportColumn
          },
        );
      }
    }
    console.log(this.gridColumns);
  }

  public onContextMenuPreparing(e) {
    if (e.target === 'header') {
      e.items = [];
      // Filter out the items related to sorting
      //if (e.items != null) {
      //  e.items = e.items.filter(item => item.text !== 'Sort Ascending' && item.text !== 'Sort Descending' && item.text !== 'Clear Sorting' && item.text !== 'Fix' && item.text !== 'Unfix');
      //} else {
      //  e.items = [];
      //}

      this.hearderMenuItems = [];
      for (var i = 0; i < this.gridColumns.length; i++) {
        if (this.gridColumns[i].isExportColumn == false) {
          this.hearderMenuItems.push(
            {
              text: this.gridColumns[i].caption != null ? this.gridColumns[i].caption : this.gridColumns[i].dataField,
              dataField: this.gridColumns[i].dataField,
              onItemClick: (e) => this.onContextMenuItemClick(e),
              selected: this.gridColumns[i].visible
            },
          );
        }
      }
      this.hearderMenuItems.push(
        {
          text: "Reset",
          dataField: "Reset",
          onItemClick: (e) => this.onResetClicked(e)
        }
      );
      e.items = e.items.concat(this.hearderMenuItems);

      // Add checkboxes to other context menu items
      e.items.forEach(item => {
        item.template = "<div><input type='checkbox' " + (item.selected ? "checked" : "") + " />&nbsp;" + item.text + "&nbsp;&nbsp;</div>";
        if (item.text == "Reset") {
          item.template = "<div><button class='btn tyl-btn btn-extra-small'> Reset </button></div>";
        }
      });
    }
  }

  public onResetClicked(e) {
    this.alertService.ShowLoader(true);
    this.resetGridDetailsInUserSettings();
  }

  public onContextMenuItemClick(e) {
    var currentVisiblility: boolean = this.gridSpotSearch.instance.columnOption(e.itemData.dataField, "visible");
    this.gridSpotSearch.instance.columnOption(e.itemData.dataField, "visible", !currentVisiblility);
    this.gridColumns.find(a => a.dataField == e.itemData.dataField).visible = !currentVisiblility;
    this.tandemGrid.tandemGridColumns.find(a => a.dataField == e.itemData.dataField).visible = !currentVisiblility;

    const userSettings = {
      settingValue: JSON.stringify(this.tandemGrid),
      gridId: this.tandemGrid.tandemGridId,
      gridName: this.tandemGrid.gridName,
      isActive: true
    };

    console.log(userSettings);
    this.saveGridDetailsInUserSettings(userSettings);
  }

  public saveGridDetailsInUserSettings(userSettings: any) {
    this.tandemGridService.saveGridDetailsInUserSettings(userSettings).subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {
        console.log("user setting have changed successfully.");
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  public resetGridDetailsInUserSettings() {
    this.tandemGridService.resetGridDetailsForUser(Constants.SEARCHALLASSETS).subscribe((res: any) => {
      console.log(res);
      this.alertService.ShowLoader(false);
      if (res.isSuccess == true) {
        this.tandemGrid = res.result as TandemGrid;
        this.setGridColumns();
        console.log("Reset user settings successfully.");
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  //public manageGridActions() {
  //  var initialState = {
  //    gridButtons: this.gridButtons
  //  };
  //  console.log(initialState);
  //  this.modalRef = this.modalService.show(ManageGridButtonsComponent, this.util.getModalComponentOptions(initialState, true, false, false));

  //  this.modalRef.content.updatedGridButtons.subscribe(result => {
  //    this.gridButtons = result;
  //    console.log(this.gridButtons);
  //  });
  //}

  //public handleGridButtonVisibilities() {
  //  for (var i = 0; i < this.gridButtons.length; i++) {
  //    if (this.gridButtons[i].gridButtonName == SearchAllAssetGridButton.AddToPlayList &&
  //      this.gridButtons[i].gridButtonStatus == true) {
  //      this.IsAddToPlayListVisible = true;
  //    } else if (this.gridButtons[i].gridButtonName == SearchAllAssetGridButton.Share &&
  //      this.gridButtons[i].gridButtonStatus == true) {
  //      this.IsShareVisible = true;
  //    }
  //  }
  //}
}
