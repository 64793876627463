<div class="modal-header">
  <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  <h4 class="modal-title">Update QC Comments</h4>
</div>
<div class="modal-body">
  <form [formGroup]="editRequest" #f="ngForm" (ngSubmit)="onSave(editRequest)">
    <div class="tylie-form-group form-group" [ngClass]="{error: (editRequest.controls['newValue'].errors) && f.submitted}">
      <textarea formControlName="newValue" class="tylie-textarea tylie-textarea--lg form-control" name="textareactrl"></textarea>
      <p class="p-extra-small" *ngIf="editRequest.controls['newValue'].errors?.required || editRequest.controls['newValue'].hasError('inValidEmailList') || editRequest.controls['newValue'].hasError('inValidFilePath')">
        Please Enter QC Notes
      </p>
    </div>

    <div class="d-flex justify-content-end">
      <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
      <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()">Cancel</button>
    </div>

  </form>
</div>
