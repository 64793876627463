<div class="modal-header" *ngIf="isPopup == true">
  <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
  <h4 class="modal-title">
    {{playListName}}
  </h4>
</div>
<div [class.modal-body]="isPopup" [class.row]="!isPopup">
  <div class="row">
    <div class="col-md-8 col-lg-8">
      <div class="tylie-v-player video-overlay" *ngIf="this.proxyUrl">
        <div class="tylie-v-player__body" style="z-index: 100">
          <div class="safe-overlay-bg-4-3" [style.height]="overyLayHeightP + 'px'" *ngIf="isOver43Overlay == true"></div>
          <div class="safe-overlay-bg-16-9" [style.height]="overyLayHeightP + 'px'" *ngIf="isOver169Overlay == true"></div>
          <video #videoPlayerJ class="video-js vjs-default-skin vjs-big-play-centered" controls preload="auto">
            Your browser does not support the video tag.
          </video>
          <div *ngIf="currentItem.fps && currentItem.fps > 0">
            SMPTE Timecode: {{ smpteTimecode }}
          </div>
          <div *ngIf="!currentItem.fps || currentItem.fps <= 0">
          </div>
        </div>
        <div class="tylie-v-player__footer" *ngIf="is430verlayRequired || is169OverlayRequired">
            <dx-select-box [(value)]="selectedOverlay"
                           [dataSource]="overlayOptions"
                           displayExpr="name"
                           valueExpr="value"
                           placeholder="OverLays"
                           showClearButton="true"
                           (onValueChanged)="toggleOverlayQ($event.value)">
            </dx-select-box>
          </div>
        </div>
      <div class="tylie-v-player video-overlay" *ngIf="!this.proxyUrl">
        <img class="tylie-v-player__fallback-img" [src]="currentItem.poster" />
      </div>
    </div>
    <div class="col-md-4 col-lg-4">
      <div class="d-flex flex-column" [style.height]="playListHeight + 'px'" style="overflow: auto;">
        <div *ngFor="let item of videoFilesSource; let $index = index" class="tylie-card tylie-card--bordered {{$index == currentIndex ? 'active' : ''}}">
          <a class="tylie-card__img-wrap tylie-card__img-wrap--h100" (click)="onClickPlaylistItem(item, $index)">
            <img class="tylie-card__img" src="{{item.poster}}" alt="...">
          </a>
          <div class="tylie-card__body">
            <h4 class="tylie-card__title">{{item.title}}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
