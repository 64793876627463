<div class="video-container">
    <div class="modal-header">
      <button type="button" class="modal-close" (click)="closePopup()">
        <icon-delete color="#000"></icon-delete>
      </button>
      <h4 class="modal-title" title="{{ title }}">{{ title | truncateByLetters }}</h4>
    </div>
    <div class="modal-body">
      <div class="tylie-v-player video-overlay" *ngIf="proxyUrl">
        <div class="tylie-v-player__body" style="z-index: 100">
          <div class="safe-overlay-bg-4-3" [style.height]="overyLayHeightQ + 'px'" *ngIf="isOver43Overlay "></div>
          <div class="safe-overlay-bg-16-9" [style.height]="overyLayHeightQ + 'px'" *ngIf="isOver169Overlay "></div>
          <video #videoPlayerQ class="video-js vjs-default-skin vjs-big-play-centered" controls preload="auto">
            Your browser does not support the video tag.
          </video>
        </div>
        
        <div class="tylie-v-player__footer" *ngIf="is169OverlayRequired || is430verlayRequired">
            <dx-select-box [(value)]="selectedOverlay"
                           [dataSource]="overlayOptions"
                           displayExpr="name"
                           valueExpr="value"
                           placeholder="Overlays"
                           showClearButton="true"
                           (onValueChanged)="toggleOverlayQ($event.value)">
            </dx-select-box>
        </div>
      </div>
  
      <div class="tylie-v-player video-overlay" *ngIf="!proxyUrl" align="center">
        <img class="tylie-v-player__fallback-img" [src]="thumbnail" />
      </div>
    </div>
  </div>
