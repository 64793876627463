import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, Subject } from "rxjs";
import { AuthService } from '../auth/auth.service';
import { LocalStoreManager } from '../core/local-store-manager.service';
import { EndpointFactory } from '../../configurations/endpoint-factory.service';
import { ApiEndPoint } from "../../configurations/api-endpoint.service";
import { AlertService } from '../core/alert.service';
import { CopyOrderFileRequestViewModel, CreateOrderRequest } from '../../models/order/order-create.model';
import { EditOrderHeaderRequest } from '../../models/order/order-edit-header.model';
import { OrderDetailGroup } from '../../models/order/order-detail.model';
import { TranscodeMaxCapRequestModel, TranscodeMediaOrderRequest } from '../../models/order/transcodeorder-config.model';
import { EditTranscodeOrderRequest } from '../../models/order/transcodeorder.model';

@Injectable()
export class OrderService {

  public onOrderClaimedforProcessing = new Subject<any>();

  constructor(
    private http: HttpClient,
    private apiendPoint: ApiEndPoint,
    private authService: AuthService) { }


  public getOrderHomeInitLoad(): Observable<Object> {
    return this.http.get(this.apiendPoint.getOrderHomeInitLoad());
  }

  public getWorkflowOperations(requestId: string) {
    return this.http.get(this.apiendPoint.getWorkflowOperationsEndPoint() + "/" + requestId);
  }

  public getOrderQueueMessages(id: string) {
    return this.http.get(this.apiendPoint.getOrderQueueMessagesEndPoint() + "/" + id);
  }

  public searchOrderWorkflows(id: string) {
    return this.http.get(this.apiendPoint.searchOrderWorkflowsEndPoint() + "/" + id);
  }

  public updateWorkflow(request: any) {
    return this.http.post(this.apiendPoint.updateWorkflowEndPoint(), request);
  }

  public getOrderDebugDetails(orderGuid: string) {
    return this.http.get(this.apiendPoint.getOrderDebugDetailsEndPoint() + "/" + orderGuid);
  }

  public resendOrderMessage(request: any) {
    return this.http.post(this.apiendPoint.resendOrderMessageEndPoint(), request);
  }

  public sendNewOrderMessage(request: any) {
    return this.http.post(this.apiendPoint.sendNewOrderMessageEndPoint(), request);
  }

  public getDraftOrders(request: any) {
    return this.http.post(this.apiendPoint.getDraftOrders(), request);
  }

  public searchOrders(request: any) {
    return this.http.post(this.apiendPoint.getOrderSearchEndpoint(), request);
  }

  public searchOrdersSpotView(request: any) {
    return this.http.post(this.apiendPoint.getOrderSearchSpotViewEndpoint(), request);
  }

  public getAwaitingReviewOrders(request: any) {
    return this.http.post(this.apiendPoint.getAwaitingReviewOrdersEndPoint(), request);
  }

  public getVendorSubmissionFailedOrders(request: any) {
    return this.http.post(this.apiendPoint.GetVendorSubmissionFailedOrdersEndPoint(), request);
  }

  public getOrdersInProgress(request: any) {
    return this.http.post(this.apiendPoint.getOrdersInProgress(), request);
  }

  public getReadyForBillingOrders(request: any) {
    return this.http.post(this.apiendPoint.getReadyForBillingOrders(), request);
  }

  public getReadyToProcessOrders(request: any) {
    return this.http.post(this.apiendPoint.getReadyToProcessOrdersEndpoint(), request);
  }

  public getProcessingOrders(request: any) {
    return this.http.post(this.apiendPoint.getProcessingOrdersEndpoint(), request);
  }

  public createOrder(createOrderRequest: CreateOrderRequest) {
    return this.http.post(this.apiendPoint.createOrderEndpoint(), createOrderRequest);
  }

  public copyClientLevelOrderFile(request: CopyOrderFileRequestViewModel) {
    return this.http.post(this.apiendPoint.copyClientLevelOrderFileEndpoint(), request);
  }

  public createTranscodeOrder(transcodeOrderRequest: TranscodeMediaOrderRequest) {
    return this.http.post(this.apiendPoint.createTranscodeOrderEndpoint(), transcodeOrderRequest);
  }

  public isMaxRequestReachedForTier(request: TranscodeMaxCapRequestModel) {
    return this.http.post(this.apiendPoint.isMaxRequestReachedForTierEndpoint(), request);
  }

  public editOrder(request: any, orderGuid: string) {
    return this.http.put(this.apiendPoint.editOrderEndPoint() + "/" + orderGuid, request);
  }

  public saveDraft(createOrderRequest: CreateOrderRequest) {
    return this.http.post(this.apiendPoint.saveDraftEndpoint(), createOrderRequest);
  }

  public saveUnSavedOrder(createOrderRequest: CreateOrderRequest) {
    return this.http.post(this.apiendPoint.saveUnSavedOrderEndpoint(), createOrderRequest);
  }

  public getDraft(draftOrderGuid: string) {
    return this.http.get(this.apiendPoint.getOrderDraftEndpoint() + "/" + draftOrderGuid);
  }

  public getUnSavedOrder(unsavedOrderGuid: string) {
    return this.http.get(this.apiendPoint.getUnSavedOrderEndpoint() + "/" + unsavedOrderGuid);
  }

  public getSelectedUploadedOrder(draftOrderGuid: string) {
    return this.http.get(this.apiendPoint.getOrderDraftEndpoint() + "/" + draftOrderGuid);
  }

  public deleteOrderFile(fileGuid: string) {
    return this.http.delete(this.apiendPoint.deleteOrderFileEndpoint() + "/" + fileGuid);
  }

  public deleteClientBrandAttachmentEndpoint(fileGuid: string) {
    return this.http.delete(this.apiendPoint.deleteClientBrandAttachmentEndpoint() + "/" + fileGuid);
  }

  public deleteOrderFtpPod(fileGuid: string, orderGuid: string, orderDestinationId: number) {
    return this.http.delete(this.apiendPoint.deleteOrderFtpPodEndpoint() + "/" + orderGuid + "/" + orderDestinationId + "/" + fileGuid);
  }

  public deleteDraftOrder(draftGuid: string) {
    return this.http.delete(this.apiendPoint.deleteDraftOrderEndpoint() + "/" + draftGuid);
  }

  public claimOrder(orderGuid: string) {
    return this.http.put(this.apiendPoint.claimOrderEndPoint() + "/" + orderGuid, "");
  }

  public claimOrderInDetail(orderGuid: string) {
    return this.http.put(this.apiendPoint.claimOrderInDetailEndPoint() + "/" + orderGuid, "");
  }

  public releaseClaimedOrder(orderGuid: string) {
    return this.http.put(this.apiendPoint.releaseClaimedOrderEndpoint() + "/" + orderGuid, "");
  }

  public sendOrderBackToAE(orderGuid: string, request: any) {
    return this.http.post(this.apiendPoint.sendOrderToAEEndpoint() + "/" + orderGuid, request);
  }

  public setOrderBackToReadyForProcess(orderGuid: string, request: any) {
    return this.http.post(this.apiendPoint.setOrderBackToReadyForProcessEndpoint() + "/" + orderGuid, request);
  }

  public flagAndNotifyOrder(orderGuid: string, request: any) {
    return this.http.post(this.apiendPoint.flagAndNotifyOrderEndpoint() + "/" + orderGuid, request);
  }

  public sendOrderForReview(orderGuid: string, request: any) {
    return this.http.post(this.apiendPoint.sendOrderForReviewEndpoint() + "/" + orderGuid, request);
  }

  public sendOrderToBilling(orderGuid: string, request: any) {
    return this.http.put(this.apiendPoint.sendOrderToBillingEndpoint() + "/" + orderGuid, request);
  }

  public orderEditStart(orderGuid: string) {
    return this.http.put(this.apiendPoint.orderEditStartEndpoint() + "/" + orderGuid, null);
  }

  public orderEditEnd(orderGuid: string) {
    return this.http.put(this.apiendPoint.orderEditEndEndpoint() + "/" + orderGuid, null);
  }

  public orderPassQC(orderGuids: string[]) {
    return this.http.post(this.apiendPoint.orderPassQCEndpoint(), orderGuids);
  }

  public releaseOrderClaimedForProcessing(orderGuid: string) {
    return this.http.put(this.apiendPoint.releaseOrderClaimedForProcessingEndPoint() + "/" + orderGuid, "");
  }

  public orderStartProcess(orderGuids: string[]) {
    return this.http.post(this.apiendPoint.orderStartProcessEndpoint(), orderGuids);
  }

  public orderStartProcessInDetail(orderGuids: string[]) {
    return this.http.post(this.apiendPoint.orderStartProcessInDetailEndpoint(), orderGuids);
  }

  public onOrderClaimedforProcessingSuccess() {
    this.onOrderClaimedforProcessing.next();
  }

  public orderCompleteProcessEndpoint(orderGuids: string[]) {
    return this.http.post(this.apiendPoint.orderCompleteProcessEndpoint(), orderGuids);
  }

  public FlagOrders(orderIds: any[]) {
    return this.http.post(this.apiendPoint.flagOrdersEndpoint(), orderIds);
  }

  public getCopyOrderDetail(request: any) {
    return this.http.post(this.apiendPoint.getCopyOrderDetailEndpoint(), request);
  }

  public getOrderDetail(orderGuid: string) {
    return this.http.get(this.apiendPoint.getOrderDetailEndpoint() + "/" + orderGuid);
  }

  public approveOrderReview(orderGuid: string) {
    return this.http.put(this.apiendPoint.approveOrderReviewEndpoint() + "/" + orderGuid, "");
  }

  public completeOrderProcessing(orderGuid: string) {
    return this.http.put(this.apiendPoint.orderCompleteProcessEndpoint() + "/" + orderGuid, "");
  }

  public complteMultipleOrderProcessing(orderGuids: string[]) {
    return this.http.post(this.apiendPoint.complteMultipleOrderProcessingEndPoint(), orderGuids);
  }

  public getSpotsFromOrder(orderGuid: string) {
    return this.http.get(this.apiendPoint.getSpotsFromOrderEndpoint() + "/" + orderGuid);
  }

  public getDestinationForOrderDetail(orderGuid: string) {
    return this.http.get(this.apiendPoint.getDestinationForOrderDetailEndpoint() + "/" + orderGuid);
  }

  public getFtpUploadDetailsForOrderDetail(orderGuid: string) {
    return this.http.get(this.apiendPoint.getFtpUploadDetailsForOrderDetailEndpoint() + "/" + orderGuid);
  }

  public getContentDownloadDetailsForOrderDetail(orderGuid: string) {
    return this.http.get(this.apiendPoint.getContentDownloadDetailsForOrderDetailEndpoint() + "/" + orderGuid);
  }

  public getShareLinkDetailsForOrderDetail(orderGuid: string) {
    return this.http.get(this.apiendPoint.getShareLinkDetailsForOrderDetailEndpoint() + "/" + orderGuid);
  }

  public editOrderHeader(orderGuid: string, editHeaderRequest: EditOrderHeaderRequest) {
    return this.http.put(this.apiendPoint.editOrderHeaderEndPoint() + "/" + orderGuid, editHeaderRequest);
  }

  public updateOrderFTPUploadDestination(request: any) {
    return this.http.post(this.apiendPoint.updateOrderFTPUploadDestinationEndPoint(), request);
  }

  public createNonDigitalVendorPO(orderGuid: string, request: any) {
    return this.http.post(this.apiendPoint.createNonDigitalVendorPOEndPoint() + "/" + orderGuid, request);
  }

  public reAssignVendorForOrderLineItems(request: any) {
    return this.http.post(this.apiendPoint.reAssignVendorForOrderLineItemsEndPoint(), request);
  }

  public assignVendorForOrderLineItems(request: any) {
    return this.http.post(this.apiendPoint.assignVendorForOrderLineItemsEndPoint(), request);
  }

  public updateDeliveryPOD(request: any) {
    return this.http.post(this.apiendPoint.updateDeliveryPODEndPoint(), request);
  }

  public getOrderAuditLogs(orderGuid: string) {
    return this.http.get(this.apiendPoint.getOrderAuditLogs() + "/" + orderGuid);
  }

  public getOrderGroupServicesForVendorPO(orderGuid: string) {
    return this.http.get(this.apiendPoint.getOrderGroupServicesForVendorPOEndPoint() + "/" + orderGuid);
  }

  public viewVendorPO(orderGuid: string) {
    return this.http.get(this.apiendPoint.viewVendorPOEndPoint() + "/" + orderGuid);
  }

  public retryVendorOrderSubmission(request: any) {
    return this.http.post(this.apiendPoint.retryVendorOrderSubmissionEndPoint(), request);
  }

  public retryVendorOrderStatusCheck(request: any) {
    return this.http.post(this.apiendPoint.retryVendorOrderStatusCheckEndPoint(), request);
  }

  public retryVendorLineItemCreation(orderGuid: string) {
    return this.http.put(this.apiendPoint.retryVendorLineItemCreationEndPoint() + "/" + orderGuid, "");
  }

  public updateVendorOrderNumber(request: any) {
    return this.http.post(this.apiendPoint.updateVendorOrderNumberEndPoint(), request);
  }

  public uploadOrders(request: any) {
    return this.http.post(this.apiendPoint.uploadOrderEndpoint(), request);
  }

  public getUploadedOrders(request: any) {
    return this.http.post(this.apiendPoint.getUploadedOrdersEndpoint(), request);
  }

  public getUploadedOrder(draftOrderGuid: string) {
    return this.http.get(this.apiendPoint.getUploadedOrderEndpoint() + "/" + draftOrderGuid);
  }

  public saveUploadedOrderChanges(createOrderRequest: CreateOrderRequest) {
    return this.http.post(this.apiendPoint.saveUploadedOrderChanges(), createOrderRequest);
  }

  public deleteUploadedOrder(uploadedOrderGuid: string) {
    return this.http.delete(this.apiendPoint.deleteUploadedOrderEndpoint() + "/" + uploadedOrderGuid);
  }

  public reSendMediaToDestinations(request: any) {
    return this.http.post(this.apiendPoint.reSendMediaToDestinationsEndPoint(), request);
  }

  //new download order file

  public downloadOrderFile(orderFileGuid: string) {
    return this.http.get(this.apiendPoint.downloadOrderFileEndpoint() + "/" + orderFileGuid);
  }

  public downloadOpsAttachment(clientBrandAttachmentGuid: string) {
    return this.http.get(this.apiendPoint.downloadOpsAttachmentEndpoint() + "/" + clientBrandAttachmentGuid);
  }

  //public getOpsSummaryUrl(baseUrl: string, orderGuid: string, token: string, profile: string): string {
  //  console.log(profile)
  //  console.log(baseUrl + orderGuid + "?user=" + token + "?state=" + profile)
  //  return baseUrl + orderGuid + "?user=" + token + "?state=" + profile;
  //}
  public getOpsSummaryUrl(baseUrl: string, orderGuid: string, token: string, profile: string): string {
    console.log(profile);
    const url = `${baseUrl}${orderGuid}?user=${token}&state=${profile}`;
    console.log(url);
    return url;
  }

  public getBillingItems(orderId: string) {
    return this.http.get(this.apiendPoint.getBillingItemsEndpoint() + "/" + orderId);
  }

  public getServiceVendors(code: string) {
    return this.http.get(this.apiendPoint.getServiceVendorsEndpoint() + "/" + code);
  }

  public updateServiceVendor(request: any) {
    return this.http.post(this.apiendPoint.updateServiceVendorEndpoint(), request);
  }

  public sendOrderForCancel(orderGuid: string, request: any) {
    return this.http.post(this.apiendPoint.cancelOrderEndPoint() + "/" + orderGuid, request);
  }

  public getAllQueues() {
    return this.http.get(this.apiendPoint.getAllQueuesEndpoint());
  }

  public releaseMultipleOrdersClaimedForProcessing(orderGuids: string[]) {
    return this.http.post(this.apiendPoint.releaseMultipleOrdersClaimedForProcessingEndPoint(), orderGuids);
  }

  public searchTranscodeOrders(request: any) {
    return this.http.post(this.apiendPoint.searchTranscodeOrdersEndPoint(), request);
  }

  public editTranscodeOrder(editRequest: EditTranscodeOrderRequest) {
    return this.http.put(this.apiendPoint.editTranscodeOrderEndPoint(), editRequest);
  }

  public searchRecentTranscodeOrders(searchReq: any) {
    return this.http.post(this.apiendPoint.searchRecentTranscodeOrdersEndPoint(), searchReq);
  }

  public forceUpdateOrderStatus(orderGuid: string, request: any) {
    return this.http.post(this.apiendPoint.forceUpdateOrderStatusEndPoint() + "/" + orderGuid, request);
  }

  public forceUpdateServiceLevel(orderGuid: string, request: any) {
    return this.http.post(this.apiendPoint.forceUpdateServiceLevelEndPoint() + "/" + orderGuid, request);
  }

  public createVendorPurchaseOrder(orderGuid: string, request: any) {
    return this.http.post(this.apiendPoint.createPurchaseOrderEndPoint() + "/" + orderGuid, request);
  }

  public updateOrderlineItemsToStatusCheck(orderGuid: string, request: any) {
    return this.http.post(this.apiendPoint.updateOrderlineItemsToStatusCheckEndPoint() + "/" + orderGuid, request);
  }


  public copyDraftOrder(draftGuid: string) {
    return this.http.get(this.apiendPoint.copyDraftOrderEndpoint() + "/" + draftGuid);
  }

  public updateOrderProduct(orderGuid: string, request: any) {
    return this.http.post(this.apiendPoint.updateOrderProductEndPoint() + "/" + orderGuid, request);
  }

  public sendOrderEmail(request: any) {
    return this.http.post(this.apiendPoint.sendOrderEmailEndpoint(), request);
  }

}
