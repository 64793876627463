<div id="vendorpo-ordergroupservice">
  <div class="modal-header">
    <button type="button" class="modal-close" (click)="closePopup()"><icon-delete color="#000"></icon-delete></button>
    <h4 class="modal-title">{{title}}</h4>
  </div>
  <div class="modal-body">
    <div *ngIf="brandName" class="row">
      <div class="col-md-4">
        <label>Brand</label>
        <p>{{brandName}}</p>
      </div>
    </div>

    <form [formGroup]="costCenterAndBillToForm" (ngSubmit)="oncostCenterAndBillToFormSubmit(costCenterAndBillToForm)" novalidate #ngForm="ngForm">

      <div class="row">
        <div class="col-md-6" *ngIf="brandName && (this.allowEdits == true || (isClientEdit == true && editConfig.canEditCutDownServices == true)) && cutDownServicesDataSource.length > 0" [ngClass]="{error: (costCenterAndBillToForm.controls['cutDownServices'].errors
             && !costCenterAndBillToForm.controls['cutDownServices'].pristine)
             || (costCenterAndBillToForm.controls['cutDownServices'].errors && ngForm.submitted)}">
          <div class="tylie-form-group form-group">
            <label class="tylie-form-group__lbl" for="bundleServices">Cutdown Services</label>

            <dx-drop-down-box [(value)]="servicesValue"
                              formControlName="cutDownServices"
                              id="cutDownServices"
                              class="tylie-select form-control"
                              valueExpr="id"
                              displayExpr="name"
                              placeholder="Select Services"
                              [showClearButton]="true"
                              (onValueChanged)="syncTreeViewSelection()"
                              [dataSource]="cutDownServicesDataSource">
              <div *dxTemplate="let data of 'content'">
                <dx-tree-view [dataSource]="cutDownServicesDataSource"
                              dataStructure="plain"
                              keyExpr="id"
                              #treeServices
                              parentIdExpr="parentId"
                              selectionMode="multiple"
                              showCheckBoxesMode="normal"
                              displayExpr="name"
                              [selectByClick]="true"
                              (onContentReady)="syncTreeViewSelection($event)"
                              (onItemSelectionChanged)="treeView_itemSelectionChanged($event)">
                </dx-tree-view>
              </div>
            </dx-drop-down-box>
          </div>
        </div>
        <div class="col-md-6" *ngIf="brandName && (this.allowEdits == true || (isClientEdit == true && editConfig.canEditCutDownServices == true)) && reslateBundleServicesDataSource.length > 0" [ngClass]="{error: (costCenterAndBillToForm.controls['reslatebundleServices'].errors
             && !costCenterAndBillToForm.controls['reslatebundleServices'].pristine)
             || (costCenterAndBillToForm.controls['reslatebundleServices'].errors && ngForm.submitted)}">
          <div class="tylie-form-group form-group">
            <label class="tylie-form-group__lbl" for="bundleServices">Reslate Bundle Services</label>

            <dx-drop-down-box [(value)]="rbservicesValue"
                              formControlName="reslatebundleServices"
                              id="reslatebundleServices"
                              class="tylie-select form-control"
                              valueExpr="id"
                              displayExpr="name"
                              placeholder="Select Services"
                              [showClearButton]="true"
                              (onValueChanged)="syncrbTreeViewSelection()"
                              [dataSource]="reslateBundleServicesDataSource">
              <div *dxTemplate="let data of 'content'">
                <dx-tree-view [dataSource]="reslateBundleServicesDataSource"
                              dataStructure="plain"
                              keyExpr="id"
                              #treeServices
                              parentIdExpr="parentId"
                              selectionMode="multiple"
                              showCheckBoxesMode="normal"
                              displayExpr="name"
                              [selectByClick]="true"
                              (onContentReady)="syncrbTreeViewSelection($event)"
                              (onItemSelectionChanged)="rbtreeView_itemSelectionChanged($event)">
                </dx-tree-view>
              </div>
            </dx-drop-down-box>
          </div>
        </div>
      </div>

      <div class="search-all-media">
        <div class="row">
          <div class="col-md-6">
            <div class="tylie-form-group form-check">
              <input type="checkbox" class="form-check-input" formControlName="isWCPFileDeliveryBilled" name="isWCPFileDeliveryBilled" id="isWCPFileDeliveryBilled">
              <label class="form-check-label" for="isWCPFileDeliveryBilled">WCP File Delivery Billed</label>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12" *ngIf="brandName && (this.allowEdits == true || (isClientEdit == true && editConfig.canEditPODDistributionEmails == true))">
          <div class="tylie-form-group form-group" [ngClass]="{error: costCenterAndBillToForm.controls['podEmails'].hasError('inValidEmailList') && (!costCenterAndBillToForm.controls['podEmails'].pristine || ngForm.submitted)}">
            <label class="tylie-form-group__lbl" for="podEmails">
              POD Recipients (via Email)<span class="required">*</span>
            </label>
            <textarea id="podEmails" name="podEmails" class="tylie-textarea tylie-textarea--md form-control" formControlName="podEmails" placeholder="semi-colon seperated"></textarea>
            <p class="p-extra-small">Please enter valid Emails seperated by semi-colon</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" *ngIf="brandName && (this.allowEdits == true || (isClientEdit == true && editConfig.canEditPODDistributionEmails == true))">
          <div class="tylie-form-group form-group">
            <label class="tylie-form-group__lbl" for="cxComments">
              CX Comments
            </label>
            <textarea id="cxComments" name="cxComments" class="tylie-textarea tylie-textarea--md form-control" formControlName="cxComments"></textarea>
            <p class="p-extra-small">Please enter Cx Comments</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" *ngIf="brandName && (this.allowEdits == true || (isClientEdit == true && editConfig.canEditPODDistributionEmails == true))">
          <div class="tylie-form-group form-group">
            <label class="tylie-form-group__lbl d-flex justify-content-between" for="opsNotes">
              Ops Notes
              <div class="tylie-upload" *ngIf="attachments == null || (attachments?.length == 0)">
                <input style="display: none;" id="f04" type="file" multiple #selectedFile (change)="onAddOPS($event)" accept="application/pdf" />
                <label class="tylie-upload__link" for="f04">
                  {{isAttaching ? 'Attaching...' : 'Add Attachments'}}
                  <i *ngIf="isAttaching" class='fa fa-circle-o-notch fa-spin'></i>
                </label>
              </div>
            </label>
            <textarea id="opsNotes" name="opsNotes" class="tylie-textarea tylie-textarea--md form-control" formControlName="opsNotes"></textarea>
            <p class="p-extra-small">Please enter Ops Notes</p>
          </div>
        </div>
      </div>

      <div class="tylie-panel tylie-panel--secondary" id="buildOrderPanel" *ngIf="(attachments?.length > 0)">
        <div class="tylie-panel__header tylie-panel__header--br-thick">
          <h4 class="tylie-panel__title">
            Attachments
            <!--<button type="button" class="tylie-button tylie-button--link tylie-button--xs" (click)="onAddAttachments()"><span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>Add Attachments</button>-->
          </h4>
        </div>
        <div class="tylie-panel__body time-comment-view">
          <ng-conatainer *ngIf="attachments?.length == 0">
            There are no attachments yet
          </ng-conatainer>
          <table class="tylie-table table table-bordered" *ngIf="attachments?.length > 0">
            <thead class="tylie-table__head">
              <tr>
                <th style="width:20%;">File Type</th>
                <th style="width:76%;">File Name</th>
                <th style="width:4%;"></th>
              </tr>
            </thead>
            <tbody class="tylie-table__body workflow-section">
              <tr *ngFor="let att of attachments; let i = index">
                <td style="width:20%;" >{{att.fileCategory}}</td>
                <td style="width:76%;" class="blue-text"><a (click)="onDownloadFile(att.clientBrandAttachmentGuid, att.fileName);">{{att.fileName}}</a></td>
                <td align="center">
                  <a class="tylie-button tylie-button--xs tylie-button--link" (click)="onAttachmentDelete(att.clientBrandAttachmentGuid)">
                    <icon-trash-can color="#1e90ff"></icon-trash-can>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row" *ngIf="brandName && (this.allowEdits == true || (isClientEdit == true && editConfig.canEditPODDistributionEmails == true))">
        <div class="col-md-3">
          <div class="tylie-form-group form-group" [ngClass]="{error: costCenterAndBillToForm.controls['stdStorageDays'].hasError('inValidAliasId') && (!costCenterAndBillToForm.controls['stdStorageDays'].pristine || parentForm.submitted)}">
            <label class="tylie-form-group__lbl" for="StdStorageDays">Standard<span class="required">*</span></label>
            <label class="tylie-form-group__slbl" for="StdStorageDays1">(Minimum # of days)<span class="required">*</span></label>
            <input formControlName="stdStorageDays" type="text" id="stdStorageDays" name="stdStorageDays" class="tylie-text form-control " />
            <p class="p-extra-small">should be numeric value</p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="tylie-form-group form-group" [ngClass]="{error: costCenterAndBillToForm.controls['infrqStorageDays'].hasError('inValidAliasId') && (!costCenterAndBillToForm.controls['infrqStorageDays'].pristine || parentForm.submitted)}">
            <label class="tylie-form-group__lbl" for="InfrqStorageDays">Infrequent Access<span class="required">*</span></label>
            <label class="tylie-form-group__slbl" for="InfrqStorageDays1">(Minimum # of days)<span class="required">*</span></label>
            <input formControlName="infrqStorageDays" type="text" id="infrqStorageDays" name="infrqStorageDays" class="tylie-text form-control " />
            <p class="p-extra-small">should be numeric value</p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="tylie-form-group form-group" [ngClass]="{error: costCenterAndBillToForm.controls['archiveDurationInDays'].hasError('inValidAliasId') && (!costCenterAndBillToForm.controls['archiveDurationInDays'].pristine || parentForm.submitted)}">
            <label class="tylie-form-group__lbl" for="ArchiveStorageDays">Archive<span class="required">*</span></label>
            <label class="tylie-form-group__slbl" for="ArchiveStorageDays1">(Minimum # of days)<span class="required">*</span></label>
            <input formControlName="archiveDurationInDays" type="text" id="archiveDurationInDays" name="archiveDurationInDays" class="tylie-text form-control " />
            <p class="p-extra-small">should be numeric value</p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="tylie-form-group form-group" [ngClass]="{error: costCenterAndBillToForm.controls['activeTandemStorageInDays'].hasError('inValidAliasId') && (!costCenterAndBillToForm.controls['activeTandemStorageInDays'].pristine || parentForm.submitted)}">
            <label class="tylie-form-group__lbl" for="ArchiveStorageDays">Active Tandem Storage<span class="required">*</span></label>
            <label class="tylie-form-group__slbl" for="ArchiveStorageDays1">(Minimum # of days)<span class="required">*</span></label>
            <input formControlName="activeTandemStorageInDays" type="text" id="activeTandemStorageInDays" name="activeTandemStorageInDays" class="tylie-text form-control " />
            <p class="p-extra-small">should be numeric value</p>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="(this.allowEdits == true || (isClientEdit == true && editConfig.canAddEditCCandBT == true))">
        <div class="col-md-6" id="costCenterFormArray">
          <div class="tylie-form-group form-group">
            <label class="tylie-form-group__lbl" for="costCenter">Cost Center</label>
            <div formArrayName="costCenters" *ngFor="let costCenterGroup of formCostCentersArray.controls; let i = index">
              <div class="" [formGroupName]="i"
                   [ngClass]="{error: (formCostCentersArray.controls[i].controls['costCenter'].errors && (!formCostCentersArray.controls[i].controls['costCenter'].pristine || ngForm.submitted))}">
                <input type="text" id="costCenter" name="costCenter" class="tylie-text form-control" formControlName="costCenter" />
                <!-- <i class="tyl-clickable glyphicon glyphicon-remove-sign form-control-feedback" *ngIf="costCenters.length > 1" (click)="onCostCenterRemove(i)"></i> -->
                <button class="tylie-button tylie-button--link ms-2" type="button" *ngIf="costCenters.length > 1" (click)="onCostCenterRemove(i)"><icon-trash-can color="#1e90ff"></icon-trash-can></button>
                <p class="p-extra-small" *ngIf="formCostCentersArray.controls[i].controls['costCenter'].errors?.pattern">Cost Center must not include semi colon (;)</p>
                <p class="p-extra-small" *ngIf="formCostCentersArray.controls[i].controls['costCenter'].errors?.maxlength">
                  Cost Center can have a maximum of 35 characters.
                </p>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onCostCenterAddMore()">
              <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
              ADD MORE
            </button>
          </div>
        </div>

        <div class="col-md-6" id="billToFormArray">
          <div class="tylie-form-group form-group">
            <label class="tylie-form-group__lbl" for="billTo">Bill To</label>
            <div formArrayName="billTos" *ngFor="let billToGroup of formbillTosArray.controls; let i = index">
              <!--move to different component-->
              <div class="" [formGroupName]="i"
                   [ngClass]="{error: (formbillTosArray.controls[i].controls['billTo'].errors && (!formbillTosArray.controls[i].controls['billTo'].pristine || ngForm.submitted))}">
                <input type="text" id="billTo" name="billTo" class="tylie-text form-control" formControlName="billTo" />
                <!-- <i class="tyl-clickable glyphicon glyphicon-remove-sign form-control-feedback" *ngIf="billTos.length > 1" (click)="onBillToRemove(i)"></i> -->
                <button class="tylie-button tylie-button--link ms-2" type="button" *ngIf="billTos.length > 1" (click)="onBillToRemove(i)"><icon-trash-can color="#1e90ff"></icon-trash-can></button>
                <p class="p-extra-small">Bill To must not include semi colon (;)</p>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <button type="button" class="tylie-button tylie-button--xs tylie-button--link" (click)="onBillToAddMore()">
              <span class="tylie-button__icon"><icon-plus-circle color="#1e90ff"></icon-plus-circle></span>
              ADD MORE
            </button>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <button class="tylie-button tylie-button--xs tylie-button--icon me-3" type="submit"><span class="tylie-button__icon"><icon-save></icon-save></span><span class="tylie-button__text">Save</span></button>
        <button class="tylie-button tylie-button--xs tylie-button--link" type="button" (click)="closePopup()">Cancel</button>
      </div>
    </form>
  </div>
</div>
