import { Component, OnInit, ViewChild, ElementRef, HostListener, Input, NgZone, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Utilities } from '../../../services/core/utilities';
import { SpotService } from '../../../services/media/spot.service';
import { BasePopupComponent } from '../../popups/basepopup/basepopup.component';
import videojs from 'video.js';
import { IMedia } from '../../../models/videofile/videofile.model';

@Component({
  selector: 'js-player',
  templateUrl: './jsplayer.component.html',
  styleUrls: ['./jsplayer.component.css']
})
export class JsPlayerComponent extends BasePopupComponent implements OnInit {
  @ViewChild('videoPlayerJ', { static: false }) videoPlayer: ElementRef;
  @Input('videoFilesSource') videoFilesSource: Array<IMedia> = [];
  @Input('playListName') playListName: string;
  @Input('isPopup') isPopup: boolean = false;
  @Input('currentIndex') currentIndex: number;
  @Input('currentItem') currentItem: IMedia;

  public overyLayHeightP: number = 0;
  public is169OverlayRequired: boolean = false;
  public is430verlayRequired: boolean = false;
  public isOver169Overlay: boolean = false;
  public isOver43Overlay: boolean = false;
  public playerWidthClass: string = '';
  public smpteTimecode: string = '00:00:00:00';
  private player: any;
  public playListHeight: number = (window.innerHeight - 60);
  selectedOverlay: any = null;
  public proxyUrl = null;
  public videoPlayerInitialized = false;
  public fps: number | null = null;

  originalWidth: number = 640;
  originalHeight: number = 360;
  scaledWidth: number;
  scaledHeight: number;

  public overlayOptions = [
    { name: '4:3 Overlay', value: '43' },
    { name: '16:9 Overlay', value: '169' }
  ];

  constructor(ngZone: NgZone, private util: Utilities, private spotService: SpotService,
    private modalService: BsModalService, private cdr: ChangeDetectorRef) {
    super();
    window.onresize = (e) => {
      ngZone.run(() => {
        this.playListHeight = (window.innerHeight - 60);
        this.isOver169Overlay = false;
        this.isOver43Overlay = false;
      });
    };
  }

  ngOnInit() {
    if (this.currentItem.format?.toLowerCase() === 'audio') {
      this.is169OverlayRequired = false;
      this.is430verlayRequired = false;
    }
    else {
      this.is169OverlayRequired = this.util.is169OverlayRequired(this.currentItem.format);
      this.is430verlayRequired = this.util.is430verlayRequired(this.currentItem.format);
    }
    this.getProxyUrl();
    if (this.currentItem) {
      this.fps = this.currentItem.fps || null;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.videoFilesSource && changes.videoFilesSource.currentValue) {
      this.getProxyUrl();
      if (this.videoPlayer && !this.player) {
        this.initializeVideoJS();
      }
      if (changes.currentItem && changes.currentItem.currentValue) {
        this.fps = this.currentItem.fps || null;
      }
    }
  }

  ngAfterViewInit() {
    if (this.videoPlayer?.nativeElement) {
      this.initializeVideoJS();
    }
  }

  ngAfterViewChecked() {
    if (this.videoPlayer?.nativeElement && !this.videoPlayerInitialized) {
      this.initializeVideoJS();
      this.videoPlayerInitialized = true;
    }
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }

  private initializeVideoJS() {
    if (!this.videoPlayer?.nativeElement) {
      console.error('Video player element is not available.');
    }

    this.player = videojs(this.videoPlayer.nativeElement, {
      controls: true,
      autoplay: false,
      preload: true,
      poster: this.currentItem.poster,
      sources: [{ src: this.proxyUrl, type: 'video/mp4' }]
    });

    this.player.on('error', (error: any) => {
      console.error('Video.js Error:', error);
    });

    this.player.on('play', () => {
      if (this.currentItem.format?.toLowerCase() === 'audio') {
        const videoContainer = this.player.el();
        videoContainer.style.backgroundImage = `url(${this.currentItem.poster})`;
        videoContainer.style.backgroundSize = 'cover';
      }
    });

    this.player.on('mousemove', () => {
      this.player.getChild('controlBar').el().style.opacity = '1';
    });

    this.player.on('mouseleave', () => {
      this.player.getChild('controlBar').el().style.opacity = '0';
    });

    this.player.on('loadeddata', () => {
      this.smpteTimecode = this.util.formatSMPTETime(this.player.currentTime(), this.currentItem.fps);
      this.loadVideoMetadata();
    });

    this.player.on('timeupdate', () => {
      this.smpteTimecode = this.util.formatSMPTETime(this.player.currentTime(), this.currentItem.fps);
    });

    this.player.on('seeked', () => {
      this.smpteTimecode = this.util.formatSMPTETime(this.player.currentTime(), this.currentItem.fps);
    });

    this.player.on('ended', () => {
      this.isOver169Overlay = false;
      this.isOver43Overlay = false;
      this.selectedOverlay = null;
    });
  }

  private loadVideoMetadata() {
    if (!this.player) {
      console.error("Player is not initialized.");
      return;
    }

    this.originalWidth = this.player.videoWidth();
    this.originalHeight = this.player.videoHeight();

    this.calculateVideoSize();
  }

  private calculateVideoSize() {
    let width = this.originalWidth || 640;
    let height = this.originalHeight || 360;

    if (width > 675 || height > 675) {
      width = width / 2;
      height = height / 2;
    }

    this.scaledWidth = width;
    this.scaledHeight = height;

    const videoContainer = this.player.el();
    videoContainer.style.width = `${this.scaledWidth}px`;
    videoContainer.style.height = `${this.scaledHeight}px`;
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (!this.currentItem.fps || this.currentItem.fps <= 0) {
      return;
    }

    if (this.player) {
      const frameDuration = 1 / this.fps;
      event.preventDefault();

      if (event.key === 'ArrowLeft') {
        const newTime = Math.max(0, this.player.currentTime() - frameDuration);
        this.player.currentTime(newTime);
        this.util.formatSMPTETime(newTime, this.currentItem.fps);
      } else if (event.key === 'ArrowRight') {
        const newTime = Math.min(this.player.duration(), this.player.currentTime() + frameDuration);
        this.player.currentTime(newTime);
        this.smpteTimecode = this.util.formatSMPTETime(newTime, this.currentItem.fps);
      }
    }
  }

  private getProxyUrl() {
    this.spotService.getProxyUrl({ objectKey: this.currentItem.src }).subscribe((res: any) => {
      if (res.isSuccess === true) {
        this.currentItem.proxyUrl = res.result;
        this.proxyUrl = this.currentItem.proxyUrl;

        if (!this.player && this.videoPlayer?.nativeElement) {
          this.initializeVideoJS();
        } else {
          this.updateVideoSource();
        }
      } else {
        this.util.handleIsNotSuccess(res.errors);
      }
    }, error => {
      this.util.handleError(error);
    });
  }

  private updateVideoSource() {
    if (this.player) {
      this.player.src([{ src: this.currentItem.proxyUrl, type: 'video/mp4' }]);
      this.player.poster(this.currentItem.poster);
      this.player.load();
    }
  }

  public closePopup() {
    this.modalService.hide();
  }

  public onClickPlaylistItem(item: IMedia, index: number) {
    this.isOver169Overlay = false;
    this.isOver43Overlay = false;
    this.selectedOverlay = null;

    this.currentIndex = index;
    this.currentItem = item;

    this.is169OverlayRequired = this.util.is169OverlayRequired(this.currentItem.format);
    item.playthisone = true;

    this.getProxyUrl();
  }

  public toggleOverlayQ(overlayType: string) {
    if (overlayType === '169') {
      this.isOver43Overlay = false;
      this.isOver169Overlay = !this.isOver169Overlay;
    } else if (overlayType === '43') {
      this.isOver169Overlay = false;
      this.isOver43Overlay = !this.isOver43Overlay;
    } else {
      this.isOver43Overlay = false;
      this.isOver169Overlay = false;
    }

    this.overyLayHeightP = this.videoPlayer.nativeElement.offsetHeight;
  }
}
