import { Component, OnInit, ViewChild } from "@angular/core";
import { Subscription } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import { FormControl, FormGroup } from "@angular/forms";
import CustomStore from "devextreme/data/custom_store";
import { RightsManagement, RightsManagementSearchRequest } from "../../models/spot/rightsmanagement.model";
import { RightsManagementService } from "../../services/media/rightsmanagement.service";
import { Utilities } from "../../services/core/utilities";
import { GridParams } from "../../models/config/gridparams.model";
import { UserRoles } from '../../configurations/user-roles';
import { RouteConstants } from "../../configurations/route-constants";
import { Router } from "@angular/router";
import { GlobalService } from "../../services/core/global.service";
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { JsPlayerComponent } from "../videoplayer/videojs/jsplayer.component";
import { ConfigService } from "../../services/core/config.service";
import { AlertService } from "../../services/core/alert.service";
import { QuickplayerComponent } from "../videoplayer/quickplayer/quickplayer.component";

@Component({
  selector: 'rights-management',
  templateUrl: './rightsmanagement.component.html',
  styleUrls: ['./rightsmanagement.component.css'],
})
export class RightsManagementComponent implements OnInit {

  @ViewChild('rightsMgmtSearch', { static: true }) gridRightsmgmtSearch: DxDataGridComponent;

  public searchRequest: FormGroup;
  searchCriteriaObj = {} as RightsManagementSearchRequest;
  resultRightsMgmtStore: any = {};
  public scroll: boolean = false;
  public quickSearchValue: string = '';
  public modalRef: BsModalRef;
  public talentRightsConfig: any;
  public clientDataSource: any;
  public brandDataSource: any;
  public productDataSource: any;

  constructor(private rightsManagementService: RightsManagementService,
    private configService: ConfigService,
    public util: Utilities,
    public userRoles: UserRoles,
    private router: Router,
    private gs: GlobalService,
    private modalService: BsModalService,
    public alertService: AlertService  ) {


  }

  ngOnInit() {
    this.searchRequest = new FormGroup({
      adId: new FormControl(),
      title: new FormControl(),
      client: new FormControl(),
      brand: new FormControl(),
      product: new FormControl(),
      createdFrom: new FormControl(),
      createdTo: new FormControl(),
      expiredOnly: new FormControl(),
      activeOnly: new FormControl(),
      elementName: new FormControl(),
      elementType: new FormControl(),
      status: new FormControl(),
      assetsNotInTandem: new FormControl()
    });

    this.getConfig();

    this.searchRightsManagement();

    this.searchRequest.get('expiredOnly').valueChanges.subscribe(
      (expiredOnly: boolean) => {
        if (expiredOnly === true) {
          this.searchRequest.get('activeOnly').setValue(false);
          this.searchRequest.get('status').setValue(null);
        }
      });

    this.searchRequest.get('activeOnly').valueChanges.subscribe(
      (activeOnly: boolean) => {
        if (activeOnly === true) {
          this.searchRequest.get('expiredOnly').setValue(false);
          this.searchRequest.get('status').setValue(null);
        }
      });

    this.searchRequest.get('status').valueChanges.subscribe(
      (status: number) => {
        if (status > 0) {
          this.searchRequest.get('expiredOnly').setValue(false);
          this.searchRequest.get('activeOnly').setValue(false);
        }
      });
  }

  public gotoTranscodeFiles() {
    this.router.navigate([RouteConstants.transcodeFilesRoute]);
  }

  public onGotoMedia(section) {
    this.router.navigate([RouteConstants.mediaRoute], { queryParams: { t: section } });
  }


  public showDetail(id) {
    if (id == "00000000-0000-0000-0000-000000000000")
      return;

    this.gs.openSpotFile(id);
  }

  public quickPlay(proxy, title, thumbnail, format) {

    if (proxy == null || proxy == '')
      return;

    const initialState = {
      videoSource: proxy,
      thumbnail: thumbnail,
      title: title,
      format: format
    };

    this.modalRef = this.modalService.show(QuickplayerComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  public getConfig() {

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.configService.getTalentRightsConfig().subscribe((res: any) => {
      if (res.isSuccess == true) {

        this.talentRightsConfig = res.result;
       
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  searchRightsManagement() {
    var thisRef = this;
    var rightsManagementService = thisRef.rightsManagementService;
    var util = thisRef.util;
    this.resultRightsMgmtStore.store = new CustomStore({
      key: "projectid",
      load: function (loadOptions: any) {
        var skip = loadOptions.skip;
        var take = loadOptions.take;
        var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "[{'selector':'isBaseSpotActiveInTandem','desc':true}]";
        let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: false };
        let request: any = { searchCriteria: thisRef.searchCriteriaObj, GridParams: gridParams };

        return rightsManagementService.SearchRightsManagement(request)
          .toPromise()
          .then((response) => {
            if (response.isSuccess) {

              var records = response.result.records as RightsManagement[];

              if (records != null) {
                for (let s of records) {
                  if (s.isBaseSpotActiveInTandem) {
                    const format = s.format != null ? s.format.toLowerCase(): ''; 
                    const qcStatus = s.qcStatus != null ? s.qcStatus.toLowerCase() : ''; 

                    s.thumbnailUrl = util.getThumbnailUrl(
                      s.thumbnailUrl,
                      format === 'audio',
                      qcStatus === 'awaiting media',
                      qcStatus === 'creating media'
                    );
                    s.proxyUrl = util.getProxyUrl(s.proxyUrl);
                  } else {
                    s.thumbnailUrl = util.assetNotInTandemThumbnailUrl;
                  }
                }
              }

              var obj: any = {
                data: records,
                totalCount: response.result.totalCount
              }
              return obj;
            }
            else {
              alert("here");
              util.handleIsNotSuccess(response.errors);
              throw 'Data Loading Error';
            }
          })
          .catch((error) => {
            alert("error")
            console.log(error)
            util.handleIsNotSuccess(error);
            throw 'Data Loading Error';
          });
      }
    })

  }

  quickSearch() {
    if (this.quickSearchValue === null || this.quickSearchValue.trim() === '') {
      return;
    }
    this.searchCriteriaObj = {} as RightsManagementSearchRequest;
    this.searchCriteriaObj.quickSearch = this.quickSearchValue;
    this.refreshSearchResults(this.searchCriteriaObj, false, true);
  }

  onEnterQuickSearch(e: any) {
    this.quickSearch();
  }

  onAdvancedSearch() {
    const searchValues = this.searchRequest.value;
    this.searchCriteriaObj = { ...this.searchCriteriaObj, ...searchValues };
    this.searchCriteriaObj.quickSearch = '';
    this.refreshSearchResults(this.searchCriteriaObj, false, true);
  }

  clearSearch() {
    this.searchRequest.reset();
    this.searchCriteriaObj = {} as RightsManagementSearchRequest;
    this.quickSearchValue = "";
    this.refreshSearchResults(null, false, true);
  }

  clearQuickSearch() {
    if (this.quickSearchValue == '') {
      this.searchCriteriaObj = {} as RightsManagementSearchRequest;
      this.refreshSearchResults(null, false, true);
    }
  }

  onCellPrepared(e: any) {

    if (e.rowType === "data" && e.column.command === "expand") {

      if (e.data != null) {
        if (e.data.rmDetails.length == 0) {
          e.cellElement.removeClass("dx-datagrid-expand");
          e.cellElement.empty();
        }
      }
    }
  }

  private refreshSearchResults(obj: RightsManagementSearchRequest, scroll: boolean, isRefresh: boolean): void {
    if (obj != null) {
      this.searchCriteriaObj.adId = obj.adId;
      this.searchCriteriaObj.elementName = obj.elementName;
      this.searchCriteriaObj.elementType = obj.elementType;
      this.searchCriteriaObj.quickSearch = obj.quickSearch;
    }
    else {
      this.searchCriteriaObj.adId = null;
      this.searchCriteriaObj.elementName = null;
      this.searchCriteriaObj.elementType = null;
      this.searchCriteriaObj.quickSearch = null;
    }

    this.scroll = scroll;

    if (isRefresh) {
      this.gridRightsmgmtSearch.instance.refresh();
    }
  }

  ngOnDestroy() {
  }
}
